import React from "react";
import { Button } from "react-bootstrap";
import Dropzone from 'components/base/Dropzone';

interface UploadFilesProps {
    num: number;
    step: number;
    next: () => void;
    previous: () => void;
    uploadFiles: File[];
    setUploadFiles: (files: File[]) => void;
    loading: boolean;
}

const UploadFiles = (props: UploadFilesProps) => {
    const { num, step, next, previous, uploadFiles, setUploadFiles, loading } = props;

    let otherClass = 'pending';
    if (step === num) otherClass = 'current';
    if (step > num) otherClass = 'completed';

    const onDrop = (files: File[]) => {
        if (files.length > 0) {
            setUploadFiles(files);
        }
    }

    return (
        <div className={otherClass} data-kt-stepper-element="content">
            <div className="w-100">
                <div className="pb-6 pb-lg-6">
                    <h1 className="fw-bold text-dark fs-6">Upload Files</h1>
                    <div className="text-muted fw-semibold fs-7">If you need more info, please check
                    <a href="#" className="link-primary ms-1">Project Guidelines</a></div>
                </div>
                <Dropzone className="h-125px" onDrop={onDrop} />
                <div className="d-flex flex-stack mt-4">
                    <Button variant="outline-primary" size="lg" onClick={previous}>Previous</Button>
                    <Button variant="primary" size="lg" onClick={next} disabled={loading}>
                        Submit
                        {loading && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default UploadFiles;