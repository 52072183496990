import { uploadFile, uploadFiles } from 'hyper_helpers/api/file';
import { useFileManagerContext } from 'providers/FileManagerProvider';
import * as React from 'react';
import { toast } from 'react-toastify';
import { convertFileSize } from 'utils';

type AttachFileProps = {
  name: string;
  size: number;
  type?: string;
  lastModified?: Date;
  percent?: number;
  uploaded?: boolean;
  handleClose: () => void;
}

const AttachFileItem = ({name, size, percent = 0 , uploaded=false, handleClose }: AttachFileProps) => {

  

  return (
    <div className="dropzone-items w-100">
      <div className="dropzone-item p-2">
        <div className="dropzone-file">
          <div className="dropzone-filename text-dark" title="some_image_file_name.jpg">
            <span data-dz-name="">{name}</span>
            <strong>(
            <span data-dz-size="">{convertFileSize(size)}</span>)</strong>
          </div>
          <div className="dropzone-error mt-0" data-dz-errormessage=""></div>
        </div>
        {!uploaded && (
          <div className="dropzone-progress">
            <div className="progress bg-gray-300">
              <div className="progress-bar bg-primary" role="progressbar" style={{width: `${percent}%`}}></div>
            </div>
          </div>
        )}
        <div className="dropzone-toolbar">
          <span className="dropzone-delete" data-dz-remove="" onClick={() => handleClose()}>
            <i className="ki-duotone ki-cross fs-6">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </span>
        </div>
      </div>
    </div>
  )
}

const UploadModal = () => {

  const query = new URLSearchParams(window.location.search);
  const [percent, setPercent] = React.useState(0);
  const [uploaded, setUploaded ] = React.useState(false);
  const [index, setIndex] = React.useState<number | null>();
  const folderId = query.get('id');

  const { setModalState } = useFileManagerContext();
  const [files, setFiles] = React.useState<File[]>([]);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const fileInputTrigger = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  }

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const attaches = e.target.files;
      let _files: File[] = [];
      for(let i = 0; i < attaches.length; i++) {
        _files.push(attaches[i]);
      }
      setFiles((prev) => [...prev, ..._files]);
    }
  }

  const closeModal = () => {
    setModalState(null);
  }

  const removeItem = (index: number) => {
    const _files = [...files];
    _files.splice(index, 1);
    setFiles(_files);
  }

  const handleUpload = () => {
    uploadFiles({folder_id: folderId, files: files, 
      uploadHandle: (progressEvent: any) => {
        if(progressEvent.total){
          setPercent(Math.round(
            (90 * progressEvent.loaded) / progressEvent.total
          ));
        }
      }, 
      downloadHandle: (progressEvent: any) => {
        if(progressEvent.total){
					setPercent(Math.round(
						(100 * progressEvent.loaded) / progressEvent.total
					));
				}
      }
    })
      .then((resp: any) => {
        if(resp.status === 201) {
          toast.success('File uploaded successfully.');
          setUploaded(true);
        }
      })
  };

  return (
    <>
      <div className="modal-backdrop fade show"></div>
      <div className="modal fade show" id="kt_modal_upload" tabIndex={-1} aria-hidden="true" style={{display: 'block'}}>
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <form className="form" action="none" id="kt_modal_upload_form">
              <div className="modal-header">
                <h2 className="fw-bold fs-7">Upload files</h2>
                <div onClick={() => closeModal()} className="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal">
                  <i className="ki-duotone ki-cross fs-6">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </div>
              </div>
              <div className="modal-body pt-4 pb-4 px-6">
                <div className="form-group">
                  <div className="dropzone dropzone-queue mb-2" id="kt_modal_upload_dropzone" 
                    style={{alignItems: 'start', display: 'flex', flexDirection: 'column'}}
                  >
                    <div className="dropzone-panel mb-2">
                      <a
                        onClick={() => fileInputTrigger()}
                        className="dropzone-select btn btn-sm btn-primary me-2">
                        Attach files
                      </a>
                      <input ref={fileInputRef} className="d-none" type='file' multiple onChange={(e) => handleFileInputChange(e)} />
                      {(files && files.length > 0) && (
                        <>
                          <a
                            onClick={() => handleUpload()} 
                            className="dropzone-upload btn btn-sm btn-outline-primary me-2">
                            Upload All
                          </a>
                          <a
                            onClick={() => setFiles([])}
                            className="dropzone-remove-all btn btn-sm btn-outline-primary">
                            Remove All
                          </a>
                        </>
                      )}
                    </div>
                    {files.map((file, idx) => {
                      return (
                        <AttachFileItem 
                          key={idx} 
                          name={file.name} 
                          size={file.size}
                          percent={percent}
                          uploaded={uploaded}
                          handleClose={() => {
                            removeItem(idx)
                          }}
                        />
                      )
                    })}
                  </div>
                  <span className="form-text fs-8 text-muted">Max file size is 1MB per file.</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default UploadModal;