import { Card, Row, Col, Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useToggle } from "hyper_hooks";
import profileImg from "assets/hyper/images/users/avatar-3.jpg";
import EditUser from "./EditUser";

const UserBox = () => {
  const [isopen, , show, hide] = useToggle();

  const personDetails = {
   ID: '45453423',
   Email: 'info@keenthemes.com',
   Address: '101 Collin Street, Melbourne 3000 VIC Australia',
   Language: 'English',
   LastLogin: '25 Oct 2023, 5:20 pm'
  }

  return (
    <Card className="text-center">
      <Card.Body>
        <img
          src={profileImg}
          className="rounded-circle avatar-lg img-thumbnail"
          alt=""
        />
        <h4 className="mb-0 mt-2">Dominic Keller</h4>
        <p className="badge mt-3 fs-9 badge-light-primary">Administrator</p>
        <h5 className="mb-4 mt-2">Assigned Tickets</h5>
        <Row className="d-flex justify-content-center mt-2">
          <Col>
            <div className="border-dashed border-2 border rounded">
              <h4 className="mb-0 mt-2">
                243<i className="mdi mdi-arrow-up text-success"></i>
              </h4>
              <p className="text-muted font-14">Total</p>
            </div>
          </Col>
          <Col>
            <div className="border-dashed border-2 border rounded">
              <h4 className="mb-0 mt-2">
                56<i className="mdi mdi-arrow-down text-danger"></i>
              </h4>
              <p className="text-muted font-14">Solved</p>
            </div>
          </Col>
          <Col>
            <div className="border-dashed border-2 border rounded">
              <h4 className="mb-0 mt-2">
                188<i className="mdi mdi-arrow-up text-success"></i>
              </h4>
              <p className="text-muted font-14">Open</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Detail</Accordion.Header>
                <Accordion.Body>
                  <div>
                    <h5>Account ID</h5>
                    <p className="text-muted">{personDetails.ID}</p>
                  </div>
                  <div>
                    <h5>Email</h5>
                    <p className="text-muted">{personDetails.Email}</p>
                  </div>
                  <div>
                    <h5>Address</h5>
                    <p className="text-muted">{personDetails.Address}</p>
                  </div>
                  <div>
                    <h5>Language</h5>
                    <p className="text-muted">{personDetails.Language}</p>
                  </div>
                  <div>
                    <h5>LastLogin</h5>
                    <p className="text-muted">{personDetails.LastLogin}</p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col className="mt-4">
            <Button variant="outline-info" onClick={show}>
              Edit
            </Button>
          </Col>
        </Row>
      </Card.Body>
      <EditUser show={isopen} handleClose={hide} />
    </Card>
  );
};

export default UserBox;
