import {
    ICheckItem,
    INavItem,
    ISocialLogin,
    ILoginSession,
    ILog,
} from "./types";
import GoogleSvg from "assets/images/brands/google-icon.svg";
import SpecSvg from "assets/images/brands/spec-icon.svg";
import SlackSvg from "assets/images/brands/slack-icon.svg";
import MailChimpSvg from "assets/images/brands/mailchimp-icon.svg";
import GoogleWebDevSvg from "assets/images/brands/google-webdev-icon.svg";
import DropboxSvg from "assets/images/brands/dropbox-icon.svg";
import AmazonSvg from "assets/images/brands/amazon.svg";

export const navItems: INavItem[] = [
    {
        title: "Organisation information",
        icon: "bi-building",
        link: "#organisationInformation",
    },
    {
        title: "Organisation Structure",
        icon: "bi-building-gear",
        link: "#organisationStructure",
    },
    {
        title: "Super Aadmin information",
        icon: "bi-person",
        link: "#superAdminInformation",
    },
    {
        title: "Email",
        icon: "bi-mailbox",
        link: "#email",
    },
    {
        title: "Password",
        icon: "bi-key",
        link: "#password",
    },
    {
        title: "Preferences",
        icon: "bi-sliders",
        link: "#preferences",
    },
    {
        title: "Two-step verification",
        icon: "bi-shield-lock",
        link: "#twoStepVerification",
    },
    {
        title: "Product Configuration",
        icon: "bi-cpu",
        link: "#productConfiguration",
    },
    {
        title: "Bot Settings",
        icon: "bi-gear",
        link: "#botSettings",
    },
    {
        title: "Recent devices",
        icon: "bi-phone",
        link: "#recentDevices",
    },
    {
        title: "Organisation Notifications",
        icon: "bi-bell",
        link: "#organisationNotifications",
    },
    {
        title: "Organisation Newsletters",
        icon: "bi-envelope",
        link: "#organisationNewsletters",
    },
    {
        title: "Connected accounts",
        icon: "bi-diagram-3",
        link: "#connectedAccounts",
    },
    {
        title: "Audit Logs",
        icon: "bi-card-list",
        link: "#auditLogs",
    },
    {
        title: "Social Login",
        icon: "bi-instagram",
        link: "#socialLogin",
    },
    {
        title: "Deactivate account",
        icon: "bi-trash",
        link: "#deactivateAccount",
    },
];

export const connectedAccounts: ICheckItem[] = [
    {
        title: "Google Workspace",
        description: "Calendar and contacts",
        svg: GoogleSvg,
    },
    {
        title: "Spec",
        description: "Project management",
        svg: SpecSvg,
    },
    {
        title: "Slack",
        description: "Communication",
        learnMore: "#",
        svg: SlackSvg,
    },
    {
        title: "Mailchimp",
        description: "Email marketing service",
        svg: MailChimpSvg,
    },
    {
        title: "SharePoint",
        description: "Cloud Storage",
        svg: GoogleSvg,
    },
    {
        title: "Box",
        description: "Calendar and contacts",
        svg: GoogleSvg,
    },
    {
        title: "One Drive",
        description: "Cloud Storage",
        svg: GoogleSvg,
    },
    {
        title: "Oracle Cloud",
        description: "Cloud Storage",
        svg: GoogleSvg,
    },
    {
        title: "IBM Cloud",
        description: "Cloud Storage",
        svg: GoogleSvg,
    },
    {
        title: "Apple iCloud",
        description: "Cloud Storage",
        svg: GoogleSvg,
    },
    {
        title: "Amazon S3",
        description: "Cloud Storage",
        svg: AmazonSvg,
    },
    {
        title: "Azure Cloud",
        description: "Cloud Storage",
        svg: GoogleSvg,
    },
    {
        title: "Dropbox",
        description: "Cloud Storage",
        svg: DropboxSvg,
    },
    {
        title: "MS Active Directory",
        description: "Cloud Storage",
        svg: GoogleSvg,
    },
    {
        title: "Red Hat Identity Management",
        description: "IAM & User Management",
        svg: GoogleSvg,
    },
    {
        title: "FreeIPA",
        description: "IAM & User Management",
        svg: GoogleSvg,
    },
    {
        title: "Samba",
        description: "IAM & User Management",
        svg: GoogleSvg,
    },
    {
        title: "Zentyal",
        description: "IAM & User Management",
        svg: GoogleSvg,
    },
    {
        title: "JumpCloud",
        description: "IAM & User Management",
        svg: GoogleSvg,
    },
    {
        title: "Open LDAP",
        description: "IAM & User Management",
        svg: GoogleSvg,
    },
    {
        title: "OneLogin",
        description: "IAM & User Management",
        svg: GoogleSvg,
    },
    {
        title: "Okta",
        description: "IAM & User Management",
        svg: GoogleSvg,
    },
    {
        title: "Google Webdev",
        description: "Tools for Web Developers",
        learnMore: "#",
        svg: GoogleWebDevSvg,
    },
];

export const socialLogin: ISocialLogin[] = [
    {
        icon: "bi-twitter",
        name: "Twitter",
        link: "www.twitter.com/htmlstream",
        href: "#",
    },
    {
        icon: "bi-facebook",
        name: "Apple",
        link: "www.apple.com",
        href: "#",
    },
    {
        icon: "bi-facebook",
        name: "Facebook",
        link: "www.facebook.com/htmlstream",
        href: "#",
    },
    {
        icon: "bi-slack",
        name: "Microsoft",
    },
    {
        icon: "bi-linkedin",
        name: "Linkedin",
        link: "www.linkedin.com/htmlstream",
        href: "#",
    },
    {
        icon: "bi-google",
        name: "Google",
    },
];

export const loginSession: ILoginSession[] = [
    {
        location: "USA(5)",
        status: "OK",
        device: "Chrome - Windows",
        ip_address: "236.125.56.78",
        time: "2 mins ago",
    },
    {
        location: "United Kingdom(10)",
        status: "OK",
        device: "Safari - Mac OS",
        ip_address: "236.125.56.78",
        time: "10 mins ago",
    },
    {
        location: "Norway(-)",
        status: "ERR",
        device: "Firefox - Windows",
        ip_address: "236.125.56.78",
        time: "20 mins ago",
    },
    {
        location: "Japan(112)",
        status: "OK",
        device: "iOS - iPhone Pro",
        ip_address: "236.125.56.78",
        time: "30 mins ago",
    },
    {
        location: "Italy(5)",
        status: "WRN",
        device: "Samsung Noted 5- Android",
        ip_address: "236.125.56.78",
        time: "40 mins ago",
    },
];

export const logs: ILog[] = [
    {
        status: "404 WRN",
        action: "POST /v1/customer/c_64b52c09842dc/not_found",
        time: "20 Jun 2023, 9:23 pm",
    },
    {
        status: "500 ERR",
        action: "POST /v1/invoice/in_6325_4920/invalid",
        time: "20 Jun 2023, 9:23 pm",
    },
    {
        status: "200 OK",
        action: "POST /v1/customer/c_64b52c09842dc/not_found",
        time: "20 Jun 2023, 9:23 pm",
    },
    {
        status: "200 OK",
        action: "POST /v1/customer/c_64b52c09842dc/not_found",
        time: "20 Jun 2023, 9:23 pm",
    },
    {
        status: "200 OK",
        action: "POST /v1/customer/c_64b52c09842dc/not_found",
        time: "20 Jun 2023, 9:23 pm",
    },
    {
        status: "404 WRN",
        action: "	POST /v1/customer/c_64b52c09842dc/not_found",
        time: "20 Jun 2023, 9:23 pm",
    },
];

export const introMsgData: string[] = [
    `Welcome [Council name]. My name is [Bot name], and I specialise in [Entity name]. You can me ask me questions like [Inherit questions from the team], or any question related to Entity name]. How can I help?`,
    `Welcome [Council name]. Staff Support Chat. My name is [Bot name], and I specialise in [Entity name]. You can me ask me questions like [Inherit questions from the team], or any question related to Entity name]. How can I help?`,
    `Welcome [Council name]. ‘Calls are recorded, press 1 if you agree, press 2 if you. do not agree [If they press 2 then we tell them they need to use the chat bot service on the website]. Thank you for confirming your ability for us to record the call. My name is [Bot name], and I specialise in [Entity name]. You can me ask me questions like [Inherit questions from the team], or any question related to Entity name]. How can I help?`,
    `Welcome [Council name]. Staff hotline. ‘Calls are recorded, press 1 if you agree, press 2 if you. do not agree [If they press 2 then we tell them they need to use the chat bot service on the website]. Thank you for confirming your ability for us to record the call. My name is [Bot name], and I specialise in [Entity name]. You can me ask me questions like [Inherit questions from the team], or any question related to Entity name]. How can I help?`,
];
