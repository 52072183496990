import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import NarrowDown from 'components/icons/NarrowDown';
import { Card, Dropdown } from 'react-bootstrap';
import { useChatContext } from 'providers/ChatProvider';
import classNames from 'classnames';
import { useMemo } from 'react';
import { EventKey } from 'picmo';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import ChatSearchResult from 'components/support-chat/ChatSearchResult';
import CloseIcon from 'components/icons/CloseIcon';

const ChatContentHeader = ({ msgContent = [], viewMsgDetail }: any) => {
  const navigate = useNavigate();
  const {
    currentConversation,
    setShowConversationDetails,
    setShowUserListOffcanvas,
    setFeedBackModalState,
    setFeedBackModalStep,
    setLanguage,
    setMenuOption,
    menuOption,
    language,
    addNewChat
  } = useChatContext();

  const firstName = useMemo(() => {
    return currentConversation?.user.name.split(' ')[0] || '';
  }, [currentConversation]);

  React.useEffect(() => {
    if(navigator.language) {
      setLanguage(navigator.language.split('-')[1]);
    }
  }, []);

  const openLanguageModal = () => {
    setFeedBackModalState(true);
    setFeedBackModalStep('language');
  }

  const openMenu = (e: EventKey<any>) => {
    switch (e) {
      case 'search':
        setMenuOption(e);
        break;
      case 'feedback':
        setFeedBackModalState(true);
        setFeedBackModalStep('share_alert');
        break;
      case 'techSupport':
        addNewChat({
          id: 101,
          user: {
            id: 101,
            status: "online",
            name: 'Technical Support1',
            bot: true,
          },
          messages: []
        });
        navigate(`/apps/chat/${101}/conversation`);
        break;
      case 'customSupport':
        addNewChat({
          id: 102,
          user: {
            id: 102,
            status: "online",
            name: 'Customer Support1',
            bot: true,
          },
          messages: []
        });
        navigate(`/apps/chat/${102}/conversation`);
        break;
      default:
        break;
    }
  }

  return (
    <>
      {menuOption === 'search' && (
        <div className='chat-history-search position-absolute d-flex border-bottom align-items-center mt-2 py-3 px-5 top-0 start-0 w-100 bg-white'>
          <DropdownSearchBox
            className="navbar-top-search-box"
            inputClassName="rounded-pill"
            size="sm"
          >
            <ChatSearchResult messages={msgContent} handleItemClick={viewMsgDetail}/>
          </DropdownSearchBox>
          <div className='position-absolute h-100 me-2 top-0 end-0 d-flex justify-content-center align-items-center'>
            <Button className='p-0' onClick={() => setMenuOption('')}>
              <CloseIcon color="black"/>
            </Button>
          </div>
        </div>
      )}
      {currentConversation && (
        <>
          <Card.Header className="p-3 p-md-4 d-flex flex-between-center">
            <Button className='d-flex p-0 align-items-center' onClick={() => openLanguageModal()}>
              <div className='rounded-pill widget-header-flag' style={{backgroundImage: `url('http://purecatamphetamine.github.io/country-flag-icons/3x2/` + `${language}` + `.svg')`, width: 28, height: 28}}>
              </div>
              <NarrowDown color="black"/>
            </Button>
            <div className="d-flex align-items-center">
              <Button
                className="ps-0 pe-2 text-700 d-sm-none"
                onClick={() => setShowUserListOffcanvas(true)}
              >
                <FontAwesomeIcon icon="chevron-left" />
              </Button>
              <div className="d-flex flex-column flex-md-row align-items-md-center">
                <Button
                  className="fs-7 fw-semi-bold text-1100 d-flex align-items-center p-0 me-3 text-start"
                  onClick={() => setShowConversationDetails(true)}
                >
                  <span>{firstName}</span>
                  <FontAwesomeIcon icon="chevron-down" className="ms-2 fs-10" />
                </Button>
                <p className="fs-9 mb-0 me-2">
                  <FontAwesomeIcon
                    icon="circle"
                    className={classNames('fs-11 me-2', {
                      'text-success':
                        currentConversation.user.status === 'online',
                      'text-300': currentConversation.user.status === 'offline'
                    })}
                  />
                  {currentConversation.user.status === 'online'
                    ? 'Active now'
                    : 'Offline'}
                </p>
              </div>
            </div>
            <div className="d-flex">
              <Button variant="primary" className="btn-icon me-1">
                <FontAwesomeIcon icon="phone" />
              </Button>
              <Button variant="primary" className="btn-icon me-1">
                <FontAwesomeIcon icon="video" />
              </Button>

              <Dropdown className='position-static' onSelect={openMenu}>
                <Dropdown.Toggle
                  variant="phoenix-primary"
                  className="btn-icon dropdown-caret-none"
                >
                  <FontAwesomeIcon icon="ellipsis-vertical" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="py-2">
                  <Dropdown.Item eventKey="1">Request call back</Dropdown.Item>
                  <Dropdown.Item eventKey="search">Search in chat</Dropdown.Item>
                  <Dropdown.Item eventKey="customSupport">Customer Services</Dropdown.Item>
                  <Dropdown.Item eventKey="techSupport">Technical Support</Dropdown.Item>
                  <Dropdown.Item eventKey="feedback">Feedback</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Card.Header>
        </>
      )}
    </>
  );
};

export default ChatContentHeader;
