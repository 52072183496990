import { Modal, Button, Accordion } from "react-bootstrap";
import Select from "react-select";
import { VerticalForm, FormInput } from "hyper_components";
import DatePicker from "components/base/DatePicker";
import ReactSelect from "react-select";
type AddScheduleProps = {
  show: boolean;
  handleClose: () => void;
};

const AddSchedule = ({ show, handleClose }: AddScheduleProps) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title as="h4">Add an Event</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-5">
        <VerticalForm onSubmit={() => {}}>
          <h5>Event Name</h5>
          <FormInput
            type="text"
            name="name"
            className="py-2 fs-8"
            containerClass={"mb-3"}
          />
          <h5>Date & Time</h5>
          <DatePicker
            options={{
              enableTime: true,
              dateFormat: "M j, Y H:i",
            }}
            style={{ width: "100%" }}
            placeholder="Select Date"
          />
          <h5>Event Organiser</h5>
          <FormInput
            type="text"
            name="organiser"
            className="py-2 fs-8"
            containerClass={"mb-3"}
          />
          <h5>Send Event Details To</h5>
          <ReactSelect
            options={[
              {
                value: "adfasd@gmail.com",
                label: "adfasd@gmail.com",
              },
              {
                value: "qwerd@gmail.com",
                label: "qwerd@gmail.com",
              },
              {
                value: "asdfqe@gmail.com",
                label: "asdfqe@gmail.com",
              },
              {
                value: "vadqe@gmail.com",
                label: "vadqe@gmail.com",
              },
            ]}
            isMulti
            placeholder="Select Email"
            required
          />
        </VerticalForm>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Save Event
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSchedule;
