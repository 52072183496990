import Button from "components/base/Button";
import { ChangeEvent, useState } from "react";
import SearchBox from "components/common/SearchBox";
import { teams, users } from "data/team/team";
import useAdvanceTable from "hooks/useAdvanceTable";
import { teamListBreadcrumbItems } from "data/commonData";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import UsersTable, {
  assignedTeamsTablecolumns,
} from "components/tables/TeamTable";
import NewProject from "../../common/NewProject";
import Header from "../../common/Header";
import { Col, Container, Row } from "react-bootstrap";

const cardItmes = {
  title: "Administrator",
  totalUser: 5,
  items: [
    "All Admin Controls",
    "View and Edit Financial Summaries",
    "Enabled Bulk Reports",
    "View and Edit Payouts",
    "View and Edit Disputes",
    "View and Edit Disputes",
    "View and Edit Disputes",
    "View and Edit Disputes",
    "View and Edit Disputes",
    "View and Edit Disputes",
    "View and Edit Disputes",
    "View and Edit Disputes",
  ],
};

const RoleView = () => {
  const [isOpen, setIsOpen] = useState(false);
  const table = useAdvanceTable({
    data: users,
    columns: assignedTeamsTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
  });
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const location = window.location.pathname;
  console.log("location :>> ", location);
  return (
    <div>
      <div className="mb-9">
        <Header title="View Role" BreadcrumbItems={teamListBreadcrumbItems} />
        <Container>
          <Row>
            <Col sm={4}>
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">{cardItmes.title}</h3>
                  <div className="d-flex flex-column text-gray-600">
                    {cardItmes.items.map((list, index) => {
                      if (index > 4) {
                        return;
                      }
                      return (
                        <div className="d-flex align-items-center py-2">
                          <i className="bi bi-dash"></i>
                          {list}
                        </div>
                      );
                    })}
                    <div className="d-flex align-items-center py-2">
                      {!(cardItmes.items.length <= 5) && <></>}
                      {cardItmes.items.length > 5 && (
                        <>
                          <i className="bi bi-dash"></i>
                          <em>and {cardItmes.items.length - 5} more...</em>
                        </>
                      )}
                    </div>
                    <div className="d-flex justify-content-start mt-2 gap-2">
                      <Button variant="soft-secondary">Edit Role</Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={8}>
              <AdvanceTableProvider {...table}>
                <div className="bg-white p-4">
                  <div className="d-flex justify-content-between">
                    <SearchBox
                      placeholder="Search customers"
                      onChange={handleSearchInputChange}
                    />
                  </div>
                  <UsersTable />
                </div>
              </AdvanceTableProvider>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default RoleView;
