import { Modal, Button, Accordion } from 'react-bootstrap';
import Select from 'react-select';
import { VerticalForm, FormInput } from 'hyper_components';
import Avatar from 'components/base/Avatar';
import profileImg from 'assets/hyper/images/users/avatar-3.jpg';
type EditUserProps = {
  show: boolean;
  handleClose: () => void;
};

const EditUser = ({ show, handleClose }: EditUserProps) => {
  return (
      <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
              <Modal.Title as="h5">Update User Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0" className="border-top border-300">
                  <Accordion.Header>User Information</Accordion.Header>
                  <Accordion.Body className="pt-0">
                    <Avatar src={profileImg} size="xl" />
                    <VerticalForm onSubmit={() => {}}>
                        <FormInput
                            type="text"
                            label="Name"
                            name="name"
                            placeholder="Enter name..."
                            containerClass={'mb-3'}
                        />
                        <FormInput
                            type="email"
                            label="Email"
                            name="email"
                            placeholder="Enter email..."
                            containerClass={'mb-3'}
                        />
                        <FormInput
                            type="textarea"
                            label="Description"
                            name="description"
                            rows="4"
                            containerClass={'mb-3'}
                        />
                    </VerticalForm>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="border-top border-300">
                  <Accordion.Header>Address Details</Accordion.Header>
                  <Accordion.Body className="pt-0">
                    <VerticalForm onSubmit={() => {}}>
                        <FormInput
                            type="text"
                            label="Name"
                            name="name"
                            placeholder="Enter name..."
                            containerClass={'mb-3'}
                        />
                        <FormInput
                            type="email"
                            label="Email"
                            name="email"
                            placeholder="Enter email..."
                            containerClass={'mb-3'}
                        />
                        <FormInput
                            type="textarea"
                            label="Description"
                            name="description"
                            rows="4"
                            containerClass={'mb-3'}
                        />
                    </VerticalForm>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="light" onClick={handleClose}>
                  Close
              </Button>
              <Button variant="primary" onClick={handleClose}>
                  Save Task
              </Button>
          </Modal.Footer>
      </Modal>
  );
};

export default EditUser;