import ToolTip from "craft_components/Tooltip";
import { Settings, SettingsError } from "./types";
import { Form } from "react-bootstrap";
interface AccountSettingsProps {
    num: number;
    step: number;
    settings: Settings;
    setSettings: (settings: Settings) => void;
    settingsError: SettingsError
}

const AccountSettings = (props: AccountSettingsProps) => {
    const { num, step, settings, setSettings, settingsError } = props;

    let otherClass = 'pending';
    if (step === num) otherClass = 'current';
    if (step > num) otherClass = 'completed';

    return (
        <div className={otherClass} data-kt-stepper-element="content">
            <div className="w-100">
                <div className="pb-5 pb-lg-7">
                    <h2 className="fw-bold text-dark fs-6">Account Settings</h2>
                    <div className="text-muted fw-semibold fs-8">
                        If you need more info, please visit the
                        <a href="https://localgovai.uk/help" className="link-primary fw-bold ms-1">Help Page</a>.
                    </div>
                </div>
                <div className="mb-5 fv-row">
                    <Form.Group className="mb-5">
                        <Form.Label className="fs-8 mb-3 ps-0 text-notrans">Domain Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={settings.domainName}
                            size="lg"
                            onChange={e => setSettings({...settings, domainName: e.target.value})}
                            isInvalid={settingsError.domainName}
                        />
                        <Form.Control.Feedback type="invalid">Domain Name is required</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-5">
                        <Form.Label className="fs-8 mb-3 ps-0 text-notrans">Confirm domain name</Form.Label>
                        <Form.Control
                            type="text"
                            value={settings.domainConfirm}
                            size="lg"
                            onChange={e => setSettings({...settings, domainConfirm: e.target.value})}
                            isInvalid={settingsError.domainConfirm}
                        />
                        <Form.Control.Feedback type="invalid">Domain Name is not match</Form.Control.Feedback>
                        <div className="form-text">Enter your organisation's domain so colleagues can join you</div>
                    </Form.Group>
                    <label className="d-flex align-items-center form-label fs-8 mb-3 ps-0 text-notrans">
                        Team Size
                        <ToolTip position="top" content="How many people do you collaborate with" />
                    </label>
                    <div className="row mb-2" data-kt-buttons="true">
                        <div className="col">
                            <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4 ${settings.teamSize === '1-1' ? 'active' : ''}`}
                                onClick={() => setSettings({...settings, teamSize: '1-1'})}
                            >
                                <span className="fw-bold fs-7">1-1</span>
                            </label>
                        </div>
                        <div className="col">
                            <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4 ${settings.teamSize === '2-10' ? 'active' : ''}`}
                                onClick={() => setSettings({...settings, teamSize: '2-10'})}
                            >
                                <span className="fw-bold fs-7">2-10</span>
                            </label>
                        </div>
                        <div className="col">
                            <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4 ${settings.teamSize === '10-50' ? 'active' : ''}`}
                                onClick={() => setSettings({...settings, teamSize: '10-50'})}
                            >
                                <span className="fw-bold fs-7">10-50</span>
                            </label>
                        </div>
                        <div className="col">
                            <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4 ${settings.teamSize === '50+' ? 'active' : ''}`}
                                onClick={() => setSettings({...settings, teamSize: '50+'})}
                            >
                                <span className="fw-bold fs-7">50+</span>
                            </label>
                        </div>
                    </div>
                    <div className="form-text">How how many people are in your team?</div>
                </div>
                <div className="mb-0 fv-row">
                    <label className="d-flex align-items-center form-label fs-8 mb-5 ps-0 text-notrans">
                        Account Plan
                        <ToolTip position="top" content="The duration of your trial period will be determined by your account type" />
                    </label>
                    <div className="mb-0">
                        <label className="d-flex justify-content-between align-items-center mb-5 cursor-pointer"
                            onClick={() => setSettings({...settings, accPlan: "organisation"})}
                        >
                            <span className="d-flex align-items-center me-2">
                                <span className="symbol symbol-50px me-6">
                                    <span className="symbol-label">
                                        <i className="ki-duotone ki-bank fs-1 text-gray-600">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>
                                    </span>
                                </span>
                                <span className="d-flex flex-column">
                                    <span className="fw-bold text-gray-800 text-hover-primary fs-8">Organisation Account</span>
                                    <span className="fs-9 fw-semibold text-muted">An account for the entire organisation</span>
                                </span>
                            </span>
                            <span className="form-check form-check-custom form-check-solid">
                                <input className="form-check-input" type="radio" name="account_plan" value="organisation" defaultChecked />
                            </span>
                        </label>
                        <label className="d-flex justify-content-between align-items-center mb-5 cursor-pointer"
                            onClick={() => setSettings({...settings, accPlan: "team"})}
                        >
                            <span className="d-flex align-items-center me-2">
                                <span className="symbol symbol-50px me-6">
                                    <span className="symbol-label">
                                        <i className="ki-duotone ki-chart fs-1 text-gray-600">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>
                                    </span>
                                </span>
                                <span className="d-flex flex-column">
                                    <span className="fw-bold text-gray-800 text-hover-primary fs-8">Team Account</span>
                                    <span className="fs-9 fw-semibold text-muted">Built for teams</span>
                                </span>
                            </span>
                            <span className="form-check form-check-custom form-check-solid">
                                <input className="form-check-input" type="radio" name="account_plan" value="team" />
                            </span>
                        </label>
                        <label className="d-flex justify-content-between align-items-center mb-0 cursor-pointer"
                            onClick={() => setSettings({...settings, accPlan: "sprint"})}
                        >
                            <span className="d-flex align-items-center me-2">
                                <span className="symbol symbol-50px me-6">
                                    <span className="symbol-label">
                                        <i className="ki-duotone ki-chart-pie-4 fs-1 text-gray-600">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                            <span className="path3"></span>
                                        </i>
                                    </span>
                                </span>
                                <span className="d-flex flex-column">
                                    <span className="fw-bold text-gray-800 text-hover-primary fs-8">Sprint Account</span>
                                    <span className="fs-9 fw-semibold text-muted">For startups, Incubators & Accelerators</span>
                                </span>
                            </span>
                            <span className="form-check form-check-custom form-check-solid">
                                <input className="form-check-input" type="radio" name="account_plan" value="sprtint" />
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountSettings;