import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from 'components/base/Button';
import { Form } from 'react-bootstrap';
import {forgotPasswordConfirm} from "hyper_helpers/api/auth";
import { toast } from 'react-toastify';
import {resetPassword} from "hyper_helpers/api/auth";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { uid, token } = useParams();
  const [newData, setNewData] = useState({
    user_id: '',
    org_id: '',
    password: ''
  });
  const [confirmPwd, setConfirmPwd] = useState<string>("");
  const [invalid, setInvalid] = useState({
    password: false,
    confirmPwd: false
  });

  useEffect(() => {
    if (uid && token) {
      forgotPasswordConfirm(uid, token)
        .then((res) => {
          setNewData({...newData, user_id: res.data.user_id, org_id: res.data.org_id});
          toast.success('Email confirmed.');
        })
        .catch(err => toast.warn(err));
    }
  }, []);

  const handleReset = () => {
    setInvalid({password: false, confirmPwd: false});
    if (newData.password === "") {
      setInvalid({...invalid, confirmPwd: false});
      return;
    }
    if (newData.password !== "" && newData.password !== confirmPwd) {
      setInvalid({...invalid, confirmPwd: true});
      return;
    }

    resetPassword(newData)
      .then(res => {
        toast.success(res.data.msg);
        navigate('/login');
      })
      .catch(err => toast.warn(err));
  }

  return (
    <div>
      <div className="text-center mb-6">
        <h4 className="text-1000">Reset new password</h4>
        <p className="text-700">Type your new password</p>
        <Form className="mt-5">
          <Form.Group className="mb-2">
            <Form.Control
              id="password"
              type="password"
              placeholder="Type new password"
              value={newData.password}
              onChange={e => setNewData({...newData, password: e.target.value})}
              isInvalid={invalid.password}
            />
            <Form.Control.Feedback type='invalid'>Password is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Control
              id="confirmPassword"
              type="password"
              placeholder="Cofirm new password"
              value={confirmPwd}
              onChange={e => setConfirmPwd(e.target.value)}
              isInvalid={invalid.confirmPwd}
            />
            <Form.Control.Feedback type='invalid'>Password is not match</Form.Control.Feedback>
          </Form.Group>
          <Button variant="primary" className="w-100" onClick={handleReset}>
            Set Password
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
