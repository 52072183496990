import * as React from 'react';
interface ButtonProps {
  className?: string,
  label: string,
  icon?: string,
  handleClick: () => void;
}
const CraftButton = ({className, label, icon, handleClick}: ButtonProps) => {
  return (
    <button type="button" className={className} onClick={() => handleClick()}>
      {icon && (
        <i className={icon}>
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
      )}
      {label}
    </button>
  )
}

export default CraftButton;