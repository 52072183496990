import React from "react";
import { Card } from "react-bootstrap";
import ChromeIcon from "assets/images/brands/chrome-icon.svg";

const RecentDevices = () => {
    return (
        <Card id="recentDevices">
            <Card.Header>
                <Card.Title className="fs-7">Recent Devices</Card.Title>
            </Card.Header>
            <Card.Body>
                <p className="card-text">View and manage devices where you're currently logged in.</p>
                <div className="table-responsive">
                    <table className="table table-thead-bordered table-nowrap table-align-middle card-table">
                        <thead className="thead-light">
                            <tr>
                                <th>Browser</th>
                                <th>Device</th>
                                <th>Location</th>
                                <th>Most recent activity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="align-items-center">
                                    <img className="avatar avatar-xs me-2" src={ChromeIcon} alt="Image Description" /> Chrome on Windows
                                </td>
                                <td><i className="bi-laptop fs-7 me-2"></i> Dell XPS 15 <span className="badge bg-soft-success text-success ms-1">Current</span></td>
                                <td>London, UK</td>
                                <td>Now</td>
                            </tr>

                            <tr>
                                <td className="align-items-center">
                                    <img className="avatar avatar-xs me-2" src={ChromeIcon} alt="Image Description0" /> Chrome on Android
                                </td>
                                <td><i className="bi-phone fs-7 me-2"></i> Google Pixel 3a</td>
                                <td>London, UK</td>
                                <td>15, August 2020 15:08</td>
                            </tr>

                            <tr>
                                <td className="align-items-center">
                                    <img className="avatar avatar-xs me-2" src={ChromeIcon} alt="Image Description" /> Chrome on Windows
                                </td>
                                <td><i className="bi-display fs-7 me-2"></i> Microsoft Studio 2</td>
                                <td>London, UK</td>
                                <td>12, August 2020 20:07</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Card.Body>
        </Card>
    );
}

export default RecentDevices;