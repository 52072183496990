import React, { ChangeEvent, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "./Header";
import { Button } from "react-bootstrap";
import avatar from "assets/craft/avatars/blank.png";
import IntroMsg from "craft_components/IntroMsg";
import { RootState } from "redux/store";
import { AvatarUrl } from "constant";
import { updateBotSetting } from "hyper_helpers/api/knowledgebase";
import { toast } from "react-toastify";

const handleConvert = (txt: string) => {
  const res = txt.replace(/\[Council name\]/g, "<span style='color: blue'>[Council name]</span>")
                  .replace(/\[Bot name\]/g, "<span style='color: blue'>[Bot name]</span>")
                  .replace(/\[Entity name\]/g, "<span style='color: blue'>[Entity name]</span>");
  return res;
}

const Settings = () => {
    const { knowledgeId } = useParams();
    const { knowledgeBases } = useSelector((state: RootState) => state.KnowledgeBase);
    const knowledgeBase = knowledgeBases.find((item: any) => item.KnowledgeBaseID === knowledgeId);
    const settings = knowledgeBase?.Setting;

    const [loading, setLoading] = React.useState<boolean>(false);
    const [avatarImage, setAvatarImage] = React.useState<any>(null);
    const [botName, setBotName] = React.useState<string>("");
    const [publicSupport, setPublicSupport] = React.useState<string>("");
    const [staffSupport, setStaffSupport] = React.useState<string>("");
    const [publicCalls, setPublicCalls] = React.useState<string>("");
    const [staffCalls, setStaffCalls] = React.useState<string>("");


    const handleAvatar = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setAvatarImage(e.target.files[0]);
        }
    }

    useEffect(() => {
        setAvatarImage(null);
        setBotName(settings?.BotName);
        setPublicSupport(handleConvert(`<p>${settings?.PublicSupportChat}</p>`));
        setStaffSupport(handleConvert(`<p>${settings?.StaffSupportChat}</p>`));
        setPublicCalls(handleConvert(`<p>${settings?.PublicCalls}</p>`));
        setStaffCalls(handleConvert(`<p>${settings?.StaffCalls}</p>`));
    }, [knowledgeId]);

    const handleSave = async () => {
        let formData = new FormData();
        formData.append('knowledge_base_id', knowledgeBase?.KnowledgeBaseID);
        formData.append('avatar', avatarImage);
        formData.append('bot_name', botName);
        formData.append('public_support_chat', publicSupport.replace(/<.*?>/g, ''));
        formData.append('staff_support_chat', staffSupport.replace(/<.*?>/g, ''));
        formData.append('public_calls', publicCalls.replace(/<.*?>/g, ''));
        formData.append('staff_calls', staffCalls.replace(/<.*?>/g, ''));

        setLoading(true);
        try {
            await updateBotSetting(formData);
        } catch (e: any) {
            toast.warn(e);
        }
        setLoading(false);
    }

    return (
        <div>
            <Header knowledgeBase={knowledgeBase} active="settings" />
            <div className="card card-flush">
                <div className="card-header pt-4">
                    <div className="card-title">
                        <h2 className="fs-7">Settings</h2>
                    </div>
                </div>
                <div className="card-body">
                    <form className="form" id="kt_file_manager_settings">
                        <label className="row mb-4" htmlFor="accountSettingsPreferencesSwitch1">
                            <p className="col-8 col-sm-9 ms-0">
                            <h4 className="d-block text-dark fs-7">Bot Profile</h4>
                            <span className="d-block fs-8">The Bot Avatar is the profile photo and name that will be used. You don't have to select one. The Bot Profile ensures that the Bot inherits the correct Profile across Products and Entities. By default, it will be set at the Team level.</span>
                            </p>
                            <p className="d-block fs-8">An example would be when a user calls would you rather they Speak to Your Organisation, James from the Housing Directorate, the Social Housing Service, or the Housing Repair Team?</p>
                            <span className="d-block fs-8 text-primary">The Profile can change based on the URL or Phone number.</span>
                        </label>
                        <label className="mt-0 avatar avatar-5xl avatar-uploader rounded-circle profile-cover-avatar">
                            <img className="avatar-img rounded-circle" src={avatarImage ? URL.createObjectURL(avatarImage) : settings?.Avatar === "" ? avatar : `${AvatarUrl}${settings?.Avatar}`} alt="Upload logo" />

                            <input type="file" accept="image/*" className="js-file-attach avatar-uploader-input" onChange={handleAvatar} />

                            <span className="avatar-uploader-trigger">
                            <i className="bi-pencil-fill avatar-uploader-icon shadow-sm"></i>
                            </span>
                        </label>
                        <div className="row">
                            <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Bot Name</label>

                            <div className="col-sm-9">
                                <input type="text" className="form-control" placeholder="Enter bot Name address" value={botName} onChange={e => setBotName(e.target.value)} />
                            </div>  
                        </div>
                        <label className="row mb-4" htmlFor="accountSettingsPreferencesSwitch1">
                            <p className="col-8 col-sm-9 ms-0">
                            <h4 className="d-block text-dark fs-7">Bot Intro Message</h4>
                            <span className="d-block fs-8">The Bot Avatar is the profile photo and name that will be used. You don't have to select one. The Bot Profile ensures that the Bot inherits the correct Profile across Products and Entities. By default, it will be set at the Team level.</span>
                            </p>
                            <p className="d-block fs-8">An example would be when a user calls would you rather they Speak to Your Organisation, James from the Housing Directorate, the Social Housing Service, or the Housing Repair Team?</p>
                            <span className="d-block fs-8 text-primary">The Profile can change based on the URL or Phone number.</span>
                        </label>
                        <div>
                            <div className="row mt-3">
                                <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Public Support Chat</label>

                                <div className="col-sm-9">
                                    <IntroMsg id='publicSupport' value={publicSupport} setValue={setPublicSupport} />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Staff Support Chat</label>

                                <div className="col-sm-9">
                                    <IntroMsg id='staffSupport' value={staffSupport} setValue={setStaffSupport} />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Public Calls</label>

                                <div className="col-sm-9">
                                    <IntroMsg id='publicCalls' value={publicCalls} setValue={setPublicCalls} />
                                </div>
                                </div>
                            <div className="row mt-3">
                                <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Staff Calls</label>

                                <div className="col-sm-9">
                                    <IntroMsg id='staffCalls' value={staffCalls} setValue={setStaffCalls} />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-4">
                            <Button variant="outline-primary" className="me-3">Cancel</Button>
                            <Button variant="primary" disabled={loading} onClick={handleSave}>
                                {loading ?
                                    <>Please wait...<span className="spinner-border spinner-border-sm align-middle ms-2"></span></>
                                    :
                                    <>Save</>
                                }
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Settings;