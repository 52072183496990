import React from "react";
import { Card, Alert } from "react-bootstrap";
import EmailSvg from "assets/images/illustrations/oc-email-at.svg";
import EmailSvgLight from "assets/images/illustrations-light/oc-email-at.svg";
import GlobeSvg from "assets/images/illustrations/oc-globe.svg";
import GlobeSvgLight from "assets/images/illustrations-light/oc-globe.svg";

const OrganisationNotification = () => {
    return (
        <Card id="organisationNotifications">
            <Card.Header>
                <Card.Title className="fs-7">Organisation Notifications</Card.Title>
            </Card.Header>
            <Alert variant="dark" className="border-0 rounded-0">
                We need permission across Devices and your Browser to display notifications. <a className="alert-link" href="#">Request permission</a>
            </Alert>
            <div className="table-responsive datatable-custom">
                <table className="table table-thead-bordered table-nowrap table-align-middle table-first-col-px-0">
                <thead className="thead-light">
                    <tr>
                    <th className="ps-4">Type</th>
                    <th className="text-center">
                        <div className="mb-1">
                            <img className="avatar avatar-xs d-dark-none" src={EmailSvg} alt="Image Description" data-hs-theme-appearance="default" />
                            <img className="avatar avatar-xs d-light-none" src={EmailSvgLight} alt="Image Description" data-hs-theme-appearance="dark" />
                        </div>
                        Email
                    </th>
                    <th className="text-center">
                        <div className="mb-1">
                            <img className="avatar avatar-xs d-dark-none" src={EmailSvg} alt="Image Description" data-hs-theme-appearance="default" />
                            <img className="avatar avatar-xs d-light-none" src={EmailSvgLight} alt="Image Description" data-hs-theme-appearance="dark" />
                        </div>
                        Browser
                    </th>
                    <th className="text-center">
                        <div className="mb-1">
                            <img className="avatar avatar-xs d-dark-none" src={GlobeSvg} alt="Image Description" data-hs-theme-appearance="default" />
                            <img className="avatar avatar-xs d-light-none" src={GlobeSvgLight} alt="Image Description" data-hs-theme-appearance="dark" />
                        </div>
                        Mobile
                    </th>
                    <th className="text-center">
                        <div className="mb-1">
                            <img className="avatar avatar-xs d-dark-none" src={EmailSvg} alt="Image Description" data-hs-theme-appearance="default" />
                            <img className="avatar avatar-xs d-light-none" src={EmailSvgLight} alt="Image Description" data-hs-theme-appearance="dark" />
                        </div>
                        Tablet
                    </th>
                    <th className="text-center">
                        <div className="mb-1">
                            <img className="avatar avatar-xs d-dark-none" src={EmailSvg} alt="Image Description" data-hs-theme-appearance="default" />
                            <img className="avatar avatar-xs d-light-none" src={EmailSvgLight} alt="Image Description" data-hs-theme-appearance="dark" />
                        </div>
                        Desktop
                    </th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                    <td className="ps-4">New for you</td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox1" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox1"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox2" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox2"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox2" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox2"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox2" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox2"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox3" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox3"></label>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td className="ps-4">Account activity <i className="bi-question-circle text-body ms-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Get important notifications about you or activity you've missed"></i></td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox4" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox4"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox2" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox2"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox2" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox2"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox5" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox5"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox6" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox6"></label>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td className="ps-4">A new browser used to sign in</td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox7" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox7"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox8" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox8"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox8" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox8"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox2" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox2"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox9" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox9"></label>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td className="ps-4">A new device is linked</td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox10" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox10"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox2" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox2"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox11" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox11"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox8" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox8"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox12" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox12"></label>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td className="ps-4">New Team Members</td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox10" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox10"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox2" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox2"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox11" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox11"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox8" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox8"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox12" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox12"></label>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td className="ps-4">Billing Update</td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox10" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox10"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox2" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox2"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox11" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox11"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox8" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox8"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox12" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox12"></label>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td className="ps-4">A new device connected <i className="bi-question-circle text-body ms-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Email me when a new device connected"></i></td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox13" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox13"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox2" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox2"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox2" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox2"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox14" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox14"></label>
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="editUserModalAlertsCheckbox15" />
                        <label className="form-check-label" htmlFor="editUserModalAlertsCheckbox15"></label>
                        </div>
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>
            <Card.Body>
                <div className="row">
                  <div className="col-sm">
                    <div className="mb-4">
                      <p className="card-text">When should we send you notifications?</p>

                      <div className="tom-select-custom">
                        <select className="js-select form-select" autoComplete="">
                          <option value="whenToSendNotification1">Always</option>
                          <option value="whenToSendNotification2">Only when I'm online</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm">
                    <div className="mb-4">
                      <p className="card-text">Send me a daily summary ("Daily Digest") of task activity.</p>

                      <div className="row">
                        <div className="col-auto mb-2">
                          <div className="tom-select-custom">
                            <select className="js-select form-select" autoComplete="">
                              <option value="EveryDay">Every day</option>
                              <option value="weekdays" selected>Weekdays</option>
                              <option value="Never">Never</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-auto mb-2">
                          <div className="tom-select-custom">
                            <select className="js-select form-select" autoComplete="">
                              <option value="0">at 12 AM</option>
                              <option value="1">at 1 AM</option>
                              <option value="2">at 2 AM</option>
                              <option value="3">at 3 AM</option>
                              <option value="4">at 4 AM</option>
                              <option value="5">at 5 AM</option>
                              <option value="6">at 6 AM</option>
                              <option value="7">at 7 AM</option>
                              <option value="8">at 8 AM</option>
                              <option value="9" selected>at 9 AM</option>
                              <option value="10">at 10 AM</option>
                              <option value="11">at 11 AM</option>
                              <option value="12">at 12 PM</option>
                              <option value="13">at 1 PM</option>
                              <option value="14">at 2 PM</option>
                              <option value="15">at 3 PM</option>
                              <option value="16">at 4 PM</option>
                              <option value="17">at 5 PM</option>
                              <option value="18">at 6 PM</option>
                              <option value="19">at 7 PM</option>
                              <option value="20">at 8 PM</option>
                              <option value="21">at 9 PM</option>
                              <option value="22">at 10 PM</option>
                              <option value="23">at 11 PM</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p className="card-text">In order to cut back on noise, email notifications are grouped together and only sent when you're idle or offline.</p>

                <div className="d-flex justify-content-end">
                  <button type="submit" className="btn btn-primary">Save changes</button>
                </div>
            </Card.Body>
        </Card>
    );
}

export default OrganisationNotification;