import { ChangeEvent, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/common/SearchBox";
import { teamMembers } from "data/team/team";
import useAdvanceTable from "hooks/useAdvanceTable";
import { teamViewBreadcrumbItems } from "data/commonData";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import Header from "../../common/Header";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import TeamsTable, { membersTablecolumns } from "components/tables/TeamTable";
import ReactSelect from "react-select";

const ExpertTeamMembers = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Button variant="outline-primary" className="w-100" onClick={handleShow}>
        <FontAwesomeIcon icon="file-export" className="fs-9 me-2" />
        <label>Export</label>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Export TeamMembers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Select Roles:</h5>
          <ReactSelect
            options={[
              {
                value: "Administrator",
                label: "Administrator",
              },
              {
                value: "Analyst",
                label: "Analyst",
              },
              {
                value: "Developer",
                label: "Developer",
              },
              {
                value: "Support",
                label: "Support",
              },
              {
                value: "Trial",
                label: "Trial",
              },
            ]}
            placeholder="Select Role"
            required
          />
          <h5>Select Export Formart:</h5>
          <ReactSelect
            options={[
              {
                value: "Excel",
                label: "Excel",
              },
              {
                value: "PDF",
                label: "PDF",
              },
              {
                value: "CVS",
                label: "CVS",
              },
              {
                value: "ZIP",
                label: "ZIP",
              },
            ]}
            placeholder="Select Format"
            required
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="phoenix-secondary" onClick={handleClose}>
            Discard
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const AddTeamMembers = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const roles = [
    {
      role: "Administrator",
      description: "Best for business owners and company administrators",
    },
    {
      role: "Developer",
      description: "Best for developers or people primarily using the API",
    },
    {
      role: "Analyst",
      description:
        "Best for people who need full access to analytics data, but don't need to update business settings",
    },
    {
      role: "Support",
      description:
        "Best for employees who regularly refund payments and respond to disputes",
    },
    {
      role: "Trial",
      description:
        "Best for people who need to preview content data, but don't need to make any updates",
    },
  ];

  return (
    <div>
      <Button variant="primary" className="w-100" onClick={handleShow}>
        <FontAwesomeIcon icon="plus" className="" />
        <label>Add Member</label>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Avatar</h5>
          <div
            style={{
              position: "relative",
            }}
          >
            <img
              className="rounded mb-0 border border-5"
              alt="100x100"
              src="https://place-hold.it/100x100"
            />
            <label
              className="btn btn-primary btn-circle"
              style={{
                position: "absolute",
                top: "-15px",
                left: "90px",
              }}
              onChange={(str) => console.log(str)}
              htmlFor="formId"
            >
              <input name="" type="file" id="formId" hidden />
              <FontAwesomeIcon icon="plus" className="me-0" />
            </label>
            <label
              className="btn btn-primary btn-circle"
              style={{
                position: "absolute",
                top: "85px",
                left: "90px",
              }}
              htmlFor="button"
            >
              <input
                type="button"
                id="button"
                hidden
                onClick={() => console.log("ok")}
              ></input>
              <FontAwesomeIcon icon="trash" className="me-0" />
            </label>
            <br />
            <br />
            <label>Allowed file types: png, jpg, jpeg.</label>
          </div>
          <h5>Full Name</h5>
          <Form.Control
            type="text"
            defaultValue={"Emma Smith"}
            placeholder="Emma Smith"
            required
          />
          <h5>Email</h5>
          <Form.Control
            type="email"
            defaultValue={"smith@kpmg.com"}
            placeholder="smith@kpmg.com"
            required
          />
          <h5>Role</h5>
          <Form.Group>
            <Col sm={12}>
              <ListGroup variant="flush">
                {roles.map((item, index) => {
                  return (
                    <ListGroup.Item>
                      <Form.Check
                        label={
                          <div>
                            <h6 className="m-0">{item.role}</h6>
                            <p className="fs-9 m-0">{item.description}</p>
                          </div>
                        }
                        type="radio"
                        name="formHorizontalRadios"
                        id={"formHorizontalRadios" + index}
                      ></Form.Check>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="phoenix-secondary" onClick={handleClose}>
            Discard
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const MemberView = () => {
  const [isMemberPopOpen, setIsMemberPopOpen] = useState(false);
  const table = useAdvanceTable({
    data: teamMembers,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
  });
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleAddTeamMembersClick = () => {
    setIsMemberPopOpen(true);
  };

  const handleMemberPopClose = () => {
    setIsMemberPopOpen(false);
  };

  const location = window.location.pathname;
  console.log("location :>> ", location);
  return (
    <div className="mb-9">
      <Header title={"View Team"} BreadcrumbItems={teamViewBreadcrumbItems}/>

      <AdvanceTableProvider {...table}>
        <div className="bg-white p-4">
          <Container className="mb-4">
            <Row>
              <Col sm={6} className="d-flex">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col sm={6} className="d-flex m-0 row">
                {/* <Row className="m-0"> */}
                <Col sm={4} className="p-1">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="w-100"
                      variant="outline-primary"
                    >
                      <FontAwesomeIcon icon={"filter"} className="fs-9 me-2" />
                      Filter
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="p-0" style={{ width: "300px" }}>
                      <Card className="p-0 mb-0">
                        <Card.Header className="m-1">
                          <h4 className="my-4">Filter Options</h4>
                        </Card.Header>
                        <Card.Body>
                          <h5>Role:</h5>
                          <ReactSelect
                            options={[
                              {
                                value: "Administrator",
                                label: "Administrator",
                              },
                              {
                                value: "Analyst",
                                label: "Analyst",
                              },
                              {
                                value: "Developer",
                                label: "Developer",
                              },
                              {
                                value: "Support",
                                label: "Support",
                              },
                              {
                                value: "Trial",
                                label: "Trial",
                              },
                            ]}
                            placeholder="Select option"
                            required
                          />
                          <h5>Two step:</h5>
                          <ReactSelect
                            options={[
                              {
                                value: "Enabled",
                                label: "Enabled",
                              },
                            ]}
                            placeholder="Select Option"
                            required
                          />
                        </Card.Body>
                        <Card.Footer
                          className="justify-content-end"
                          style={{ display: "block" }}
                        >
                          <Button variant="phoenix-secondary me-2">
                            Reset
                          </Button>
                          <Button>Apply</Button>
                        </Card.Footer>
                      </Card>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col sm={4} className="p-1">
                  <ExpertTeamMembers />
                </Col>
                <Col sm={4} className="p-1">
                  <AddTeamMembers />
                </Col>
                {/* </Row> */}
              </Col>
            </Row>
          </Container>
          <TeamsTable />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default MemberView;
