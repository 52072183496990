import { combineReducers } from "redux";
import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import KnowledgeBase from "./knowledgebase/reducers";

export default combineReducers({
    Auth,
    Layout,
    KnowledgeBase,
});
