import { AuthActionTypes } from "./constants";
import { signup as signupApi } from "hyper_helpers";

export type AuthActionType = {
    type:
        | AuthActionTypes.API_RESPONSE_SUCCESS
        | AuthActionTypes.API_RESPONSE_ERROR
        | AuthActionTypes.FORGOT_PASSWORD
        | AuthActionTypes.FORGOT_PASSWORD_CHANGE
        | AuthActionTypes.LOGIN_USER
        | AuthActionTypes.LOGOUT_USER
        | AuthActionTypes.RESET
        | AuthActionTypes.SIGNUP_USER;
    payload: {} | string;
};

type User = {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    token: string;
};

type UserData = {
    account_type: string;

    domain_name: string;
    confirm_domain: string;
    team_size: string;
    account_plan: string;

    organization_name: string;
    organization_description: string;
    organization_type: string;
    contact_email: string;

    first_name: string;
    last_name: string;
    username: string;
    email: string;
    password: string;
    confirm_password: string;
};

// common success
export const authApiResponseSuccess = (
    actionType: string,
    data: User | {}
): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (
    actionType: string,
    error: string
): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const loginUser = (email: string, password: string): AuthActionType => ({
    type: AuthActionTypes.LOGIN_USER,
    payload: { email, password },
});

export const logoutUser = (): AuthActionType => ({
    type: AuthActionTypes.LOGOUT_USER,
    payload: {},
});

// export const signupUser = (userData: UserData): AuthActionType => ({
//     type: AuthActionTypes.SIGNUP_USER,
//     payload: userData,
// });

export const signupUser = (userData: UserData) => {
    return signupApi(userData);
};

// export const forgotPassword = (username: string): AuthActionType => ({
//     type: AuthActionTypes.FORGOT_PASSWORD,
//     payload: { username },
// });

export const resetAuth = (): AuthActionType => ({
    type: AuthActionTypes.RESET,
    payload: {},
});
