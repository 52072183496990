import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Task } from "../types";
import { taskData } from "../data";

const MyTask = () => {
    return (
        <div className="col-lg-6">
            <div className="card card-flush h-lg-100">
                <div className="card-header mt-3">
                    <div className="card-title flex-column">
                        <h3 className="fw-bold mb-1 fs-7">My Tasks</h3>
                        <div className="fs-8 text-400">Total 25 tasks in backlog</div>
                    </div>
                    <div className="card-toolbar">
                        <Button variant="outline-primary" size="sm">View All</Button>
                    </div>
                </div>
                <div className="card-body d-flex flex-column mb-2 px-6 pt-1 pb-0">
                    {
                        taskData.map((item, idx) => <TaskItem key={idx} checked={item.checked} title={item.title} status={item.status} owner={item.owner} />)
                    }
                </div>
            </div>
        </div>
    );
}

const TaskItem = (props: Task) => {
    const { checked, title, status, owner } = props;

    return (
        <div className="d-flex align-items-center position-relative mb-3">
            <div className="position-absolute top-0 start-0 rounded h-100 bg-secondary-lighten w-4px"></div>
            <div className="form-check form-check-custom form-check-solid ms-3 me-1">
                <input className="form-check-input" type="checkbox" defaultChecked={checked} />
            </div>
            <div className="fw-semibold">
                <a href="#" className="fs-8 fw-bold text-900 text-hover-primary">{title}</a>
                <div className="text-400 fs-8">
                    {status}
                    <a href="#" className="ms-1">{owner}</a>
                </div>
            </div>
            <div className="btn-reveal-trigger ms-auto">
                <Dropdown
                    align="end"
                    id="dropdown-menu-align-right"
                >
                    <Dropdown.Toggle as='a' className='cursor-pointer'>
                        <FontAwesomeIcon icon={faEllipsisV} size="sm" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <div className="px-3 pb-2">
                            <div className="fs-8 text-dark fw-bold">Filter Options</div>
                        </div>
                        <div className="separator border-200"></div>
                        <div className="px-3 py-2">
                            <div className="mb-4">
                                <label className="fw-semibold">Status:</label>
                                <div>
                                    <select className="form-select form-select-solid" multiple={true} data-kt-select2="true" data-close-on-select="false" data-placeholder="Select option" data-dropdown-parent="#kt_menu_64b52c0404ea7" data-allow-clear="true">
                                        <option></option>
                                        <option value="1">Approved</option>
                                        <option value="2">Pending</option>
                                        <option value="2">In Process</option>
                                        <option value="2">Rejected</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="fw-semibold">Member Type:</label>
                                <div className="d-flex">
                                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                                        <input className="form-check-input" type="checkbox" value="1" />
                                        <span className="form-check-label">Author</span>
                                    </label>
                                    <label className="form-check form-check-sm form-check-custom form-check-solid">
                                        <input className="form-check-input" type="checkbox" value="2" defaultChecked />
                                        <span className="form-check-label">Customer</span>
                                    </label>
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="fw-semibold">Notifications:</label>
                                <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                    <input className="form-check-input" type="checkbox" value="" name="notifications" defaultChecked />
                                    <label className="form-check-label">Enabled</label>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <Button className="me-2" variant="outline-primary" size="sm">Reset</Button>
                                <Button variant="primary" size="sm">Apply</Button>
                            </div>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
}

export default MyTask;