import React from 'react';

const MicIcon = ({ className, color = '#002D40' }: { className?: string, color?: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M12 13.5C11.3013 13.5 10.7099 13.258 10.2259 12.774C9.74198 12.29 9.5 11.6987 9.5 11V4.99998C9.5 4.30128 9.74198 3.70993 10.2259 3.22595C10.7099 2.74198 11.3013 2.5 12 2.5C12.6987 2.5 13.29 2.74198 13.774 3.22595C14.258 3.70993 14.5 4.30128 14.5 4.99998V11C14.5 11.6987 14.258 12.29 13.774 12.774C13.29 13.258 12.6987 13.5 12 13.5ZM11.25 20.75V17.4538C9.6 17.2653 8.22917 16.5576 7.1375 15.3307C6.04583 14.1038 5.5 12.6602 5.5 11H6.99997C6.99997 12.3833 7.48747 13.5625 8.46247 14.5375C9.43747 15.5125 10.6166 16 12 16C13.3833 16 14.5625 15.5125 15.5375 14.5375C16.5125 13.5625 17 12.3833 17 11H18.5C18.5 12.6602 17.9541 14.1038 16.8625 15.3307C15.7708 16.5576 14.4 17.2653 12.7499 17.4538V20.75H11.25ZM12 12C12.2833 12 12.5208 11.9041 12.7125 11.7125C12.9041 11.5208 13 11.2833 13 11V4.99998C13 4.71664 12.9041 4.47914 12.7125 4.28748C12.5208 4.09581 12.2833 3.99998 12 3.99998C11.7166 3.99998 11.4791 4.09581 11.2875 4.28748C11.0958 4.47914 11 4.71664 11 4.99998V11C11 11.2833 11.0958 11.5208 11.2875 11.7125C11.4791 11.9041 11.7166 12 12 12Z" fill={color}/>
    </svg>
  );
};

export default MicIcon;
