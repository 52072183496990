import React from "react";
import { Button } from "react-bootstrap";
import NewKnowledge from "./newKnowledge";

const CreateNew = () => {
    const [showNew, setShowNew] = React.useState<boolean>(false);
    const [showInvite, setShowInvite] = React.useState<boolean>(false);

    return (
        <div>
            <div className="fs-10 d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="toolbar" id="kt_toolbar">
                    <div className="container-fluid d-flex justify-content-between align-items-center flex-wrap flex-sm-nowrap">
                        <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                            <h1 className="text-dark my-1 fs-6">Create Knowledge base</h1>
                            <ul className="breadcrumb fw-semibold fs-base my-1">
                                <li className="breadcrumb-item text-muted">
                                    <a href="../dist/index.html" className="text-muted text-hover-primary">Apps</a>
                                </li>
                                <li className="breadcrumb-item text-muted">Knowledge base</li>
                                <li className="breadcrumb-item text-dark">Create Knowledge base</li>
                            </ul>
                        </div>
                        <div className="d-flex align-items-center flex-nowrap text-nowrap py-1">
                            <Button variant="outline-primary" size="lg" className="me-4" onClick={() => setShowInvite(true)}>Invite Friends</Button>
                            <Button variant="primary" size="lg" onClick={() => setShowNew(true)}>New Knowledge base</Button>
                        </div>
                    </div>
                </div>
                <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
                    <div className="container-xxl">
                        <div className="card mt-3">
                            <div className="card-body">
                                <div className="card-px text-center pt-7 pb-7">
                                    <h2 className="fs-2x fw-bold mb-0">Create Knowledge base</h2>
                                    <p className="text-gray-400 fs-8 fw-semibold py-3">Click the button below to create a
                                    <br /> new knowledge base workflow.</p>
                                    <Button variant="primary" size="lg" onClick={() => setShowNew(true)}>Create Knowledge base</Button>
                                </div>
                                <div className="text-center pb-7 px-2">
                                    <img src="assets/media/illustrations/" alt="" className="mw-100 h-200px h-sm-325px" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <NewKnowledge showNew={showNew} setShowNew={setShowNew} showInvite={showInvite} setShowInvite={setShowInvite} />
        </div>
    );
}

export default CreateNew;