import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React, { Component } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import ProjectType from "./ProjectType";
import ProjectSetting from "./ProjectSetting";
import Budget from "./Budget";
import BuildATeam from "./BuildATeam";
import FirstTarget from "./FirstTarget";
import UploadFiles from "./Upload Files";
import CreatedProject from "./Completed";

type NewProjectProps = {
  show: boolean;
  handleClose: () => void;
};

const NewProject = ({ show, handleClose }: NewProjectProps) => {
  const steps = [
    {
      label: "Project Type",
      components: () => {
        return <ProjectType handleNext={handleNext} />;
      },
    },
    {
      label: "Project Setting",
      components: () => {
        return (
          <ProjectSetting handleNext={handleNext} handleBack={handleBack} />
        );
      },
    },
    {
      label: "Budget",
      components: () => {
        return <Budget handleNext={handleNext} handleBack={handleBack} />;
      },
    },
    {
      label: "Build A Team",
      components: () => {
        return <BuildATeam handleNext={handleNext} handleBack={handleBack} />;
      },
    },
    {
      label: "Set First Target",
      components: () => {
        return <FirstTarget handleNext={handleNext} handleBack={handleBack} />;
      },
    },
    {
      label: "Upload Files",
      components: () => {
        return <UploadFiles handleNext={handleNext} handleBack={handleBack} />;
      },
    },
    {
      label: "Completed",
      components: () => {
        return (
          <CreatedProject handleNext={handleNext} handleBack={handleBack} />
        );
      },
    },
  ];
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <Container>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h4">Create Project</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep} style={{ overflowX: "auto" }}>
              {steps.map((step, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step className="w-100" key={step.label} {...stepProps}>
                    <StepLabel {...labelProps}>{step.label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  {steps[activeStep].components()}
                </Typography>
              </React.Fragment>
            )}
          </Box>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default NewProject;
