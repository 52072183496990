import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { useSimpleAuthConfig } from 'layouts/SimpleAuthLayout';
import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import {forgotPassword } from "hyper_helpers/api/auth";
const emailRegExp = /^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const ForgotPassword = () => {
  const { setConfig } = useSimpleAuthConfig();
  const [email, setEmail] = useState<string>("");
  const [invalid, setInvalid] = useState<boolean>(false);

  useEffect(() => {
    setConfig({ logo: true, className: 'col-xxl-4' });
  }, []);

  const handleSend = () => {
    setInvalid(false);
    if (!emailRegExp.test(email)) {
      setInvalid(true);
      return;
    }
    forgotPassword({email})
      .then(() => toast.success('Confirmation Email is sent. Please check your inbox.'))
      .catch(err => toast.warn(err));
  }

  return (
    <div>
      <div className="px-xxl-5">
        <div className="text-center mb-6">
          <h4 className="text-1000">Forgot your password?</h4>
          <p className="text-700 mb-5">
            Enter your email below and we will send <br className="d-sm-none" />
            you a reset link
          </p>
          <div className="d-flex align-items-start mb-5">
            <Form className='w-75'>
              <Form.Control
                type="email"
                id="email"
                placeholder="Email"
                isInvalid={invalid}
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <Form.Control.Feedback type='invalid'>Email is invalid</Form.Control.Feedback>
            </Form>
            <Button
              variant="primary"
              className="ms-2"
              endIcon={
                <FontAwesomeIcon icon="chevron-right" className="ms-2" />
              }
              disabled={email === ""}
              onClick={handleSend}
            >
              Send
            </Button>
          </div>
          <Link to="#!" className="fs-9 fw-bold">
            Still having problems?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
