import React from 'react';
import { Dropdown } from 'react-bootstrap';
import CardStateChat from 'satoshi_components/CardStateChat';
import HeadingPage from 'satoshi_components/HeadingPage';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import OrganisationTopSection from 'components/modules/organisation/OrganisationTopSection';
import { Card } from 'react-bootstrap';
import OrganisationDirectoratesTable, { organisationDirectoratesTableColumns } from 'components/tables/OrganisationDirectoratesTable';

const Directorates = () => {
  const [list, setList] = React.useState<any>(new Array(9).fill(1));

  const table = useAdvanceTable<any>({
    data: list,
    columns: organisationDirectoratesTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  const AddNew = (
    <Dropdown className="btn-reveal-trigger">
      <Dropdown.Toggle as={'button'} className="btn btn-sm btn-soft-secondary">
        <i className="bi bi-plus pe-2" />
        <i className="bi bi-people " />
        <i className="bi bi-people-fill " />
      </Dropdown.Toggle>
      <Dropdown.Menu className='py-1'>
        <Dropdown.Header className='py-1'>Create New</Dropdown.Header>
        <div className="dropdown-item py-1 px-2 d-flex align-items-center cursor-pointer">
          <i className="bi bi-people pe-2" />
          Team
        </div>
        <div className="dropdown-item py-1 px-2 d-flex align-items-center cursor-pointer">
          <i className="bi bi-people-fill pe-2" />
          Service
        </div>
        <div className="dropdown-item py-1 px-2 d-flex align-items-center cursor-pointer">
          <i className="bi bi-people pe-2" />
          Directorate
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    <Card className="vstack">
      <div className="p-3">
        <HeadingPage title="Directorates" dropdown={AddNew} />
        <div className="d-none d-xl-flex justify-content-between">
          <div className="d-flex flex-column">
            <h5 className="fw-normal mb-2 fs-7 text-1100">All Products</h5>
            <div className="fs-8 text-900">
                Directorate Name
              </div>
              <div className="text-muted">
                25
              </div>
          </div>
          <div className="hstack gap-15">
            <div className="">
              <h6 className="fw-normal mb-2 fs-7 text-1100">Total Engagement</h6>
              <div className="fs-8 text-900">
                Across The Organisation
              </div>
              <div className="text-muted">
                25
              </div>
            </div>
            <div className="">
              <h6 className="fw-normal mb-2 fs-7 text-1100">Incomplete</h6>
              <div className="fs-8 text-900">
                Across The Organisation
              </div>
              <div className="text-muted">
                25
              </div>
            </div>
            <div className="">
              <h6 className="fw-normal mb-2 fs-7 text-1100">Completions</h6>
              <div className="fs-8 text-900">
                Across The Organisation
              </div>
              <div className="text-muted">
                25
              </div>
            </div>
            <div className="">
              <h6 className="fw-normal mb-2 fs-7 text-1100">Total Savings</h6>
              <div className="fs-8 text-900">
                Across The Organisation
              </div>
              <div className="text-success">
                +8.41%
              </div>
            </div>
          </div>
        </div>
        <div className="row row-cols-xl-4 row-cols-md-2 g-3 mt-3">
          <CardStateChat title='Journeys' subtitle='Engagement' badge='success' total='23,000.00' />
          <CardStateChat title='Knowledge Bases' subtitle='Engagement' badge='danger' total='7,000.00' />
          <CardStateChat title='Web Crawlers' subtitle='Engagement' badge="info" total='5,000.00' />
          <CardStateChat title='RPAs' subtitle='Engagement' badge='warning' total='13,000.00' />
        </div>
      </div>
      <AdvanceTableProvider {...table}>
        <OrganisationTopSection />
        <OrganisationDirectoratesTable />
      </AdvanceTableProvider>
    </Card>
  );
}

export default Directorates;
