import React from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown, faLocationDot, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import ToolTip from "craft_components/Tooltip";
import Avatar1 from "assets/craft/avatars/300-1.jpg";
import Avatar2 from "assets/craft/avatars/300-10.jpg";
import Avatar3 from "assets/craft/avatars/300-11.jpg";
import Avatar4 from "assets/craft/avatars/300-12.jpg";
import Avatar5 from "assets/craft/avatars/300-13.jpg";
import { TeamMember, teamMembers } from "data/teamMemeber";

const Header = ({
    active,
    knowledgeBase
} : {
    active: string,
    knowledgeBase?: any
}) => {
    const [showUser, setShowUser] = React.useState<boolean>(false);
    const [showTarget, setShowTarget] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    return (
        <div>
            <div className="card mb-3 mb-xl-4">
                <div className="card-body pt-4 pb-0">
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                        <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-start flex-wrap">
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-1">
                                        <p className="text-800 text-hover-primary fs-6 fw-bold me-2 mb-0">{knowledgeBase ? knowledgeBase?.KnowledgeBaseName : "Knowledge Base Name"}</p>
                                        <span className="badge badge-light-success me-auto fs-9">Published</span>
                                    </div>
                                    <div className="d-flex flex-wrap fw-semibold fs-7 mb-4 pe-2">
                                        {knowledgeBase?.KnowledgeBaseParent && <p className="d-flex align-items-center text-muted me-5 mb-2">
                                            <FontAwesomeIcon className="me-2" icon={faUserCircle} size="sm" />
                                            Parent Knowledge Base: {knowledgeBase.KnowledgeBaseParent}
                                        </p>}
                                        <p className="d-flex align-items-center text-muted me-5 mb-2">
                                            <FontAwesomeIcon className="me-2" icon={faLocationDot} size="sm" />
                                            Public Knowledge Base
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex mb-4">
                                    <Button className="me-3" variant="outline-primary" size="sm" onClick={() => setShowUser(true)}>Add User</Button>
                                    <Button className="me-3" variant="primary" size="sm" onClick={() => setShowTarget(true)}>Add Target</Button>
                                    <div className="btn-reveal-trigger">
                                        <Dropdown
                                            align="end"
                                            id="dropdown-menu-align-right"
                                        >
                                            <Dropdown.Toggle variant="outline-primary" className='cursor-pointer px-3'>
                                                <FontAwesomeIcon icon="ellipsis" size="lg" className="text-black" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Header>Manage Project</Dropdown.Header>
                                                <Dropdown.Item eventKey="1">Manage URL(s)</Dropdown.Item>
                                                <Dropdown.Item eventKey="2">Manage Question(s)</Dropdown.Item>
                                                <Dropdown.Item eventKey="3">
                                                    Manage Number(s)
                                                    <ToolTip position="top" content="Specify a target name for future usage and reference" />
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="4">
                                                    <div className="d-flex justify-content-between">
                                                        <span>Subscription</span>
                                                        <span>&gt;</span>
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="5">Settings</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className="fw-semibold">
                                <p className="fs-7 fw-bold text-900 text-hover-primary mb-0">Knowledge Base Overview </p>
                                <p className="text-400 fs-8 text-muted">
                                    {knowledgeBase?.ProjectDescription}
                                </p>
                            </div>
                            <div className="d-flex flex-wrap justify-content-start">
                                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                                    <div className="d-flex flex-wrap align-items-center">
                                        <div className="border border-300 border-dashed rounded min-w-125px py-2 px-3 me-3 mb-2">
                                            <div className="d-flex align-items-center">
                                                <div className="fs-7 fw-bold">DD.MM.YYYY</div>
                                            </div>
                                            <div className="fw-semibold fs-8 text-400 text-muted">Updated</div>
                                        </div>
                                        <div className="border border-300 border-dashed rounded min-w-125px py-2 px-3 me-3 mb-2">
                                            <div className="d-flex align-items-center">
                                                <div className="fs-7 fw-bold">DD.MM.YYYY</div>
                                            </div>
                                            <div className="fw-semibold fs-8 text-400 text-muted">Final Target Due Date</div>
                                        </div>
                                        <div className="border border-300 border-dashed rounded min-w-125px py-2 px-3 me-3 mb-2">
                                            <div className="d-flex align-items-center">
                                                <FontAwesomeIcon className="text-danger fs-8 me-2" size="sm" icon={faArrowDown} />
                                                <div className="fs-7 fw-bold" data-kt-countup="true" data-kt-countup-value="75">0</div>
                                            </div>
                                            <div className="fw-semibold fs-8 text-400 text-muted">Open Tasks</div>
                                        </div>
                                        <div className="border border-300 border-dashed rounded min-w-125px py-2 px-3 me-3 mb-2">
                                            <div className="d-flex align-items-center">
                                                <FontAwesomeIcon className="text-success fs-8 me-2" size="sm" icon={faArrowUp} />
                                                <div className="fs-7 fw-bold" data-kt-countup="true" data-kt-countup-value="15000" data-kt-countup-prefix="$">0</div>
                                            </div>
                                            <div className="fw-semibold fs-8 text-400 text-muted">Budget Spent</div>
                                        </div>
                                        <div className="avatar-group mb-3">
                                            <div className="avatar avatar-m" title="Alan Warden">
                                                <span className="avatar-name rounded-circle">A</span>
                                            </div>
                                            <div className="avatar avatar-m" title="Michael Eberon">
                                                <img className="rounded-circle" alt="Pic" src={Avatar1} />
                                            </div>
                                            <div className="avatar avatar-m" title="Michelle Swanston">
                                                <img className="rounded-circle" alt="Pic" src={Avatar2} />
                                            </div>
                                            <div className="avatar avatar-m" title="Francis Mitcham">
                                                <img className="rounded-circle" alt="Pic" src={Avatar3} />
                                            </div>
                                            <div className="avatar avatar-m" title="Susan Redwood">
                                                <span className="avatar-name rounded-circle">S</span>
                                            </div>
                                            <div className="avatar avatar-m" title="Melody Macy">
                                                <img className="rounded-circle" alt="Pic" src={Avatar4} />
                                            </div>
                                            <div className="avatar avatar-m" title="Perry Matthew">
                                                <span className="avatar-name rounded-circle">P</span>
                                            </div>
                                            <div className="avatar avatar-m" title="Barry Walter">
                                                <img className="rounded-circle" alt="Pic" src={Avatar5} />
                                            </div>
                                            <div className="avatar avatar-m">
                                                <span className="avatar-name rounded-circle">+42</span>
                                            </div>
                                    </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="separator"></div>
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-8 fw-bold">
                        <li className="nav-item">
                            <Link className={`nav-link text-muted py-3 px-0 me-5 ${active === "overview" ? "active" : ""}`} to={`/app/knowledge-base/${knowledgeBase?.KnowledgeBaseID}/overview`}>Overview</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link text-muted py-3 px-0 me-5 ${active === "activity" ? "active" : ""}`} to={`/app/knowledge-base/${knowledgeBase?.KnowledgeBaseID}/overview`}>Activity</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link text-muted py-3 px-0 me-5 ${active === "targets" ? "active" : ""}`} to={"#"}>Targets</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link text-muted py-3 px-0 me-5 ${active === "budget" ? "active" : ""}`} to={"#"}>Budget</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link text-muted py-3 px-0 me-5 ${active === "calendar" ? "active" : ""}`} to={"#"}>Calendar</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link text-muted py-3 px-0 me-5 ${active === "roadmap" ? "active" : ""}`} to={"#"}>Roadmap</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link text-muted py-3 px-0 me-5 ${active === "to-do" ? "active" : ""}`} to={"#"}>To-do</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link text-muted py-3 px-0 me-5 ${active === "files" ? "active" : ""}`} to={"/app/knowledge-base/files/folders"}>Files</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link text-muted py-3 px-0 me-5 ${active === "workflow" ? "active" : ""}`} to={"#"}>Workflow</Link>
                        </li>
                            <li className="nav-item">
                            <Link className={`nav-link text-muted py-3 px-0 me-5 ${active === "team" ? "active" : ""}`} to={"/app/knowledge-base/team"}>Team</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link text-muted py-3 px-0 me-5 ${active === "settings" ? "active" : ""}`} to={`/app/knowledge-base/${knowledgeBase?.KnowledgeBaseID}/settings`}>Settings</Link>
                        </li>
                    </ul>
                </div>
            </div>

            <Modal size="lg" show={showUser} onHide={() => setShowUser(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="mx-5 mx-xl-9 pt-0 pb-7">
                    <div className="text-center mb-3">
                        <h1 className="mb-3 fs-6">Search Users</h1>
                        <div className="text-muted fw-semibold fs-7">Invite Collaborators To Your Project</div>
                    </div>
                    <input type="text" className="form-control form-control-lg form-control-solid" name="search" value="" placeholder="Search by username, full name or email..." data-kt-search-element="input" />
                    <div className="pt-5">
                        <div className="mh-375px scroll-y me-n3 pe-3">
                            {
                                teamMembers.map((member, idx) => <UserItem key={member.id} isLast={teamMembers.length === idx + 1} user={member} />)
                            }
                        </div>
                        <div className="d-flex flex-center mt-7">
                            <Button className="me-3" variant="outline-primary">Cancel</Button>
                            <Button variant="primary">Add Selected Users</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={showTarget} onHide={() => setShowTarget(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="px-5 px-lg-7 pt-0 pb-7">
                    <form id="kt_modal_new_target_form" className="form" action="#">
                        <div className="mb-3 text-center">
                            <h1 className="mb-3 fs-6">Set First Target</h1>
                            <div className="text-muted fw-semibold fs-7">If you need more info, please check
                            <a href="#" className="fw-bold link-primary ms-1">Project Guidelines</a>.</div>
                        </div>
                        <div className="d-flex flex-column mb-4 fv-row">
                            <label className="d-flex align-items-center fs-8 fw-semibold mb-2">
                                <span className="required">Target Title</span>
                                <span className="ms-1" data-bs-toggle="tooltip" title="Specify a target name for future usage and reference">
                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                            </label>
                            <input type="text" className="form-control form-control-solid" placeholder="Enter Target Title" name="target_title" />
                        </div>
                        <div className="row g-9 mb-4">
                            <div className="col-md-6 fv-row">
                                <label className="required fs-8 fw-semibold mb-2">Assign</label>
                                <select className="form-select form-select-solid" data-control="select2" data-hide-search="true" data-placeholder="Select a Team Member" name="target_assign">
                                    <option value="">Select user...</option>
                                    <option value="1">Karina Clark</option>
                                    <option value="2">Robert Doe</option>
                                    <option value="3">Niel Owen</option>
                                    <option value="4">Olivia Wild</option>
                                    <option value="5">Sean Bean</option>
                                </select>
                            </div>
                            <div className="col-md-6 fv-row">
                                <label className="required fs-8 fw-semibold mb-2">Due Date</label>
                                <div className="position-relative d-flex align-items-center">
                                    <input className="form-control form-control-solid" type="date" placeholder="Select a date" name="due_date" />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column mb-4">
                            <label className="fs-8 fw-semibold mb-2">Target Details</label>
                            <textarea className="form-control form-control-solid" rows={3} name="target_details" placeholder="Type Target Details"></textarea>
                        </div>
                        <div className="d-flex flex-column mb-4 fv-row">
                            <label className="d-flex align-items-center fs-8 fw-semibold mb-2">
                                <span className="required">Tags</span>
                                <span className="ms-1" data-bs-toggle="tooltip" title="Specify a target priorty">
                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-8">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                            </label>
                            <input className="form-control form-control-solid" value="Important, Urgent" name="tags" />
                        </div>
                        <div className="d-flex flex-stack mb-4">
                            <div className="me-5">
                                <label className="fs-8 fw-semibold">Adding Users by Team Members</label>
                                <div className="fs-9 fw-semibold text-muted">If you need more info, please check budget planning</div>
                            </div>
                            <label className="d-flex align-items-center form-check form-switch form-check-custom form-check-solid">
                                <input className="form-check-input h-30px w-50px" type="checkbox" value="1" defaultChecked />
                                <span className="form-check-label fw-semibold text-muted ms-2">Allowed</span>
                            </label>
                        </div>
                        <div className="mb-7 fv-row">
                            <div className="d-flex flex-stack">
                                <div className="fw-semibold me-5">
                                    <label className="fs-8">Notifications</label>
                                    <div className="fs-9 text-muted">Allow Notifications by Phone or Email</div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <label className="d-flex align-items-center form-check form-check-custom form-check-solid me-10">
                                        <input className="form-check-input h-20px w-20px" type="checkbox" name="communication[]" value="email" defaultChecked />
                                        <span className="form-check-label fw-semibold mt-1 ms-1">Email</span>
                                    </label>
                                    <label className="d-flex align-items-center form-check form-check-custom form-check-solid">
                                        <input className="form-check-input h-20px w-20px" type="checkbox" name="communication[]" value="phone" />
                                        <span className="form-check-label fw-semibold mt-1 ms-1">Phone</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <Button className="me-3" variant="outline-primary">Cancel</Button>
                            <Button variant="primary">
                                {loading ?
                                    <>Please wait...<span className="spinner-border spinner-border-sm align-middle ms-2"></span></>
                                    :
                                    <>Submit</>
                                }
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const UserItem = ({ isLast, user } : {
    isLast: boolean,
    user: TeamMember
}) => {
    return (
        <div className={`d-flex flex-stack bg-active-lighten p-2 ${!isLast ? "border-bottom border-300 border-bottom-dashed" :  ""}`}>
            <div className="d-flex align-items-center">
                <label className="form-check form-check-custom form-check-solid me-3">
                    <input className="form-check-input h-20px w-20px" type="checkbox" name="users" data-kt-check="true" />
                </label>
                <div className="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src={user.avatar} />
                </div>
                <div className="ms-4">
                    <a href="#" className="fs-7 fw-bold text-900 text-hover-primary mb-2">{user.name}</a>
                    <div className="fw-semibold text-muted fs-8">{user.email}</div>
                </div>
            </div>
            <div className="ms-2 w-100px">
                <select className="form-select form-select-solid form-select-sm" data-control="select2" defaultValue={user.position}>
                    <option value="1">Guest</option>
                    <option value="2">Owner</option>
                    <option value="3">Can Edit</option>
                </select>
            </div>
        </div>
    );
}

export default Header;