import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Logo from "assets/images/light_logo.png";
import { Button } from "react-bootstrap";

import AccountType from "./AccountType";
import AccountSettings from "./AccountSettings";
import OrganisationRegistration from "./OrganisationRegistration";
import UserRegistration from "./UserRegistration";
import BillingDetails from "./BillingDetails";
import Success from "./Success";
import Stepper from "./Stepper";
import { Settings, SettingsError, Organisation, OrganisationError, UserInfo, Billing, UserInfoError } from "./types";
import {
    initialSettings,
    initialSettingsError,
    initialOrganisation,
    initialOrganisationError,
    initialUserInfo,
    initialUserInfoError
} from "./data";
import { checkAccountSettings, checkOrganisation, checkRegistration } from "./validator";
import { signupUser } from "redux/auth/actions";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const initialBilling: Billing = {
    nameOnCard: '',
    cardNumber: '4111 1111 1111 1111',
    expMonth: '',
    expYear: '',
    cvv: '',
    saveCard: true
}

const Register = () => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState<boolean>(false);
    const [step, setStep] = useState(1);

    const [accountType, setAccountType] = useState("government");

    const [accSettings, setAccSettings] = useState<Settings>(initialSettings);
    const [settingsError, setSettingsError] = useState<SettingsError>(initialSettingsError);

    const [organisation, setOrganisation] = useState<Organisation>(initialOrganisation);
    const [organisationError, setOrganisationError] = useState<OrganisationError>(initialOrganisationError);

    const [userInfo, setUserInfo] = useState<UserInfo>(initialUserInfo);
    const [userInfoError, setUserInfoError] = useState<UserInfoError>(initialUserInfoError);

    const [billing, setBilling] = useState<Billing>(initialBilling);
    const [error, setError] = useState<{
        msg: string;
        show: boolean;
    }>({
        msg: '',
        show: false
    })

    const handleNext = () => {
        if (step === 2) {
            const res = checkAccountSettings(accSettings);
            setSettingsError(res);
            if (!res.status) {
                return;
            }
        }

        if (step === 3) {
            const res = checkOrganisation(organisation);
            setOrganisationError(res);
            if (!res.status) {
                return;
            }
        }

        setStep(step => step + 1);
    }

    const handleSubmit = async () => {
        if (step === 4) {
            const res = checkRegistration(userInfo);
            setUserInfoError(res);
            if (!res.status) {
                return;
            }
            
            setLoading(true);
            try {
                await signupUser({
                    account_type: accountType,
                    domain_name: accSettings.domainName,
                    confirm_domain: accSettings.domainConfirm,
                    team_size: accSettings.teamSize,
                    account_plan: accSettings.accPlan,
                    organization_name: organisation.name,
                    organization_description: organisation.description,
                    organization_type: organisation.type,
                    contact_email: organisation.contactEmail,
                    first_name: userInfo.firstName,
                    last_name: userInfo.lastName,
                    username: userInfo.userName,
                    email: userInfo.email,
                    password: userInfo.password,
                    confirm_password: userInfo.confirmPwd
                });

                setStep(step => step + 2);
            } catch (err: any) {
                toast.warn(err);
            }
            setLoading(false);
        } else {
            setStep(step => step + 1);
        }
        
    }

    return (
        <div className="d-flex flex-column flex-root">
			<div className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-dark stepper-column">
                <div className="d-flex flex-column flex-lg-row-auto w-xl-400px positon-xl-relative" data-bs-theme="light">
                    <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-400px bg-dark">
                        <div className="d-flex flex-row-fluid flex-column align-items-center align-items-lg-start p-10 p-lg-20">         
                            <a href="/craft/index.html" className="mb-10 mb-lg-20">
                                <img alt="Logo" src={Logo} className="h-30px" />
                            </a>    
                            <div className="stepper-nav">
                                <Stepper num={1} title='Account Type' desc='Select your account type' current={step} />            
                                <Stepper num={2} title='Account Settings' desc='Configure your account settings' current={step} />            
                                <Stepper num={3} title='Organisation Registration' desc='Create your Organisations account' current={step} />            
                                <Stepper num={4} title='User Registration' desc='Create your profile' current={step} />            
                                {/* <Stepper num={5} title='Billing Details' desc='Provide your payment info for an extended trial' current={step} />        */}
                                <Stepper num={5} title='Success !!!' desc='Your account has been created!' current={step} />
                            </div>
                        </div>
                        <div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-250px" style={{backgroundImage: "url(/craft/assets/media/)"}}> 
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column flex-lg-row-fluid py-5">
                    <div className="d-flex flex-center flex-column flex-column-fluid">
                        <div className="w-lg-800px p-5 p-lg-8 mx-auto">
                            <div className="my-auto pb-5" id="kt_create_account_form">
                                <AccountType num={1} step={step} accountType={accountType} setAccountType={setAccountType} />
                                <AccountSettings num={2} step={step} settings={accSettings} setSettings={setAccSettings} settingsError={settingsError} />
                                <OrganisationRegistration num={3} step={step} organisation={organisation} setOrganisation={setOrganisation} organisationError={organisationError} />
                                <UserRegistration num={4} step={step} userInfo={userInfo} setUserInfo={setUserInfo} userInfoError={userInfoError} />
                                <BillingDetails num={5} step={step} billing={billing} setBilling={setBilling} />
                                <Success num={6} step={step} />

                                <div className="d-flex justify-content-between align-items-center pt-7">
                                    <div className="mr-2">
                                        {(step > 1 && step < 5) && <Button variant="outline-primary" size="lg" className="me-4" onClick={() => setStep(step => step - 1)}>
                                            <FontAwesomeIcon icon={faArrowLeft} className="me-1" />
                                            Previous
                                        </Button>}
                                        {step === 6 && <Link className="btn btn-lg btn-primary me-3" to="/app/knowledge-base/create-new">Go to Dashboard</Link>}
                                    </div>
                                    {step !== 6 && <div>
                                        {step > 3 ?
                                            <Button variant="primary" size="lg" onClick={handleSubmit}>
                                                {step === 5 ? 'Skip' : 'Submit'}
                                                {loading ? 
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    :
                                                    <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                                                }
                                            </Button>
                                            :
                                            <Button variant="primary" size="lg" onClick={handleNext}>
                                                Continue
                                                <FontAwesomeIcon icon={faArrowRight} className="ms-1" />
                                            </Button>
                                        }
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
    )
}

export default Register;