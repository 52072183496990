import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef } from '@tanstack/react-table';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import ProjectsTopSection from 'components/modules/project-management/ProjectsTopSection';
import CardViewItem from 'components/modules/project-management/card-view/CardViewItem';
import { defaultBreadcrumbItems } from 'data/commonData';
import { Project, projects } from 'data/project-management/projects';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export const columns: ColumnDef<Project>[] = [
  {
    // For filtering and searching projects by status
    id: 'status',
    accessorFn: ({ status }) => status.label
  },
  {
    // For searching projects by name
    accessorKey: 'name'
  }
];

const ProjectCardView = () => {
  const { knowledgeBases } = useSelector((state: RootState) => state.KnowledgeBase);

  const table = React.useMemo(() => useAdvanceTable<Project>({
    data: projects.slice(0, knowledgeBases.length),
    columns,
    pageSize: 10,
    pagination: true,
    sortable: true
  }), [knowledgeBases]);

  return (
    <div>
      {/* <PageBreadcrumb items={defaultBreadcrumbItems} /> */}
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">My Knowledge Bases</span>{' '}
            <span className="fw-normal text-700">({knowledgeBases.length})</span>
          </h2>
          <Link
            className="btn btn-primary px-5"
            to="/app/knowledge-base/create-new"
          >
            <FontAwesomeIcon icon="plus" className="me-2" />
            Add New Knowledge Base
          </Link>
        </div>
        <ProjectsTopSection activeView="card" />
        <Row className="g-3 mb-9">
          {table
            .getRowModel()
            .rows.map(row => row.original)
            .map(project => (
              <Col xs={12} sm={6} xl={4} xxl={3} key={project.id}>
                <CardViewItem project={project} />
              </Col>
            ))}
        </Row>
      </AdvanceTableProvider>
    </div>
  );
};

export default ProjectCardView;
