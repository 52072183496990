import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown, faLocationDot, faUserCircle, faCopy, faKey, faLock, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import { Dropdown } from 'react-bootstrap';
import { getQuestion, deleteQuestion } from "hyper_helpers/api/knowledgebase";
import { toast } from "react-toastify";

const Question = ({
    knowledgeBaseId
} : {
    knowledgeBaseId: string
}) => {
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        getQuestion({knowledge_base_id: knowledgeBaseId})
            .then(res => {
                setQuestions(res.data.data);
            })
            .catch(e => console.log(e));
    }, [knowledgeBaseId]);

    const handleRemove = async (knowledgeBaseId: string, questionId: string) => {
        try {
            await deleteQuestion({knowledge_base_id: knowledgeBaseId, question_id: questionId});
            let tmp = questions.filter((question: any) => question?.KnowledgeBaseQuestionID !== questionId);
            setQuestions(tmp);
        } catch (e: any) {
            toast.warn(e);
        }
    }

    return (
        <div className="card mb-3 mb-xl-4">
            <div className="card-header mt-2">
                <div className="card-title flex-column">
                    <h3 className="fw-bold mb-1 fs-7"> Question(s)</h3>
                </div>
                <p className="fs-8 fw-semibold text-gray-600 py-2 m-0">Our system auto-populates relevant questions to guide users, contextualizing interactions with the bot for real-time clarity, directing them to accurate answers and desired outcomes. Triggered questions can initiate specific workflows or Q&A sessions, ensuring the bot consistently delivers an optimal user experience.
                <br /> </p>
                <table className="table align-middle table-row-bordered table-row-solid gy-2 gs-4 mb-4" id="kt_api_keys_table">
                    <thead className="border-gray-200 fs-8 fw-semibold bg-lighten">
                        <tr>
                            <th className="min-w-175px ps-4">Question Name</th>
                            <th className="min-w-250px px-0">Question</th>
                            <th className="min-w-100px">Type</th>
                            <th className="min-w-100px">Status</th>
                            <th className="min-w-100px">Copy</th>
                            <th className="w-100px">Control</th>
                            <th className="w-100px">Edit</th>
                        </tr>
                    </thead>
                    <tbody className="fs-8 fw-semibold text-gray-600">
                        {questions.map((question: any) => (<tr key={question?.KnowledgeBaseQuestionID}>
                            <td className="ps-4">{question?.Title}</td>
                            <td data-bs-target="license" className="ps-0">{question?.Question}</td>
                            <td>Q&A</td>
                            <td>
                                <span className="badge badge-light-success fs-9 fw-semibold">Active</span>
                            </td>
                            <td>
                                <button data-action="copy" className="btn btn-active-color-primary btn-color-gray-400 btn-icon btn-sm btn-outline-light">
                                    <FontAwesomeIcon className="text-muted" icon={faCopy} />
                                </button>
                            </td>
                            <td>
                                <button data-action="copy" className="btn btn-color-gray-400 btn-active-color-primary btn-icon btn-sm btn-outline-light">
                                    <FontAwesomeIcon className="text-muted" icon={faKey} />
                                </button>
                            </td>
                            <td>
                                <RevealDropdownTrigger>
                                    <Dropdown align="end">
                                        <Dropdown.Toggle
                                            variant=""
                                            size="sm"
                                            className='btn-reveal dropdown-caret-none transition-none'
                                        >
                                            <FontAwesomeIcon className="text-muted" icon={faEllipsisV} size="xl" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                            align="end"
                                            className={'py-2'}
                                        >
                                            <Dropdown.Item eventKey="1">View</Dropdown.Item>
                                                <Dropdown.Item eventKey="2">Export</Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item eventKey="4" className="text-danger" onClick={() => handleRemove(question?.KnowledgeBaseID, question?.KnowledgeBaseQuestionID)}>
                                                    Remove
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                    </Dropdown>
                                </RevealDropdownTrigger>
                            </td>
                        </tr>))
                        }
                    </tbody>
                </table>
            </div>	
        </div>
    );
}

export default Question;