import React from "react";
import { Card } from "react-bootstrap";

const Email = () => {
    return (
        <Card id="email">
            <Card.Header>
                <Card.Title className="fs-7">Email</Card.Title>
            </Card.Header>
            <Card.Body>
                <p>Your current email address is <span className="fw-semibold">mark@site.com</span></p>
                <div className="row mb-4">
                    <label htmlFor="newEmailLabel" className="col-sm-3 col-form-label form-label text-notrans">New email address</label>

                    <div className="col-sm-9">
                      <input type="email" className="form-control" name="newEmail" id="newEmailLabel" placeholder="Enter new email address" aria-label="Enter new email address" />
                    </div>
                </div>

                <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary">Save changes</button>
                </div>
            </Card.Body>
        </Card>
    );
}

export default Email;