import React from "react";
import { useChatContext } from "providers/ChatProvider";
import { AttachInfo } from "data/chat";

const SourceItem = ({file} : {file: AttachInfo}) => {
    const { setShowSources } = useChatContext();

    return (
        <div className="d-flex justify-content-between align-items-center my-2">
            <div>
                <div className="d-flex">
                    <div className="d-flex justify-content-center align-items-center bg-300 me-2" style={{width: '40px', height: '40px', flexShrink: 0}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M8.82685 12.4423H9.71147V10.4423H10.8268C11.0781 10.4423 11.2884 10.3577 11.4576 10.1884C11.6269 10.0192 11.7115 9.80896 11.7115 9.55768V8.44228C11.7115 8.19099 11.6269 7.98074 11.4576 7.81152C11.2884 7.64229 11.0781 7.55768 10.8268 7.55768H8.82685V12.4423ZM9.71147 9.55768V8.44228H10.8268V9.55768H9.71147ZM12.7307 12.4423H14.6922C14.9435 12.4423 15.1538 12.3577 15.323 12.1884C15.4922 12.0192 15.5768 11.809 15.5768 11.5577V8.44228C15.5768 8.19099 15.4922 7.98074 15.323 7.81152C15.1538 7.64229 14.9435 7.55768 14.6922 7.55768H12.7307V12.4423ZM13.6153 11.5577V8.44228H14.6922V11.5577H13.6153ZM16.6922 12.4423H17.5768V10.4423H18.7692V9.55768H17.5768V8.44228H18.7692V7.55768H16.6922V12.4423ZM8.05765 17.5C7.55252 17.5 7.12496 17.325 6.77498 16.975C6.42498 16.625 6.24998 16.1974 6.24998 15.6923V4.3077C6.24998 3.80257 6.42498 3.375 6.77498 3.025C7.12496 2.675 7.55252 2.5 8.05765 2.5H19.4422C19.9473 2.5 20.3749 2.675 20.7249 3.025C21.0749 3.375 21.2499 3.80257 21.2499 4.3077V15.6923C21.2499 16.1974 21.0749 16.625 20.7249 16.975C20.3749 17.325 19.9473 17.5 19.4422 17.5H8.05765ZM8.05765 16H19.4422C19.5191 16 19.5897 15.9679 19.6538 15.9038C19.7179 15.8397 19.7499 15.7692 19.7499 15.6923V4.3077C19.7499 4.23077 19.7179 4.16024 19.6538 4.09613C19.5897 4.03203 19.5191 3.99998 19.4422 3.99998H8.05765C7.98072 3.99998 7.9102 4.03203 7.8461 4.09613C7.78198 4.16024 7.74993 4.23077 7.74993 4.3077V15.6923C7.74993 15.7692 7.78198 15.8397 7.8461 15.9038C7.9102 15.9679 7.98072 16 8.05765 16ZM4.55768 20.9999C4.05256 20.9999 3.625 20.8249 3.275 20.4749C2.925 20.1249 2.75 19.6973 2.75 19.1922V6.3077H4.24998V19.1922C4.24998 19.2692 4.28203 19.3397 4.34613 19.4038C4.41024 19.4679 4.48076 19.5 4.55768 19.5H17.4422V20.9999H4.55768Z" fill="#002D40"/>
                        </svg>
                    </div>
                    <div>
                        <p className="fs-9 mb-0 fw-bold">{file.fileName}</p>
                        <div className="d-flex fs-10">
                            <p className="me-4 mb-0">PDF</p>
                            <p className="mb-0">
                                Date: {new Date().toDateString()}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="fs-10 mt-1">{file.url}</div>
            </div>
            <div className="fs-9 fw-bold cursor-pointer" onClick={() => setShowSources(true)}>View Sources</div>
        </div>
    )
}

export default SourceItem;