import React, { ReactNode } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Placement } from "react-bootstrap/esm/types";

const ToolTip = ({position, content} : {position: Placement, content: ReactNode}) => {

    const renderTooltip = (content : ReactNode) => (
        <Tooltip>
            {content}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement={position}
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(content)}
        >
            <FontAwesomeIcon className="ms-1" size="sm" color="lightgrey" icon={faExclamationCircle} />
        </OverlayTrigger>
    );

}

export default ToolTip;