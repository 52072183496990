import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import { currencyFormat } from "helpers/utils";
import { Link } from "react-router-dom";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import { Customer } from "data/e-commerce/customers";
import { Team, Permission, TeamMember, Role, User } from "data/team/team";
import Avatar from "components/base/Avatar";
import {
  Alert,
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const TrustButton = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        onClick={handleShow}
        className="btn btn-link btn-sm px-3 text-danger"
      >
        <FontAwesomeIcon icon="trash" className="me-2" />
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="sm"
      >
        <Modal.Body className="justify-content-md-center">
          <div style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={"circle-info"} size="5x" className="p-5" />
          </div>
          <div style={{ textAlign: "center" }}>
            Are you sure you want to delete Financial Management?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Yes, delete!
          </Button>
          <Button variant="default" onClick={handleClose}>
            No, cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const DeleteButton = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <p className="btn btn-sm mx-3 mb-0 fs-9" onClick={handleShow}>
        Delete
      </p>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="sm"
      >
        <Modal.Body className="justify-content-md-center">
          <div style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={"circle-info"} size="5x" className="p-5" />
          </div>
          <div style={{ textAlign: "center" }}>
            Are you sure you want to delete Financial Management?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Yes, delete!
          </Button>
          <Button variant="default" onClick={handleClose}>
            No, cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const AddRole = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const datas = [
    {
      label: "User Management",
      isRead: false,
      isWrite: false,
      isCreate: false,
    },
    {
      label: "User Management",
      isRead: false,
      isWrite: false,
      isCreate: false,
    },
    {
      label: "User Management",
      isRead: false,
      isWrite: false,
      isCreate: false,
    },
    {
      label: "User Management",
      isRead: false,
      isWrite: false,
      isCreate: false,
    },
    {
      label: "User Management",
      isRead: false,
      isWrite: false,
      isCreate: false,
    },
    {
      label: "User Management",
      isRead: false,
      isWrite: false,
      isCreate: false,
    },
    {
      label: "User Management",
      isRead: false,
      isWrite: false,
      isCreate: false,
    },
  ];

  return (
    <div>
      <div
        onClick={handleShow}
        className="btn btn-link btn-sm px-0 text-primary"
      >
        <FontAwesomeIcon icon="edit" className="me-3" />
      </div>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="mx-3">Add a Role</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-7">
          <h5>Role name</h5>
          <Form.Control type="text" placeholder="Enter a role name" required />
          <h5>Role Permissions</h5>
          <Container style={{ overflowY: "auto", maxHeight: "300px" }}>
            <ListGroup variant="flush">
              <ListGroup.Item className="p-3">
                <Row>
                  <Col sm={4}>Administrator Access</Col>
                  <Col sm={8}>
                    <Container>
                      <Row>
                        <Col sm={4}>
                          <Form.Check type="checkbox">
                            <Form.Check.Input type="checkbox" />{" "}
                            <label className="text-muted">Select All</label>
                          </Form.Check>
                        </Col>
                        <Col sm={4}></Col>
                        <Col sm={4}></Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </ListGroup.Item>
              {datas.map((data) => {
                return (
                  <ListGroup.Item className="p-3">
                    <Row>
                      <Col sm={4}>{data.label}</Col>
                      <Col sm={8}>
                        <Container>
                          <Row>
                            <Col sm={4}>
                              <Form.Check type="checkbox">
                                <Form.Check.Input type="checkbox" />{" "}
                                <label className="text-muted">Read</label>
                              </Form.Check>
                            </Col>
                            <Col sm={4}>
                              <Form.Check type="checkbox">
                                <Form.Check.Input type="checkbox" />{" "}
                                <label className="text-muted">Write</label>
                              </Form.Check>
                            </Col>
                            <Col sm={4}>
                              <Form.Check type="checkbox">
                                <Form.Check.Input type="checkbox" />{" "}
                                <label className="text-muted">Create</label>
                              </Form.Check>
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center mb-3">
          <Button variant="phoenix-secondary" onClick={handleClose}>
            Discard
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const UpdatePermission = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        onClick={handleShow}
        className="btn btn-link btn-sm px-0 text-primary"
      >
        <FontAwesomeIcon icon="edit" className="me-3" />
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>UpdatePermission</Modal.Header>
        <Modal.Body className="justify-content-md-center">
          <Alert variant={"warning"}>
            <div style={{ color: "black" }}>
              <FontAwesomeIcon icon="info-circle" className="me-2" size="lg" />
              <strong>{"Warning!"}</strong> By editing the permission name, you
              might break the system permissions functionality. Please ensure
              you're absolutely certain before proceeding.
            </div>
          </Alert>
          <Form>
            <Form.Group
              className="mb-3 m-3"
              controlId="exampleForm.ControlInput1"
            >
              <h5>Permission Name </h5>
              <Form.Control
                type="text"
                placeholder="Enter a permission name"
                autoFocus
              />
              <div className="text-danger">Permission name is required</div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-md-center">
          <Button
            className="justify-content-md-center"
            variant="default"
            onClick={handleClose}
          >
            Discard
          </Button>
          <Button
            className="justify-content-md-center"
            variant="primary"
            onClick={handleClose}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const AddTeam = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const roles = [
    {
      role: "Administrator",
      description: "Best for business owners and company administrators",
    },
    {
      role: "Developer",
      description: "Best for developers or people primarily using the API",
    },
    {
      role: "Analyst",
      description:
        "Best for people who need full access to analytics data, but don't need to update business settings",
    },
    {
      role: "Support",
      description:
        "Best for employees who regularly refund payments and respond to disputes",
    },
    {
      role: "Trial",
      description:
        "Best for people who need to preview content data, but don't need to make any updates",
    },
  ];

  return (
    <div>
      <p className="btn btn-sm mx-3 mb-0 fs-9" onClick={handleShow}>
        Edit
      </p>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Team</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Team Name</h5>
          <Form.Control
            type="text"
            defaultValue={"Power"}
            placeholder="Power"
            required
          />
          <h5>Admin Avatar</h5>
          <div
            style={{
              position: "relative",
            }}
          >
            <img
              className="rounded mb-0 border border-5"
              alt="100x100"
              src="https://place-hold.it/100x100"
            />
            <label
              className="btn btn-primary btn-circle"
              style={{
                position: "absolute",
                top: "-15px",
                left: "90px",
              }}
              onChange={(str) => console.log(str)}
              htmlFor="formId"
            >
              <input name="" type="file" id="formId" hidden />
              <FontAwesomeIcon icon="plus" className="me-0" />
            </label>
            <label
              className="btn btn-primary btn-circle"
              style={{
                position: "absolute",
                top: "85px",
                left: "90px",
              }}
              htmlFor="button"
            >
              <input
                type="button"
                id="button"
                hidden
                onClick={() => console.log("ok")}
              ></input>
              <FontAwesomeIcon icon="trash" className="me-0" />
            </label>
            <br />
            <br />
            <label>Allowed file types: png, jpg, jpeg.</label>
          </div>
          <h5>Admin Full Name</h5>
          <Form.Control
            type="text"
            defaultValue={"Emma Smith"}
            placeholder="Emma Smith"
            required
          />
          <h5>Admin Email</h5>
          <Form.Control
            type="email"
            defaultValue={"smith@kpmg.com"}
            placeholder="smith@kpmg.com"
            required
          />
          <h5>Number Of Members</h5>
          <Form.Control
            type="number"
            defaultValue={"20"}
            placeholder="20"
            required
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="phoenix-secondary" onClick={handleClose}>
            Discard
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const AddTeamMembers = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const roles = [
    {
      role: "Administrator",
      description: "Best for business owners and company administrators",
    },
    {
      role: "Developer",
      description: "Best for developers or people primarily using the API",
    },
    {
      role: "Analyst",
      description:
        "Best for people who need full access to analytics data, but don't need to update business settings",
    },
    {
      role: "Support",
      description:
        "Best for employees who regularly refund payments and respond to disputes",
    },
    {
      role: "Trial",
      description:
        "Best for people who need to preview content data, but don't need to make any updates",
    },
  ];

  return (
    <div>
      <p className="btn btn-sm mx-3 mb-0 fs-9" onClick={handleShow}>
        Edit
      </p>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Avatar</h5>
          <div
            style={{
              position: "relative",
            }}
          >
            <img
              className="rounded mb-0 border border-5"
              alt="100x100"
              src="https://place-hold.it/100x100"
            />
            <label
              className="btn btn-primary btn-circle"
              style={{
                position: "absolute",
                top: "-15px",
                left: "90px",
              }}
              onChange={(str) => console.log(str)}
              htmlFor="formId"
            >
              <input name="" type="file" id="formId" hidden />
              <FontAwesomeIcon icon="plus" className="me-0" />
            </label>
            <label
              className="btn btn-primary btn-circle"
              style={{
                position: "absolute",
                top: "85px",
                left: "90px",
              }}
              htmlFor="button"
            >
              <input
                type="button"
                id="button"
                hidden
                onClick={() => console.log("ok")}
              ></input>
              <FontAwesomeIcon icon="trash" className="me-0" />
            </label>
            <br />
            <br />
            <label>Allowed file types: png, jpg, jpeg.</label>
          </div>
          <h5>Full Name</h5>
          <Form.Control
            type="text"
            defaultValue={"Emma Smith"}
            placeholder="Emma Smith"
            required
          />
          <h5>Email</h5>
          <Form.Control
            type="email"
            defaultValue={"smith@kpmg.com"}
            placeholder="smith@kpmg.com"
            required
          />
          <h5>Role</h5>
          <Form.Group>
            <Col sm={12}>
              <ListGroup variant="flush">
                {roles.map((item, index) => {
                  return (
                    <ListGroup.Item>
                      <Form.Check
                        label={
                          <div>
                            <h6 className="m-0">{item.role}</h6>
                            <p className="fs-9 m-0">{item.description}</p>
                          </div>
                        }
                        type="radio"
                        name="formHorizontalRadios"
                        id={"formHorizontalRadios" + index}
                      ></Form.Check>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="phoenix-secondary" onClick={handleClose}>
            Discard
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const teamsTablecolumns: ColumnDef<Team>[] = [
  {
    accessorKey: "teamName",
    header: "Team Name",
    meta: {
      headerProps: {
        style: { width: "25%" },
        className: "fs-9 px-1 text-start",
      },
      cellProps: { className: "fw-semi-bold text-1000 fs-9 px-1 " },
    },
    cell: ({ row: { original } }) => {
      const { teamName } = original;
      return (
        <Link
          to="/apps/user-management/teams/view"
          className="d-flex align-items-center fs-9"
        >
          <div className="text-start fw-bold fs-8">{teamName}</div>
        </Link>
      );
    },
  },
  {
    accessorKey: "teamAdmin",
    header: "Team Admin",
    cell: ({ row: { original } }) => {
      const { teamAdmin, avatar, email } = original;
      return (
        <div className="d-flex">
          <Avatar src={avatar} size="m" />
          <div>
            <p className="mb-0 ms-3 fw-bold text-black-50">{teamAdmin}</p>
            <p className="mb-0 ms-3 fw-bold text-black-50">{email}</p>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: {
        style: { width: "25%" },
        className: "pe-5 ps-0 text-start",
      },
      cellProps: { className: "py-0 pe-5 ps-0 white-space-nowrap text-start" },
    },
  },
  {
    accessorKey: "nMembers",
    header: "Number of members",
    meta: {
      headerProps: {
        style: { width: "25%" },
        className: "fs-9 px-1 text-start",
      },
      cellProps: { className: "fw-semi-bold text-1000 fs-9 px-1 " },
    },
    cell: ({ row: { original } }) => {
      const { nMembers } = original;
      return (
        // <div className="badge badge-light fw-bold">Yesterday</div>
        <div className="text-start fw-bold fs-8">{nMembers}</div>
      );
    },
  },
  {
    accessorKey: "actions",
    header: "Actions",
    meta: {
      headerProps: { style: { width: "25%" }, className: "text-start px-1" },
      cellProps: { className: "text-700 text-start px-1" },
    },
    cell: ({ row: { original } }) => {
      return (
        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-basic"
            className="bg-light text-dark shadow-none fs-9"
          >
            Action
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-0">
            <Dropdown.Item href="#">
              <AddTeam />
            </Dropdown.Item>
            <Dropdown.Item href="#">
              <DeleteButton />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    },
  },
];

export const membersTablecolumns: ColumnDef<TeamMember>[] = [
  {
    accessorKey: "team",
    header: "Team Members",
    cell: ({ row: { original } }) => {
      const { team, avatar, email } = original;
      return (
        <Link
          to="/apps/user-management/teams/member"
          className="d-flex align-items-center fs-9"
        >
          <div className="d-flex">
            <Avatar src={avatar} size="m" />
            <div>
              <p className="mb-0 ms-3 fw-bold text-black-50">{team}</p>
              <p className="mb-0 ms-3 fw-bold text-black-50">{email}</p>
            </div>
          </div>
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: "20%" }, className: "pe-5 ps-0" },
      cellProps: { className: "py-0 pe-5 ps-0 white-space-nowrap" },
    },
  },
  {
    accessorKey: "role",
    header: "Role",
    meta: {
      headerProps: { style: { width: "20%" }, className: "pe-5 fs-9 ps-1" },
      cellProps: { className: "pe-5 ps-1 fs-9 text-black-50" },
    },
  },
  {
    accessorKey: "lastLogin",
    header: "Last Login",
    meta: {
      headerProps: { style: { width: "15%" }, className: "fs-9 px-1" },
      cellProps: { className: "fw-semi-bold text-1000 fs-9 px-1 " },
    },
    cell: ({ row: { original } }) => {
      const { lastLogin } = original;
      return (
        // <div className="badge badge-light fw-bold">Yesterday</div>
        <div className="badge badge-light-secondary fw-bold fs-9">
          {lastLogin}
        </div>
      );
    },
  },
  {
    accessorKey: "twoStep",
    header: "Two Step",
    meta: {
      headerProps: { style: { width: "15%" }, className: "fs-9 ps-3 pe-1" },
      cellProps: { className: "fw-bold text-1100 ps-3 fs-9 pe-1  " },
    },
    cell: ({ row }) => {
      return (
        <div className="badge badge-light-success fw-bold fs-9">Enabled</div>
      );
    },
  },
  {
    accessorKey: "joinedDate",
    header: "Joined Date",
    meta: {
      headerProps: { style: { width: "15%" }, className: "ps-7 fs-9" },
      cellProps: { className: "ps-7 text-1000 white-space-nowrap" },
    },
  },
  {
    accessorKey: "actions",
    header: "Actions",
    meta: {
      headerProps: { style: { width: "15%" }, className: "text-end px-1" },
      cellProps: { className: "text-700 text-end px-1" },
    },
    cell: ({ row: { original } }) => {
      return (
        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-basic"
            className="bg-light text-dark shadow-none fs-9"
          >
            Action
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-0">
            <Dropdown.Item href="#">
              <AddTeamMembers />
            </Dropdown.Item>
            <Dropdown.Item href="#">
              <DeleteButton />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    },
  },
];

export const usersTablecolumns: ColumnDef<TeamMember>[] = [
  {
    accessorKey: "user",
    header: "User",
    cell: ({ row: { original } }) => {
      const { team, avatar, email } = original;
      return (
        <Link
          to="/apps/user-management/users/view"
          className="d-flex align-items-center fs-9"
        >
          <div className="d-flex">
            <Avatar src={avatar} size="m" />
            <div>
              <p className="mb-0 ms-3 fw-bold text-black-50">{team}</p>
              <p className="mb-0 ms-3 fw-bold text-black-50">{email}</p>
            </div>
          </div>
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: "20%" }, className: "pe-5 ps-0" },
      cellProps: { className: "py-0 pe-5 ps-0 white-space-nowrap" },
    },
  },
  {
    accessorKey: "role",
    header: "Role",
    meta: {
      headerProps: { style: { width: "20%" }, className: "pe-5 fs-9 ps-1" },
      cellProps: { className: "pe-5 ps-1 fs-9 text-black-50" },
    },
  },
  {
    accessorKey: "lastLogin",
    header: "Last Login",
    meta: {
      headerProps: { style: { width: "15%" }, className: "fs-9 px-1" },
      cellProps: { className: "fw-semi-bold text-1000 fs-9 px-1 " },
    },
    cell: ({ row: { original } }) => {
      const { lastLogin } = original;
      return (
        // <div className="badge badge-light fw-bold">Yesterday</div>
        <div className="badge badge-light-secondary fw-bold fs-9">
          {lastLogin}
        </div>
      );
    },
  },
  {
    accessorKey: "twoStep",
    header: "Two Step",
    meta: {
      headerProps: { style: { width: "15%" }, className: "fs-9 ps-3 pe-1" },
      cellProps: { className: "fw-bold text-1100 ps-3 fs-9 pe-1  " },
    },
    cell: ({ row }) => {
      return (
        <div className="badge badge-light-success fw-bold fs-9">Enabled</div>
      );
    },
  },
  {
    accessorKey: "joinedDate",
    header: "Joined Date",
    meta: {
      headerProps: { style: { width: "15%" }, className: "ps-7 fs-9" },
      cellProps: { className: "ps-7 text-1000 white-space-nowrap" },
    },
  },
  {
    accessorKey: "actions",
    header: "Actions",
    meta: {
      headerProps: { style: { width: "15%" }, className: "text-end px-1" },
      cellProps: { className: "text-700 text-end px-1" },
    },
    cell: ({ row: { original } }) => {
      return (
        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-basic"
            className="bg-light text-dark shadow-none fs-9"
          >
            Action
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-0">
            <Dropdown.Item href="#">
              <Link
                to="/apps/user-management/teams/member"
                className="btn btn-sm mx-3 fs-9"
              >
                Edit
              </Link>
            </Dropdown.Item>
            <Dropdown.Item href="#">
              <DeleteButton />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    },
  },
];

export const assignedTeamsTablecolumns: ColumnDef<User>[] = [
  {
    accessorKey: "id",
    header: "ID",
    cell: ({ row: { original } }) => {
      const { ID } = original;
      <p>{ID}</p>;
    },
    meta: {
      headerProps: { style: { width: "15%" }, className: "ps-7 fs-9" },
      cellProps: { className: "ps-7 text-1000 white-space-nowrap" },
    },
  },
  {
    accessorKey: "team",
    header: "Team",
    cell: ({ row: { original } }) => {
      const { team, avatar, email } = original;
      return (
        <Link
          to="/apps/e-commerce/admin/customer-details"
          className="d-flex align-items-center fs-9"
        >
          <div className="d-flex">
            <Avatar src={avatar} size="m" />
            <div>
              <p className="mb-0 ms-3 fw-bold text-black-50">{team}</p>
              <p className="mb-0 ms-3 fw-bold text-black-50">{email}</p>
            </div>
          </div>
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: "20%" }, className: "pe-5 ps-0" },
      cellProps: { className: "py-0 pe-5 ps-0 white-space-nowrap" },
    },
  },
  {
    accessorKey: "joinedDate",
    header: "Joined Date",
    meta: {
      headerProps: { style: { width: "15%" }, className: "ps-7 fs-9" },
      cellProps: { className: "ps-7 text-1000 white-space-nowrap" },
    },
  },
  {
    accessorKey: "actions",
    header: "Actions",
    meta: {
      headerProps: { style: { width: "15%" }, className: "text-end px-1" },
      cellProps: { className: "text-700 text-end px-1" },
    },
    cell: ({ row: { original } }) => {
      return (
        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-basic"
            className="bg-light text-dark shadow-none fs-9"
          >
            Action
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-0">
            <Dropdown.Item href="#">
              <Link
                to="/apps/user-management/users/view"
                className="btn btn-sm m-1 mx-3 fs-9"
              >
                View
              </Link>
            </Dropdown.Item>
            <Dropdown.Item href="#">
              <DeleteButton />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    },
  },
];

export const permissionTablecolumns: ColumnDef<Permission>[] = [
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row: { original } }) => {
      const { name } = original;
      return (
        <div className="d-flex">
          <div>
            <p className="mb-0 ms-0 fw-bold fs-8">{name}</p>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: "20%" }, className: "pe-5 ps-0" },
      cellProps: { className: "py-0 pe-5 ps-0 white-space-nowrap" },
    },
  },
  {
    accessorKey: "assignedto",
    header: "Assigned To",
    meta: {
      headerProps: { style: { width: "50%" }, className: " ps-3 pe-1" },
      cellProps: { className: "fw-bold text-1500 ps-3 fs-8 pe-1  " },
    },
    cell: ({ row: { original } }) => {
      const { assignedTo } = original;
      return assignedTo.map((item) => (
        <>
          <Badge key={item.label} bg={item.type}>
            {item.label}
          </Badge>{" "}
          <span></span>
        </>
      ));
    },
  },
  {
    accessorKey: "createdDate",
    header: "Created Date",
    meta: {
      headerProps: { style: { width: "15%" }, className: "fs-9 ps-7" },
      cellProps: { className: "ps-7 text-1000 white-space-nowrap" },
    },
  },
  {
    accessorKey: "actions",
    header: "Actions",
    meta: {
      headerProps: { style: { width: "15%" }, className: "text-end px-1" },
      cellProps: { className: "text-700 text-end px-1" },
    },
    cell: ({ row: { original } }) => {
      let isOpen = false;
      const setOpen = (flag: boolean) => {
        isOpen = flag;
      };
      const handleClose = () => {
        setOpen(false);
      };
      return (
        <div>
          <TrustButton />
          <UpdatePermission />
        </div>
      );
    },
  },
];

export const roleTablecolumns: ColumnDef<Role>[] = [
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row: { original } }) => {
      const { name } = original;
      return (
        <Link
          to="/apps/user-management/role/view"
          className="d-flex align-items-center fs-9"
        >
          <div className="d-flex">
            <div>
              <p className="mb-0 ms-0 fw-bold fs-8">{name}</p>
            </div>
          </div>
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: "30%" }, className: "pe-5 ps-0" },
      cellProps: { className: "py-0 pe-5 ps-0 white-space-nowrap" },
    },
  },
  {
    accessorKey: "permissions",
    header: "Permission",
    meta: {
      headerProps: { style: { width: "40%" }, className: " ps-3 pe-1" },
      cellProps: { className: "fw-bold text-1500 ps-3 fs-8 pe-1  " },
    },
    cell: ({ row: { original } }) => {
      const { permissions } = original;
      return permissions.map((item) => (
        <>
          <Badge key={item.label} bg={item.type}>
            {item.label}
          </Badge>{" "}
          <span></span>
        </>
      ));
    },
  },
  {
    accessorKey: "numberofusers",
    header: "Number of users",
    cell: ({ row: { original } }) => {
      const { numberofusers } = original;
      return (
        <div className="d-flex">
          <div>
            <p className="mb-0 ms-0 fw-bold fs-8">{numberofusers}</p>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: "20%" }, className: "pe-5 ps-0" },
      cellProps: { className: "py-0 pe-5 ps-0 white-space-nowrap" },
    },
  },
  {
    accessorKey: "actions",
    header: "Actions",
    meta: {
      headerProps: { style: { width: "10%" }, className: "text-end px-1" },
      cellProps: { className: "text-700 text-end px-1" },
    },
    cell: ({ row: { original } }) => {
      let isOpen = false;
      const setOpen = (flag: boolean) => {
        isOpen = flag;
      };
      const handleClose = () => {
        setOpen(false);
      };
      return (
        <Container>
          <Row>
            <Col sm="6">
              <TrustButton />
            </Col>
            <Col sm="6">
              <AddRole />
            </Col>
          </Row>
        </Container>
      );
    },
  },
];

const TeamsTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default TeamsTable;
