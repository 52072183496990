import { faBriefcase, faChartPie, faUniversity, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToolTip from "craft_components/Tooltip";

interface AccountTypeProps {
    num: number;
    step: number;
    accountType: string;
    setAccountType: (type: string) => void
}

const AccountType = (props: AccountTypeProps) => {
    const { num, step, accountType, setAccountType } = props;

    let otherClass = 'pending';
    if (step === num) otherClass = 'current';
    if (step > num) otherClass = 'completed';

    return (
        <div className={otherClass} data-kt-stepper-element="content">
            <div className="w-100">
                <div className="pb-5 pb-lg-7">
                    <h3 className="stepper-title fs-6">Choose Your Account Type</h3>
                    <div className="text-muted fw-semibold fs-8">
                        If you need more info, please visit the
                        <a href="https://localgovai.uk/help" className="link-primary fw-bold ms-1">Help Page</a>.
                    </div>
                </div>
                <div className="fv-row">
                    <div className="row">
                        <div className="col-lg-6" onClick={() => setAccountType('government')}>
                            <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary p-4 d-flex align-items-center mb-5 ${accountType === 'government' ? 'active' : ''}`} htmlFor="kt_create_account_form_account_type_personal">
                                <FontAwesomeIcon icon={faUniversity} size="2xl" className="me-5" />
                                <span className="d-block fw-semibold text-start">
                                    <span className="text-dark fw-bold d-block fs-7 mb-2">Government Account</span>
                                    <span className="text-muted fw-semibold fs-8">For Governement & Public Sector Organisations</span>
                                </span>
                            </label>
                        </div>
                        <div className="col-lg-6" onClick={() => setAccountType('company')}>
                            <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary p-4 d-flex align-items-center mb-5 ${accountType === 'company' ? 'active' : ''}`} htmlFor="kt_create_account_form_account_type_corporate">
                                <FontAwesomeIcon icon={faBriefcase} size="2xl" className="me-5" />
                                <span className="d-block fw-semibold text-start">
                                    <span className="text-dark fw-bold d-block fs-7 mb-2">Company Account</span>
                                    <span className="text-muted fw-semibold fs-8">For Profit, Enterprises, Mid-Corps and SMEs</span>
                                </span>
                            </label>
                        </div>
                        <div className="col-lg-6" onClick={() => setAccountType('social')}>
                            <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary p-4 d-flex align-items-center mb-5 ${accountType === 'social' ? 'active' : ''}`} htmlFor="kt_create_account_form_account_type_corporate">
                                <FontAwesomeIcon icon={faChartPie} size="2xl" className="me-5" />
                                <span className="d-block fw-semibold text-start">
                                    <span className="text-dark fw-bold d-block fs-7 mb-2">Social Good Account</span>
                                    <span className="text-muted fw-semibold fs-8">For Not-for-profit and for social impact Organisations</span>
                                </span>
                            </label>
                        </div>
                        <div className="col-lg-6" onClick={() => {setAccountType('personal'); console.log('personal')}}>
                            <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary p-4 d-flex align-items-center mb-5 ${accountType === 'personal' ? 'active' : ''}`} htmlFor="kt_create_account_form_account_type_corporate">
                                <FontAwesomeIcon icon={faUser} size="2xl" className="me-5" />
                                <span className="d-block fw-semibold text-start">
                                    <span className="text-dark fw-bold d-block fs-7 mb-2">Personal Account</span>
                                    <span className="text-muted fw-semibold fs-8 d-block">For peronsal and private use</span>
                                    <br />
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountType;