import React from "react";
import { Card } from "react-bootstrap";
import { connectedAccounts } from "./data";

const ConnectedAccounts = () => {
    return (
        <Card id="connectedAccounts">
            <Card.Header>
                <Card.Title className="fs-7">Connected accounts</Card.Title>
            </Card.Header>
            <Card.Body>
                <p className="card-text">Integrated features from these accounts make it easier to collaborate with people you know on Front Dashboard.</p>
                <div className="list-group list-group-lg list-group-flush list-group-no-gutters">
                    {connectedAccounts.map((account, idx) => (
                        <div className="list-group-item mb-2" key={`connected_account_${idx}`}>
                            <div className="d-flex">
                                <div className="flex-shrink-0">
                                    <img className="avatar avatar-xs" src={account?.svg} alt="Image Description" />
                                </div>

                                <div className="flex-grow-1 ms-3">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h4 className="my-0">{account.title}</h4>
                                        <p className="fs-8 text-body mb-0">
                                            {account.description}
                                            {account.learnMore && <a className="link ms-1" href={account.learnMore}>Learn more</a>}
                                        </p>
                                    </div>

                                    <div className="col-auto">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input h-30px w-50px" type="checkbox" id="connectedAccounts1" />
                                        <label className="form-check-label" htmlFor="connectedAccounts1"></label>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Card.Body>
        </Card>
    );
}

export default ConnectedAccounts;