import { APICore } from './apiCore';

const api = new APICore();

function createNewFolder(data: any) {
  const baseUrl = '/v1/rsc/mkdir';
  return api.create(`${baseUrl}`, data);
}

function getAllFolders() {
  const baseUrl = '/v1/rsc/dir';
  return api.get(`${baseUrl}`, {});
}

function removeFolder(data: any) {
  const baseUrl = '/v1/rsc/rmdir';
  return api.delete(`${baseUrl}`, data);
}

function renameFolder(data: any) {
  const baseUrl = '/v1/rsc/rem';
  return api.update(`${baseUrl}`, data);
}

function getFiles(params: any) {
  const baseUrl = '/v1/rsc/files';
  return api.getByQuery(`${baseUrl}`, params)
}

function uploadFiles(data: any) {
  const baseUrl = '/v1/rsc/files/upload';
  return api.createWithFile(`${baseUrl}`, {folder_id: data.folder_id, files: data.files}, data.uploadHandle, data.downloadHandle);
}

function uploadFile(data: any) {
  const baseUrl = '/v1/rsc/files/upload';
  return api.createWithFile(`${baseUrl}`, {folder_id: data.folder_id, files: data.file}, data.uploadHandle, data.downloadHandle);
}

function deleteFile(data: any) {
  const baseUrl = '/v1/rsc/files/del';
  return api.delete(`${baseUrl}`, data);
}

export { createNewFolder, getAllFolders, removeFolder, renameFolder, getFiles, uploadFiles, uploadFile, deleteFile }