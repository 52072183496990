import { IKnowledgeBase } from "data/knowledgebase";
import { KnowledgeBaseActionTypes } from "./constants";

// set knowledge bases
export const setKnowledgeBases = (data: any): any => ({
    type: KnowledgeBaseActionTypes.SET_KNOWLEDGE_BASE,
    payload: data,
});

// delete knowledge base
export const deleteKnowledgeBase = (data: any): any => ({
    type: KnowledgeBaseActionTypes.DELETE_KNOWLEDGE_BASE,
    payload: data,
});
