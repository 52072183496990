import React from "react";
import { Button } from "react-bootstrap";

interface CompletedProps {
    num: number;
    step: number;
    next: () => void;
}

const Completed = (props: CompletedProps) => {
    const { num, step, next } = props;

    let otherClass = 'pending';
    if (step === num) otherClass = 'current';
    if (step > num) otherClass = 'completed';

    return (
        <div className={otherClass} data-kt-stepper-element="content">
            <div className="w-100">
                <div className="pb-6 text-center">
                    <h1 className="fw-bold text-dark fs-6">{`Knowledge Base Name`} Created!</h1>
                    <div className="text-muted fw-semibold fs-7">If you need more info, please check how to create project</div>
                </div>
                <div className="d-flex flex-center pb-5">
                    <Button variant="outline-primary" size="lg" onClick={next}>Create New Knowledge Base</Button>
                    <Button variant="primary" className="ms-2" size="lg" href={'/app/knowledge-base/my-knowledge-bases'}>View Knowledge Base</Button>
                </div>
                <div className="text-center px-2">
                    <img src="assets/media/illustrations/" alt="" className="mww-100 mh-350px" />
                </div>
            </div>
        </div>
    );
}

export default Completed;