import React from "react";

const ConnectedService = () => {
    return (
        <div className="col-lg-7">
            <div className="card card-flush h-lg-100">
                <div className="card-header mt-2">
                    <div className="card-title flex-column">
                        <h3 className="fw-bold mb-1 fs-7">Connected Service(s)</h3>
                    </div>
                    <p className="fs-8 fw-semibold text-gray-600 py-2 m-0">Connected services allow users to utilize our Knowledge Base content through various channels, including support chat and automated workflows. These services guide our bots and RPA, ensuring efficient user assistance. Additionally, our analytics tool, Elixr, is seamlessly integrated to continually optimize bot performance.</p>
                    <div className="row w-100 g-3 mb-4">
                        <div className="col-6 d-flex align-items-center">
                            <div className="min-w-100px fs-8 fw-bold">Support Chat</div>
                            <div className="d-flex align-items-center form-check form-check-custom form-check-solid">
                                <input className="form-check-input" type="checkbox" value="" id="project1" data-kt-settings-notification="email" checked disabled />
                                <label className="form-check-label ps-2 fs-8 mt-1" htmlFor="kt_settings_notification_email">Connected</label>
                            </div>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <div className="min-w-100px fs-8 fw-bold">Chat</div>
                            <div className="d-flex align-items-center form-check form-check-custom form-check-solid">
                                <input className="form-check-input" type="checkbox" value="" id="project1" data-kt-settings-notification="email" checked disabled />
                                <label className="form-check-label ps-2 fs-8 mt-1" htmlFor="kt_settings_notification_email">Connected</label>
                            </div>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <div className="min-w-100px fs-8 fw-bold">Workflow</div>
                            <div className="d-flex align-items-center form-check form-check-custom form-check-solid">
                                <input className="form-check-input" type="checkbox" value="" id="project1" data-kt-settings-notification="email" checked disabled />
                                <label className="form-check-label ps-2 fs-8 mt-1" htmlFor="kt_settings_notification_email">Connected</label>
                            </div>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <div className="min-w-100px fs-8 fw-bold">Web Crawler</div>
                            <div className="d-flex align-items-center form-check form-check-custom form-check-solid">
                                <input className="form-check-input" type="checkbox" value="" id="project1" data-kt-settings-notification="email" checked disabled />
                                <label className="form-check-label ps-2 fs-8 mt-1" htmlFor="kt_settings_notification_email">Connected</label>
                            </div>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <div className="min-w-100px fs-8 fw-bold">RPA</div>
                            <div className="d-flex align-items-center form-check form-check-custom form-check-solid">
                                <input className="form-check-input" type="checkbox" value="" id="project1" data-kt-settings-notification="email" checked disabled />
                                <label className="form-check-label ps-2 fs-8 mt-1" htmlFor="kt_settings_notification_email">Connected</label>
                            </div>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <div className="min-w-100px fs-8 fw-bold">Analytics</div>
                            <div className="d-flex align-items-center form-check form-check-custom form-check-solid">
                                <input className="form-check-input" type="checkbox" value="" id="project1" data-kt-settings-notification="email" checked disabled />
                                <label className="form-check-label ps-2 fs-8 mt-1" htmlFor="kt_settings_notification_email">Connected</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConnectedService;