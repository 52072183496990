// AddPomission component
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Input } from "react-bootstrap-typeahead";
import CancelModal from "./CancelModal";

interface AddPomissionProps {
  isOpen: boolean;
  isSelected: boolean;
  handleClose: () => void;
  onSelected: (flag: boolean) => void;
}

const AddPermission: React.FC<AddPomissionProps> = ({
  isOpen,
  isSelected,
  handleClose,
}) => {
  const [isCancelPopOpen, setIsCancelPopOpen] = useState(false);

  const handleClickDiscard = () => {
    setIsCancelPopOpen(true)
  };

  const handleClickSubmit = () => {
    setIsCancelPopOpen(false)
  };

  return (
    <>
      <Modal show={isOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add a Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3 m-3"
              controlId="exampleForm.ControlInput1"
            >
              <h5>Permission Name </h5>
              <Form.Control
                type="text"
                placeholder="Enter a permission name"
                autoFocus
              />
            </Form.Group>
            <Form.Group className="m-3">
              <Form.Check.Input></Form.Check.Input>
              <Form.Check.Label className="px-2">
                Set as core permission
              </Form.Check.Label>
            </Form.Group>
            <div className="m-3">
              Permission set as a <strong>Core Permission</strong> will be
              locked and <strong>not editable</strong> in future
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-md-center p-2">
          <Button variant="light" onClick={handleClose}>
            Discard
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Submit
          </Button>
          <CancelModal
            isOpen={isCancelPopOpen}
            handleClickDiscard={handleClickDiscard}
            handleClickSubmit={handleClickSubmit}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddPermission;
