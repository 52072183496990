import * as React from 'react';
import Button from "components/base/Button";
import MicIcon from 'components/icons/MicIcon';
import CloseIcon from 'components/icons/CloseIcon';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SendIcon from 'components/icons/SendIcon';
import { useChatContext } from 'providers/ChatProvider';
import { uploadAudio } from 'hyper_helpers/api/chat';
import axios from 'axios';
import MicRecorder from 'mic-recorder-to-mp3';
import { toast } from 'react-toastify';

const Mp3Recorder = new MicRecorder({bitRate: 128});
interface WidgetFooterProps {
  handleMsg: (msg: string) => void;
}
let audioContext: AudioContext;
let scriptProcessor: ScriptProcessorNode;

const WidgetFooter = ({ handleMsg }: WidgetFooterProps) => {
  const [msg, setMsg] = React.useState<string>('');
  // const [micPermission, setMicPermission] = React.useState<boolean>(false);
  const [recording, setRecording] = React.useState<boolean>(false);
  const [recordTime, setRecordTime] = React.useState<number>(0);
  const intervalIDRef = React.useRef<any>(null);
  const [audioStream, setAudioStram] = React.useState<MediaStream>();
  const { setMicPermission, setSpeaking, setSpeechAgent, micPermissionGranted, setAudioVolume} = useChatContext();

  const sendMsg = React.useCallback(() => {
    handleMsg(msg);
    setMsg('');
  }, [msg]);

  const checkMicPermission = React.useCallback(() => {
    navigator.mediaDevices.getUserMedia({video: false, audio: true})
    .then((stream) => {
      if(stream) {
        stream.getTracks().forEach(track => {
          track.stop();
        })
        setMicPermission(true);
        localStorage.setItem('MicPermission', 'Granted');
      }
    })
    .catch((err) => {
      console.error(`you got an error: ${err}`);
    })
  }, []);

  const convertSTM = (secs: number) => {
    let minutes = Math.floor(secs / 60);
    let seconds = secs % 60;
    let m, s;
    if(minutes < 10) {
      m = `0${minutes}`;
    } else {
      m = `${minutes}`
    }
    if(seconds < 10) {
      s = `0${seconds}`;
    } else {
      s = `${seconds}`
    }
    return `${m}:${s}`
  }

  const startRecordInterval = React.useCallback(() => {
    intervalIDRef.current = setInterval(() => {
      setRecordTime(prev => prev + 1);
    }, 1000);
  }, []);

  const startRecording = React.useCallback(() => {
    navigator.mediaDevices.getUserMedia({video: false, audio: true})
    .then((stream) => {
      // if(stream) {
        setSpeechAgent('user');
        setAudioStram(stream);
        setRecording(true);
        startRecordInterval();
        setSpeaking(true);
        Mp3Recorder.start();
        audioContext = new AudioContext();
        
        const mediaStreanSource = audioContext.createMediaStreamSource(stream);
       
        const analyser = audioContext.createAnalyser();
        scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);
        mediaStreanSource.connect(analyser);

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;
        mediaStreanSource.connect(analyser);
        analyser.connect(scriptProcessor);
        scriptProcessor.connect(audioContext.destination);
        scriptProcessor.onaudioprocess = function() {
          const array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          const arraySum = array.reduce((a, value) => a + value, 0);
          const average = arraySum / array.length;
          setAudioVolume(Math.round(average))
        };

      // }
    })
    .catch((err) => {
      console.error(`you got an error: ${err}`);
    })
  }, []);

  const stopRecording = React.useCallback(() => {
    audioStream?.getAudioTracks().forEach(track => {
      track.stop();
    });
    setRecording(false);
    setRecordTime(0);
    setSpeaking(false);
    setSpeechAgent('');
    clearInterval(intervalIDRef.current);
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]: any) => {
        const audioFile = new File([blob], 'speech.mp3', {
          type: 'audio/mp3'
        });
        scriptProcessor.disconnect();
        setAudioVolume(0);
        uploadAudio({audio: audioFile})
          .then(resp => {
            if(resp && resp.data.msg) {
              handleMsg(resp.data.msg);
            }
          })
          .catch(err => {
            toast.error('Something went wrong! Please try again later');
          })
      })
  }, [intervalIDRef, audioStream]);

  const handleSpeech = React.useCallback(() => {
    if(!micPermissionGranted) {
      checkMicPermission();
    } else {
      if(recording) {
        stopRecording();
      } else {
        startRecording();
      }
    }
  }, [micPermissionGranted, recording]);

  React.useEffect(() => {
    localStorage.getItem('MicPermission') === 'Granted' && checkMicPermission();
  }, []);

  return (
    <div
      className={`card-footer d-flex align-items-center gap-2 border-top ps-3 pe-4 py-3 widget-footer${recording ? '-active' : ' bg-white'}`}
      style={{ zIndex: "5"}}
    >
      {recording ? (
        <Button className="p-0" onClick={() => stopRecording()}>
          <CloseIcon/>
        </Button>
      ) : (
        <button className="btn p-0 border-0 mic-btn" onClick={() => handleSpeech()}>
          <label
            className="text-900 fs-9 cursor-pointer"
            htmlFor="microphone"
          >
            <FontAwesomeIcon icon="microphone" />
          </label>
        </button>
      )}
      <div className={`d-flex align-items-center flex-1 gap-3 border rounded-pill px-4 ${recording ? 'hidden' : ''}`}>
        <input
          className="form-control outline-none border-0 flex-1 fs--1 px-0"
          type="text"
          placeholder="Write message"
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
        />
        <label
          className="btn btn-link d-flex p-0 text-500 fs--1 border-0"
          htmlFor="supportChatPhotos"
          >
            <FontAwesomeIcon icon="image" />
        </label>
        <input
          className="d-none"
          type="file"
          accept="image/"
          id="supportChatPhotos"
        />
        <label
          className="btn btn-link d-flex p-0 text-500 fs--1 border-0"
          htmlFor="supportChatAttachment"
        >
          <FontAwesomeIcon icon="paperclip" />
        </label>
        <input
          className="d-none"
          type="file"
          id="supportChatAttachment"
        />
        <input
          className="d-none"
          type="file"
          id="supportChatAttachment"
        />
        <label
          className="btn btn-link d-flex p-0 text-500 fs--1 border-0"
          htmlFor="supportChatCamera"
        >
          <FontAwesomeIcon icon="camera" />
        </label>
        <input
          className="d-none"
          type="file"
          accept="image/_, video/*"
          id="supportChatCamera"
        />
      </div>
      {!recording && (
        <button
          className="btn p-0 border-0 send-btn"
          onClick={() => sendMsg()}
          disabled={msg ? false : true}
        >
          <SendIcon/>
        </button>
      )}
      {recording && (
        <>
          <div className='d-flex align-items-center'>
            <div className='record-indiciator me-2'></div>
            <div className="record-counter text-white">{convertSTM(recordTime)}</div>
          </div>
          <button className="btn p-0 border-0 mic-btn">
            <Button className="p-0" onClick={() => handleSpeech()}>
              <label
                className="text-900 fs-9 cursor-pointer"
                htmlFor="microphone"
              >
                <MicIcon color={micPermissionGranted ? recording ? '#F26D7E' : 'green' : undefined}/>
              </label>
            </Button>
          </button>
        </>
      )}
    </div>
  )
}

export default WidgetFooter;