import Button from 'components/base/Button';
import AlertBg1 from 'components/icons/AlertBg1';
import AlertBg2 from 'components/icons/AlertBg2';
import CloseIcon from 'components/icons/CloseIcon';
import FlowerBaloon from 'components/icons/FlowerBaloon';
import FlowerIcon from 'components/icons/FlowerIcon';
import NarrowDown from 'components/icons/NarrowDown';
import { LanguageOption, supportedLanguages } from 'constant';
import { useChatContext } from 'providers/ChatProvider';
import * as React from 'react';
import { Dropdown, Form } from 'react-bootstrap';



const ModalViews = () => {
  
  const { feedBackStep, setFeedBackModalState, setFeedBackModalStep, language, setLanguage, setMicPermission, setMenuOption } = useChatContext();
  const [dimenssion, setDimenssion] = React.useState({width: 0, height: 0})
  const [languageOption, setLanguageOption] = React.useState<LanguageOption>();

  React.useEffect(() => {
    const supportChat = document.querySelector(".support-chat");
    if (supportChat) {
      const width = supportChat.clientWidth - 32;
      const height = supportChat.clientHeight - 72;
      setDimenssion({width, height});
    }
  }, []);

  React.useEffect(() => {
    if(language) {
      const option = supportedLanguages.filter(item => item.CountryCode === language)[0];
      setLanguageOption(option);
    }
  }, [language]);

  const handleClose = React.useCallback(() =>  {
    setFeedBackModalState(false);
    setFeedBackModalStep('');
  }, []);

  const requestMicPermission = () => {
    navigator.mediaDevices.getUserMedia({video: false, audio: true})
    .then((stream) => {
      if(stream) {
        stream.getTracks().forEach(track => {
          track.stop();
        })
        setMicPermission(true);
        localStorage.setItem('MicPermission', 'Granted');
        handleClose();
      }
    })
    .catch((err) => {
      console.error(`you got an error: ${err}`);
    })
  }


  return (
    <div className='feedback-container d-flex justify-content-center align-items-center rounded-3 p-4' style={{width: dimenssion.width, height: dimenssion.height}}>
      <div className='feedback-backdrop w-100 h-100 rounded-3' onClick={() => handleClose()}></div>
      {feedBackStep === 'leave' && (
        <div className='fade card w-85 p-3 show mb-0'>
          <Button className='position-absolute top-8 right-8 p-0' onClick={() => handleClose()}>
            <CloseIcon color='#111111' />
          </Button>
          <h4>Are you leaving already?</h4>
          <p className='font-12'>Are you sure you want to leave the Conversation?</p>
          <div className='d-flex justify-content-end'>
            <Button variant='outline-secondary' size='sm' onClick={() => handleClose()}> Return to chat </Button>
            <Button variant='secondary' className='mx-2' size='sm' onClick={() => setFeedBackModalStep('share_alert')}> Leave </Button>
          </div>
        </div>
      )}
      {feedBackStep === 'feedback_sent' && (
        <div className='fade align-items-center card w-95 p-3 mb-0 show'>
          <FlowerBaloon className='position-absolute bottom-0 end-0 h-75 mb-4 me-n7'/>
          <FlowerIcon />
          <h4 className='text-center'>Your feedback has been sent!</h4>
          <Button variant='secondary' className='mx-2 text-center mt-4' size='sm' onClick={() => setMenuOption('finish')}> Continue </Button>
        </div>
      )}
      {feedBackStep === 'share_alert' && (
        <div className='share_alert_container fade align-items-center card w-85 mb-0 p-3 show'>
          <h4>Share your Experience</h4>
          <p className='text-start w-100 mt-6 font-14'>Thank you for chatting with us today!</p>
          <p className="font-14">We're always looking to improve your experience. Would you take a moment to tell us about your interaction with our chatbot? Your insights are invaluable and will help us to serve you better in the future.</p>
          <p className="font-14">Click 'Yes, I'll Help' to provide your feedback, and know that we're grateful for your time and thoughts.</p>
          <Button variant='secondary' className='mx-2 text-center mt-4' size='sm' onClick={() => setFeedBackModalStep('feedback_form')}> Yes, I'll Help </Button>
          <Button onClick={() => setMenuOption('finish')}>Maybe Later</Button>
        </div>
      )}
      {feedBackStep === 'feedback_form' && (
        <div className='share_alert_container fade card w-85 mb-0 p-3 mb-0 show'>
          <h4>Feedback</h4>
          <p>We are committed to improving!</p>
          <p className='text-700 mb-0'>Feedback type</p>
          <Form.Check 
            type='radio'
            id='defaultRadio'
            label='General Feedback'
            defaultChecked
            name='feedbackType'
          />
          <Form.Check 
            type='radio'
            id='checkedRadio'
            label='Suggestion'
            name='feedbackType'
          />
          <Form.Check 
            type='radio'
            id='disabledRadio'
            label='Other'
            name='feedbackType'
          />
          <p className='mb-0'>Support Type</p>
          <Form.Check 
            type='radio'
            id='defaultRadio'
            label='Urgent Critical'
            defaultChecked
            name='supportType'
          />
          <Form.Check 
            type='radio'
            id='checkedRadio'
            label='Important (It can wiat)...'
            name='supportType'
          />
          <Form.Select aria-label="Select the type of support you have problem with...">
            <option>Select the type of support you have problem with...</option>
            <option value="1">Category1</option>
            <option value="2">Category2</option>
            <option value="3">Category3</option>
          </Form.Select>
          {/* <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1"> */}
          {/* <Form.Label>Example textarea</Form.Label> */}
            <Form.Control className='mt-2' as="textarea" placeholder='Please give us much details as you can...' rows={3} />
          {/* </Form.Group> */}
          <p className="text-center">Can we contact you to discuss your review?</p>
          <div className='d-flex justify-content-center gap-2'>
            <Form.Check 
              type='radio'
              id='defaultRadio'
              label='Yes'
              defaultChecked
              name='contactable'
            />
            <Form.Check 
              type='radio'
              id='checkedRadio'
              label='No'
              name='contactable'
            />
          </div>
          <Button variant='outline-secondary' onClick={() => setFeedBackModalStep('feedback_sent')}>Submit</Button>
        </div>
      )}
      {feedBackStep === 'language' && (
        <div className='language_select_container fade card w-85 mb-0 p-3 show'>
          <Button className='position-absolute top-8 right-8 p-0' onClick={() => handleClose()}>
            <CloseIcon color='#111111' />
          </Button>
          <h4>Language</h4>
          <div className="btn-reveal-trigger-custom w-80 mx-auto mt-4">
            <Dropdown
              onSelect={e => {if (e) setLanguage(e)}}
            >
              <Dropdown.Toggle as='div' className='cursor-pointer'>
                <div className='d-flex p-1 px-3 align-items-center'>
                  <div className='rounded-pill widget-header-flag' style={{backgroundImage: `url('http://purecatamphetamine.github.io/country-flag-icons/3x2/` + `${languageOption?.CountryCode}` + `.svg')`, width: 28, height: 28}}>
                  </div>
                  <label className='text-black ms-2 flex-1 cursor-pointer'>
                    {languageOption?.LanguageName}
                  </label>
                  <NarrowDown color='black'/>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className='w-100'>
                <div style={{height: 180, overflow: 'scroll'}}>
                  {supportedLanguages.map((language, index) => {
                    return (
                      <Dropdown.Item eventKey={language.CountryCode} key={index}>
                        <div className='d-flex align-items-center'>
                          <div className='rounded-pill widget-header-flag' style={{backgroundImage: `url('http://purecatamphetamine.github.io/country-flag-icons/3x2/` + `${language?.CountryCode}` + `.svg')`, width: 28, height: 28}}>
                          </div>
                          <label className='text-black ms-2 flex-1 font-14 cursor-pointer'>
                            {language?.LanguageName}
                          </label>
                        </div>
                      </Dropdown.Item>
                    )
                  })}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className='d-flex justify-content-center'>
            <Button variant='secondary' className='mx-2 text-center mt-4' size='sm' onClick={() => setFeedBackModalStep('mic_permission')}>
              Continue
            </Button>
          </div>
        </div>
      )}
      {feedBackStep === 'mic_permission' && (
        <div className='mic-permission-container fade card w-85 mb-0 p-4 show'>
          <Button className='position-absolute top-8 right-8 p-0' onClick={() => handleClose()}>
            <CloseIcon color='#111111' />
          </Button>
          <h4 className='mt-4'>Voice assistance activation: Let's Talk!</h4>
          <p>Hello! I can help with any questions you have. </p>
          <p>Can we access your microphone for easier communication? You can speak instead of typing.</p>
          <p>Also, please ensure the volume is up for clear responses. Ready to proceed?</p>
          <div className='d-flex justify-content-center flex-column px-4'>
            <Button variant='secondary' className='mx-2 text-center mt-4' size='sm' onClick={() => requestMicPermission()}>
              Yes, enable microphone
            </Button>
            <Button 
              onClick={() => handleClose()}
              variant='link' className='mx-2 text-center mt-0' size='sm' style={{color: '#31374a'}}>
              No, Continue with text chat
            </Button>
          </div>
        </div>
      )}
      {feedBackStep === 'miscommunication' && (
        <div className='w-85 fade card show p-4'>
          <Button className='position-absolute top-8 right-8 p-0' onClick={() => handleClose()}>
            <CloseIcon color='#111111' />
          </Button>
          <h4>Miscommunication Detected</h4>
          <p className='pt-2'>Could you please clarify what you mean?</p>
          <div className='d-flex justify-content-center'>
            <Button variant='secondary' onClick={() => handleClose()}>Resolve</Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ModalViews;