import { useEffect, useMemo, useState } from 'react';
import { Tab } from 'react-bootstrap';
import UserList from './UserList';
import { ConversationFilterType, useChatContext } from 'providers/ChatProvider';
import { Conversation } from 'data/chat';
import PhoenixNav from 'components/base/PhoenixNav';

interface IFilterItem {
  label: string;
  eventKey: string;
  onClick: () => void;
}

const ChatFilterTab = (
  {
    filterItems,
    filteredConversations
  }
  :
  {
    filterItems: IFilterItem[],
    filteredConversations: Conversation[]
  }
) => {
  return (
    <Tab.Container defaultActiveKey="all">
      <PhoenixNav navItems={filterItems} />
      <UserList conversations={filteredConversations} />
    </Tab.Container>
  );
};

export default ChatFilterTab;
