import React from "react";
import { Button, Pagination } from "react-bootstrap";
import { Project } from "../types";
import { projectData } from "../data";

const ProjectSpending = () => {
    return (
        <div className="card card-flush mt-3 mt-xl-4">
            <div className="card-header mt-5">
                <div className="card-title flex-column">
                    <h3 className="fw-bold mb-1 fs-7">Project Spendings</h3>
                    <div className="fs-8 text-gray-400">Total $260,300 sepnt so far</div>
                </div>
                <div className="card-toolbar my-1">
                    <div className="me-3 my-1">
                        <select id="kt_filter_year" name="year" data-control="select2" data-hide-search="true" className="w-125px form-select form-select-solid form-select-sm">
                            <option value="All">All time</option>
                            <option value="thisyear">This year</option>
                            <option value="thismonth">This month</option>
                            <option value="lastmonth">Last month</option>
                            <option value="last90days">Last 90 days</option>
                        </select>
                    </div>
                    <div className="me-2 my-1">
                        <select id="kt_filter_orders" name="orders" data-control="select2" data-hide-search="true" className="w-125px form-select form-select-solid form-select-sm">
                            <option value="All">All Orders</option>
                            <option value="Approved">Approved</option>
                            <option value="Declined">Declined</option>
                            <option value="In Progress">In Progress</option>
                            <option value="In Transit">In Transit</option>
                        </select>
                    </div>
                    <div className="d-flex align-items-center position-relative my-1 ms-2">
                        <input type="text" id="kt_filter_search" className="form-control form-control-solid form-select-sm w-150px" placeholder="Search Order" />
                    </div>
                </div>
            </div>
            <div className="card-body pt-0">
                <div className="table-responsive">
                    <table id="kt_profile_overview_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bold">
                        <thead className="fs-8 text-400 text-uppercase">
                            <tr>
                                <th className="min-w-250px">Manager</th>
                                <th className="min-w-150px">Date</th>
                                <th className="min-w-90px">Amount</th>
                                <th className="min-w-90px">Status</th>
                                <th className="min-w-50px text-end">Details</th>
                            </tr>
                        </thead>
                        <tbody className="fs-6">
                            {
                                projectData.map((project, idx) => <ProjectItem key={idx} avatar={project.avatar} name={project.name} email={project.email} date={project.date} amount={project.amount} status={project.status} online={project.online} />)
                            }
                        </tbody>
                    </table>
                    <div className="d-flex justify-content-between">
                        <select id="kt_filter_year" name="year" data-control="select2" data-hide-search="true" className="w-125px form-select form-select-solid form-select-sm">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <Pagination className="mb-0">
                            <Pagination.First />
                            <Pagination.Item active>1</Pagination.Item>
                            <Pagination.Item>2</Pagination.Item>
                            <Pagination.Item>3</Pagination.Item>
                            <Pagination.Item>4</Pagination.Item>
                            <Pagination.Item>5</Pagination.Item>
                            <Pagination.Last />
                        </Pagination>
                    </div>
                </div>
            </div>
        </div>
    );
}

const ProjectItem = (props: Project) => {
    const { avatar, name, email, date, amount, status, online } = props;

    let bgColor = '';
    switch (status) {
        case "Rejected":
            bgColor = "badge-light-danger";
            break;
        case "Pending":
            bgColor = "badge-light-warning";
            break;
        case "In progress":
            bgColor = "badge-light-info";
            break;
        case "Approved":
            bgColor = "badge-light-success";
            break;
        default:
            break;
    }

    return (
        <tr>
            <td>
                <div className="d-flex align-items-center">
                    <div className="me-2 position-relative">
                        <div className="symbol symbol-35px symbol-circle">
                            {avatar ?
                                <img alt="Pic" src={avatar} />
                                :
                                <span className="d-flex justify-content-center align-items-center symbol-label bg-light-danger text-danger fw-semibold text-uppercase fs-7">{name[0]}</span>
                            }
                        </div>
                        {online && <div className="bg-success position-absolute border border-1 border-white h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1"></div>}
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                        <a href="" className="fs-8 text-800 text-hover-primary">{name}</a>
                        <div className="fw-semibold text-400 fs-8">{email}</div>
                    </div>
                </div>
            </td>
            <td>
                <span className="fs-8">{date}</span>
            </td>
            <td>
                <span className="fs-8">{`${Number(amount).toFixed(2)}`}</span>
            </td>
            <td>
                <span className={`badge ${bgColor} fw-bold p-2 fs-9`}>{status}</span>
            </td>
            <td className="text-end">
                <Button variant="outline-primary" size="sm">View</Button>
            </td>
        </tr>
    )
}

export default ProjectSpending;