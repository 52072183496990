import { faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "components/base/DatePicker";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
} from "react-bootstrap";
import ReactSelect from "react-select";

type FirstTargetProps = {
  handleBack: () => void;
  handleNext: () => void;
};

const FirstTarget = ({ handleBack, handleNext }: FirstTargetProps) => {
  return (
    <div className="d-flex justify-content-center ">
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <div className="w-100 rounded-3 mb-3">
              <h3>Set First Target</h3>
              <p className="fs-8 text-muted">
                If you need more info, please check out{" "}
                <strong className="text-primary">
                  <a href="#">Project Guidelines</a>
                </strong>
              </p>
            </div>
            <div className="w-100 rounded-3 mb-3">
              <Form.Group>
                <h5>Target Title</h5>
                <Form.Control
                  type="text"
                  className="fs-8"
                  placeholder="Enter Target Title"
                />
              </Form.Group>
            </div>
            <div className="w-100 rounded-3 mb-3">
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <h5>Customer</h5>
                    <ReactSelect
                      className="fs-8"
                      options={[
                        {
                          value: "Keenthemes",
                          label: "Keenthemes",
                        },
                        {
                          value: "CRM App",
                          label: "CRM App",
                        },
                      ]}
                      placeholder="Select customer"
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <h5>Due Date</h5>
                    <DatePicker placeholder="Select Date" />
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div className="w-100 rounded-3 mb-3">
              <Form.Group>
                <h5>Target Details</h5>
                <Form.Control as="textarea" className="fs-8" />
              </Form.Group>
            </div>
            <div className="w-100 rounded-3 mb-3">
              <Form.Group>
                <h5>Tags</h5>
                <ReactSelect
                  className="fs-8"
                  options={[
                    {
                      value: "Important",
                      label: "Important",
                    },
                    {
                      value: "Urgent",
                      label: "Urgent",
                    },
                  ]}
                  isMulti
                  placeholder="Select organizer..."
                />
              </Form.Group>
            </div>
            <div className="w-100 rounded-3 mb-3">
              <div className="d-flex align-items-center rounded p-0 mb-2">
                <div className="flex-grow-1">
                  <h5 className="fw-semibold m-0">Allow Changes in Budget</h5>
                  <p className="text-muted fs-9 mb-0">
                    If you need more info, please check budget planning
                  </p>
                </div>
                <div className="flex-shrink-0 my-0">
                  <Form.Check
                    type="checkbox"
                    id="showPhone"
                    className="form-switch"
                  >
                    <Form.Check.Input
                      type="checkbox"
                      name="showPhone"
                      value="option2"
                      defaultChecked
                    />
                  </Form.Check>
                </div>
              </div>
            </div>
            <div className="w-100 rounded-3 mb-3">
              <Row>
                <Col sm={8}>
                  <h5 className="m-0">Notification</h5>
                  <p className="fs-9 text-muted">
                    Allow Notifications by Phone or Email
                  </p>
                </Col>
                <Col sm={4}>
                  <Row>
                    <Col sm={6} className="d-flex">
                      <Form.Check label="Email" className="fs-8 align-middle" />
                    </Col>
                    <Col sm={6}>
                      <Form.Check label="Phone" className="fs-8" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <Container>
                <Row>
                  <Col className="mb-1" sm={6}>
                    <Button
                      className="btn-secondary fs-7 w-100"
                      onClick={() => handleBack()}
                    >
                      Budget
                    </Button>
                  </Col>
                  <Col className="mb-1" sm={6}>
                    <Button
                      className="btn-primary fs-7 w-100"
                      onClick={() => handleNext()}
                    >
                      Upload Files
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FirstTarget;
