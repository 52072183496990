import { FileItem } from 'data/fileManager';
import { removeFolder, renameFolder } from 'hyper_helpers/api/file';
import { useFileManagerContext } from 'providers/FileManagerProvider';
import * as React from 'react';
import { toast } from 'react-toastify';

const UpdateModal = () => {

  const { removalFileName, cancelRemove, updateNameByIndex, setModalState, folders, editIndex } = useFileManagerContext();
  const handleRename = () => {
    if(editIndex && removalFileName) {
      let updateFile = folders[editIndex];
      renameFolder({folder_id: updateFile.id, folder_name: removalFileName})
        .then(resp => {
          if(resp.status === 200) {
            updateNameByIndex(editIndex, removalFileName);
            setModalState('success');
          }
        })
        .catch(err => {
          toast.error('Something went wrong. Please try again.');
        })
    }
  }

  return (
    <div aria-labelledby="swal2-title" aria-describedby="swal2-html-container" className="swal2-popup swal2-modal swal2-icon-warning swal2-show" tabIndex={-1} role="dialog" aria-live="assertive" aria-modal="true" style={{display: 'grid'}}>
      <div className="swal2-icon swal2-warning swal2-icon-show" style={{display: 'flex'}}>
        <div className="swal2-icon-content">!</div>
      </div>
      <div className="swal2-html-container text-center" id="swal2-html-container" style={{display: 'block'}}>
        Are you sure you want to rename {removalFileName} ?
      </div>
      <div className="swal2-actions" style={{display: 'flex'}}>
        <div className="swal2-loader"></div>
        <button
          onClick={() => handleRename()}
          type="button" 
          className="swal2-confirm btn fw-bold btn-danger" aria-label="" style={{display: 'inline-block'}}>
            Yes, rename it!
        </button>
        <button 
          onClick={() => cancelRemove()}
          type="button"
          className="swal2-cancel btn fw-bold btn-active-light-primary" aria-label="" style={{display: 'inline-block'}}>
            No, cancel
        </button>
      </div>
    </div>
  )
}

export default UpdateModal;