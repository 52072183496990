import { faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Container, ListGroup, Row } from "react-bootstrap";

type CreatedProjectProps = {
  handleBack: () => void;
  handleNext: () => void;
};

const CreatedProject = ({ handleBack, handleNext }: CreatedProjectProps) => {
  return (
    <div className="d-flex justify-content-center align-middle">
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <div className="w-100 rounded-3 d-flex justify-content-center">
              <h3>Project Created!</h3>
            </div>
            <div className="w-100 rounded-3 d-flex justify-content-center">
              <p className="fs-8 text-muted">
                If you need more info, please check how to create project
              </p>
            </div>
            <div className="d-flex justify-content-center gap-4">
              <Container>
                <Row>
                  <Col className="mb-1" sm={6}>
                    <Button
                      className="btn-secondary fs-8 w-100"
                      onClick={() => handleBack()}
                    >
                      Create New Project
                    </Button>
                  </Col>
                  <Col className="mb-1" sm={6}>
                    <Button
                      className="btn-primary fs-8 w-100"
                      onClick={() => handleNext()}
                    >
                      View Project
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreatedProject;
