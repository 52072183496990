import * as React from 'react';
import FileManagerHeader from './FileManagerHeader';
import FileManagerStatusBar from './FileManagerStatusBar';
import FileManagerItemView from './FileManagerItemView';
import NoItemsView from './NoItemsView';
import FileManagerBreadCrumb from './FileManagerBreadCrumb';
import CraftButton from './CraftButton';
import FileManagerListHeader from './FileManagerListHeader';
import { useFileManagerContext } from 'providers/FileManagerProvider';
import NewFolderView from './NewFolderView';
import FileManagerModalView from './FileManagerModalView';
import { getAllFolders, getFiles } from 'hyper_helpers/api/file';
import { FileItem } from 'data/fileManager';

const Folder = () => {

  const { folders, editNewFoloder, editIndex, setFolders, selectAll } = useFileManagerContext();
  const [showSetting, setShowSetting] = React.useState(false);
  const [clearSetting, setClearSetting] = React.useState(false);
  const [checkedAll, setCheckedAll] = React.useState(false);
  const query = new URLSearchParams(window.location.search);
  const folderId = query.get('id');

  const openFileSetting = React.useCallback(() => {
    setShowSetting(!showSetting);
  }, [showSetting]);

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedAll(e.target.checked);
    selectAll(e.target.checked);
  }

  React.useEffect(() => {
    console.log('***************')
    if(folderId) {
      getFiles({folder_id: folderId})
        .then(resp => {
          if(resp.data.data) {
            let folders: FileItem[] = [];
            resp.data.data.map((folder: any) => {
              folders.push({
                name: folder.FileName,
                type: 'file',
                created: folder.Created_At,
                id: folder.FileID,
                updated: folder.Updated_At,
                size: folder.FileSize,
                files: []
              })
            })
            setFolders(folders, folders);
          }
        })
        .catch(err => {
          setFolders([]);
        })
    } else {
      getAllFolders()
        .then(resp => {
          if(resp.data.data) {
            let folders: FileItem[] = [];
            resp.data.data.map((folder: any) => {
              folders.push({
                name: folder.FolderName,
                type: 'folder',
                created: folder.Created_At,
                id: folder.FolderID,
                updated: folder.Updated_At,
                size: folder.FolderSize,
                files: []
              })
            })
            setFolders(folders, folders);
          }
        })
    }
  }, [folderId]);

  return (
    <div className="fs-6 d-flex flex-column flex-column-fluid" id="kt_content" onClick={() => setClearSetting(!clearSetting)}>
      <FileManagerHeader/>
      <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
        <div className="container-xxl">
          <FileManagerStatusBar/>
          <div className="card card-flush">
            <FileManagerListHeader/>
            <div className="card-body">
              <FileManagerBreadCrumb/>
              <table id="kt_file_manager_list" data-kt-filemanager-table="folders" className="table align-middle table-row-dashed fs-6 gy-5">
                <thead>
                  <tr className="text-start text-gray-400 fw-bold text-uppercase gs-0">
                    <th className="w-10px pe-2">
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-3 fs-8 mb-0">
                        <input className="form-check-input" type="checkbox" checked={checkedAll} onChange={e => handleCheck(e)} />
                      </div>
                    </th>
                    <th className="min-w-250px fs-9">Name</th>
                    <th className="min-w-10px fs-9">Size</th>
                    <th className="min-w-125px fs-9">Last Modified</th>
                    <th className="w-125px fs-9"></th>
                  </tr>
                </thead>
                <tbody className="fw-semibold text-gray-600">
                  {editNewFoloder && (
                    <NewFolderView/>
                  )}
                  {folders && folders.length > 0 ? 
                    (
                      folders.map((folder, index) => {
                        return <FileManagerItemView key={index} file={folder} index={index} edit={editIndex === index} clear={clearSetting}/>
                      })
                    )
                    : <NoItemsView />
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-none" data-kt-filemanager-template="rename">
            <div className="fv-row">
              <div className="d-flex align-items-center">
                <span id="kt_file_manager_rename_folder_icon"></span>
                <input type="text" id="kt_file_manager_rename_input" name="rename_folder_name" placeholder="Enter the new folder name" className="form-control mw-250px me-3" value="" />
                <button className="btn btn-icon btn-light-primary me-3" id="kt_file_manager_rename_folder">
                  <i className="ki-duotone ki-check fs-1"></i>
                </button>
                <button className="btn btn-icon btn-light-danger" id="kt_file_manager_rename_folder_cancel">
                  <span className="indicator-label">
                    <i className="ki-duotone ki-cross fs-1">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </span>
                  <span className="indicator-progress">
                    <span className="spinner-border spinner-border-sm align-middle"></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="d-none" data-kt-filemanager-template="action">
            <div className="d-flex justify-content-end">
              <div className="ms-2" data-kt-filemanger-table="copy_link">
                <button type="button" className="btn btn-sm btn-icon btn-light btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                  <i className="ki-duotone ki-fasten fs-5 m-0">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </button>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px" data-kt-menu="true">
                  <div className="card card-flush">
                    <div className="card-body p-5">
                      <div className="d-flex" data-kt-filemanger-table="copy_link_generator">
                        <div className="me-5" data-kt-indicator="on">
                          <span className="indicator-progress">
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </div>
                        <div className="fs-6 text-dark">Generating Share Link...</div>
                      </div>
                      <div className="d-flex flex-column text-start d-none" data-kt-filemanger-table="copy_link_result">
                        <div className="d-flex mb-3">
                          <i className="ki-duotone ki-check fs-6 text-success me-3"></i>
                          <div className="fs-6 text-dark">Share Link Generated</div>
                        </div>
                        <input type="text" className="form-control form-control-sm" value="https://path/to/file/or/folder/" />
                        <div className="text-muted fw-normal mt-2 fs-8 px-3">Read only.
                        <a href="../dist/apps/file-manager/settings/.html" className="ms-2">Change permissions</a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ms-2">
                <button type="button" className="btn btn-sm btn-icon btn-light btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                  <i className="ki-duotone ki-dots-square fs-5 m-0">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                  </i>
                </button>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3">Download File</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-filemanager-table="rename">Rename</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-filemanager-table-filter="move_row" data-bs-toggle="modal" data-bs-target="#kt_modal_move_to_folder">Move to folder</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link text-danger px-3" data-kt-filemanager-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-none" data-kt-filemanager-template="checkbox">
            <div className="form-check form-check-sm form-check-custom form-check-solid">
              <input className="form-check-input" type="checkbox" value="1" />
            </div>
          </div>
          <div className="modal fade show" id="kt_modal_upload" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered mw-650px">
              <div className="modal-content">
                <form className="form" action="none" id="kt_modal_upload_form">
                  <div className="modal-header">
                    <h2 className="fw-bold">Upload files</h2>
                    <div className="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal">
                      <i className="ki-duotone ki-cross fs-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </div>
                  </div>
                  <div className="modal-body pt-10 pb-15 px-lg-17">
                    <div className="form-group">
                      <div className="dropzone dropzone-queue mb-2" id="kt_modal_upload_dropzone">
                        <div className="dropzone-panel mb-4">
                          <a className="dropzone-select btn btn-sm btn-primary me-2">Attach files</a>
                          <a className="dropzone-upload btn btn-sm btn-light-primary me-2">Upload All</a>
                          <a className="dropzone-remove-all btn btn-sm btn-light-primary">Remove All</a>
                        </div>
                        <div className="dropzone-items wm-200px">
                          <div className="dropzone-item p-5" style={{display:'none'}}>
                            <div className="dropzone-file">
                              <div className="dropzone-filename text-dark" title="some_image_file_name.jpg">
                                <span data-dz-name="">some_image_file_name.jpg</span>
                                <strong>(
                                <span data-dz-size="">340kb</span>)</strong>
                              </div>
                              <div className="dropzone-error mt-0" data-dz-errormessage=""></div>
                            </div>
                            <div className="dropzone-progress">
                              <div className="progress bg-gray-300">
                                <div className="progress-bar bg-primary" role="progressbar" aria-valuemin={0} aria-valuemax={100} aria-valuenow={0} data-dz-uploadprogress=""></div>
                              </div>
                            </div>
                            <div className="dropzone-toolbar">
                              <span className="dropzone-start">
                                <i className="ki-duotone ki-to-right fs-1"></i>
                              </span>
                              <span className="dropzone-cancel" data-dz-remove="" style={{display: 'none'}}>
                                <i className="ki-duotone ki-cross fs-6">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </i>
                              </span>
                              <span className="dropzone-delete" data-dz-remove="">
                                <i className="ki-duotone ki-cross fs-6">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="form-text fs-6 text-muted">Max file size is 1MB per file.</span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="modal fade" id="kt_modal_move_to_folder" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered mw-650px">
              <div className="modal-content">
                <form className="form" action="#" id="kt_modal_move_to_folder_form">
                  <div className="modal-header">
                    <h2 className="fw-bold">Move to folder</h2>
                    <div className="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal">
                      <i className="ki-duotone ki-cross fs-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </div>
                  </div>
                  <div className="modal-body pt-10 pb-15 px-lg-17">
                    <div className="form-group fv-row">
                      <div className="d-flex">
                        <div className="form-check form-check-custom form-check-solid">
                          <input className="form-check-input me-3" name="move_to_folder" type="radio" value="0" id="kt_modal_move_to_folder_0" />
                          <label className="form-check-label" htmlFor="kt_modal_move_to_folder_0">
                            <div className="fw-bold">
                            <i className="ki-duotone ki-folder fs-6 text-primary me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>account</div>
                          </label>
                        </div>
                      </div>
                      <div className='separator separator-dashed my-5'></div>
                      <div className="d-flex">
                        <div className="form-check form-check-custom form-check-solid">
                          <input className="form-check-input me-3" name="move_to_folder" type="radio" value="1" id="kt_modal_move_to_folder_1" />
                          <label className="form-check-label" htmlFor="kt_modal_move_to_folder_1">
                            <div className="fw-bold">
                            <i className="ki-duotone ki-folder fs-6 text-primary me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>apps</div>
                          </label>
                        </div>
                      </div>
                      <div className='separator separator-dashed my-5'></div>
                      <div className="d-flex">
                        <div className="form-check form-check-custom form-check-solid">
                          <input className="form-check-input me-3" name="move_to_folder" type="radio" value="2" id="kt_modal_move_to_folder_2" />
                          <label className="form-check-label" htmlFor="kt_modal_move_to_folder_2">
                            <div className="fw-bold">
                            <i className="ki-duotone ki-folder fs-6 text-primary me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>widgets</div>
                          </label>
                        </div>
                      </div>
                      <div className='separator separator-dashed my-5'></div>
                      <div className="d-flex">
                        <div className="form-check form-check-custom form-check-solid">
                          <input className="form-check-input me-3" name="move_to_folder" type="radio" value="3" id="kt_modal_move_to_folder_3" />
                          <label className="form-check-label" htmlFor="kt_modal_move_to_folder_3">
                            <div className="fw-bold">
                            <i className="ki-duotone ki-folder fs-6 text-primary me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>assets</div>
                          </label>
                        </div>
                      </div>
                      <div className='separator separator-dashed my-5'></div>
                      <div className="d-flex">
                        <div className="form-check form-check-custom form-check-solid">
                          <input className="form-check-input me-3" name="move_to_folder" type="radio" value="4" id="kt_modal_move_to_folder_4" />
                          <label className="form-check-label" htmlFor="kt_modal_move_to_folder_4">
                            <div className="fw-bold">
                            <i className="ki-duotone ki-folder fs-6 text-primary me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>documentation</div>
                          </label>
                        </div>
                      </div>
                      <div className='separator separator-dashed my-5'></div>
                      <div className="d-flex">
                        <div className="form-check form-check-custom form-check-solid">
                          <input className="form-check-input me-3" name="move_to_folder" type="radio" value="5" id="kt_modal_move_to_folder_5" />
                          <label className="form-check-label" htmlFor="kt_modal_move_to_folder_5">
                            <div className="fw-bold">
                            <i className="ki-duotone ki-folder fs-6 text-primary me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>layouts</div>
                          </label>
                        </div>
                      </div>
                      <div className='separator separator-dashed my-5'></div>
                      <div className="d-flex">
                        <div className="form-check form-check-custom form-check-solid">
                          <input className="form-check-input me-3" name="move_to_folder" type="radio" value="6" id="kt_modal_move_to_folder_6" />
                          <label className="form-check-label" htmlFor="kt_modal_move_to_folder_6">
                            <div className="fw-bold">
                            <i className="ki-duotone ki-folder fs-6 text-primary me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>modals</div>
                          </label>
                        </div>
                      </div>
                      <div className='separator separator-dashed my-5'></div>
                      <div className="d-flex">
                        <div className="form-check form-check-custom form-check-solid">
                          <input className="form-check-input me-3" name="move_to_folder" type="radio" value="7" id="kt_modal_move_to_folder_7" />
                          <label className="form-check-label" htmlFor="kt_modal_move_to_folder_7">
                            <div className="fw-bold">
                            <i className="ki-duotone ki-folder fs-6 text-primary me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>authentication</div>
                          </label>
                        </div>
                      </div>
                      <div className='separator separator-dashed my-5'></div>
                      <div className="d-flex">
                        <div className="form-check form-check-custom form-check-solid">
                          <input className="form-check-input me-3" name="move_to_folder" type="radio" value="8" id="kt_modal_move_to_folder_8" />
                          <label className="form-check-label" htmlFor="kt_modal_move_to_folder_8">
                            <div className="fw-bold">
                            <i className="ki-duotone ki-folder fs-6 text-primary me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>dashboards</div>
                          </label>
                        </div>
                      </div>
                      <div className='separator separator-dashed my-5'></div>
                      <div className="d-flex">
                        <div className="form-check form-check-custom form-check-solid">
                          <input className="form-check-input me-3" name="move_to_folder" type="radio" value="9" id="kt_modal_move_to_folder_9" />
                          <label className="form-check-label" htmlFor="kt_modal_move_to_folder_9">
                            <div className="fw-bold">
                            <i className="ki-duotone ki-folder fs-6 text-primary me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>pages</div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-center mt-12">
                      <button type="button" className="btn btn-primary" id="kt_modal_move_to_folder_submit">
                        <span className="indicator-label">Save</span>
                        <span className="indicator-progress">Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Folder;