import React from "react";
import { Card, Dropdown } from "react-bootstrap";
import { CountryOption, countryList } from 'constant';
import NarrowDown from 'components/icons/NarrowDown';

const OrgInfo = () => {
    const [languageOption, setLanguageOption] = React.useState<CountryOption>();
    const [filteredCountryList, setFilteredCountryList] = React.useState<CountryOption[]>(countryList);
    const [searchKey, setSearchKey] = React.useState<string>('');

    const setLanguage = (value: string) => {
        const option = countryList.filter(item => item.CountryCode === value)[0];
        setLanguageOption(option);
    }

    const handleFilterCountry = (searchKey: string) => {
        let filteredCountry = countryList.filter(country => country.CountryName.toLowerCase().startsWith(searchKey));
        setFilteredCountryList(filteredCountry);
    }

    return (
        <Card id="organisationInformation">
            <Card.Header>
                <Card.Title className="fs-7">Organisations Information</Card.Title>
            </Card.Header>
            <Card.Body>
                <div className="row mb-4">
                    <label htmlFor="emailLabel" className="col-sm-3 col-form-label form-label text-notrans">Organisation Name</label>

                    <div className="col-sm-9">
                        <input type="email" className="form-control" name="email" id="emailLabel" placeholder="Email" aria-label="Email" value="Enter Organisation Name" />
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="emailLabel" className="col-sm-3 col-form-label form-label text-notrans">Domain(s)</label>

                    <div className="col-sm-9">
                        <input type="email" className="form-control" name="email" id="emailLabel" placeholder="Email" aria-label="Email" value="Enter Domain Name" />
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="emailLabel" className="col-sm-3 col-form-label form-label text-notrans">Organisation's website</label>

                    <div className="col-sm-9">
                        <input type="email" className="form-control" name="email" id="emailLabel" placeholder="Email" aria-label="Email" value="Enter Website" />
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="emailLabel" className="col-sm-3 col-form-label form-label text-notrans">Contact Email</label>

                    <div className="col-sm-9">
                        <input type="email" className="form-control" name="email" id="emailLabel" placeholder="Email" aria-label="Email" value="Enter Contact Email" />
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="phoneLabel" className="col-sm-3 col-form-label form-label text-notrans">Contact Telephone Number<span className="text-400"> (Optional)</span></label>

                    <div className="col-sm-9">
                        <input type="text" className="js-input-mask form-control" name="phone" id="phoneLabel" placeholder="+x(xxx)xxx-xx-xx" aria-label="+x(xxx)xxx-xx-xx" value="+44 (020) 97-22-22"/>
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="organizationLabel" className="col-sm-3 col-form-label form-label text-notrans">Currency</label>

                    <div className="col-sm-9">
                        <select className="form-select" placeholder="Select currency">
                            <option value="usd">USD</option>
                            <option value="euro">EURO</option>
                        </select>
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="departmentLabel" className="col-sm-3 col-form-label form-label text-notrans">Department</label>

                    <div className="col-sm-9">
                        <input type="text" className="form-control" name="department" id="departmentLabel" placeholder="Your department" aria-label="Your department" />
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="locationLabel" className="col-sm-3 col-form-label form-label text-notrans">Account Settings</label>

                    <div className="col-sm-9">
                        <select className="form-select">
                            <option value="organisation">Organisation</option>
                            <option value="team">Team</option>
                            <option value="sprint">Sprint</option>
                        </select>
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="locationLabel" className="col-sm-3 col-form-label form-label text-notrans">Account Type</label>

                    <div className="col-sm-9">
                        <select className="form-select">
                            <option value="government">Government</option>
                            <option value="company">Company</option>
                            <option value="social">Social</option>
                            <option value="personal">Personal</option>
                        </select>
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="locationLabel" className="col-sm-3 col-form-label form-label text-notrans">Headquarters</label>

                    <div className="col-sm-9">
                        <div className="btn-reveal-trigger-custom mx-auto">
                            <Dropdown
                                onSelect={e => {if (e) setLanguage(e)}}
                                >
                                <Dropdown.Toggle as='div' className='cursor-pointer'>
                                    <div className='d-flex p-1 px-3 align-items-center'>
                                    <div className='rounded-pill widget-header-flag' style={{backgroundImage: `url('http://purecatamphetamine.github.io/country-flag-icons/3x2/` + `${languageOption?.CountryCode}` + `.svg')`, width: 28, height: 28}}>
                                    </div>
                                    <label className='text-black ms-2 flex-1 cursor-pointer'>
                                        {languageOption?.CountryName}
                                    </label>
                                    <NarrowDown color='black'/>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='w-100'>
                                    <div className="px-3 mb-1">
                                        <input type="text" className="form-control" placeholder="search" value={searchKey} onChange={e => handleFilterCountry(e.target.value)} />
                                    </div>
                                    <div style={{height: 180, overflow: 'scroll'}}>
                                    {filteredCountryList.map((country, index) => {
                                        return (
                                        <Dropdown.Item eventKey={country.CountryCode} key={index}>
                                            <div className='d-flex align-items-center'>
                                            <div className='rounded-pill widget-header-flag' style={{backgroundImage: `url('http://purecatamphetamine.github.io/country-flag-icons/3x2/` + `${country?.CountryCode}` + `.svg')`, width: 28, height: 28}}>
                                            </div>
                                            <label className='text-black ms-2 flex-1 font-14 cursor-pointer'>
                                                {country?.CountryName}
                                            </label>
                                            </div>
                                        </Dropdown.Item>
                                        )
                                    })}
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="locationLabel" className="col-sm-3 col-form-label form-label text-notrans">Time Zone</label>

                    <div className="col-sm-9">
                        <div className="btn-reveal-trigger-custom mx-auto">
                            <Dropdown
                                onSelect={e => {if (e) setLanguage(e)}}
                                >
                                <Dropdown.Toggle as='div' className='cursor-pointer'>
                                    <div className='d-flex p-1 px-3 align-items-center'>
                                    <div className='rounded-pill widget-header-flag' style={{backgroundImage: `url('http://purecatamphetamine.github.io/country-flag-icons/3x2/` + `${languageOption?.CountryCode}` + `.svg')`, width: 28, height: 28}}>
                                    </div>
                                    <label className='text-black ms-2 flex-1 cursor-pointer'>
                                        {languageOption?.CountryName}
                                    </label>
                                    <NarrowDown color='black'/>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='w-100'>
                                    <div className="px-3 mb-1">
                                        <input type="text" className="form-control" placeholder="search" value={searchKey} onChange={e => handleFilterCountry(e.target.value)} />
                                    </div>
                                    <div style={{height: 180, overflow: 'scroll'}}>
                                    {filteredCountryList.map((country, index) => {
                                        return (
                                        <Dropdown.Item eventKey={country.CountryCode} key={index}>
                                            <div className='d-flex align-items-center'>
                                            <div className='rounded-pill widget-header-flag' style={{backgroundImage: `url('http://purecatamphetamine.github.io/country-flag-icons/3x2/` + `${country?.CountryCode}` + `.svg')`, width: 28, height: 28}}>
                                            </div>
                                            <label className='text-black ms-2 flex-1 font-14 cursor-pointer'>
                                                {country?.CountryName}
                                            </label>
                                            </div>
                                        </Dropdown.Item>
                                        )
                                    })}
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="locationLabel" className="col-sm-3 col-form-label form-label text-notrans">Default Language</label>

                    <div className="col-sm-9">
                        <div className="btn-reveal-trigger-custom mx-auto">
                            <Dropdown
                                onSelect={e => {if (e) setLanguage(e)}}
                                >
                                <Dropdown.Toggle as='div' className='cursor-pointer'>
                                    <div className='d-flex p-1 px-3 align-items-center'>
                                    <div className='rounded-pill widget-header-flag' style={{backgroundImage: `url('http://purecatamphetamine.github.io/country-flag-icons/3x2/` + `${languageOption?.CountryCode}` + `.svg')`, width: 28, height: 28}}>
                                    </div>
                                    <label className='text-black ms-2 flex-1 cursor-pointer'>
                                        {languageOption?.CountryName}
                                    </label>
                                    <NarrowDown color='black'/>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='w-100'>
                                    <div className="px-3 mb-1">
                                        <input type="text" className="form-control" placeholder="search" value={searchKey} onChange={e => handleFilterCountry(e.target.value)} />
                                    </div>
                                    <div style={{height: 180, overflow: 'scroll'}}>
                                    {filteredCountryList.map((country, index) => {
                                        return (
                                        <Dropdown.Item eventKey={country.CountryCode} key={index}>
                                            <div className='d-flex align-items-center'>
                                            <div className='rounded-pill widget-header-flag' style={{backgroundImage: `url('http://purecatamphetamine.github.io/country-flag-icons/3x2/` + `${country?.CountryCode}` + `.svg')`, width: 28, height: 28}}>
                                            </div>
                                            <label className='text-black ms-2 flex-1 font-14 cursor-pointer'>
                                                {country?.CountryName}
                                            </label>
                                            </div>
                                        </Dropdown.Item>
                                        )
                                    })}
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="organizationLabel" className="col-sm-3 col-form-label form-label text-notrans"></label>

                    <div className="col-sm-9">
                        <input type="text" className="form-control" name="organization" id="organizationLabel" placeholder="City" aria-label="Your organization" />
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="organizationLabel" className="col-sm-3 col-form-label form-label text-notrans"></label>

                    <div className="col-sm-9">
                        <input type="text" className="form-control" name="organization" id="organizationLabel" placeholder="State" aria-label="Your organization" />
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="organizationLabel" className="col-sm-3 col-form-label form-label text-notrans">Address line 1</label>

                    <div className="col-sm-9">
                        <input type="text" className="form-control" name="organization" id="organizationLabel" placeholder="Your address" aria-label="Your organization" />
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="addressLine2Label" className="col-sm-3 col-form-label form-label text-notrans">Address line 2 <span className="text-400">(Optional)</span></label>

                    <div className="col-sm-9">
                      <input type="text" className="form-control" name="addressLine2" id="addressLine2Label" placeholder="Your address" aria-label="Your address" />
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="zipCodeLabel" className="col-sm-3 col-form-label form-label text-notrans">Zip code <i className="bi-question-circle text-body ms-1" data-bs-toggle="tooltip" data-bs-placement="top" title="You can find your code in a postal address."></i></label>

                    <div className="col-sm-9">
                      <input type="text" className="js-input-mask form-control" name="zipCode" id="zipCodeLabel" placeholder="Your zip code" aria-label="Your zip code" value="KW5 8NW" />
                    </div>
                </div>
                <div className="row align-items-center mb-4">
                    <label className="col-sm-3 col-form-label form-label text-notrans">Disable ads <span className="badge bg-primary text-uppercase ms-1">PRO</span></label>

                    <div className="col-sm-9">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                          With your Pro account, you can disable ads across the site.
                        </label>
                      </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary">Save changes</button>
                </div>
            </Card.Body>
        </Card>
    )
}

export default OrgInfo;