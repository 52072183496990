import { useState, useEffect, useRef } from "react";
import { Card } from "react-bootstrap";
import { useChatContext } from "providers/ChatProvider";
import PhoenixOffcanvas, {
  PhoenixOffcanvasContainer,
} from "components/base/PhoenixOffcanvas";
import ConversationDetails from "../conversation-details";
import ChatSidebar from "../ChatSidebar";
import { useBreakpoints } from "providers/BreakpointsProvider";
import ChatContentHeader from "./ChatContentHeader";
import ConversationStarter from "./ConversationStarter";
import Message from "../message";
import Scrollbar from "components/base/Scrollbar";
import CustomChatFooter from "./CustomChatFooter";
import { AttachInfo, Message as MessageType, User as UserType } from "data/chat";
import ModalViews from "components/support-chat/ModalViews";
import ChatStateIndicator from "components/support-chat/ChatStateIndicator";
import classNames from "classnames";
import { sendMessage } from "hyper_helpers/api/chat";

const ChatContent = () => {
  const {
    currentConversation,
    chatDispatch,
    showConversationDetails,
    showUserListOffcanvas,
    setShowConversationDetails,
    feedBackModalState,
    setAiLoading,
    showSources,
    setShowSources,
    setSpeaking,
    setSpeechAgent,
    menuOption,
    feedBackStep,
    setMenuOption,
    setFeedBackModalState,
    setFeedBackModalStep
  } = useChatContext();

  const messageEndRef = useRef<null | HTMLSpanElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [percent, setPercent] = useState(0);

  const isJson = (str: string) => {
    try {
      JSON.parse(str);
    } catch(err) {
      return false;
    }
    return true;
  }

  const { breakpoints } = useBreakpoints();

  useEffect(() => {
    chatDispatch({
      type: "SET_CHAT_STATE",
      payload: {
        showConversationDetails: false,
        showUserListOffcanvas: false,
      },
    });

    messageEndRef.current?.scrollIntoView();
  }, [currentConversation]);

  const goToMsgDetail = (e: MessageType) => {
    const msgNode = document.querySelector(`#chat_${new Date(e.time).getTime()}`);
    if(msgNode) msgNode.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  }

  const sentMessage = async (msg: string) => {
    if (currentConversation) {
      chatDispatch({
        type: 'SENT_MESSAGE',
        payload: {
          conversationId: currentConversation.id,
          message: msg,
          type: 'sent'
        }
      });

      // bot
      if (currentConversation.user.bot) {
        setAiLoading(true);
        sendMessage({message_content: msg, sender: "user",
          uploadHandle: (progressEvent: any) => {
            if (progressEvent.total) {
              setPercent(Math.round(
                (20 * progressEvent.loaded) / progressEvent.total
              ));
            }
          },
          downloadHandle: (e: any) => {
            if (e.total) {
              setPercent(10 + Math.round(
                (5 * e.loaded) / e.total
              ));
            }
          }
        })
          .then(resp => {
            if (resp && resp.data.res_msg.content) {
              setAiLoading(false);
              let botMessage = '';
              let attachments: AttachInfo[] = [];
              if(isJson(resp.data.res_msg.content)) {
                botMessage = JSON.parse(resp.data.res_msg.content).text;
                attachments = JSON.parse(resp.data.res_msg.content).attachments;
              } else {
                botMessage = resp.data.res_msg.content;
              }
              setSpeechAgent('bot');
              chatDispatch({
                type: 'SENT_MESSAGE',
                payload: {
                  conversationId: currentConversation.id,
                  type: 'received',
                  message: botMessage,
                  attachments: attachments
                }
              });
              fetch("https://api.localgovai.uk:8000/v1/comm/msg/text-to-speech", {
                method: 'POST',
                body: JSON.stringify({
                  text: resp.data.res_msg.content
                })
              })
                .then((res: any) => res.arrayBuffer())
                .then(buffer => {
                  
                  const blob = new Blob([buffer], {type: 'audio/wav'});
                  const url = URL.createObjectURL(blob);
                  if(audioRef.current) {
                    audioRef.current.src = url;
                    setSpeaking(true);
                    audioRef.current.play();
                  }
                })
            }
          })
          .catch(err => {
            setAiLoading(false);
            console.log(err);
          });
      }
    }
  }

  useEffect(() => {
    console.log('menu option ====>', menuOption);
    if(menuOption && menuOption === 'close') {
      setFeedBackModalState(true);
      setFeedBackModalStep('leave');
    }
    if(menuOption && menuOption === 'finish') {
      console.log('feedBackStep =====>', feedBackStep);
      if(feedBackStep === 'feedback_sent' || feedBackStep === 'share_alert') {
        setMenuOption(null);
        setFeedBackModalState(false);
      } 
    }
  }, [menuOption])

  if (currentConversation) {
    return (
      <Card as={PhoenixOffcanvasContainer} className="h-100 w-100 chat-content">
        <ChatContentHeader msgContent={currentConversation.messages} viewMsgDetail={goToMsgDetail} />
        <Card.Body className="px-4 pt-4 pb-8 scrollbar d-flex flex-column gap-2">
          {currentConversation.messages.length === 0 && <ConversationStarter />}
          {currentConversation.messages.map((message, idx) => (
            <Message
              width='max-w-75'
              message={message}
              user={currentConversation.user}
              // key={message.id}
              key={`msg_${idx}`}
              id={`chat_${new Date(message.time).getTime()}`}
            />
          ))}
          <span ref={messageEndRef} />
          {!showConversationDetails && <ChatStateIndicator percent={percent} />}
        </Card.Body>

        {/* <ChatContentFooter /> */}
        <CustomChatFooter handleMsg={sentMessage} />

        <PhoenixOffcanvas
          open={showConversationDetails}
          placement="top"
          noBackdrop
          className="bg-white w-100 z-index-0 "
        >
          <Scrollbar>
            <ConversationDetails
              conversation={currentConversation}
              handleClose={() => setShowConversationDetails(false)}
            />
          </Scrollbar>
        </PhoenixOffcanvas>

        <PhoenixOffcanvas
          open={showSources}
          placement="end"
          noBackdrop
          className={classNames("z-index", {
            "w-50": breakpoints.up("xl"),
            "w-75": breakpoints.up("sm") && breakpoints.down("xl"),
            "w-100": breakpoints.down("sm"),
          })}
        >
          <Scrollbar className="border rounded">
            <div className="d-flex justify-content-end align-items-center p-1">
              Hide Panel
              <span className="ms-2 cursor-pointer" onClick={() => setShowSources(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M8.35567 8.53451V15.4652L11.8306 11.9999L8.35567 8.53451ZM5.38834 20.3181C4.91739 20.3181 4.5152 20.1514 4.18177 19.818C3.84835 19.4845 3.68164 19.0823 3.68164 18.6114V5.38834C3.68164 4.91739 3.84835 4.5152 4.18177 4.18177C4.5152 3.84835 4.91739 3.68164 5.38834 3.68164H18.6114C19.0823 3.68164 19.4845 3.84835 19.818 4.18177C20.1514 4.5152 20.3181 4.91739 20.3181 5.38834V18.6114C20.3181 19.0823 20.1514 19.4845 19.818 19.818C19.4845 20.1514 19.0823 20.3181 18.6114 20.3181H5.38834ZM16.0604 18.9191H18.9191V5.38834C18.9191 5.31142 18.887 5.24091 18.8229 5.17679C18.7588 5.11269 18.6883 5.08064 18.6114 5.08064H16.0604V18.9191ZM14.6614 18.9191V5.08064H5.38834C5.31142 5.08064 5.24091 5.11269 5.17679 5.17679C5.11269 5.24091 5.08064 5.31142 5.08064 5.38834V18.6114C5.08064 18.6883 5.11269 18.7588 5.17679 18.8229C5.24091 18.887 5.31142 18.9191 5.38834 18.9191H14.6614Z" fill="#015B7E"/>
                </svg>
              </span>
            </div>
            <div className="p-3">
              <h6 className="fs-7 fw-bold">Current Source</h6>
              {currentConversation.messages[0] && <Message
                width='w-100'
                message={currentConversation.messages[0]}
                user={currentConversation.user}
              />}
              <h6 className="fs-7 fw-bold">Sources</h6>
              {currentConversation.messages[1] && <Message
                width='w-100'
                message={currentConversation.messages[1]}
                user={currentConversation.user}
              />}
            </div>
          </Scrollbar>
        </PhoenixOffcanvas>

        {breakpoints.down("sm") && (
          <PhoenixOffcanvas
            open={showUserListOffcanvas}
            placement="start"
            noBackdrop
            className="w-100 z-index-0"
          >
            <ChatSidebar className="border-0 h-100" />
          </PhoenixOffcanvas>
        )}

        {feedBackModalState && (
          <ModalViews />
        )}
        <audio ref={audioRef} style={{display: 'none'}} onEnded={() => {
          setSpeaking(false);
          setSpeechAgent('');
        }}/>
      </Card>
    );
  } else {
    return <></>;
  }
};

export default ChatContent;
