import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Card, Dropdown, Form, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import ChatFilterTab from './ChatFilterTab';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import { useState, useEffect, useMemo } from 'react';
import { ConversationFilterType, useChatContext } from 'providers/ChatProvider';
import { Conversation } from 'data/chat';
import { useNavigate } from 'react-router-dom';

const ChatSidebar = ({ className }: { className?: string }) => {
  const navigate = useNavigate();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [searchKey, setSearchKey] = useState<string>('');

  const { conversations, addNewChat } = useChatContext();
  const [filteredConversations, setFilteredConversations] = useState<
    Conversation[]
  >([]);

  const filterConversations = (type: ConversationFilterType) => {
    setFilteredConversations(
      conversations.filter(conversation => {
        const hasUnreadMessages = conversation.messages.some(
          message => message.type === 'received' && !message.readAt
        );
        return type === 'support'
          ? (conversation.user.bot ? true : false)
          : (type === 'unread' ? hasUnreadMessages : true);
      })
    );
  };
  
  const filterItems = useMemo(
    () => [
      {
        label: 'All',
        eventKey: 'all',
        onClick() {
          filterConversations('all');
        }
      },
      // {
      //   label: 'Read',
      //   eventKey: 'read',
      //   onClick() {
      //     filterConversations('read');
      //   }
      // },
      {
        label: 'Unread',
        eventKey: 'unread',
        onClick() {
          filterConversations('unread');
        }
      },
      {
        label: 'Support',
        eventKey: 'support',
        onClick() {
          filterConversations('support');
        }
      }
    ],
    [filteredConversations]
  );

  useEffect(() => {
    filterConversations('all');
  }, [conversations, searchKey]);

  const handleAddNewConversation = () => {
    addNewChat({
      id: 100,
      user: {
        id: 100,
        status: "online",
        name: 'Contact Service',
        bot: true,
      },
      messages: []
    });
    navigate(`/apps/chat/${100}/conversation`);
  }

  return (
    <>
      <Card className={classNames(className, 'chat-sidebar p-3 p-xl-1')}>
        <Button
          className="d-none d-sm-block d-xl-none mb-2"
          onClick={() => setOpenSearchModal(true)}
        >
          <FontAwesomeIcon icon="magnifying-glass" className="text-600 fs-7" />
        </Button>
        <Dropdown className="d-none d-sm-block d-xl-none mb-5">
          <Dropdown.Toggle
            variant=""
            size="sm"
            className="w-100 mx-auto dropdown-caret-none"
          >
            <FontAwesomeIcon icon="bars" className="fs-7 text-600" />
          </Dropdown.Toggle>
          <Dropdown.Menu align="start" className="p-0" style={{ zIndex: 1020 }}>
            <Dropdown.Item eventKey="all" onClick={() => filterConversations('all')}>All</Dropdown.Item>
            <Dropdown.Item eventKey="unread" onClick={() => filterConversations('unread')}>Unread</Dropdown.Item>
            <Dropdown.Item eventKey="support" onClick={() => filterConversations('support')}>Support</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className='d-flex justify-content-between align-items-center mb-4'>
          <Form.Group className="form-icon-container d-sm-none d-xl-block w-100">
            <Form.Control
              type="text"
              placeholder="People, Groups and Messages"
              className="form-icon-input"
              value={searchKey}
              onChange={e => setSearchKey(e.target.value)}
            />
            <FontAwesomeIcon icon="user" className="text-900 fs-9 form-icon" />
          </Form.Group>
          <FontAwesomeIcon icon="pencil" className='mx-3 cursor-pointer' onClick={handleAddNewConversation} />
        </div>
        <ChatFilterTab filterItems={filterItems} filteredConversations={filteredConversations.filter(conversation => conversation.user.name.toLowerCase().includes(searchKey))}  />
      </Card>
      <Modal
        show={openSearchModal}
        onHide={() => setOpenSearchModal(false)}
        className="search-box-modal mt-15"
      >
        <Modal.Body className="p-0 bg-transparent">
          <DropdownSearchBox
            placeholder="Search People, Groups and Messages"
            size="lg"
            style={{ width: 'auto' }}
            autoFocus
            value={searchKey}
            onChange={e => setSearchKey(e.target.value)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChatSidebar;
