import { Conversation } from 'data/chat';
import React, {
  Dispatch,
  PropsWithChildren,
  createContext,
  useContext,
  useReducer
} from 'react';
import { ACTIONTYPE, chatReducer } from 'reducers/ChatReducer';

export type ConversationFilterType = 'all' | 'read' | 'unread' | 'support';
interface ChatProviderInterface {
  conversations: Conversation[];
}

export interface ChatState {
  conversations: Conversation[];
  currentConversation: null | Conversation;
  filterBy: ConversationFilterType;
  showConversationDetails: boolean;
  showUserListOffcanvas: boolean;
  micPermissionGranted: boolean;
  isSpeaking: boolean;
  speechAgent: string;
  feedBackModalState: boolean;
  feedBackStep: string;
  aiLoading: boolean;
  language: string;
  menuOption: string | null;
  showSources: boolean;
  volume: number;
}

interface ChatContextInterface extends ChatState {
  // chatState: ChatState;
  chatDispatch: Dispatch<ACTIONTYPE>;
  setShowConversationDetails: (value: boolean) => void;
  setShowUserListOffcanvas: (value: boolean) => void;
  setMicPermission: (value: boolean) => void;
  setSpeaking: (value: boolean) => void;
  setSpeechAgent: (value: string) => void;
  setFeedBackModalState: (value: boolean) => void;
  setFeedBackModalStep: (value: string) => void;
  setAiLoading: (value: boolean) => void;
  setLanguage: (value?: string) => void;
  setMenuOption: (value: string | null) => void;
  setShowSources: (value: boolean) => void;
  addNewChat: (value: Conversation) => void;
  setAudioVolume: (value: number) => void;
}

export const ChatContext = createContext({} as ChatContextInterface);

const ChatProvider = ({
  children,
  conversations
}: PropsWithChildren<ChatProviderInterface>) => {
  const initState: ChatState = {
    conversations: conversations,
    currentConversation: null,
    filterBy: 'all',
    showUserListOffcanvas: false,
    showConversationDetails: false,
    micPermissionGranted: false,
    isSpeaking: false,
    speechAgent: '',
    feedBackModalState: false,
    feedBackStep: '',
    aiLoading: false,
    language: 'US',
    menuOption: '',
    showSources: false,
    volume: 0,
  };

  const [chatState, chatDispatch] = useReducer(chatReducer, initState);

  const setShowConversationDetails = (value: boolean) => {
    chatDispatch({
      type: 'SET_CHAT_STATE',
      payload: { showConversationDetails: value }
    });
  };

  const setShowUserListOffcanvas = (value: boolean) => {
    chatDispatch({
      type: 'SET_CHAT_STATE',
      payload: { showUserListOffcanvas: value }
    });
  };

  const setMicPermission = (value: boolean) => {
    chatDispatch({
      type: 'SET_CHAT_STATE',
      payload: { micPermissionGranted: value }
    })
  }

  const setSpeaking = (value: boolean) => {
    chatDispatch({
      type: 'SET_CHAT_STATE',
      payload: { isSpeaking: value }
    })
  }

  const setSpeechAgent = (value: string) => {
    chatDispatch({
      type: 'SET_CHAT_STATE',
      payload: { speechAgent: value }
    })
  }

  const setFeedBackModalState = (value: boolean) => {
    chatDispatch({
      type: 'SET_CHAT_STATE',
      payload: { feedBackModalState: value }
    })
  }

  const setFeedBackModalStep = (value: string) => {
    chatDispatch({
      type: 'SET_CHAT_STATE',
      payload: { feedBackStep: value }
    })
  }

  const setAiLoading = (value: boolean) => {
    chatDispatch({
      type: 'SET_CHAT_STATE',
      payload: { aiLoading: value }
    })
  }

  const setLanguage = (value?: string) => {
    chatDispatch({
      type: 'SET_CHAT_STATE',
      payload: { language: value }
    })
  }

  const setMenuOption = (value: string | null) => {
    chatDispatch({
      type: 'SET_CHAT_STATE',
      payload: { menuOption: value }
    })
  }

  const setShowSources = (value: boolean) => {
    chatDispatch({
      type: 'SET_CHAT_STATE',
      payload: { showSources: value }
    })
  }

  const addNewChat = (newChat: Conversation) => {
    chatDispatch({
      type: 'ADD_NEW_CHAT',
      payload: newChat
    })
  }

  const setAudioVolume = (value: number) => {
    chatDispatch({
      type: 'SET_CHAT_STATE',
      payload: { volume: value }
    })
  }

  return (
    <ChatContext.Provider
      value={{
        ...chatState,
        chatDispatch,
        setShowConversationDetails,
        setShowUserListOffcanvas,
        setMicPermission,
        setSpeaking,
        setSpeechAgent,
        setFeedBackModalState,
        setFeedBackModalStep,
        setAiLoading,
        setLanguage,
        setMenuOption,
        setShowSources,
        addNewChat,
        setAudioVolume
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => useContext(ChatContext);

export default ChatProvider;
