import { FileItem } from "data/fileManager";
import { FileManagerState } from "providers/FileManagerProvider";


export type ACTIONTYPE = 
  | {type: 'SET_FILE_MANAGER_STATE'; payload: Partial<FileManagerState>}
  | {type: 'CREATE_FOLDER'; payload: {name: string}}
  | {type: 'UPDATE_NAME_BY_INDEX'; payload: {index: number, name: string}}
  | {type: 'REMOVE_FILE', payload: { folderIndex?: number | null, fileIndex?: number | null}}

export const FileManagerReducer = (state: FileManagerState, action: ACTIONTYPE) => {
  switch(action.type) {
    case 'SET_FILE_MANAGER_STATE': {
      const { payload } = action;
      return {
        ...state,
        ...payload
      };
    }
    case 'CREATE_FOLDER': {
      const { payload } = action;
      const folders = [...state.folders, {
        name: payload.name,
        created: new Date().getTime().toString(),
        type: 'folder'
      } as FileItem]
      return {
        ...state,
        folders,
        tempFolders: folders
      }
    }
    case 'UPDATE_NAME_BY_INDEX': {
      const { payload } = action;
      let folders = state.folders;
      folders[payload.index].name = payload.name;
      return {
        ...state,
        folders,
        editIndex: null,
        tempFolders: folders
      }
    }
    case 'REMOVE_FILE': {
      const { fileIndex, folderIndex } = action.payload;
      if (fileIndex && folderIndex) {
        let folders = state.folders;
        folders[folderIndex].files.splice(fileIndex, 1);
        return {
          ...state,
          folders,
          tempFolders: folders
        }
      }
      if(folderIndex) {
        let folders = state.folders;
        folders.splice(folderIndex, 1);
        return {
          ...state,
          folders,
          tempFolders: folders
        }
      }
      return {
        ...state
      }
    }
    default:
      return state;
      
  }
}