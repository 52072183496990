import { Card, Alert, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { CardTitle } from "hyper_components";
import googleLogo from "assets/images/social-logos/google.svg";
import githubLogo from "assets/images/social-logos/github.svg";
import slackLogo from "assets/images/social-logos/slack.svg";

const ConnectedAccounts = () => {
  return (
    <Card>
      <Card.Header>
        <div>
          <h4 className="my-4">Connected Accounts</h4>
        </div>
      </Card.Header>
      <Card.Body>
        <Alert
          variant="info"
          className="text-dark border-dashed border-2 border rounded"
        >
          By connecting an account, you hereby agree to our{" "}
          <strong className="text-primary">privacy policy</strong> and{" "}
          <strong className="text-primary">terms of use</strong>.
        </Alert>
        <div className="d-flex align-items-center border border-light rounded p-3 mb-2">
          <div className="flex-shrink-0 me-3">
            <img src={googleLogo} />
          </div>
          <div className="flex-grow-1">
            <h5 className="fw-semibold my-0">Google</h5>
            <p className="mb-0">Plan properly your workflow</p>
          </div>
          <div className="flex-shrink-0 my-0">
            <Form.Check type="checkbox" id="showPhone" className="form-switch">
              <Form.Check.Input
                type="checkbox"
                name="showPhone"
                value="option2"
                defaultChecked
              />
            </Form.Check>
          </div>
        </div>
        <div className="d-flex align-items-center border border-light rounded p-3 mb-2">
          <div className="flex-shrink-0 me-3">
            <img src={githubLogo} />
          </div>
          <div className="flex-grow-1">
            <h5 className="fw-semibold my-0">Github</h5>
            <p className="mb-0">Keep eye on on your Repositories</p>
          </div>
          <div className="flex-shrink-0 my-0">
            <Form.Check type="checkbox" id="showPhone" className="form-switch">
              <Form.Check.Input
                type="checkbox"
                name="showPhone"
                value="option2"
                defaultChecked
              />
            </Form.Check>
          </div>
        </div>
        <div className="d-flex align-items-center border border-light rounded p-3 mb-2">
          <div className="flex-shrink-0 me-3">
            <img src={slackLogo} />
          </div>
          <div className="flex-grow-1">
            <h5 className="fw-semibold my-0">Slack</h5>
            <p className="mb-0">Integrate Projects Discussions</p>
          </div>
          <div className="flex-shrink-0 my-0">
            <Form.Check type="checkbox" id="showPhone" className="form-switch">
              <Form.Check.Input
                type="checkbox"
                name="showPhone"
                value="option2"
              />
            </Form.Check>
          </div>
        </div>
        <div className="d-flex rounded justify-content-center p-3">
          <button className="btn btn-primary">Save Changes</button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ConnectedAccounts;
