import React from "react";
import { Card } from "react-bootstrap";

const TwoStepVerification = () => {
    return (
        <Card id="twoStepVerification">
            <Card.Header>
                <Card.Title className="fs-7">
                    <p className="my-0">Two-step verification</p>
                    <span className="badge bg-soft-danger text-danger ms-2">Disabled</span>
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <p className="card-text">Start by entering your password so that we know it's you. Then we'll walk you through two more simple steps.</p>
                <div className="row mb-4">
                    <label htmlFor="accountPasswordLabel" className="col-sm-3 col-form-label form-label text-notrans">Account password</label>

                    <div className="col-sm-9">
                      <input type="password" className="form-control" name="currentPassword" id="accountPasswordLabel" placeholder="Enter current password" aria-label="Enter current password" />
                      <small className="form-text">This is the password you use to log in to your Front account.</small>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary">Set up</button>
                </div>
            </Card.Body>
        </Card>
    );
}

export default TwoStepVerification;