import { useFileManagerContext } from 'providers/FileManagerProvider';
import * as React from 'react';

const FileManagerBreadCrumb = () => {

  const { folders } = useFileManagerContext();

  return (
    <div className="d-flex flex-stack">
      <div className="badge badge-lg text-primary">
        <div className="d-flex align-items-center flex-wrap fs-8">
          {/* <i className="ki-duotone ki-abstract-32 fs-8 text-primary me-3">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
          <a href="#" className='fs-8'>Keenthemes</a>
          <i className="ki-duotone ki-right fs-8 text-primary mx-1"></i>
          <a href="#" className='fs-8'>themes</a>
          <i className="ki-duotone ki-right fs-8 text-primary mx-1"></i>
          <a href="#" className='fs-8'>html</a>
          <i className="ki-duotone ki-right fs-8 text-primary mx-1"></i>
          demo1 */}
        </div>
      </div>
      <div className="badge badge-sm bg-primary">
        <span id="kt_file_manager_items_counter">{folders.length} items</span>
      </div>
    </div>
  )
}

export default FileManagerBreadCrumb;