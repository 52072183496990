import React from "react";
import { Card, Button } from "react-bootstrap";

const DeactivateAccount = () => {
    return (
        <Card id="deactivateAccount">
            <Card.Header>
                <Card.Title className="fs-7">Deactivate account</Card.Title>
            </Card.Header>
            <Card.Body>
                <p className="card-text">Deactivating an account, you lose access to Local Gov.Ai account products, and data. You can cancel the Deactivation within calendar 14 days.</p>
                <div className="mb-4">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="deleteAccountCheckbox" />
                    <label className="form-check-label" htmlFor="deleteAccountCheckbox">
                      I confirm that I want to delete [Organisation Name] account.
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-end gap-3">
                  <Button className="border border-light" variant="outline-light" to="#">Learn more</Button>
                  <Button variant="danger">Deactivate</Button>
                </div>
            </Card.Body>
        </Card>
    );
}

export default DeactivateAccount;