import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown, faLocationDot, faUserCircle, faCopy, faKey, faLock, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "../Header";
import Availability from "./Availability";
import Urls from "./Urls";
import TelephoneNumber from "./TelephoneNumber";
import ConnectedService from "./ConnectedService";
import ConnectedUrl from "./ConnectedUrl";
import Question from "./Question";
import TaskSummary from "./TaskSummary";
import TaskOvertime from "./TaskOvertime";
import WhatOnRoad from "./WhatOnRoad";
import LatestFiles from "./LatestFiles";
import NewContributers from "./NewContributers";
import MyTask from "./MyTask";
import ProjectSpending from "./ProjectSpending";
import { RootState } from "redux/store";

const Project = () => {
    const { knowledgeId } = useParams();
    const { knowledgeBases } = useSelector((state: RootState) => state.KnowledgeBase);
    const knowledgeBase = knowledgeBases.find((item: any) => item.KnowledgeBaseID === knowledgeId);

    return (
        <div>
            <Header knowledgeBase={knowledgeBase} active="overview" />
            <div className="row g-3 g-xl-4 mb-4">
                <Availability knowledgeBase={knowledgeBase} />
                <TelephoneNumber number={knowledgeBase?.KnowledgeBasePhone} />
                <Urls />
                <ConnectedService />
            </div>
            <ConnectedUrl />
            <Question knowledgeBaseId={knowledgeBase?.KnowledgeBaseID} />
            <div className="row g-3 g-xl-4">
                <TaskSummary />
                <TaskOvertime />
                <WhatOnRoad />
                <LatestFiles />
                <NewContributers />
                <MyTask />
            </div>
            <ProjectSpending />
        </div>
    );
}

export default Project;