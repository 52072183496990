import React from "react";
import { Button } from "react-bootstrap";
import { Schedule } from "../types";
import { dateData, scheduleData } from "../data";

const WhatOnRoad = () => {
    const [curDate, setCurDate] = React.useState<number>(22);

    return (
        <div className="col-lg-6">
            <div className="card card-flush h-lg-100">
                <div className="card-header mt-3">
                    <div className="card-title flex-column">
                        <h3 className="fw-bold mb-1 fs-7">What's on the road?</h3>
                        <div className="fs-8 text-gray-400">Total 482 participants</div>
                    </div>
                    <div className="card-toolbar">
                        <select name="status" data-control="select2" data-hide-search="true" className="form-select form-select-solid form-select-sm fw-bold">
                            <option value="1" selected={true}>Options</option>
                            <option value="2">Option 1</option>
                            <option value="3">Option 2</option>
                            <option value="4">Option 3</option>
                        </select>
                    </div>
                </div>
                <div className="card-body p-4 pt-2">
                    <ul className="nav nav-pills d-flex flex-nowrap hover-scroll-x py-2">
                        {
                            dateData.map((date) => <DateItem key={date.date} date={date.date} dayOfWeek={date.dayOfWeek} curDate={curDate} setCurDate={setCurDate} />)
                        }
                    </ul>
                    <div className="tab-content">
                        <ScheduleItem scheduleList={scheduleData[0]} />
                    </div>
                </div>
            </div>
        </div>
    );
}

interface DateItemProps {
    curDate: number;
    dayOfWeek: string;
    date: number;
    setCurDate: (date: number) => void;
}

const DateItem = (props: DateItemProps) => {
    const { curDate, dayOfWeek, date, setCurDate } = props;

    return (
        <li className="nav-item me-1">
            <a
                className={`nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-2 px-2 btn-active-primary ${curDate === date ? "active" : ""}`}
                data-bs-toggle="tab"
                href="#kt_schedule_day_1"
                onClick={() => setCurDate(date)}
            >
                <span className="opacity-50 fs-8 fw-semibold">{dayOfWeek}</span>
                <span className="fs-8 fw-bold">{date}</span>
            </a>
        </li>
    );
}

interface ScheduleItemProps {
    scheduleList: Schedule[]
}

const ScheduleItem = (props: ScheduleItemProps) => {
    const { scheduleList } = props;

    return (
        <div className="tab-pane fade show active">
            {
                scheduleList.map((schedule, idx) => (
                    <div className="d-flex flex-stack position-relative mt-2">
                        <div className="position-absolute h-100 w-4px bg-secondary-lighten rounded top-0 start-0"></div>
                        <div className="fw-semibold ms-3 text-600">
                            <div className="fs-8">
                                {schedule.time}
                                <span className="fs-9 text-400 text-uppercase ms-1">{schedule.noon}</span>
                            </div>
                            <p className="fs-8 fw-bold text-800 text-hover-primary mb-0">{schedule.title}</p>
                            <div className="text-400 fs-8">
                                Lead by
                                <a className="text-decoration-none text-primary ms-1" href="#">{schedule.leader}</a>
                            </div>
                        </div>
                        <Button variant="outline-primary" size="sm">View</Button>
                    </div>
                ))
            }
        </div>
    );
}

export default WhatOnRoad;