import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import ProjectsTopSection from 'components/modules/project-management/ProjectsTopSection';
import ProjectListTable, {
  projectListTableColumns
} from 'components/tables/ProjectListTable';
import { defaultBreadcrumbItems } from 'data/commonData';
import { projects, Project } from 'data/project-management/projects';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { RootState } from "redux/store";
import { members } from 'data/users';
import bg51 from 'assets/phoenix/img/generic/51.png';
import { IKnowledgeBase } from "data/knowledgebase";
import NewKnowledge from "../knowledge-base/newKnowledge";

const ProjectListView = () => {
  const { knowledgeBases } = useSelector((state: RootState) => state.KnowledgeBase);
  const [projectList, setProjectList] = React.useState<Project[]>([]);
  const [showNew, setShowNew] = React.useState<boolean>(false);
  const [showInvite, setShowInvite] = React.useState<boolean>(false);

  React.useEffect(() => {
    let tableData = knowledgeBases.map((knowledge: IKnowledgeBase) => ({
      id: knowledge.KnowledgeBaseID,
      name: knowledge.KnowledgeBaseName,
      start: "Dec 12, 2018",
      deadline: "Dec 12, 2026",
      calculation: {
          amount: "$4",
          label: "Cost",
      },
      assigness: [17, 16, 11, 5, 18, 19].map((index) => members[index]),
      progress: {
          min: 145,
          max: 145,
      },
      statusProgress: {
          ongoing: 30,
          critical: 5,
          inactive: 45,
          completed: 15,
      },
      task: 287,
      status: {
          label: "completed",
          type: "success",
      },
      bg: bg51,
      budget: 3991,
    }))

    setProjectList(tableData);
  }, [knowledgeBases]);

  const table = useAdvanceTable({
    data: projectList,
    columns: projectListTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">My Knowledge Bases</span>{' '}
            <span className="fw-normal text-700">({knowledgeBases.length})</span>
          </h2>
          <button className="btn btn-primary px-5" onClick={() => setShowNew(true)}>
            <FontAwesomeIcon icon="plus" className="me-2" />
            Add New Knowledge Base
          </button>
        </div>
        <ProjectsTopSection activeView="list" />
        <ProjectListTable />
      </AdvanceTableProvider>

      <NewKnowledge showNew={showNew} setShowNew={setShowNew} showInvite={showInvite} setShowInvite={setShowInvite} />
    </div>
  );
};

export default ProjectListView;
