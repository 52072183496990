import { APICore } from "./apiCore";

const api = new APICore();

type RegisterData = {
    account_type: string;

    domain_name: string;
    confirm_domain: string;
    team_size: string;
    account_plan: string;

    organization_name: string;
    organization_description: string;
    organization_type: string;
    contact_email: string;

    first_name: string;
    last_name: string;
    username: string;
    email: string;
    password: string;
    confirm_password: string;
};

function login(params: { email: string; password: string }) {
    const baseUrl = "/v1/auth/login";
    return api.create(`${baseUrl}`, params);
}

function logout() {
    const baseUrl = "/v1/auth/logout";
    return api.create(`${baseUrl}`, {});
}

function signup(params: RegisterData) {
    const baseUrl = "/v1/auth/register";
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
    const baseUrl = "/v1/auth/send-reset-link";
    return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(uid: string, token: string) {
    const baseUrl = "/v1/auth/confirm";
    return api.get(`${baseUrl}/${uid}/${token}`, {});
}

function resetPassword(params: {
    user_id: string;
    org_id: string;
    password: string;
}) {
    const baseUrl = "/v1/auth/reset-password";
    return api.update(`${baseUrl}`, params);
}

function searchUser(params: { who: string }) {
    const baseUrl = "/v1/auth/user/search";
    return api.get(`${baseUrl}`, params);
}

export {
    login,
    logout,
    signup,
    forgotPassword,
    forgotPasswordConfirm,
    resetPassword,
    searchUser,
};
