import React from 'react';

const HeadingPage = (
  {
    title,
    dropdown
  }
  :
  {
    title: string,
    dropdown: React.ReactNode
  }
) => {
  return (
    <div className="mb-1 mb-xl-3">
      <div className="row g-1 align-items-center">
        <div className="col">
          <h1 className="ls-tight">{ title }</h1>
        </div>
        <div className="col">
          <div className="hstack gap-2 justify-content-end">
            <button  className="btn d-inline-flex btn-sm btn-soft-secondary text-1100">
              <span className="pe-1">
                <i className="bi bi-plus"></i>
              </span>
              <span>Export</span>
            </button>
            {dropdown}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeadingPage;
