import ChatIcon from 'components/icons/ChatIcon';
import { useChatContext } from 'providers/ChatProvider';
import * as React from 'react';
import BotLoading from './BotLoading';
import ChatDeactiveIcon from 'components/icons/ChatDeactiveIcon';


const ChatStateIndicator = ({percent}: any) => {

  const { micPermissionGranted, isSpeaking, speechAgent, aiLoading, volume } = useChatContext();
  
  return (
    <div className='chat-support-indicator w-100 position-absolute'>
      <div className='d-flex justify-content-between'>
        {!aiLoading && isSpeaking && (
          <div className={`bot ${speechAgent === 'bot' ? '': 'hidden'}`}>
            <ChatIcon style={{zIndex: 2}}></ChatIcon>
            <div className='pulsate' style={{width: 64+volume, height: 64+volume, background: volume + 64 > 65 ? '#002D40' : 'transparent'}}></div>
          </div>
        )}
        {!aiLoading && isSpeaking && (
          <div className={`user ${speechAgent === 'user' ? '': 'hidden'}`}>
            <ChatIcon style={{zIndex: 2}}></ChatIcon>
            <div className='pulsate' style={{width: 64+volume, height: 64+volume, background: volume + 64 > 65 ? '#00E3FF' : 'transparent'}}></div>
          </div>
        )}
        {!aiLoading && !micPermissionGranted && (
          <div className='bot'>
            <ChatDeactiveIcon style={{zIndex: 2}}/>
            <div className='pulsate' style={{width: 64+volume, height: 64+volume, background: volume + 64 > 65 ? '#002D40' : 'transparent'}}></div>
          </div>
        )}
        {micPermissionGranted && !aiLoading && (speechAgent === null || speechAgent === '') && (
          <div className='bot'>
            <ChatIcon style={{zIndex: 2}}></ChatIcon>
            <div className='pulsate' style={{width: 64+volume, height: 64+volume, background: volume + 64 > 65 ? '#002D40' : 'transparent'}}></div>
          </div>
        )}
      </div>
      {aiLoading && (
        <BotLoading percent={percent}/>
      )}
    </div>
  )
}

export default ChatStateIndicator;