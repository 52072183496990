import { useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  Tabs,
  Tab,
  Nav,
  Dropdown,
  Container,
} from "react-bootstrap";
import classNames from "classnames";
import { Project } from "./types";
import { useToggle } from "hyper_hooks";
import AddSchedule from "./AddSchedule";
import AddTask from "./AddTask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactSelect from "react-select";
type OverViewProps = {
  projects: Project[];
};

const OverView = ({ projects }: OverViewProps) => {
  const [isopen, , show, hide] = useToggle();

  const [isopentask, , showtask, hidetask] = useToggle();
  const weeksName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  console.log(projects);

  return (
    <>
      <Card>
        <Card.Header>
          <Container className="p-3">
            <Row>
              <Col sm={6}>
                <div>
                  <h4 className="pb-1 m-0">Team Schedule</h4>
                  <p className="text-muted fs-8 p-0 mb-1">
                    2 upcoming meetings
                  </p>
                </div>
              </Col>
              <Col sm={6} className="d-flex justify-content-end">
                <Button
                  variant="outline-info"
                  className="w-100 align-middle"
                  onClick={show}
                >
                  <FontAwesomeIcon icon={"pen"} className="pe-2" />
                  Add Schedule
                </Button>
              </Col>
            </Row>
          </Container>
        </Card.Header>
        <Card.Body>
          <Tab.Container defaultActiveKey="overview">
            <Nav
              as="ul"
              variant="pills"
              style={{
                WebkitOverflowScrolling: "auto",
              }}
              className="overflow-auto nav nav-pills d-flex flex-nowrap hover-scroll-x py-2"
            >
              {weeksName.map((week, index) => {
                return (
                  <Nav.Item role="presentation" className="fs-8 m-2">
                    <Nav.Link
                      eventKey={index}
                      role="presentation"
                      className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-30px me-1 py-2 px-3 btn-active-primary"
                    >
                      <span className="opacity-50 fs-8 fw-semibold">
                        {weeksName[index]}
                      </span>
                      <span className="fs-7 fw-bolder">{21 + index}</span>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
            <Tab.Content>
              {weeksName.map((week, index) => {
                return (
                  <Tab.Pane eventKey={index} className="mt-3">
                    {projects.map((project, index) => {
                      return (
                        <Row className="border-4 border-start m-3">
                          <Col sm={9}>
                            <p className="mb-1">
                              9:00 - 10:00{" "}
                              <strong className="text-muted">AM</strong>
                            </p>
                            <h5 className="m-1">{project.name}</h5>
                            <p className="m-0">
                              Lead by{"  "}
                              <strong className="m-0 text-primary">
                                {project.client}
                              </strong>
                            </p>
                          </Col>
                          <Col sm={3}>
                            <div className="mt-3">
                              <Button variant="phoenix-secondary text-muted text-center fs-8">
                                View
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <Container className="p-3">
            <Row>
              <Col sm={6}>
                <div>
                  <h4 className="pb-1 m-0">Team's Task</h4>
                  <p className="text-muted fs-8 p-0 mb-1">
                    Total 25 tasks in backlog
                  </p>
                </div>
              </Col>
              <Col sm={6} className="d-flex justify-content-end">
                <Button
                  variant="outline-info"
                  className="w-100"
                  onClick={showtask}
                >
                  <FontAwesomeIcon icon={"info"} />
                  Add Task
                </Button>
              </Col>
            </Row>
          </Container>
        </Card.Header>
        <Card.Body>
          {projects.map((project, index) => {
            return (
              <Row className="border-4 border-start m-3">
                <Col sm={8}>
                  <p className="mb-1">
                    9:00 - 10:00 <strong className="text-muted">AM</strong>
                  </p>
                  <h5 className="m-0b-1">{project.name}</h5>
                  <p className="m-0">
                    Lead by{"  "}
                    <strong className="m-0 text-primary">
                      {project.client}
                    </strong>
                  </p>
                </Col>
                <Col sm={4}>
                  <div className="">
                    <Dropdown className="btn me-2 p-0">
                      <Dropdown.Toggle
                        variant="outline-primary"
                        className="fs-9 Drop-start phoenix-secondary text-muted text-center"
                      >
                        <FontAwesomeIcon icon={"refresh"} /> Update
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="p-0 bottom"
                        style={{ width: "300px" }}
                      >
                        <Card className="p-0 mb-0">
                          <Card.Header>
                            <h4>Filter Options</h4>
                          </Card.Header>
                          <Card.Body>
                            <h5>Role:</h5>
                            <ReactSelect
                              className="fs-8"
                              options={[
                                {
                                  value: "Approved",
                                  label: "Approved",
                                },
                                {
                                  value: "Pending",
                                  label: "Pending",
                                },
                                {
                                  value: "In Progress",
                                  label: "In Progress",
                                },
                                {
                                  value: "Rejected",
                                  label: "Rejected",
                                },
                              ]}
                              placeholder="Select option"
                            />
                            <div
                              className="d-flex justify-content-end mt-4"
                              style={{ width: "100%" }}
                            >
                              <Button variant="phoenix-secondary me-2">
                                Reset
                              </Button>
                              <Button>Apply</Button>
                            </div>
                          </Card.Body>
                        </Card>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
              </Row>
            );
          })}
        </Card.Body>
      </Card>
      <AddSchedule show={isopen} handleClose={hide} />
      <AddTask show={isopentask} handleClose={hidetask} />
    </>
  );
};

export default OverView;
