import { createNewFolder } from 'hyper_helpers/api/file';
import { useFileManagerContext } from 'providers/FileManagerProvider';
import * as React from 'react';
import { toast } from 'react-toastify';

const NewFolderView = () => {

  const [loading, setLoading] = React.useState(false);
  const [canceling, setCanceling] = React.useState(false);
  const [folderName, setFolderName] = React.useState('');
  const { editIndex, createFolder, setEditFolder } = useFileManagerContext();
  const query = new URLSearchParams(window.location.search);
  const folderId = query.get('id');

  const create = React.useCallback(() => {
    setLoading(true);
    if(folderName) {
      createNewFolder({ folder_name: folderName })
        .then((resp) => {
          setLoading(false);
          createFolder(folderName);
          setEditFolder(false);
          toast.success(resp.data.msg);
        })
        .catch(err => {
          setLoading(false);
          toast.error(err);
        })
    }
  }, [folderName, folderId]);

  const cancelNewFolder = () => {
    setEditFolder(false);
    setFolderName('');
  }

  return (
    <tr id="kt_file_manager_new_folder_row" data-kt-filemanager-template="upload">
      <td></td>
      <td id="kt_file_manager_add_folder_form" className="fv-row">
        <div className="d-flex align-items-center">
          <span id="kt_file_manager_folder_icon">
            <i className="ki-duotone ki-folder fs-6 text-primary me-4">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </span>
          <input 
            type="text" 
            name="new_folder_name" 
            placeholder="Enter the folder name" 
            className="form-control mw-250px me-3"
            value={folderName}
            onChange={e => setFolderName(e.target.value)}
          />
          <button
            onClick={() => create()}
            className="btn btn-icon btn-outline-primary me-3" id="kt_file_manager_add_folder">
            {!loading ? 
              <span className="indicator-label">
                <i className="ki-duotone ki-check fs-6"></i>
              </span>
              :
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle"></span>
              </span>
            }
          </button>
          <button 
            onClick={() => cancelNewFolder()}
            className="btn btn-icon btn-outline-danger" 
            id="kt_file_manager_cancel_folder">
            {!canceling ?
              <span className="indicator-label">
                  <i className="ki-duotone ki-cross fs-6">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
              </span>
              :
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle"></span>
              </span>
              }
          </button>
        </div>
      </td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  )
}

export default NewFolderView;