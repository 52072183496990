import { Button, Col, Container, Row } from "react-bootstrap";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import { PageBreadcrumbItem } from "components/common/PageBreadcrumb";
import NewProject from "./NewProject";
import { useState } from "react";

interface HeaderProps {
  title?: string;
  BreadcrumbItems: PageBreadcrumbItem[];
}

const Header = ({ title, BreadcrumbItems }: HeaderProps) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Container className="mb-4">
        <Row>
          <Col sm={8} className="d-flex gap-4">
            <div>
              <h3 className="mb-1">{title}</h3>
              <PageBreadcrumb items={BreadcrumbItems} />
            </div>
          </Col>
          {/* <Col
            sm={4}
            className="d-flex gap-4 justify-content-center"
            style={{ height: "3rem" }}
          >
            <Button
              type="button"
              className="align-middle"
              style={{ height: "3rem" }}
            >
              Invite Friends
            </Button>
            <Button
              type="button"
              className="align-middle"
              style={{ height: "3rem" }}
              onClick={() => setIsOpen(true)}
            >
              New Project
            </Button>
          </Col> */}
        </Row>
      </Container>
      <NewProject
        show={isOpen}
        handleClose={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
};

export default Header;
