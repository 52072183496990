import { Modal, Button, Accordion } from "react-bootstrap";
import { VerticalForm, FormInput } from "hyper_components";
import DatePicker from "components/base/DatePicker";
import ReactSelect from "components/base/ReactSelect";
type AddTaskProps = {
  show: boolean;
  handleClose: () => void;
};

const AddTask = ({ show, handleClose }: AddTaskProps) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">Add a Task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VerticalForm onSubmit={() => {}}>
          <h5>Task Name</h5>
          <FormInput
            type="text"
            name="name"
            className="py-2 fs-8"
            containerClass={"mb-3"}
          />
          <h5>Task Due Date</h5>
          <DatePicker
            options={{
                dateFormat: "Y-m-d"
            }}
            style={{ width: "100%" }}
            placeholder="Select Date"
          />
          <h5>Task Description</h5>
          <FormInput
            type="textarea"
            name="organiser"
            className="py-2 fs-8"
            containerClass={"mb-3"}
          />
        </VerticalForm>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Discard
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Save Event
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTask;
