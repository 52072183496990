import { ISettings, IBudget, ITeam, ISettingsError } from "./types";

export const initialSettings: ISettings = {
    parentName: "",
    name: "",
    searchable: true,
    url: "",
    telephone: "",
    description: "",
    date: "2023-01-01",
    email: false,
    phone: false,
};

export const initialBudget: IBudget = {
    budget: 0,
    usage: "On-Demand",
    changable: true,
};

export const initialTeam: ITeam = {
    teamMembers: [],
    addUserByMember: true,
};

export const initialSettingsError: ISettingsError = {
    status: true,
    parentName: false,
    name: false,
    description: false,
};
