import React, { ChangeEvent } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, Form } from "react-bootstrap";
import ToolTip from "craft_components/Tooltip";
import ReactSelect from 'components/base/ReactSelect';
import { IQuestion, QuestionError } from "./types";
import { initialQuestion, initialError } from "./data";
import classNames from "classnames";
import { createKnowledgeBaseQuestion } from "hyper_helpers/api/knowledgebase";
import { toast } from "react-toastify";

const CreateKnowledgeQuestion = () => {
    const { knowledgeId } = useParams();
    const [showNew, setShowNew] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [question, setQuestion] = React.useState<IQuestion>(initialQuestion);
    const [error, setError] = React.useState<QuestionError>(initialError);

    const validate = () => {
        let result = initialError;
        if (question.title === '')
            result = {...result, status: false, title: true};
        if (question.triggers === '')
            result = {...result, status: false, triggers: true};
        if (question.tags.length === 0)
            result = {...result, status: false, tags: true};
        return result;
    }

    const handleService = (service: string, e: ChangeEvent) => {
        let tmp = question.connected_service.slice();
        let idx = tmp.findIndex(item => item === service);
        if (idx === -1) {
            tmp.push(service);
        } else {
            tmp.splice(idx, 1);
        }
        setQuestion({...question, connected_service: tmp});
    }

    const handleSubmit = async () => {
        let valid = validate();
        setError(valid);
        if (valid.status) {
            try {
                setLoading(true);
                let res = await createKnowledgeBaseQuestion({
                    ...question,
                    knowledge_base_id: knowledgeId,
                    published: question.published ? "True" : "False",
                    tags: question.tags.map(tag => tag.value).join()
                });
                setLoading(false);
                setQuestion(initialQuestion);
                setShowNew(false);
                toast.success(res.data.msg);
            } catch (e: any) {
                setLoading(false);
                toast.warn(e);
            }
        }
    }

    return (
        <div>
            <div className="fs-10 d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="toolbar" id="kt_toolbar">
                    <div className="container-fluid d-flex justify-content-between align-items-center flex-wrap flex-sm-nowrap">
                        <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                            <h1 className="text-dark my-1 fs-6">Knowledge Base Questions</h1>
                            <ul className="breadcrumb fw-semibold fs-base my-1">
                                <li className="breadcrumb-item text-muted">
                                    <a href="../dist/index.html" className="text-muted text-hover-primary">Apps</a>
                                </li>
                                <li className="breadcrumb-item text-muted">Knowledge base</li>
                                <li className="breadcrumb-item text-dark">Create Knowledge base Question</li>
                            </ul>
                        </div>
                        <div className="d-flex align-items-center flex-nowrap text-nowrap py-1">
                            <Button variant="outline-primary" size="lg" className="me-4">Invite Friends</Button>
                            <Button variant="primary" size="lg" onClick={() => setShowNew(true)}>New Question</Button>
                        </div>
                    </div>
                </div>
                <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
                    <div className="container-xxl">
                        <div className="card mt-3">
                            <div className="card-body">
                                <div className="card-px text-center pt-7 pb-7">
                                    <h2 className="fs-2x fw-bold mb-0">Initial Knowledge Base Questions</h2>
                                    <p className="text-gray-400 fs-8 fw-semibold py-3">
                                        Click on the button below to create a new question. <br />
                                        "inital Questions" are limited to 5 to optimise Customer Experience.
                                    </p>
                                    <Button variant="primary" size="lg" onClick={() => setShowNew(true)}>New Question</Button>
                                </div>
                                <div className="text-center pb-7 px-2">
                                    <img src="assets/media/illustrations/" alt="" className="mw-100 h-200px h-sm-325px" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal size="lg" show={showNew} onHide={() => setShowNew(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="mx-2 mx-xl-9 pt-0 pb-7">
                    <div className="mb-6 text-center">
                        <h1 className="mb-3 fs-6">Create a new Question</h1>
                        <div className="text-muted fw-semibold fs-7">If you need more info, please check
                        <a href="#" className="fw-bold link-primary ms-1">Project Guidelines</a>.</div>
                    </div>
                    <Form.Group className="mb-4">
                        <Form.Label className="fs-8 mb-2 ps-0">
                            <span className="required text-notrans">Question Title</span>
                            <ToolTip position="top" content="Specify a target name for future usage and reference" />
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Question Title"
                            isInvalid={error.title}
                            value={question.title}
                            onChange={e => setQuestion({...question, title: e.target.value})}
                        />
                        <Form.Control.Feedback type="invalid">Question Title is required</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label className="fs-8 text-notrans mb-2 ps-0">Question</Form.Label>
                        <Form.Control
                            as="textarea"
                            type="text"
                            rows={3}
                            placeholder="Enter your questions"
                            value={question.question}
                            onChange={e => setQuestion({...question, question: e.target.value})}
                        />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label className="fs-8 mb-2 ps-0">
                            <span className="required text-notrans">Trigger(s)</span>
                            <ToolTip position="top" content="Specify a target name for future usage and reference" />
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Specify a target e.g Call or RPA workflow, file, etc"
                            isInvalid={error.triggers}
                            value={question.triggers}
                            onChange={e => setQuestion({...question, triggers: e.target.value})}
                        />
                        <Form.Control.Feedback type="invalid">Trigger(s) is required</Form.Control.Feedback>
                    </Form.Group>
                    <div className="d-flex flex-column mb-4 fv-row">
                        <Form.Label className="fs-8 mb-2 ps-0">
                            <span className="required text-notrans">Tags</span>
                            <ToolTip position="top" content="pecify a target priorty" />
                        </Form.Label>
                        <ReactSelect
                            className={classNames('border rounded', {'border-red': error.tags})}
                            isMulti
                            options={[
                                { value: 'Biology', label: 'Biology' },
                                { value: 'Brainlessness', label: 'Brainlessness' },
                                { value: 'Jerry', label: 'Jerry' },
                                { value: 'Neurology', label: 'Neurology' },
                                { value: 'Not_the_mouse', label: 'Not_the_mouse' },
                                { value: 'Rick', label: 'Rick' },
                                { value: 'Stupidity', label: 'Stupidity' }
                            ]}
                            value={question.tags}
                            onChange={(e: any) => setQuestion({...question, tags: e})}
                        />
                        <div className={classNames({'d-block': error.tags}, {'d-none': !error.tags}, 'invalid-feedback')}>Tags is required</div>
                    </div>
                    <div className="d-flex flex-stack mb-4">
                        <div className="me-5">
                            <label className="fs-8 fw-semibold">Pubslished</label>
                            <div className="fs-9 fw-semibold text-muted">Selecting this will mean the question is live in the service.</div>
                        </div>
                        <label className="d-flex align-items-center form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input h-30px w-50px" type="checkbox" value="1" checked={question.published} onChange={e => setQuestion({...question, published: e.target.checked})} />
                            <span className="form-check-label fw-semibold text-muted fs-8 ms-2">Yes</span>
                        </label>
                    </div>
                    <div className="mb-7 fv-row">
                        <div className="d-flex flex-column">
                            <div className="fw-semibold mb-3">
                                <label className="fs-8">Connected Service(s)</label>
                                <div className="fs-9 text-muted">Published Questions are used here.</div>
                            </div>
                            <div className="d-flex flex-wrap">
                                <label className="d-flex align-items-center form-check form-check-custom form-check-solid me-7">
                                    <input className="form-check-input h-20px w-20px" type="checkbox" name="communication[]" value="Support Chat" checked={question.connected_service.includes('Support Chat')} onChange={e => handleService('Support Chat', e)} />
                                    <span className="form-check-label fw-semibold ms-2 mt-1 fs-8">Support Chat</span>
                                </label>
                                <label className="d-flex align-items-center form-check form-check-custom form-check-solid me-7">
                                    <input className="form-check-input h-20px w-20px" type="checkbox" name="communication[]" value="Chat" checked={question.connected_service.includes('Chat')} onChange={e => handleService('Chat', e)} />
                                    <span className="form-check-label fw-semibold ms-2 mt-1 fs-8">Chat</span>
                                </label>
                                <label className="d-flex align-items-center form-check form-check-custom form-check-solid me-7">
                                    <input className="form-check-input h-20px w-20px" type="checkbox" name="communication[]" value="Calls" checked={question.connected_service.includes('Calls')} onChange={e => handleService('Calls', e)} />
                                    <span className="form-check-label fw-semibold ms-2 mt-1 fs-8">Calls</span>
                                </label>
                                <label className="d-flex align-items-center form-check form-check-custom form-check-solid">
                                    <input className="form-check-input h-20px w-20px" type="checkbox" name="communication[]" value="RPA" checked={question.connected_service.includes('RPA')} onChange={e => handleService('RPA', e)} />
                                    <span className="form-check-label fw-semibold ms-2 mt-1 fs-8">RPA</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <Button variant="outline-primary" size="lg">Cancel</Button>
                        <Button className="ms-3" variant="primary" size="lg" onClick={handleSubmit}>
                            Submit
                            {loading && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default CreateKnowledgeQuestion;