import * as React from 'react';

const FlowerIcon = ({ className, color = '#07B208' }: { className?: string, color?: string }) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.66351 35.2883L11.9711 14.8398L25.1121 27.9807L4.66351 35.2883ZM8.78522 31.1666L20.5352 26.9999L12.9519 19.4166L8.78522 31.1666ZM23.3044 21.173L21.907 19.7756L31.048 10.6346C31.8834 9.79911 32.8941 9.38138 34.08 9.38138C35.2659 9.38138 36.2766 9.79911 37.1121 10.6346L37.9198 11.4423L36.5544 12.8397L35.7467 12.032C35.2937 11.579 34.7435 11.3525 34.0961 11.3525C33.4486 11.3525 32.8984 11.579 32.4454 12.032L23.3044 21.173ZM17.2147 15.0833L15.8173 13.6859L16.8173 12.6859C17.313 12.1902 17.5609 11.5897 17.5609 10.8846C17.5609 10.1794 17.313 9.57901 16.8173 9.08329L15.766 8.032L17.1634 6.63458L18.2147 7.68587C19.1036 8.57476 19.548 9.641 19.548 10.8846C19.548 12.1282 19.1036 13.1944 18.2147 14.0833L17.2147 15.0833ZM20.2596 18.1282L18.8621 16.7307L24.6698 10.9231C25.1228 10.4701 25.3493 9.91986 25.3493 9.27242C25.3493 8.62497 25.1228 8.07475 24.6698 7.62175L22.1955 5.14742L23.5929 3.75L26.0672 6.22433C26.9134 7.0705 27.3364 8.08653 27.3364 9.27242C27.3364 10.4583 26.9134 11.4743 26.0672 12.3205L20.2596 18.1282ZM26.3492 24.2179L24.9518 22.8205L26.9454 20.8269C27.8449 19.9273 28.9304 19.4775 30.2018 19.4775C31.4732 19.4775 32.5586 19.9273 33.4582 20.8269L35.4517 22.8205L34.0543 24.2179L32.0608 22.2243C31.5544 21.7179 30.9347 21.4647 30.2018 21.4647C29.4689 21.4647 28.8492 21.7179 28.3428 22.2243L26.3492 24.2179Z" fill={color}/>
    </svg>
  );
};

export default FlowerIcon;