import {
    Settings,
    SettingsError,
    Organisation,
    OrganisationError,
    UserInfo,
    UserInfoError,
} from "./types";
import {
    initialOrganisationError,
    initialSettingsError,
    initialUserInfoError,
} from "./data";

const emailRegExp = /^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const domainRegExp =
    /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})/;

export const checkAccountSettings = (settings: Settings) => {
    let result: SettingsError = initialSettingsError;

    if (!domainRegExp.test(settings.domainName))
        result = { ...result, status: false, domainName: true };
    if (
        settings.domainName === "" ||
        settings.domainName !== settings.domainConfirm
    )
        result = { ...result, status: false, domainConfirm: true };
    return result;
};

export const checkOrganisation = (organisation: Organisation) => {
    let result: OrganisationError = initialOrganisationError;

    if (organisation.name === "")
        result = { ...result, status: false, name: true };
    if (organisation.description === "")
        result = { ...result, status: false, description: true };
    if (!emailRegExp.test(organisation.contactEmail))
        result = { ...result, status: false, contactEmail: true };
    return result;
};

export const checkRegistration = (userInfo: UserInfo) => {
    let result: UserInfoError = initialUserInfoError;

    if (userInfo.firstName === "")
        result = { ...result, status: false, firstName: true };
    if (userInfo.lastName === "")
        result = { ...result, status: false, lastName: true };
    if (userInfo.userName === "")
        result = { ...result, status: false, userName: true };
    if (!emailRegExp.test(userInfo.email))
        result = { ...result, status: false, email: true };
    if (userInfo.password === "")
        result = { ...result, status: false, password: true };
    if (userInfo.confirmPwd === "" || userInfo.password !== userInfo.confirmPwd)
        result = { ...result, status: false, confirmPwd: true };
    if (!userInfo.policy) result = { ...result, status: false, policy: true };
    return result;
};
