import { FileItem } from 'data/fileManager';
import { useFileManagerContext } from 'providers/FileManagerProvider';
import * as React from 'react';
import { convertFileSize } from 'utils';

const FileManagerStatusBar = () => {

  const [totalSize, setSize] = React.useState(0);
  const [title, setTitle] = React.useState('Folders');
  const { folders } = useFileManagerContext();
  const query = new URLSearchParams(window.location.search);
  const folderId = query.get('id');
  
  React.useEffect(() => {
    if(folderId) {
      setTitle('Files');
    } else {
      setTitle('Folders');
    }
  }, [folderId]);

  React.useEffect(() => {
    let size = 0;
    folders.map((folder: FileItem) => {
      size+=Number(folder.size)
    });
    setSize(size);
  }, [folders]);

  return (
    <div className="card card-flush pb-0 bgi-position-y-center bgi-no-repeat mb-4 file-folder-header">
      <div className="card-header pt-4">
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column">
            <h2 className="mb-1 fs-6">File Manager</h2>
            <div className="text-muted fw-bold fs-8">
              <a href="#">{title}</a>
              <span className="mx-3">|</span>{convertFileSize(totalSize)}
              <span className="mx-3">|</span>{folders.length} items
            </div>
          </div>
        </div>
      </div>
      <div className="card-body pb-0">
        <div className="d-flex overflow-auto h-55px">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-8 fw-semibold flex-nowrap">
            {/* <li className="nav-item">
              <a className="nav-link text-active-primary me-6 active" href="">Folders</a>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default FileManagerStatusBar;