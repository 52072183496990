import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';

export const organisationDirectoratesTableColumns: ColumnDef<any>[] = [
  {
    accessorKey: 'name',
    header: "Name",
    cell: ({row: { original }}) => {
      const { name } = original;
      return (
        <div className="d-flex align-items-center gap-3">
          <div className="">
            <Link className="d-inline-block text-sm text-heading fw-semibold text-1100" to={'/organisation/directorates/services'}>
              Directorate Name
            </Link>
            <span className="d-block text-xs text-800">No.of Services</span>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: {className: "ps-3"},
      cellProps: {className: "ps-3"},
    }
  },
  {
    accessorKey: 'price',
    header: "Price",
    cell: ({row: { original }}) => {
      const { price } = original;
      return <span className="text-1000">$17,044.22</span>;
    },
  },
  {
    accessorKey: 'hour',
    header: "1H",
    cell: ({row: { original }}) => {
      const { hour } = original;
      return (
        <span className="text-success fw-semibold">+7.4%</span>
      );
    },
    meta: {
      headerProps: {className: "d-none d-sm-table-cell"},
      cellProps: {className: "d-none d-sm-table-cell"}
    }
  },
  {
    accessorKey: 'day',
    header: "24H",
    cell: ({row: { original }}) => {
      const { day } = original;
      return (
        <span className="text-success fw-semibold">+7.4%</span>
      );
    },
  },
  {
    accessorKey: 'week',
    header: "7D",
    cell: ({row: { original }}) => {
      const { week } = original;
      return (
        <span className="text-danger fw-semibold">-2.3%</span>
      );
    },
    meta: {
      headerProps: {className: "d-none d-lg-table-cell"},
      cellProps: {className: "d-none d-lg-table-cell"}
    }
  },
  {
    accessorKey: 'volume',
    header: "Volume",
    cell: ({row: { original }}) => {
      const { volume } = original;
      return <span className="text-1000">$4.97M</span>;
    },
    meta: {
      headerProps: {className: "d-none d-sm-table-cell"},
      cellProps: {className: "d-none d-sm-table-cell"}
    }
  },
  {
    accessorKey: 'chart',
    header: "Last 7 Days",
    cell: ({row: { original }}) => {
      const { week } = original;
      return (
        <span className="text-danger fw-semibold">chart</span>
      );
    },
    meta: {
      headerProps: {className: "d-none d-xl-table-cell"},
      cellProps: {className: "d-none d-xl-table-cell"}
    }
  },
];

export const organisationServicesTableColumns: ColumnDef<any>[] = [
  {
    accessorKey: 'name',
    header: "Name",
    cell: ({row: { original }}) => {
      const { name } = original;
      return (
        <div className="d-flex align-items-center gap-3">
          <div className="">
            <Link className="d-inline-block text-sm text-heading fw-semibold text-1100" to={'/organisation/directorates/services/teams'}>
              Service Name
            </Link>
            <span className="d-block text-xs text-800">No.of Teams</span>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: {className: "ps-3"},
      cellProps: {className: "ps-3"},
    }
  },
  {
    accessorKey: 'price',
    header: "Price",
    cell: ({row: { original }}) => {
      const { price } = original;
      return <span className="text-1000">$17,044.22</span>;
    },
  },
  {
    accessorKey: 'hour',
    header: "1H",
    cell: ({row: { original }}) => {
      const { hour } = original;
      return (
        <span className="text-success fw-semibold">+7.4%</span>
      );
    },
    meta: {
      headerProps: {className: "d-none d-sm-table-cell"},
      cellProps: {className: "d-none d-sm-table-cell"}
    }
  },
  {
    accessorKey: 'day',
    header: "24H",
    cell: ({row: { original }}) => {
      const { day } = original;
      return (
        <span className="text-success fw-semibold">+7.4%</span>
      );
    },
  },
  {
    accessorKey: 'week',
    header: "7D",
    cell: ({row: { original }}) => {
      const { week } = original;
      return (
        <span className="text-danger fw-semibold">-2.3%</span>
      );
    },
    meta: {
      headerProps: {className: "d-none d-lg-table-cell"},
      cellProps: {className: "d-none d-lg-table-cell"}
    }
  },
  {
    accessorKey: 'volume',
    header: "Volume",
    cell: ({row: { original }}) => {
      const { volume } = original;
      return <span className="text-1000">$4.97M</span>;
    },
    meta: {
      headerProps: {className: "d-none d-sm-table-cell"},
      cellProps: {className: "d-none d-sm-table-cell"}
    }
  },
  {
    accessorKey: 'chart',
    header: "Last 7 Days",
    cell: ({row: { original }}) => {
      const { week } = original;
      return (
        <span className="text-danger fw-semibold">chart</span>
      );
    },
    meta: {
      headerProps: {className: "d-none d-xl-table-cell"},
      cellProps: {className: "d-none d-xl-table-cell"}
    }
  },
];

export const organisationTeamsTableColumns: ColumnDef<any>[] = [
  {
    accessorKey: 'name',
    header: "Name",
    cell: ({row: { original }}) => {
      const { name } = original;
      return (
        <div className="d-flex align-items-center gap-3">
          <div className="">
            <a className="d-inline-block text-sm text-heading fw-semibold text-1100" href="#">
              Team Name
            </a>
            <span className="d-block text-xs text-800">No.of Products</span>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: {className: "ps-3"},
      cellProps: {className: "ps-3"},
    }
  },
  {
    accessorKey: 'price',
    header: "Price",
    cell: ({row: { original }}) => {
      const { price } = original;
      return <span className="text-1000">$17,044.22</span>;
    },
  },
  {
    accessorKey: 'hour',
    header: "1H",
    cell: ({row: { original }}) => {
      const { hour } = original;
      return (
        <span className="text-success fw-semibold">+7.4%</span>
      );
    },
    meta: {
      headerProps: {className: "d-none d-sm-table-cell"},
      cellProps: {className: "d-none d-sm-table-cell"}
    }
  },
  {
    accessorKey: 'day',
    header: "24H",
    cell: ({row: { original }}) => {
      const { day } = original;
      return (
        <span className="text-success fw-semibold">+7.4%</span>
      );
    },
  },
  {
    accessorKey: 'week',
    header: "7D",
    cell: ({row: { original }}) => {
      const { week } = original;
      return (
        <span className="text-danger fw-semibold">-2.3%</span>
      );
    },
    meta: {
      headerProps: {className: "d-none d-lg-table-cell"},
      cellProps: {className: "d-none d-lg-table-cell"}
    }
  },
  {
    accessorKey: 'volume',
    header: "Volume",
    cell: ({row: { original }}) => {
      const { volume } = original;
      return <span className="text-1000">$4.97M</span>;
    },
    meta: {
      headerProps: {className: "d-none d-sm-table-cell"},
      cellProps: {className: "d-none d-sm-table-cell"}
    }
  },
  {
    accessorKey: 'chart',
    header: "Last 7 Days",
    cell: ({row: { original }}) => {
      const { week } = original;
      return (
        <span className="text-danger fw-semibold">chart</span>
      );
    },
    meta: {
      headerProps: {className: "d-none d-xl-table-cell"},
      cellProps: {className: "d-none d-xl-table-cell"}
    }
  },
];

const OrganisationDirectoratesTable = () => {
  return (
    <div className="border-bottom">
      <div className="table-responsive">
        <AdvanceTable
          tableProps={{ className: 'table table-hover table-striped table-nowrap', style: {verticalAlign: "middle"} }}
        />
      </div>
      <AdvanceTableFooter pagination className="p-3" />
    </div>
  )
}

export default OrganisationDirectoratesTable
