import React from "react";
import { Card } from "react-bootstrap";
import ChromeIcon from "assets/images/brands/chrome-icon.svg";

const ProductConfig = () => {
    return (
        <Card id="productConfiguration">
            <Card.Header>
                <Card.Title className="fs-7">Product Configuration</Card.Title>
            </Card.Header>
            <Card.Body>
                <p className="card-text">Integrated features from these accounts make it easier to collaborate with people you know on Front Dashboard.</p>
                <div className="list-group list-group-lg list-group-flush list-group-no-gutters">
                    <div className="list-group-item mb-2">
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <img className="avatar avatar-xs" src={ChromeIcon} alt="Image Description" />
                        </div>

                        <div className="flex-grow-1 ms-3">
                          <div className="row align-items-center">
                            <div className="col">
                              <h4 className="my-0">Knowledge Bases</h4>
                              <p className="fs-8 text-body mb-0">Calendar and contacts</p>
                            </div>

                            <div className="col-auto">
                              <div className="form-check form-switch">
                                <input className="form-check-input h-30px w-50px" type="checkbox" id="connectedAccounts1" />
                                <label className="form-check-label" htmlFor="connectedAccounts1"></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item mb-2">
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <img className="avatar avatar-xs" src={ChromeIcon} alt="Image Description" />
                        </div>

                        <div className="flex-grow-1 ms-3">
                          <div className="row align-items-center">
                            <div className="col">
                              <h4 className="my-0">Journeys</h4>
                              <p className="fs-8 text-body mb-0">Project management</p>
                            </div>

                            <div className="col-auto">
                              <div className="form-check form-switch">
                                <input className="form-check-input h-30px w-50px" type="checkbox" id="connectedAccounts2" />
                                <label className="form-check-label" htmlFor="connectedAccounts2"></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item mb-2">
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <img className="avatar avatar-xs" src={ChromeIcon} alt="Image Description" />
                        </div>

                        <div className="flex-grow-1 ms-3">
                          <div className="row align-items-center">
                            <div className="col">
                              <h4 className="my-0">Digi-Teams (RPA)</h4>
                              <p className="fs-8 text-body mb-0">Project management</p>
                            </div>

                            <div className="col-auto">
                              <div className="form-check form-switch">
                                <input className="form-check-input h-30px w-50px" type="checkbox" id="connectedAccounts2" />
                                <label className="form-check-label" htmlFor="connectedAccounts2"></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item mb-2">
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <img className="avatar avatar-xs" src={ChromeIcon} alt="Image Description" />
                        </div>

                        <div className="flex-grow-1 ms-3">
                          <div className="row align-items-center">
                            <div className="col">
                              <h4 className="my-0">Data Net</h4>
                              <p className="fs-8 text-body mb-0">Project management</p>
                            </div>

                            <div className="col-auto">
                              <div className="form-check form-switch">
                                <input className="form-check-input h-30px w-50px" type="checkbox" id="connectedAccounts2" />
                                <label className="form-check-label" htmlFor="connectedAccounts2"></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item mb-2">
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <img className="avatar avatar-xs" src={ChromeIcon} alt="Image Description" />
                        </div>

                        <div className="flex-grow-1 ms-3">
                          <div className="row align-items-center">
                            <div className="col">
                              <h4 className="my-0">Chat</h4>
                              <p className="fs-8 text-body mb-0">Project management</p>
                            </div>
                            <div className="col-auto">
                              <div className="form-check form-switch">
                                <input className="form-check-input h-30px w-50px" type="checkbox" id="connectedAccounts2" />
                                <label className="form-check-label" htmlFor="connectedAccounts2"></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item mb-2">
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <img className="avatar avatar-xs" src={ChromeIcon} alt="Image Description" />
                        </div>

                        <div className="flex-grow-1 ms-3">
                          <div className="row align-items-center">
                            <div className="col">
                              <h4 className="my-0">Public Support Chat</h4>
                              <p className="fs-8 text-body mb-0">Project management</p>
                            </div>

                            <div className="col-auto">
                              <div className="form-check form-switch">
                                <input className="form-check-input h-30px w-50px" type="checkbox" id="connectedAccounts2" />
                                <label className="form-check-label" htmlFor="connectedAccounts2"></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item mb-2">
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <img className="avatar avatar-xs" src={ChromeIcon} alt="Image Description" />
                        </div>

                        <div className="flex-grow-1 ms-3">
                          <div className="row align-items-center">
                            <div className="col">
                              <h4 className="my-0">Staff Support Chat</h4>
                              <p className="fs-8 text-body mb-0">Tools for Web Developers <a className="link" href="#">Learn more</a></p>
                            </div>

                            <div className="col-auto">
                              <div className="form-check form-switch">
                                <input className="form-check-input h-30px w-50px" type="checkbox" id="connectedAccounts5" />
                                <label className="form-check-label" htmlFor="connectedAccounts5"></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}

export default ProductConfig;