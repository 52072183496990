import React from "react";
import { Button, Form } from "react-bootstrap";
import ToolTip from "craft_components/Tooltip";
import DatePicker from 'components/base/DatePicker';
import { ISettings, ISettingsError } from "./types";
import { getAvailableNumber } from "hyper_helpers/api/knowledgebase";
import { toast } from "react-toastify";

interface SettingsProps {
    num: number;
    step: number;
    next: () => void;
    previous: () => void;
    settings: ISettings;
    setSettings: (settings: ISettings) => void;
    settingsError: ISettingsError;
}

const Settings = (props: SettingsProps) => {
    const { num, step, next, previous, settings, setSettings, settingsError } = props;
    const [loading, setLoading] = React.useState<boolean>(false);

    let otherClass = 'pending';
    if (step === num) otherClass = 'current';
    if (step > num) otherClass = 'completed';

    const getTelephoneNumber = () => {
        setLoading(true);
        getAvailableNumber({})
            .then(res => {
                if (res.data.results[0])
                    setSettings({...settings, telephone: res.data.results[0].phone_number});
                setLoading(false);
            })
            .catch(err => {
                toast.warn(err);
                setLoading(false);
                console.log(err);
            });
    }

    return (
        <div className={otherClass} data-kt-stepper-element="content">
            <div className="w-100">
                <div className="pb-6">
                    <h1 className="fw-bold text-dark fs-6">Knowledge Base Settings</h1>
                    <div className="text-muted fw-semibold fs-7">If you need more info, please check
                    <a href="#" className="link-primary ms-1">Knowledge Guidelines</a></div>
                </div>
                <Form.Group className="mb-4">
                    <Form.Label className="required fs-8 fw-semibold mb-2 text-notrans ps-0">Parent Knowledge Base (Folder)</Form.Label>
                    <Form.Select value={settings.parentName} onChange={e => setSettings({...settings, parentName: e.target.value})} isInvalid={settingsError.parentName}>
                        <option></option>
                        <option value="1">Keenthemes</option>
                        <option value="2">CRM App</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Parent Knowledge Base is required</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label className="fs-8 mb-2 text-notrans ps-0">
                        <span className="required">Knowledge Base Name</span>
                        <ToolTip position="top" content="This is the name that will be used to locate, search and provide answers from." />
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={settings.name}
                        onChange={e => setSettings({...settings, name: e.target.value})}
                        isInvalid={settingsError.name}
                    />
                    <Form.Control.Feedback type="invalid">Knowledge Base Name is required</Form.Control.Feedback>
                </Form.Group>
                <div className="d-flex mb-4">
                    <div className="d-flex align-items-center form-check form-switch form-check-custom form-check-solid me-10">
                        <input
                            className="form-check-input h-30px w-50px"
                            name="public_read"
                            type="checkbox"
                            checked={settings.searchable}
                            onChange={e => setSettings({...settings, searchable: e.target.checked})}
                        />
                        <label className="form-check-label fs-8 ms-2 mt-1" htmlFor="public_read">Searchable</label>
                        <ToolTip position="top" content="Understanding Project and Knowledge Base Settings.If your project involves sensitive or confidential information, you might prefer keeping it as a private project.Opt for the Knowledge Base mode if you want to leverage the power of quick, chat-based searches to enhance productivity and knowledge dissemination." />
                    </div>
                </div>
                <div className="fv-row mb-4">
                    <label className="fs-8 fw-semibold mb-2">Knowledge Base (Public) URL</label>
                    <ToolTip position="top" content="The URL(s) entered here, will determine which page(s) the Support-Chat Bot appears on, and limits the Bot to providing answers based on the information within this Knowledge Base." />
                    <input
                        className="form-control form-control-solid"
                        name="target_tags"
                        value={settings.url}
                        onChange={e => setSettings({...settings, url: e.target.value})}
                    />
                </div>
                <div className="fv-row mb-1">
                    <label className="fs-8 fw-semibold mb-2">Generate Telephone number</label>
                    <ToolTip position="top" content="By generating a unique contact number, this feature empowers staff, customers, residents, and other users to directly access our advanced chatbot via phone calls, which is seamlessly integrated with our comprehensive knowledge base. Once connected, users can interact with a wide range of documents, efficiently navigating through them in accordance with established permissions and workflows. This intuitive interaction ensures a smoother, more effective communication experience, providing prompt and accurate responses to queries and facilitating easier access to vital information. Please note, there are additional costs associated with this feature as it makes additional API calls" />
                </div>
                <div className="d-flex fv-row mb-4">
                    <Button variant="primary" size="sm" onClick={getTelephoneNumber}>
                        Generate number
                        {loading && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}
                    </Button>
                    <input className="form-control form-control-solid ms-2 w-50" readOnly value={settings.telephone} />
                </div>
                <Form.Group className="mb-4">
                    <Form.Label className="required fs-8 mb-2 text-notrans ps-0">Project Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        type="text"
                        placeholder="Enter Project Description"
                        rows={3}
                        value={settings.description}
                        onChange={e => setSettings({...settings, description: e.target.value})}
                        isInvalid={settingsError.description}
                    />
                    <Form.Control.Feedback type="invalid">Project Description is required</Form.Control.Feedback>
                </Form.Group>
                <div className="fv-row mb-4">
                    <label className="required fs-8 fw-semibold mb-2">Publish Date</label>
                    <DatePicker
                        options={{
                            defaultDate: '2023-01-01',
                            dateFormat: 'Y-m-d'
                        }}
                        onChange={(date, dateStr) => setSettings({...settings, date: dateStr})}
                    />
                </div>
                <div className="fv-row mb-4">
                    <div className="d-flex flex-stack">
                        <div className="me-5">
                            <label className="fs-8 fw-semibold">Notifications</label>
                            <div className="fs-9 fw-semibold text-muted">Allow Notifications by Phone or Email when the knowledge based is accessed. Don't worry, we keep an audit log of all activity.</div>
                        </div>
                        <div className="d-flex mt-3">
                            <label className="d-flex align-items-center form-check form-check-custom form-check-solid me-5">
                                <input
                                    className="form-check-input h-20px w-20px"
                                    type="checkbox"
                                    value="email"
                                    name="settings_notifications[]"
                                    checked={settings.email}
                                    onChange={e => setSettings({...settings, email: e.target.checked})}
                                />
                                <span className="form-check-label fw-semibold ms-2 mt-1 fs-9">Email</span>
                            </label>
                            <label className="d-flex align-items-center form-check form-check-custom form-check-solid">
                                <input
                                    className="form-check-input h-20px w-20px"
                                    type="checkbox"
                                    value="phone"
                                    name="settings_notifications[]"
                                    checked={settings.phone}
                                    onChange={e => setSettings({...settings, phone: e.target.checked})}
                                />
                                <span className="form-check-label fw-semibold ms-2 mt-1 fs-9">Phone</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-stack">
                    <Button variant="outline-primary" size="lg" onClick={previous}>Previous</Button>
                    <Button variant="primary" size="lg" onClick={next}>
                        Next
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Settings;