import React from "react";
import Header from "../Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faGrip } from "@fortawesome/free-solid-svg-icons";
import { Pagination } from "react-bootstrap";
import UserTable from "./UserTable";
import UserCard from "./UserCard";
import { teamData } from "../data";

const Users = () => {
    const [shape, setShape] = React.useState<string>("table");

    return (
        <div>
            <Header active="team" />
            <div className="d-flex flex-wrap flex-stack pb-3">
                <div className="d-flex flex-wrap align-items-center my-1">
                    <h3 className="fw-bold me-3 my-1 fs-7">Users (38)</h3>
                    <div className="d-flex align-items-center position-relative my-1">
                        <input type="text" className="form-control form-control-sm border-body bg-body w-150px" placeholder="Search" />
                    </div>
                </div>
                <div className="d-flex flex-wrap my-1">
                    <ul className="nav nav-pills me-3 mb-2 mb-sm-0">
                        <li className="nav-item m-0">
                            <button className={`btn btn-sm btn-icon btn-light btn-color-muted btn-outline-primary me-3 ${shape === "card" ? "active" : ""}`} onClick={() => setShape("card")}>
                                <FontAwesomeIcon icon={faGrip} size="xl" />
                            </button>
                        </li>
                        <li className="nav-item m-0">
                            <button className={`btn btn-sm btn-icon btn-light btn-color-muted btn-outline-primary ${shape === "table" ? "active" : ""}`} onClick={() => setShape("table")}>
                                <FontAwesomeIcon icon={faBars} size="xl" />
                            </button>
                        </li>
                    </ul>
                    <div className="d-flex my-0">
                        <select name="status" data-control="select2" data-hide-search="true" data-placeholder="Filter" className="form-select form-select-sm border-body bg-body w-150px me-3">
                            <option value="1">Recently Updated</option>
                            <option value="2">Last Month</option>
                            <option value="3">Last Quarter</option>
                            <option value="4">Last Year</option>
                        </select>
                        <select name="status" data-control="select2" data-hide-search="true" data-placeholder="Export" className="form-select form-select-sm border-body bg-body w-100px">
                            <option value="1">Excel</option>
                            <option value="1">PDF</option>
                            <option value="2">Print</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="tab-content">
                {shape === "card" ?
                    <UserCard users={teamData} />
                    :
                    <UserTable users={teamData} />
                }
                <div className="d-flex flex-stack flex-wrap pt-5">
                    <div className="fs-8 fw-semibold text-700">Showing 1 to 10 of 50 entries</div>
                    <Pagination className="mb-0">
                        <Pagination.First />
                        <Pagination.Item active>1</Pagination.Item>
                        <Pagination.Item>2</Pagination.Item>
                        <Pagination.Item>3</Pagination.Item>
                        <Pagination.Item>4</Pagination.Item>
                        <Pagination.Item>5</Pagination.Item>
                        <Pagination.Last />
                    </Pagination>
                </div>
            </div>
        </div>
    );
}

export default Users;