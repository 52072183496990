import classNames from "classnames";
import Avatar from "components/base/Avatar";
import Lightbox from "components/base/LightBox";
import { Message as MessageType, User, actions } from "data/chat";
import useLightbox from "hooks/useLightbox";
import MessageActionButtons from "./MessageActionButtons";
import MessageAttachments from "./MessageAttachments";
import Moment from 'react-moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import SourceItem from "components/common/SourceItem";
import { Button } from "react-bootstrap";

interface MessageProps {
  width: string;
  message: MessageType;
  user?: User;
  id?: string;
}

const Message = ({ width, message, user, id }: MessageProps) => {
  const { lightboxProps, openLightbox } = useLightbox(
    message.attachments?.map(item => item.url) || []
  );

  return (
    <div className="chat-message" id={id}>
      <div
        className={classNames("d-flex flex-1", {
          "justify-content-end": message.type === "sent",
        })}
      >
        <div className={width}>
          {/* <div
            className={classNames("d-flex hover-actions-trigger", {
              "flex-end-center": message.type === "sent",
            })}
          > */}
            {/* {message.type === "received" && (
              <Avatar
                src={user?.avatar}
                size="m"
                className="me-3 flex-shrink-0"
              />
            )} */}

            {/* {message.type === "sent" && (
              <MessageActionButtons actions={actions} variant="sent" />
            )} */}

            <div
              className={classNames("chat-message-content me-2", {
                received: message.type === "received",
              })}
            >
              <div
                className={classNames("mb-1", {
                  "sent-message-content light": message.type === "sent",
                  "received-message-content border":
                    message.type === "received",
                    "attachments": message.attachments && !message.message,
                  })}
              >
                <div className="d-flex justify-content-between align-items-start mb-3">
                  <div className="d-flex justify-content-center">
                    <Avatar src={user?.avatar} size="s" />
                    <div className="fs-9 mx-2 mt-1">{user?.name}</div>
                  </div>
                  <FontAwesomeIcon icon={faEllipsisV} />
                </div>
                {message.message && <div className="text-word-break message-content" dangerouslySetInnerHTML={{__html: message.message}} />}
                {user?.bot && message.sources && <>
                  {
                    message.sources.map((source, idx) => <SourceItem key={`src_${idx}`} file={source} />)
                  }
                  <div>
                    <Button size="sm" variant="outline-primary" className="include me-2 fs-9">Include</Button>
                    <Button size="sm" variant="primary" className="swap fs-9">Swap</Button>
                  </div>
                </>}
                {message.attachments && (
                  <MessageAttachments
                    attachments={message.attachments}
                    openLightbox={openLightbox}
                  />
                )}
                <div className="d-flex justify-content-between mt-1 mx-n2 mb-n2">
                  <Moment className="fs-10 fw-semi-bold" format="HH:mm">{message.time}</Moment>
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              </div>
            </div>
            {/* {message.type === "received" && (
              <MessageActionButtons
                actions={actions.slice(1)}
                variant="received"
              />
            )} */}
          </div>
          {/* <div
            className={classNames({
              "ms-7": message.type === "received",
              "text-end": message.type === "sent",
            })}
          >
            <p className="mb-0 fs-10 text-600 fw-semi-bold">
              <Moment format="M.D HH:mm">
                {message.time}
              </Moment>
            </p>
          </div> */}
          <Lightbox {...lightboxProps} />
        {/* </div> */}
      </div>
    </div>
  );
};

export default Message;
