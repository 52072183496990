import {
  Card,
  CardBody,
  Table,
  Row,
  Col,
  Form,
  Button,
  Badge,
  Container,
} from "react-bootstrap";

import { Project } from "./types";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addClassName } from "@react-leaflet/core";

type EventLogsProps = {
  projects: Project[];
};

const logs = [
  {
    status: {
      label: "200 OK",
      badge: "success",
    },
    APIInfo: {
      Method: "POST",
      api: "/v1/invoices/in_1155_6502/payment	",
    },
    time: "25 Jul 2023, 8:43 pm",
  },
  {
    status: {
      label: "200 OK",
      badge: "success",
    },
    APIInfo: {
      Method: "POST",
      api: "/v1/invoices/in_1155_6502/payment	",
    },
    time: "25 Jul 2023, 8:43 pm",
  },
];

const events = [
  {
    name: [
      <a href="#" className="text-gray-600 text-hover-primary me-1">
        Max Smith
      </a>,
      " has made payment to ",
      <a href="#" className="fw-bold text-gray-900 text-hover-primary">
        #SDK-45670
      </a>,
    ],
    time: "21 Feb 2023, 8:43 pm",
  },
  {
    name: [
      "Invoice ",
      <a href="#" className="fw-bold text-gray-900 text-hover-primary me-1">
        #SEP-45656
      </a>,
      " status has changed from ",
      <span className="badge badge-light-warning me-1">Pending</span>,
      " to ",
      <span className="badge badge-light-info">In Progress</span>,
    ],
    time: "24 Jun 2023, 2:40 pm",
  },
];

const EventLogs = ({ projects }: EventLogsProps) => {
  return (
    <>
      <Card>
        <Card.Header className="h-70 d-flex">
          <Container className="p-0">
            <Row className="d-flex justify-content-between w-100 m-3 mx-0">
              <Col sm={6} className="text-start align-middle mx-0">
                <h5 className="text-uppercase" >Login Sessions</h5>
              </Col>
              <Col sm={6} className="d-flex justify-content-end h-70">
                <Button variant="primary fs-8">Sign out all sessions</Button>
              </Col>
            </Row>
          </Container>
        </Card.Header>
        <Card.Body>
          <Table
            responsive
            className="table table-borderless table-nowrap mb-0"
          >
            <thead className="table-light">
              <tr>
                <th className="">Location</th>
                <th>Device</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {(projects || []).map((project, index) => {
                return (
                  <tr key={index.toString()}>
                    <td>{project.client}</td>
                    <td>{project.name}</td>
                    <td>{project.startDate}</td>
                    <td>{project.dealine}</td>
                    <td>{project.status}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between  m-3 w-100">
            <div>
              <h4>Logs</h4>
            </div>
            <div>
              <Button variant="primary">
                <FontAwesomeIcon icon={"download"} /> Download Report
              </Button>
            </div>
          </div>
        </Card.Header>
        <CardBody>
          <Table
            responsive
            className="table table-borderless table-nowrap mb-0"
          >
            <thead className="table-light">
              <tr>
                <th>Location</th>
                <th>Device</th>
                <th>IP ADDRESS</th>
              </tr>
            </thead>
            <tbody>
              {(logs || []).map((log, index) => {
                return (
                  <tr key={index.toString()}>
                    <td
                      className="d-flex align-items-center"
                      style={{ height: "5rem" }}
                    >
                      <Badge bg={log.status.badge} className="fs-10">
                        {log.status.label}
                      </Badge>
                    </td>
                    <td>
                      <p className="fs-8 text-uppercase mb-1">
                        {log.APIInfo.Method}
                      </p>
                      <p className=" mb-1">{log.APIInfo.api}</p>
                    </td>
                    <td className="d-flex align-items-center">{log.time}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between w-100 m-3">
            <div>
              <h5 className="text-uppercase">Events</h5>
            </div>
            <div>
              <Button variant="primary">
                <FontAwesomeIcon icon={"download"} /> Download Report
              </Button>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          {(events || []).map((event, index) => {
            return (
              <Row className="mb-3">
                <Col sm={8}>
                  <p className="fs-8">{event.name}</p>
                </Col>
                <Col sm={4}>
                  <p
                    className="fs-9 text-uppercase mb-1"
                    style={{
                      minWidth: "150px",
                    }}
                  >
                    {event.time}
                  </p>
                </Col>
              </Row>
            );
          })}
        </Card.Body>
      </Card>
    </>
  );
};

export default EventLogs;
