import React, { ChangeEvent, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { teamMembers } from "data/teamMemeber";
import TeamMemberItem from "craft_components/TeamMemberItem";
import DropdownSearch from "craft_components/DropdownSearch";
import { ITeam } from "./types";
import { searchUser } from "hyper_helpers/api/auth";
import DefaultAvatar from "assets/craft/avatars/blank.png";

interface InviteTeamProps {
    num: number;
    step: number;
    next: () => void;
    previous: () => void;
    team: ITeam;
    setTeam: (team: ITeam) => void;
}

const InviteTeam = (props: InviteTeamProps) => {
    const { num, step, next, previous, team, setTeam } = props;
    const [userList, setUserList] = useState<any>([]);

    let otherClass = 'pending';
    if (step === num) otherClass = 'current';
    if (step > num) otherClass = 'completed';

    const handleSearch = async (val: string) => {
        try {
            const res = await searchUser({who: val});
            setUserList(res.data.data);
        } catch (e) {
            console.log(e);
        }
    }

    const addToTeam = (user: any) => {
        let members = team.teamMembers.filter(member => member.id !== user.UserID);
        members.push({
            id: user.UserID,
            avatar: DefaultAvatar,
            name: `${user.FirstName} ${user.LastName}`,
            email: user.Email,
            position: "view"
        });
        setTeam({...team, teamMembers: members});
    }

    const handleRemove = (id: string) => {
        let members = team.teamMembers.filter(member => member.id !== id);
        setTeam({...team, teamMembers: members});
    }

    const handleRoleChange = (id: string, role: string) => {
        let members = team.teamMembers.map(member => member.id === id ? {...member, position: role} : member);
        setTeam({...team, teamMembers: members});
    }

    return (
        <div className={otherClass} data-kt-stepper-element="content">
            <div className="w-100">
                <div className="pb-6">
                    <h1 className="fw-bold text-dark fs-5">Build a Team</h1>
                    <div className="text-muted fw-semibold fs-7">If you need more info, please check
                    <a href="#" className="link-primary ms-1">Project Guidelines</a></div>
                </div>
                <div className="mb-4">
                    <label className="fs-8 fw-semibold mb-2">Invite Teammates</label>
                    <DropdownSearch
                        inputClassName="rounded"
                        style={{width: '100%'}}
                        handleChange={handleSearch}
                    >
                    {
                        userList.map((user: any, idx: any) => (
                            <div key={`searchedUser_${idx}`} className="d-flex align-items-center p-2 cursor-pointer" onClick={() => addToTeam(user)}>
                                <div className="avatar avatar-m">
                                    <img className="rounded-circle" alt="Pic" src={DefaultAvatar} />
                                </div>
                                <div className="ms-3">
                                    <span className="fs-8 fw-bold text-gray-900 text-hover-primary mb-1">{`${user.FirstName} ${user.LastName}`}</span>
                                    <div className="fw-semibold text-muted">{user.Email}</div>
                                </div>
                            </div>
                        ))
                    }
                    </DropdownSearch>
                </div>
                <div className="mb-4">
                    <div className="fs-8 fw-semibold mb-2">Team Members</div>
                    <div className="mh-300px scroll-y me-n7 pe-7">
                        {
                            team.teamMembers.map((member, idx) => <TeamMemberItem key={member.id} isLast={teamMembers.length === idx + 1} user={member} roleChange={handleRoleChange} onRemove={handleRemove} />)
                        }
                    </div>
                </div>
                <div className="d-flex flex-stack mb-7">
                    <div className="me-3 fw-semibold">
                        <label className="fs-8">Adding Users by Team Members</label>
                        <div className="fs-9 text-muted">If you need more info, please check budget planning</div>
                    </div>
                    <label className="form-check form-switch form-check-custom form-check-solid">
                        <input className="form-check-input h-30px w-50px" type="checkbox" value="" defaultChecked />
                    </label>
                </div>
                <div className="d-flex flex-stack">
                    <Button variant="outline-primary" size="lg" onClick={previous}>Previous</Button>
                    <Button variant="primary" size="lg" onClick={next}>
                        Next
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default InviteTeam;