import { APICore } from './apiCore';

const api = new APICore();

function uploadAudio(data: any) {
  const baseUrl = '/v1/comm/msg/speech-to-text';
  return api.createWithFile(`${baseUrl}`, data);
}

function sendMessage(data: any) {
  const baseUrl = '/v1/comm/msg/send-message-bot';
  return api.create(`${baseUrl}`, data, data?.uploadHandle, data?.downloadHandle);
}

function convertText2Audio(data: any) {
  const baseUrl = '/v1/comm/msg/text-to-speech';
  return api.create(`${baseUrl}`, data);
}

export { uploadAudio, sendMessage, convertText2Audio}