import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartData,
    ChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const TaskOvertime = () => {
    // chart data
    const chartData: ChartData<'line'> = {
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        datasets: [
            {
                label: 'Completed Tasks',
                backgroundColor: 'transparent',
                borderColor: '#0acf97',
                tension: 0.4,
                data: [32, 42, 42, 62, 52, 75, 62],
                // fill: {
                //     target: 'origin',
                //     above: 'rgba(10, 207, 151, 0.3)',
                // },
                capBezierPoints: true,
            },
            {
                label: 'Incompleted Tasks',
                backgroundColor: 'transparent',
                borderColor: '#727cf5',
                tension: 0.4,
                data: [42, 58, 66, 93, 82, 105, 92],
                // fill: {
                //     target: 'origin',
                //     above: 'rgba(10, 207, 151, 0.3)',
                // },
                capBezierPoints: true,
            },
        ],
    };

    // chart options
    const chartOpts: ChartOptions<'line'> = {
        maintainAspectRatio: false,
        hover: {
            intersect: true,
        },
        plugins: {
            filler: {
                propagate: true,
            },
            legend: {
                display: false,
            },
            tooltip: {
                intersect: false,
            },
        },
        scales: {
            x: {
                grid: {
                    color: 'rgba(0,0,0,0.05)',
                },
            },
            y: {
                ticks: {
                    stepSize: 20,
                },
                display: true,
                grid: {
                    color: 'rgba(0,0,0,0)',
                },
            },
        },
    };

    return (
        <div className="col-lg-6">
            <div className="card card-flush h-lg-100">
                <div className="card-header mt-3">
                    <div className="card-title flex-column">
                        <h3 className="fw-bold mb-1 fs-7">Tasks Over Time</h3>
                        <div className="fs-8 d-flex text-gray-400 fw-semibold">
                            <div className="d-flex align-items-center me-3">
                            <span className="menu-bullet d-flex align-items-center me-2">
                                <span className="bullet bg-success"></span>
                            </span>Complete</div>
                            <div className="d-flex align-items-center">
                            <span className="menu-bullet d-flex align-items-center me-2">
                                <span className="bullet bg-primary"></span>
                            </span>Incomplete</div>
                        </div>
                    </div>
                    <div className="card-toolbar">
                        <select name="status" data-control="select2" data-hide-search="true" className="form-select form-select-solid form-select-sm fw-bold">
                            <option value="1">2020 Q1</option>
                            <option value="2">2020 Q2</option>
                            <option value="3" selected={true}>2020 Q3</option>
                            <option value="4">2020 Q4</option>
                        </select>
                    </div>
                </div>
                <div className="card-body pt-5 pb-0 px-5">
                    <div className="chartjs-chart" style={{ height: '300px' }}>
                        <Line data={chartData} options={chartOpts} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TaskOvertime;