import React from "react";
import { Card, Dropdown } from "react-bootstrap";
import { CountryOption, countryList } from 'constant';
import NarrowDown from 'components/icons/NarrowDown';

const SuperAdminInfo = () => {
    const [languageOption, setLanguageOption] = React.useState<CountryOption>();
    const [filteredCountryList, setFilteredCountryList] = React.useState<CountryOption[]>(countryList);
    const [searchKey, setSearchKey] = React.useState<string>('');

    const setLanguage = (value: string) => {
        const option = countryList.filter(item => item.CountryCode === value)[0];
        setLanguageOption(option);
    }

    const handleFilterCountry = (searchKey: string) => {
        let filteredCountry = countryList.filter(country => country.CountryName.toLowerCase().startsWith(searchKey));
        setFilteredCountryList(filteredCountry);
    }

    return (
        <Card id="superAdminInformation">
            <Card.Header>
                <Card.Title className="fs-7">Super Admin Information</Card.Title>
            </Card.Header>
            <Card.Body>
                <div className="row mb-4">
                    <label htmlFor="firstNameLabel" className="col-sm-3 col-form-label form-label text-notrans">Super Admin's name <i className="bi-question-circle text-body ms-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Displayed on public forums, such as Front."></i></label>

                    <div className="col-sm-9">
                      <div className="input-group input-group-sm-vertical">
                        <input type="text" className="form-control" name="firstName" id="firstNameLabel" placeholder="Your first name" aria-label="Your first name" value="Mark" />
                        <input type="text" className="form-control" name="lastName" id="lastNameLabel" placeholder="Your last name" aria-label="Your last name" value="Williams" />
                      </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="emailLabel" className="col-sm-3 col-form-label form-label text-notrans">Email</label>

                    <div className="col-sm-9">
                      <input type="email" className="form-control" name="email" id="emailLabel" placeholder="Email" aria-label="Email" value="mark@site.com" />
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="phoneLabel" className="col-sm-3 col-form-label form-label text-notrans">Phone <span className="form-label-secondary">(Optional)</span></label>

                    <div className="col-sm-9">
                      <input type="text" className="js-input-mask form-control" name="phone" id="phoneLabel" placeholder="+x(xxx)xxx-xx-xx" aria-label="+x(xxx)xxx-xx-xx" value="+44 (020) 67-22-22" />
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="organizationLabel" className="col-sm-3 col-form-label form-label text-notrans">Organization Name</label>

                    <div className="col-sm-9">
                      <input type="text" className="form-control" name="organization" id="organizationLabel" placeholder="Your organization" aria-label="Your organization" value="Organisation Name" />
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="locationLabel" className="col-sm-3 col-form-label form-label text-notrans">Location</label>

                    <div className="col-sm-9">
                        <div className="btn-reveal-trigger-custom mx-auto">
                            <Dropdown
                                onSelect={e => {if (e) setLanguage(e)}}
                                >
                                <Dropdown.Toggle as='div' className='cursor-pointer'>
                                    <div className='d-flex p-1 px-3 align-items-center'>
                                    <div className='rounded-pill widget-header-flag' style={{backgroundImage: `url('http://purecatamphetamine.github.io/country-flag-icons/3x2/` + `${languageOption?.CountryCode}` + `.svg')`, width: 28, height: 28}}>
                                    </div>
                                    <label className='text-black ms-2 flex-1 cursor-pointer'>
                                        {languageOption?.CountryName}
                                    </label>
                                    <NarrowDown color='black'/>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='w-100'>
                                    <div className="px-3 mb-1">
                                        <input type="text" className="form-control" placeholder="search" value={searchKey} onChange={e => handleFilterCountry(e.target.value)} />
                                    </div>
                                    <div style={{height: 180, overflow: 'scroll'}}>
                                    {filteredCountryList.map((country, index) => {
                                        return (
                                        <Dropdown.Item eventKey={country.CountryCode} key={index}>
                                            <div className='d-flex align-items-center'>
                                            <div className='rounded-pill widget-header-flag' style={{backgroundImage: `url('http://purecatamphetamine.github.io/country-flag-icons/3x2/` + `${country?.CountryCode}` + `.svg')`, width: 28, height: 28}}>
                                            </div>
                                            <label className='text-black ms-2 flex-1 font-14 cursor-pointer'>
                                                {country?.CountryName}
                                            </label>
                                            </div>
                                        </Dropdown.Item>
                                        )
                                    })}
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="organizationLabel" className="col-sm-3 col-form-label form-label text-notrans"></label>

                    <div className="col-sm-9">
                      <input type="text" className="form-control" name="organization" id="organizationLabel" placeholder="Your organization" aria-label="Your organization" value="Organisation Name" />
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary">Save changes</button>
                </div>
            </Card.Body>
        </Card>
    );
}

export default SuperAdminInfo;