// AddPomission component
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

interface CancelModelProps {
  isOpen: boolean;
  handleClickDiscard: () => void;
  handleClickSubmit: () => void;
}

const CancelModal: React.FC<CancelModelProps> = ({ isOpen, handleClickDiscard, handleClickSubmit }) => {
  return (
    <>
      <Modal show={isOpen} onHide={handleClickDiscard}>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3 m-3"
              controlId="exampleForm.ControlInput1"
            >
              <h5>Permission Name </h5>
              <Form.Control
                type="text"
                placeholder="Enter a permission name"
                autoFocus
              />
            </Form.Group>
            <Form.Group className="m-3">
              <Form.Check.Input></Form.Check.Input>
              <Form.Check.Label className="px-2">
                Set as core permission
              </Form.Check.Label>
            </Form.Group>
            <div className="m-3">
              Permission set as a <strong>Core Permission</strong> will be
              locked and <strong>not editable</strong> in future
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-md-center p-2">
          <Button variant="light" onClick={handleClickDiscard}>
            Discard
          </Button>
          <Button variant="primary" onClick={handleClickSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CancelModal;
