import React, { ChangeEvent, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterButtonGroup, {
  FilterMenu,
} from "components/common/FilterButtonGroup";
import FilterTab, { FilterTabItem } from "components/common/FilterTab";
import SearchBox from "components/common/SearchBox";
import { teams } from "data/team/team";
import { role } from "data/team/team";
import useAdvanceTable from "hooks/useAdvanceTable";
import { defaultBreadcrumbItems } from "data/commonData";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import MembersTable from "components/tables/MembersTable";
import { roleTablecolumns } from "components/tables/TeamTable";
import AddPermission from "./AddPermission";
import Header from "../../common/Header";
import EditImage from "assets/images/add_new_role.png";

const RoleList = () => {
  const [isUserPopOpen, setIsUserPopOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(true);
  const table = useAdvanceTable({
    data: role,
    columns: roleTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleAddUserClick = () => {
    setIsUserPopOpen(true);
  };

  const handleUserPopClose = () => {
    setIsUserPopOpen(false);
  };

  const handleOnSelcted = (flag: boolean) => {
    console.log(flag);
    setIsSelected(flag);
  };

  const location = window.location.pathname;
  console.log("location :>> ", location);
  return (
    <div>
      <div className="mb-9">
        <Header title="Roles List" BreadcrumbItems={defaultBreadcrumbItems} />
        <AdvanceTableProvider {...table}>
          <div className="bg-white p-4">
            <Row>
              <Col sm={9}>
                <SearchBox
                  placeholder="Search customers"
                  className="w-100"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col sm={3}>
                <div className="m-2 d-flex justify-content-end">
                  <Button variant="primary" onClick={handleAddUserClick}>
                    <FontAwesomeIcon icon="plus" className="me-2" />
                    Add Permission
                  </Button>
                  <AddPermission
                    isOpen={isUserPopOpen}
                    isSelected={isSelected}
                    handleClose={handleUserPopClose}
                    onSelected={handleOnSelcted}
                  />
                </div>
              </Col>
            </Row>
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default RoleList;
