import React from "react";
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { useAppContext } from 'providers/AppProvider';
import { Button } from "react-bootstrap";
import { TooltipComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import ChartLegend from 'components/common/ChartLegend';
echarts.use([TooltipComponent, PieChart]);

const TaskSummary = () => {
    const { getThemeColor } = useAppContext();

    const getDefaultOptions = (getThemeColor: (name: string) => string) => ({
        color: [
            getThemeColor('primary'),
            getThemeColor('primary-200'),
            getThemeColor('info-500'),
            getThemeColor('secondary')
        ],
        tooltip: {
            trigger: 'item',
            padding: [7, 10],
            backgroundColor: getThemeColor('gray-100'),
            borderColor: getThemeColor('gray-300'),
            textStyle: { color: getThemeColor('dark') },
            borderWidth: 1,
            transitionDuration: 0,
            formatter: (params: CallbackDataParams) =>
            `<strong>${params.name}:</strong> ${params.percent}%`
        },
        legend: { show: false },
        series: [
            {
                name: '237\nTotal Tasks',
                type: 'pie',
                radius: ['100%', '75%'],
                avoidLabelOverlap: false,
                emphasis: {
                    scale: false,
                    itemStyle: {
                    color: 'inherit'
                    }
                },
                itemStyle: {
                    borderWidth: 2,
                    borderColor: getThemeColor('white')
                },
                label: {
                    show: true,
                    position: 'center',
                    formatter: '{a}',
                    fontSize: 23,
                    color: getThemeColor('dark')
                },
                data: [
                    { value: 6200000, name: 'Percentage discount' },
                    { value: 1900000, name: 'Fixed card discount' },
                    { value: 1000000, name: 'Fixed product discount' },
                    { value: 900000, name: 'Fixed product1 discount' }
                ]
            }
        ],
        grid: { containLabel: true }
    });

    return (
        <div className="col-lg-6">
            <div className="card card-flush h-lg-100">
                <div className="card-header mt-3">
                    <div className="card-title flex-column">
                        <h3 className="fw-bold fs-7 mb-1">Tasks Summary</h3>
                        <div className="fs-8 fw-semibold text-400">24 Overdue Tasks</div>
                    </div>
                    <div className="card-toolbar">
                        <Button variant="outline-primary" size="sm">View Tasks</Button>
                    </div>
                </div>
                <div className="card-body px-5 pt-5">
                    <div className="d-flex flex-wrap align-items-center">
                        <div className="position-relative d-flex flex-center me-7 mb-3">
                            <ReactEChartsCore
                                echarts={echarts}
                                option={getDefaultOptions(getThemeColor)}
                                style={{ height: '175px', width: '175px' }}
                            />
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <ChartLegend
                                bulletBg="primary"
                                label="Active"
                                value="72%"
                            />
                            <ChartLegend
                                bulletBg="primary-200"
                                label="Completed"
                                value="18%"
                            />
                            <ChartLegend
                                bulletBg="info"
                                label="Overdue"
                                value="70%"
                            />
                            <ChartLegend
                                bulletBg="secondary"
                                label="Yet to start"
                                value="20%"
                            />
                        </div>
                    </div>
                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-3">
                        <div className="d-flex flex-stack flex-grow-1">
                            <div className="fw-semibold">
                                <div className="fs-8 text-700">
                                    <a href="#" className="fw-bold me-1">Invite New .NET Collaborators</a>
                                    to create great outstanding business to business .jsp modutr class scripts
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TaskSummary;