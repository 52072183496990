import {
    Day,
    Schedule,
    LatestFile,
    Contributer,
    Task,
    Project,
    TeamMember,
    FileInfo,
    IQuestion,
    QuestionError,
} from "./types";
import File1 from "assets/craft/files/pdf.svg";
import File2 from "assets/craft/files/css.svg";
import File3 from "assets/craft/files/ai.svg";
import File4 from "assets/craft/files/doc.svg";

import File5 from "assets/craft/avatars/300-1.jpg";
import File6 from "assets/craft/avatars/300-10.jpg";
import File7 from "assets/craft/avatars/300-11.jpg";
import File8 from "assets/craft/avatars/300-12.jpg";

export const dateData: Day[] = [
    {
        dayOfWeek: "Su",
        date: 22,
    },
    {
        dayOfWeek: "Mo",
        date: 23,
    },
    {
        dayOfWeek: "Tu",
        date: 24,
    },
    {
        dayOfWeek: "We",
        date: 25,
    },
    {
        dayOfWeek: "Th",
        date: 26,
    },
    {
        dayOfWeek: "Fr",
        date: 27,
    },
    {
        dayOfWeek: "Sa",
        date: 28,
    },
    {
        dayOfWeek: "Su",
        date: 29,
    },
    {
        dayOfWeek: "Mo",
        date: 30,
    },
    {
        dayOfWeek: "Tu",
        date: 31,
    },
];

export const scheduleData: Schedule[][] = [
    [
        {
            time: "13:00 - 14:00",
            noon: "pm",
            title: "Committee Review Approvals",
            leader: "Yannis Gloverson",
        },
        {
            time: "10:00 - 11:00",
            noon: "am",
            title: "Dashboard UI/UX Design Review",
            leader: "Bob Harris",
        },
        {
            time: "13:00 - 14:00",
            noon: "pm",
            title: "Project Review & Testing",
            leader: "Kendell Trevor",
        },
    ],
    [
        {
            time: "12:00 - 13:00",
            noon: "pm",
            title: "Weekly Team Stand-Up",
            leader: "Michael Walters",
        },
        {
            time: "9:00 - 10:00",
            noon: "am",
            title: "Development Team Capacity Review",
            leader: "Kendell Trevor",
        },
        {
            time: "11:00 - 11:45",
            noon: "am",
            title: "Committee Review Approvals",
            leader: "Yannis Gloverson",
        },
    ],
    [
        {
            time: "13:00 - 14:00",
            noon: "pm",
            title: "Committee Review Approvals",
            leader: "Yannis Gloverson",
        },
        {
            time: "10:00 - 11:00",
            noon: "am",
            title: "Dashboard UI/UX Design Review",
            leader: "Bob Harris",
        },
        {
            time: "13:00 - 14:00",
            noon: "pm",
            title: "Project Review & Testing",
            leader: "Kendell Trevor",
        },
    ],
    [
        {
            time: "12:00 - 13:00",
            noon: "pm",
            title: "Weekly Team Stand-Up",
            leader: "Michael Walters",
        },
        {
            time: "9:00 - 10:00",
            noon: "am",
            title: "Development Team Capacity Review",
            leader: "Kendell Trevor",
        },
        {
            time: "11:00 - 11:45",
            noon: "am",
            title: "Committee Review Approvals",
            leader: "Yannis Gloverson",
        },
    ],
    [
        {
            time: "13:00 - 14:00",
            noon: "pm",
            title: "Committee Review Approvals",
            leader: "Yannis Gloverson",
        },
        {
            time: "10:00 - 11:00",
            noon: "am",
            title: "Dashboard UI/UX Design Review",
            leader: "Bob Harris",
        },
        {
            time: "13:00 - 14:00",
            noon: "pm",
            title: "Project Review & Testing",
            leader: "Kendell Trevor",
        },
    ],
    [
        {
            time: "12:00 - 13:00",
            noon: "pm",
            title: "Weekly Team Stand-Up",
            leader: "Michael Walters",
        },
        {
            time: "9:00 - 10:00",
            noon: "am",
            title: "Development Team Capacity Review",
            leader: "Kendell Trevor",
        },
        {
            time: "11:00 - 11:45",
            noon: "am",
            title: "Committee Review Approvals",
            leader: "Yannis Gloverson",
        },
    ],
    [
        {
            time: "13:00 - 14:00",
            noon: "pm",
            title: "Committee Review Approvals",
            leader: "Yannis Gloverson",
        },
        {
            time: "10:00 - 11:00",
            noon: "am",
            title: "Dashboard UI/UX Design Review",
            leader: "Bob Harris",
        },
        {
            time: "13:00 - 14:00",
            noon: "pm",
            title: "Project Review & Testing",
            leader: "Kendell Trevor",
        },
    ],
    [
        {
            time: "12:00 - 13:00",
            noon: "pm",
            title: "Weekly Team Stand-Up",
            leader: "Michael Walters",
        },
        {
            time: "9:00 - 10:00",
            noon: "am",
            title: "Development Team Capacity Review",
            leader: "Kendell Trevor",
        },
        {
            time: "11:00 - 11:45",
            noon: "am",
            title: "Committee Review Approvals",
            leader: "Yannis Gloverson",
        },
    ],
    [
        {
            time: "13:00 - 14:00",
            noon: "pm",
            title: "Committee Review Approvals",
            leader: "Yannis Gloverson",
        },
        {
            time: "10:00 - 11:00",
            noon: "am",
            title: "Dashboard UI/UX Design Review",
            leader: "Bob Harris",
        },
        {
            time: "13:00 - 14:00",
            noon: "pm",
            title: "Project Review & Testing",
            leader: "Kendell Trevor",
        },
    ],
    [
        {
            time: "12:00 - 13:00",
            noon: "pm",
            title: "Weekly Team Stand-Up",
            leader: "Michael Walters",
        },
        {
            time: "9:00 - 10:00",
            noon: "am",
            title: "Development Team Capacity Review",
            leader: "Kendell Trevor",
        },
        {
            time: "11:00 - 11:45",
            noon: "am",
            title: "Committee Review Approvals",
            leader: "Yannis Gloverson",
        },
    ],
    [
        {
            time: "13:00 - 14:00",
            noon: "pm",
            title: "Committee Review Approvals",
            leader: "Yannis Gloverson",
        },
        {
            time: "10:00 - 11:00",
            noon: "am",
            title: "Dashboard UI/UX Design Review",
            leader: "Bob Harris",
        },
        {
            time: "13:00 - 14:00",
            noon: "pm",
            title: "Project Review & Testing",
            leader: "Kendell Trevor",
        },
    ],
    [
        {
            time: "12:00 - 13:00",
            noon: "pm",
            title: "Weekly Team Stand-Up",
            leader: "Michael Walters",
        },
        {
            time: "9:00 - 10:00",
            noon: "am",
            title: "Development Team Capacity Review",
            leader: "Kendell Trevor",
        },
        {
            time: "11:00 - 11:45",
            noon: "am",
            title: "Committee Review Approvals",
            leader: "Yannis Gloverson",
        },
    ],
];

export const latestFiles: LatestFile[] = [
    {
        icon: File1,
        title: "Project tech requirements",
        status: "2 days ago",
        owner: "Karina Clark",
    },
    {
        icon: File2,
        title: "Create FureStibe branding proposal",
        status: "Due in 1 day",
        owner: "Marcus Blake",
    },
    {
        icon: File3,
        title: "Completed Project Stylings",
        status: "Due in 1 day",
        owner: "Terry Barry",
    },
    {
        icon: File4,
        title: "Create Project Wireframes",
        status: "Due in 3 day",
        owner: "Roth Bloom",
    },
];

export const contributerData: Contributer[] = [
    {
        avatar: File5,
        name: "Emma Smith",
        status: "8 Pending & 97 Completed Tasks",
        badge: 5,
        online: false,
    },
    {
        avatar: undefined,
        name: "Melody Macy",
        status: "5 Pending & 84 Completed",
        badge: 8,
        online: true,
    },
    {
        avatar: File6,
        name: "Max Smith",
        status: "9 Pending & 103 Completed Tasks",
        badge: 9,
        online: true,
    },
    {
        avatar: File7,
        name: "Sean Bean",
        status: "3 Pending & 55 Completed Tasks",
        badge: 3,
        online: false,
    },
    {
        avatar: File8,
        name: "Brian Cox",
        status: "4 Pending & 105 Completed Tasks",
        badge: 4,
        online: false,
    },
];

export const taskData: Task[] = [
    {
        checked: true,
        title: "Project tech requirements",
        status: "2 days ago",
        owner: "Karina Clark",
    },
    {
        checked: false,
        title: "Create FureStibe branding proposal",
        status: "Due in 3 day",
        owner: "Marcus Blake",
    },
    {
        checked: true,
        title: "Completed Project Stylings",
        status: "Due in 1 week",
        owner: "Terry Barry",
    },
    {
        checked: false,
        title: "Create Project Wireframes",
        status: "Due in 1 week",
        owner: "Roth Bloom",
    },
    {
        checked: false,
        title: "Mivy App R&D, Meeting with clients",
        status: "Due in 2 weeks",
        owner: "Sean Bean",
    },
];

export const projectData: Project[] = [
    {
        avatar: File5,
        name: "Emma Smith",
        date: "Dec 20, 2023",
        email: "smith@kpmg.com",
        amount: 300,
        status: "Rejected",
        online: false,
    },
    {
        avatar: undefined,
        name: "Melody Macy",
        date: "Apr 15, 2023",
        email: "melody@altbox.com",
        amount: 300,
        status: "In progress",
        online: true,
    },
    {
        avatar: File6,
        name: "Max Smith",
        date: "Jun 24, 2023",
        email: "max@kt.com",
        amount: 300,
        status: "Pending",
        online: true,
    },
    {
        avatar: File7,
        name: "Sean Bean",
        date: "Sep 22, 2023",
        email: "mik@pex.com",
        amount: 300,
        status: "Approved",
        online: false,
    },
    {
        avatar: File8,
        name: "Brian Cox",
        date: "May 05, 2023",
        email: "f.mit@kpmg.com",
        amount: 300,
        status: "Rejected",
        online: false,
    },
];

export const teamData: TeamMember[] = [
    {
        avatar: File5,
        name: "Emma Smith",
        date: "Dec 20, 2023",
        email: "smith@kpmg.com",
        amount: 300,
        status: "Rejected",
        online: false,
        position: "Art Director at Novica Co.",
        earnings: 14560,
        tasks: 23,
        sales: 236400,
    },
    {
        avatar: undefined,
        name: "Melody Macy",
        date: "Apr 15, 2023",
        email: "melody@altbox.com",
        amount: 300,
        status: "In progress",
        online: true,
        position: "Developer at Loop Inc",
        earnings: 14560,
        tasks: 23,
        sales: 236400,
    },
    {
        avatar: File6,
        name: "Max Smith",
        date: "Jun 24, 2023",
        email: "max@kt.com",
        amount: 300,
        status: "Pending",
        online: true,
        position: "Web Designer at Nextop Ltd.",
        earnings: 14560,
        tasks: 23,
        sales: 236400,
    },
    {
        avatar: File7,
        name: "Sean Bean",
        date: "Sep 22, 2023",
        email: "mik@pex.com",
        amount: 300,
        status: "Approved",
        online: false,
        position: "Marketing Analytic at Avito Ltd.",
        earnings: 14560,
        tasks: 23,
        sales: 236400,
    },
    {
        avatar: File8,
        name: "Brian Cox",
        date: "May 05, 2023",
        email: "f.mit@kpmg.com",
        amount: 300,
        status: "Rejected",
        online: false,
        position: "Art Director at Seal Inc.",
        earnings: 14560,
        tasks: 23,
        sales: 236400,
    },
];

export const fileData: FileInfo[] = [
    {
        icon: File2,
        title: "Project Reqs..",
        status: "3 days ago",
    },
    {
        icon: File3,
        title: "CRM App Docs..",
        status: "3 days ago",
    },
    {
        icon: File4,
        title: "User CRUD Styles",
        status: "3 days ago",
    },
    {
        icon: File1,
        title: "Product Logo",
        status: "4 days ago",
    },
    {
        icon: File2,
        title: "Orders backup",
        status: "1 week ago",
    },
    {
        icon: File3,
        title: "UTAIR CRM API Co..",
        status: "2 weeks ago",
    },
    {
        icon: File4,
        title: "Tower Hill App..",
        status: "3 weeks ago",
    },
];

export const initialQuestion: IQuestion = {
    title: "",
    question: "",
    triggers: "",
    tags: [],
    published: true,
    connected_service: [],
};

export const initialError: QuestionError = {
    status: true,
    title: false,
    triggers: false,
    tags: false,
};
