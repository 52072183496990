import React from "react";
import { Card, Button } from "react-bootstrap";
import { socialLogin } from "./data";

const SocialLogin = () => {
    return (
        <Card id="socialLogin">
            <Card.Header>
                <Card.Title className="fs-7">Social Login</Card.Title>
            </Card.Header>
            <Card.Body>
                <div className="list-group list-group-lg list-group-flush list-group-no-gutters">
                    {socialLogin.map((social, idx) => (
                        <div className="list-group-item mb-1" key={`social_${idx}`}>
                            <div className="d-flex">
                                <div className="flex-shrink-0 text-600">
                                    <i className={`${social.icon} list-group-icon fs-6 me-3`}></i>
                                </div>

                                <div className="flex-grow-1">
                                <div className="row align-items-center">
                                    <div className="col-sm mb-2 mb-sm-0">
                                        <h4 className="my-0">{social.name}</h4>
                                        {social.link ?
                                            <a className="fs-8" href={social.href}>{social.link}</a>
                                            :
                                            <p className="fs-8 text-body mb-0">Not connected</p>
                                        }
                                    </div>

                                    <div className="col-sm-auto">
                                        <Button className="border border-light" variant="outline-light" size="sm">{social.link ? 'Disconnect' : 'Connect'}</Button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Card.Body>
        </Card>
    );
}

export default SocialLogin;