import { Row, Col, Card, Tab, Nav, Button, Dropdown } from "react-bootstrap";
import UserBox from "./UserBox";
import OverView from "./OverView";
import Security from "./Security";
import EventLogs from "./EventLogs";
import { projects, posts } from "./data";
import Header from "../../common/Header";
import ConnectedAccounts from "./ConnectedAccounts";
import { teamViewBreadcrumbItems } from "data/commonData";

const UserView = () => {
  return (
    <>
      <Header title={"View Team"} BreadcrumbItems={teamViewBreadcrumbItems}/>
      <Row>
        <Col xl={5} lg={5}>
          <UserBox />
          <ConnectedAccounts />
        </Col>
        <Col xl={7} lg={7}>
          <Tab.Container defaultActiveKey="overview">
            <Row>
              <Col sm={8}>
                <Nav
                  as="ul"
                  variant="pills"
                  className="nav nav-pills mb-3 d-flex justify-content-between"
                >
                  <Nav onSelect={(selectedKey) => console.log(selectedKey)}>
                    <Nav.Item as="li">
                      <Nav.Link eventKey="overview" className="fs-8 p-3">
                        OverView
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link eventKey="security" className="fs-8 p-3">
                        Security
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link eventKey="events_logs" className="fs-8 p-3">
                        Events & Logs
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="fs-8 d-flex float-end"></Nav.Item>
                  </Nav>
                </Nav>
              </Col>
              <Col sm={4}>
                <Dropdown className="pull-right justify-content-center">
                  <Dropdown.Toggle
                    variant="primary"
                    className="bg-primary mb-3 text-white w-100 fs-8 p-3 bottom-start"
                  >
                    Actions
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="py-2">
                    <Dropdown.Item className="fs-8">
                      Create Invoice
                    </Dropdown.Item>
                    <Dropdown.Item className="fs-8">
                      Create payment
                    </Dropdown.Item>
                    <Dropdown.Item className="fs-8">Subscription</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item className="fs-8 text-muted">
                      Team
                    </Dropdown.Item>
                    <Dropdown.Item className="fs-8">Reports</Dropdown.Item>
                    <Dropdown.Item className="fs-8">
                      Team Settings
                    </Dropdown.Item>
                    <Dropdown.Item className="fs-8 text-danger">
                      Delete Team
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>

            <Tab.Content>
              <Tab.Pane eventKey="overview">
                <OverView projects={projects} />
              </Tab.Pane>
              <Tab.Pane eventKey="security">
                <Security projects={projects} />
              </Tab.Pane>
              <Tab.Pane eventKey="events_logs">
                <EventLogs projects={projects} />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </>
  );
};

export default UserView;
