import { Form } from "react-bootstrap";
import { UserInfo, UserInfoError } from "./types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faFacebook, faGoogle, faLinkedin, faMicrosoft, faTwitter } from "@fortawesome/free-brands-svg-icons";

interface UserProps {
    num: number;
    step: number;
    userInfo: UserInfo;
    setUserInfo: (userInfo: UserInfo) => void;
    userInfoError: UserInfoError
}

const UserRegistration = (props: UserProps) => {
    const { num, step, userInfo, setUserInfo, userInfoError } = props;

    let otherClass = 'pending';
    if (step === num) otherClass = 'current';
    if (step > num) otherClass = 'completed';

    return (
        <div className={otherClass} data-kt-stepper-element="content">
            <div className="w-100">
                <div className="pb-5 pb-lg-7">
                    <h2 className="fw-bold text-dark fs-6">User Registration</h2>
                    <div className="text-muted fw-semibold fs-8">
                        If you need more info, please visit the 
                        <a href="https://localgovai.uk/help" className="link-primary fw-bold ms-1">Help Page</a>.
                    </div>
                </div>
                <div className="container">
                    <p>Already have an account? <Link to={'/login'}>Sign in to your account.</Link></p>
                    <div className="row mb-3">
                        <Form.Group className="col">
                            <Form.Label className="fs-8 ps-0 text-notrans">First Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={userInfo.firstName}
                                onChange={e => setUserInfo({...userInfo, firstName: e.target.value})}
                                isInvalid={userInfoError.firstName}
                            />
                            <Form.Control.Feedback type="invalid">First Name is required</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="col">
                            <Form.Label className="fs-8 ps-0 text-notrans">Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={userInfo.lastName}
                                onChange={e => setUserInfo({...userInfo, lastName: e.target.value})}
                                isInvalid={userInfoError.lastName}
                            />
                            <Form.Control.Feedback type="invalid">Last Name is required</Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <Form.Group className="mb-3">
                        <Form.Label className="fs-8 ps-0 text-notrans">Username</Form.Label>
                        <Form.Control
                            type="text"
                            value={userInfo.userName}
                            onChange={e => setUserInfo({...userInfo, userName: e.target.value})}
                            isInvalid={userInfoError.userName}
                        />
                        <Form.Control.Feedback type="invalid">Username is required</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="fs-8 ps-0 text-notrans">Email</Form.Label>
                        <Form.Control
                            type="email"
                            value={userInfo.email}
                            onChange={e => setUserInfo({...userInfo, email: e.target.value})}
                            isInvalid={userInfoError.email}
                        />
                        <Form.Control.Feedback type="invalid">Email is required</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="fs-8 ps-0 text-notrans">Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={userInfo.password}
                            onChange={e => setUserInfo({...userInfo, password: e.target.value})}
                            isInvalid={userInfoError.password}
                        />
                        <Form.Control.Feedback type="invalid">Password is required</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="fs-8 ps-0 text-notrans">Password Confirm</Form.Label>
                        <Form.Control
                            type="password"
                            value={userInfo.confirmPwd}
                            onChange={e => setUserInfo({...userInfo, confirmPwd: e.target.value})}
                            isInvalid={userInfoError.confirmPwd}
                        />
                        <Form.Control.Feedback type="invalid">Password is not match</Form.Control.Feedback>
                    </Form.Group>
                    {/* <div className="d-flex justify-content-around mb-3">
                        <button className="btn btn-primary btn-sm">
                            <FontAwesomeIcon className="me-1" icon={faTwitter} size="lg" />
                            Twitter
                        </button>
                        <button className="btn btn-primary btn-sm">
                            <FontAwesomeIcon className="me-1" icon={faMicrosoft} size="lg" />
                            Microsoft
                        </button>
                        <button className="btn btn-primary btn-sm">
                            <FontAwesomeIcon className="me-1" icon={faLinkedin} size="lg" />
                            LinkedIn
                        </button>
                        <button className="btn btn-primary btn-sm">
                            <FontAwesomeIcon className="me-1" icon={faApple} size="lg" />
                            Apple
                        </button>
                        <button className="btn btn-primary btn-sm">
                            <FontAwesomeIcon className="me-1" icon={faGoogle} size="lg" />
                            Google
                        </button>
                        <button className="btn btn-primary btn-sm">
                            <FontAwesomeIcon className="me-1" icon={faFacebook} size="lg" />
                            Facebook
                        </button>
                    </div> */}
                    <Form.Group>
                        <Form.Check
                            label={
                                <label className="form-label fs--1 text-none fs-8" htmlFor="termsService">
                                    I accept the 
                                    <Link className="mx-1" to={'/pages/faq/faq-accordion'}>terms of service</Link>
                                    and 
                                    <Link className="ms-1" to={'/pages/faq/faq-accordion'}>privacy policy</Link>
                                </label>
                            }
                            feedback="You must agree before submitting."
                            feedbackType="invalid"
                            isInvalid={userInfoError.policy}
                            checked={userInfo.policy}
                            onChange={e => setUserInfo({...userInfo, policy: e.target.checked})}
                        />
                    </Form.Group>
                </div>
            </div>
        </div>
    )
}

export default UserRegistration;