import React, { useEffect, useState } from 'react';

const ChatIcon = ({ style }: { style: any}) => {
  
  // const [userBlink, setUserBlinkColor] = useState('white');
  // const [blinkBorder1, setBlinkBorder1] = useState('#07B208');
  // const [botBlink, setBotBlinkColor] = useState('white');
  // const [blinkBorde2, setBlinkBorder2] = useState('#07B208');

  // useEffect(() => {
  //   if (speaking)   {
  //     if(agent === 'user') {
  //       setTimeout(() => {
  //         if(userBlink === 'white') {
  //           setUserBlinkColor('#00E3FF');
  //         } else {
  //           setUserBlinkColor('white');
  //         }

  //         if(blinkBorder1 === '#07B208') {
  //           setBlinkBorder1('white');
  //         } else {
  //           setBlinkBorder1('#07B208');
  //         }
  //       }, 500);
  //     }
  //   }
  // }, [speaking, userBlink, blinkBorder1]);

  // useEffect(() => {
  //   if(speaking) {
  //     setTimeout(() => {
  //       if(botBlink === 'white') {
  //         setBotBlinkColor('#002d40');
  //       } else {
  //         setBotBlinkColor('white');
  //       }
  //     }, 500)
  //   }
  // }, [speaking, botBlink, blinkBorde2]);

  return (
    <svg width="64" style={style} height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="24" fill="#07B208"/>
      <g clipPath="url(#clip0_4520_2229)">
      <path d="M20.6667 34.3588H30.97V20H20.6667V34.3588ZM22.4113 21.7368H29.2254V32.622H22.4113V21.7368ZM33.0301 20V26.154H43.3334V20H33.0301ZM41.5888 24.4172H34.7747V21.7368H41.5888V24.4172ZM20.6667 42.565H30.97V36.411H20.6667V42.565ZM22.4113 38.1478H29.2254V40.8282H22.4113V38.1478ZM33.0301 42.565H39.2117L43.3334 46.6681V28.2048H33.0301V42.5635V42.565ZM34.7747 29.943H41.5888V42.4753L40.446 41.3377L39.9358 40.8297H34.7761V29.9445L34.7747 29.943Z" fill="white"/>
      </g>
      <circle cx="32" cy="32" r="27.5" stroke="#07B208"/>
      <circle cx="32" cy="32" r="31.5" stroke="#07B208"/>
      <defs>
      <clipPath id="clip0_4520_2229">
      <rect width="22.6667" height="26.6667" fill="white" transform="translate(20.6667 20)"/>
      </clipPath>
      </defs>
    </svg>
  );
};



export default ChatIcon;

