import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import DatePicker from 'components/base/DatePicker';
import ReactSelect from 'components/base/ReactSelect';

interface TargetProps {
    num: number;
    step: number;
    next: () => void;
    previous: () => void;
}

const SetTargets = (props: TargetProps) => {
    const { num, step, next, previous } = props;

    let otherClass = 'pending';
    if (step === num) otherClass = 'current';
    if (step > num) otherClass = 'completed';

    return (
        <div className={otherClass} data-kt-stepper-element="content">
            <div className="w-100">
                <div className="pb-6">
                    <h1 className="fw-bold text-dark fs-5">Set First Target</h1>
                    <div className="text-muted fw-semibold fs-7">If you need more info, please check
                    <a href="#" className="link-primary ms-1">Project Guidelines</a></div>
                </div>
                <div className="fv-row mb-4">
                    <label className="fs-8 fw-semibold mb-2">KPI (Target) Title</label>
                    <input className="form-control form-control-solid" placeholder="Enter Target Title" name="Project Launch" />
                </div>
                <div className="row mb-4">
                    <div className="col-md-6 fv-row">
                        <label className="required fs-8 fw-semibold mb-2">Who owns the Target?</label>
                        <select className="form-select form-select-solid" data-control="select2" data-hide-search="true" data-placeholder="Select a Team Member" name="target_assign">
                            <option></option>
                            <option value="1">User 1</option>
                            <option value="2">User 2</option>
                            <option value="3">User 2</option>
                            <option value="4">User 3</option>
                            <option value="5">User 4</option>
                        </select>
                    </div>
                    <div className="col-md-6 fv-row">
                        <label className="required fs-8 fw-semibold mb-2">Due Date</label>
                        <DatePicker
                            options={{
                                defaultDate: 'May 1, 2023'
                            }}
                        />
                    </div>
                </div>
                <div className="fv-row mb-4">
                    <label className="fs-8 fw-semibold mb-2">Target Details</label>
                    <textarea className="form-control form-control-solid" rows={2} name="target_details" placeholder="Enter Target Details"></textarea>
                </div>
                <div className="fv-row mb-4">
                    <label className="required fs-8 fw-semibold mb-2">Metrics </label>
                    <ReactSelect
                        isMulti
                        placeholder="Add tags"
                        options={[
                            { value: 'Biology', label: 'Biology' },
                            { value: 'Brainlessness', label: 'Brainlessness' },
                            { value: 'Jerry', label: 'Jerry' },
                            { value: 'Neurology', label: 'Neurology' },
                            { value: 'Not_the_mouse', label: 'Not_the_mouse' },
                            { value: 'Rick', label: 'Rick' },
                            { value: 'Stupidity', label: 'Stupidity' }
                        ]}
                    />
                </div>
                <div className="d-flex flex-stack">
                    <Button variant="outline-primary" size="lg" onClick={previous}>Previous</Button>
                    <Button variant="primary" size="lg" onClick={next}>
                        Skip
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default SetTargets;