import { APICore } from "./apiCore";

const api = new APICore();

function createKnowledgebase(params: FormData) {
    const baseUrl = "/v1/knowledge-base/";
    return api.create(`${baseUrl}`, params);
}

function getAvailableNumber(params: {}) {
    const baseUrl = "/v1/comm/phone/available-phone";
    return api.get(`${baseUrl}`, params);
}

function getKnowledgeBase(params: { knowledge_base_id?: string }) {
    const baseUrl = "/v1/knowledge-base/";
    return api.get(`${baseUrl}`, params);
}

function deleteKnowledgeBase(params: { knowledge_base_id: string }) {
    const baseUrl = "/v1/knowledge-base/del";
    return api.delete(`${baseUrl}`, params);
}

function createKnowledgeBaseQuestion(params: any) {
    const baseUrl = "/v1/knowledge-base/question";
    return api.create(`${baseUrl}`, params);
}

function getQuestion(params: any) {
    const baseUrl = "/v1/knowledge-base/question";
    return api.get(`${baseUrl}`, params);
}

function updateBotSetting(params: FormData) {
    const baseUrl = "/v1/knowledge-base/setting";
    return api.create(`${baseUrl}`, params);
}

function deleteQuestion(params: any) {
    const baseUrl = "v1/knowledge-base/question";
    return api.delete(`${baseUrl}`, params);
}

export {
    createKnowledgebase,
    getAvailableNumber,
    getKnowledgeBase,
    deleteKnowledgeBase,
    createKnowledgeBaseQuestion,
    getQuestion,
    deleteQuestion,
    updateBotSetting,
};
