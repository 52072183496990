import * as React from 'react';
import CraftButton from './CraftButton';
import { useFileManagerContext } from 'providers/FileManagerProvider';

const FileManagerListHeader = () => {

  const { setEditFolder, folders, tempFolders, setModalState, setFolders } = useFileManagerContext();
  const [selectItems, setItemsCount] = React.useState(0);
  const [searchKey, setSearchKey] = React.useState('');

  React.useEffect(() => {
    let count: number = 0;
    folders.map((folder) => {
      if(folder.selected) {
        count += 1;
      }
    });
    setItemsCount(count);
  }, [folders])

  React.useEffect(() => {
    if(searchKey) {
      let filtered = tempFolders.filter((folder) => folder.name.toLowerCase().includes(searchKey.toLowerCase()))
      setFolders(filtered);
    } else {
      setFolders(tempFolders);
    }
  }, [searchKey]);

  return (
    <div className="card-header pt-4">
      <div className="card-title">
        <div className="d-flex align-items-center position-relative my-1">
          <i className="ki-duotone ki-magnifier fs-7 position-absolute ms-3">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
          <input 
            type="text" 
            className="form-control form-control-solid w-250px ps-7" 
            placeholder="Search Files & Folders"
            value={searchKey}
            onChange={e => setSearchKey(e.target.value)}
          />
        </div>
      </div>
      <div className="card-toolbar">
      {selectItems === 0 ?
        <div className="d-flex justify-content-end" data-kt-filemanager-table-toolbar="base">
          <CraftButton
            className='d-flex justify-content-center align-items-center btn btn-flex btn-outline-primary me-3'
            icon='ki-duotone ki-add-folder fs-6 me-1'
            label='New Foloder'
            handleClick={() => setEditFolder(true)}
          />
          <CraftButton
            className="d-flex justify-content-center align-items-center btn btn-flex btn-primary"
            icon='ki-duotone ki-folder-up fs-6 me-1'
            label="Upload Files"
            handleClick={() => setModalState('upload')}
          />
        </div>
        : (
          <div className="d-flex justify-content-end align-items-center" data-kt-filemanager-table-toolbar="selected">
            <div className="fw-bold me-5">
            <span className="me-2" data-kt-filemanager-table-select="selected_count">{selectItems}</span>Selected</div>
            <button type="button" className="btn btn-danger" data-kt-filemanager-table-select="delete_selected">Delete Selected</button>
          </div>  
        )}
      </div>
    </div>
  )
}

export default FileManagerListHeader;