import { useFileManagerContext } from 'providers/FileManagerProvider';
import * as React from 'react';

const SuccessModal = () => {

  const { removalFileName, cancelRemove } = useFileManagerContext();

  return (
    <div aria-labelledby="swal2-title" aria-describedby="swal2-html-container" className="swal2-popup swal2-modal swal2-icon-success swal2-show" tabIndex={-1} role="dialog" aria-live="assertive" aria-modal="true" style={{display: 'grid'}}>
      <div className="swal2-icon swal2-success swal2-icon-show" style={{display: 'flex'}}>
        <div className="swal2-success-circular-line-left" style={{backgroundColor: 'rgb(255, 255, 255)'}}></div>
        <span className="swal2-success-line-tip"></span>
        <span className="swal2-success-line-long"></span>
        <div className="swal2-success-ring"></div> 
        <div className="swal2-success-fix" style={{backgroundColor: 'rgb(255, 255, 255)'}}></div>
        <div className="swal2-success-circular-line-right" style={{backgroundColor: 'rgb(255, 255, 255)'}}></div>
      </div>
      <div className="swal2-html-container text-center" id="swal2-html-container" style={{display: 'block'}}>
        You have deleted {removalFileName}!.
      </div>
      <div className="swal2-actions" style={{display: 'flex'}}>
        <div className="swal2-loader"></div>
        <button
          onClick={() => cancelRemove()}
          type="button" 
          className="swal2-confirm btn fw-bold btn-primary" aria-label="" style={{display: 'inline-block'}}>
            Ok, got it!
        </button>
      </div>
    </div>
  )
}

export default SuccessModal;