import { faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Container, ListGroup, Row } from "react-bootstrap";

type ProjectTypeProps = {
  handleNext: () => void;
};

const ProjectType = ({ handleNext }: ProjectTypeProps) => {
  return (
    <div className="d-flex justify-content-center">
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <div className="w-100 rounded-3">
              <h3>Project Type</h3>
              <p className="fs-8 text-muted">
                If you need more info, please check out{" "}
                <strong className="text-primary">
                  <a href="#">FAQ Page</a>
                </strong>
              </p>
            </div>
            <div className="w-100 rounded-3">
              <Button variant="soft-info" className="border-2 w-100 mb-3">
                <Row>
                  <Col className="col-2 h-25">
                    <FontAwesomeIcon icon={"user"} className="h-25" />
                  </Col>
                  <Col className="col-10 float-left">
                    <Row>
                      <h3 className="text-start">Personal Project</h3>
                    </Row>
                    <Row>
                      <p className="text-start fs-8 text-muted mb-0">
                        If you need more info, please check it out
                      </p>
                    </Row>
                  </Col>
                </Row>
              </Button>
              <Button variant="soft-info" className="border-2 w-100 mb-3">
                <Row>
                  <Col className="col-2 h-25">
                    <FontAwesomeIcon icon={faRocket} className="h-25" />
                  </Col>
                  <Col className="col-10 h-25 float-left">
                    <Row>
                      <h3 className="text-start">Team Project</h3>
                    </Row>
                    <Row>
                      <p className="text-start fs-8 text-muted mb-0">
                        Create corporate account to manage users
                      </p>
                    </Row>
                  </Col>
                </Row>
              </Button>
            </div>
            <div className="d-flex justify-content-end mt-3">
              <Button className="btn-primary fs-8" onClick={() => handleNext()}>
                Project Settings
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProjectType;
