import { faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "components/base/Avatar";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  ListGroup,
  Row,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";

type BuildATeamProps = {
  handleBack: () => void;
  handleNext: () => void;
};

type TeamMember = {
  name: String;
  mail: String;
};

const BuildATeam = ({ handleBack, handleNext }: BuildATeamProps) => {
  const members = [
    {
      name: "Emma Smith",
      mail: "smith@kpmg.com",
    },
    {
      name: "Emma Smith",
      mail: "smith@kpmg.com",
    },
    {
      name: "Emma Smith",
      mail: "smith@kpmg.com",
    },
    {
      name: "Emma Smith",
      mail: "smith@kpmg.com",
    },
    {
      name: "Emma Smith",
      mail: "smith@kpmg.com",
    },
    {
      name: "Emma Smith",
      mail: "smith@kpmg.com",
    },
    {
      name: "Emma Smith",
      mail: "smith@kpmg.com",
    },
  ];
  return (
    <div className="d-flex justify-content-center">
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <div className="w-100 rounded-3">
              <h3>Build A Team</h3>
              <p className="fs-8 text-muted">
                If you need more info, please check out{" "}
                <strong className="text-primary">
                  <a href="#">FAQ Page</a>
                </strong>
              </p>
            </div>
            <div className="w-100 rounded-3 mb-3">
              <h5>Invite Teammates</h5>
              <Form.Control size="lg" type="text" className="fs-8" placeholder="Large text" />
            </div>
            <div className="w-100 rounded-3 mb-3 ">
              <h5>Team Members</h5>
              <div className="w-100 mt-2 mb-1 p-1">
                <ListGroup style={{ maxHeight: "300px", overflowY: "auto" }}>
                  {members.map((member) => {
                    return (
                      <ListGroup.Item>
                        <div className="w-100 d-flex mb-2 justify-content-between">
                          <Container>
                            <Row>
                              <Col sm={9}>
                                <Link
                                  to="/apps/e-commerce/admin/customer-details"
                                  className="d-flex align-items-center fs-9"
                                >
                                  <div className="d-flex">
                                    <Avatar src={""} size="m" />
                                    <div>
                                      <p className="mb-0 ms-3 fw-bold text-black-50">
                                        {member.name}
                                      </p>
                                      <p className="mb-0 ms-3 fw-bold text-black-50">
                                        {member.mail}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </Col>
                              <Col sm={3}>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="bg-light text-dark shadow-none fs-9 w-100 mt-1"
                                  >
                                    Owner
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="p-0">
                                    <Dropdown.Item href="#">
                                      <Link
                                        to="/apps/e-commerce/admin/customer-details"
                                        className="btn btn-sm mx-3 fs-9"
                                      >
                                        Guest
                                      </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#">
                                      <Link
                                        to="#"
                                        className="btn btn-sm mx-3 fs-9"
                                      >
                                        Owner
                                      </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#">
                                      <Link
                                        to="#"
                                        className="btn btn-sm mx-3 fs-9"
                                      >
                                        Can Edit
                                      </Link>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </div>
            </div>
            <div className="w-100 rounded-3">
              <div className="d-flex align-items-center rounded p-0 mb-2">
                <div className="flex-grow-1">
                  <h5 className="fw-semibold my">
                    Adding Users by Team Members
                  </h5>
                  <p className="text-muted fs-9 mb-0">
                    If you need more info, please check budget planning
                  </p>
                </div>
                <div className="flex-shrink-0 my-0">
                  <Form.Check
                    type="checkbox"
                    id="showPhone"
                    className="form-switch"
                  >
                    <Form.Check.Input
                      type="checkbox"
                      name="showPhone"
                      value="option2"
                      defaultChecked
                    />
                  </Form.Check>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-5">
              <Container>
                <Row>
                  <Col className="mb-1" sm={6}>
                    <Button
                      className="bg-secondary fs-8 w-100"
                      onClick={() => handleBack()}
                    >
                      Project Settings
                    </Button>
                  </Col>
                  <Col className="mb-1" sm={6}>
                    <Button
                      className="btn-primary fs-8 w-100"
                      onClick={() => handleNext()}
                    >
                      Build Team
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BuildATeam;
