import React from 'react';
import ReactQuill from 'react-quill';
import 'quill-mention';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import 'quill-mention/dist/quill.mention.min.css';
import 'quill-emoji';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { ChangeEvent, useState, useRef } from 'react';
import { useChatContext } from 'providers/ChatProvider';
import { Card, Form } from 'react-bootstrap';
import EmojiPicker from 'components/base/EmojiPicker';
import AttachmentPreview from 'components/common/AttachmentPreview';
import { convertFileToAttachment } from 'helpers/utils';
import { uploadAudio } from 'hyper_helpers/api/chat';
import MicRecorder from 'mic-recorder-to-mp3';
import CloseIcon from 'components/icons/CloseIcon';
import MicIcon from 'components/icons/MicIcon';
// import ImageAttachmentPreview from 'components/common/ImageAttachmentPreview';
//@ts-ignore
import { faPaperPlane, faPaperclip, faVideo } from '@fortawesome/free-solid-svg-icons';

const Mp3Recorder = new MicRecorder({bitRate: 128});

const atValues = [
  { id: 1, value: 'nick' },
  { id: 2, value: 'rick' },
  { id: 3, value: 'lick' },
  { id: 4, value: 'tric' },
  { id: 5, value: 'mick' },
];
const hashValues = [
  { id: 3, value: 'Fredrik Sundqvist 2' },
  { id: 4, value: 'Patrik Sjölin 2' },
];

const toolbarOptions = {
  container: [
    ['bold', 'italic', 'strike'],
    ['link'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['blockquote'],
    ['code', 'code-block'],
    ['clean'],
    [{ 'emoji': { placement: 'top' } }],
  ],

  handlers: {
    emoji: (e: any) => {
      console.log('e', e);
    },
  },
};

const mentionModuleConfig = {
  allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
  mentionDenotationChars: ['@', '#'],
  source: function (searchTerm: any, renderList: any, mentionChar: any) {
    let values;

    if (mentionChar === '@') {
      values = atValues;
    } else {
      values = hashValues;
    }

    if (searchTerm.length === 0) {
      renderList(values, searchTerm);
    } else {
      const matches = [];
      for (let i = 0; i < values.length; i++)
        if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
          matches.push(values[i]);
      renderList(matches, searchTerm);
    }
  },
};

const CustomChatFooter = ({handleMsg}: {handleMsg: (msg: string) => void;}) => {
  const { currentConversation } = useChatContext();
  const quillRef = useRef<ReactQuill>(null);

  const [messageText, setMessageText] = useState('<p><br></p>');
  const [fileAttachments, setFileAttachments] = useState<File[]>([]);
  // const [imageAttachments, setImageAttachments] = useState<File[]>([]);

  const [recording, setRecording] = React.useState<boolean>(false);
  const [recordTime, setRecordTime] = React.useState<number>(0);
  const intervalIDRef = React.useRef<any>(null);
  const [audioStream, setAudioStram] = React.useState<MediaStream>();
  const { setMicPermission, setSpeaking, setSpeechAgent, setFeedBackModalState, setFeedBackModalStep, micPermissionGranted } = useChatContext();

  const sentMessage = () => {
    if (quillRef.current) {
      if (currentConversation?.user.bot)
        handleMsg(quillRef.current.getEditor().getText());
      else
        handleMsg(quillRef.current.getEditor().root.innerHTML);
      setMessageText('<p><br></p>');
    }
  }

  const modules = React.useMemo(
    () => ({
        mention: mentionModuleConfig,
        toolbar: toolbarOptions,
        // keyboard: {
        //   bindings: {
        //     tab: false,
        //     enter: {
        //       key: 13,
        //       handler: sentMessage
        //     }
        //   }
        // },
    }),
    []
  );

  const handleChange = (content: any, delta: any, source: any, editor: any) => {
    setMessageText(content);
  };

  const addAutoMark = () => {
    if (quillRef.current) {
        quillRef.current.focus();
    }
    
    const quill = quillRef.current;
    if (quill) {
        const selection = quill.getEditor().getSelection();
        if (selection) {
            quill.getEditor().insertText(selection.index, '@');
            quill.getEditor().setSelection(selection.index, 1);
            quill.getEditor().setSelection(selection.index + 1, 1);
            quill.focus();
        }
    }
  }

  const addEmoji = (selected: any) => {
    if (quillRef.current) {
        quillRef.current.focus();
    }
    
    const quill = quillRef.current;
    if (quill) {
        const selection = quill.getEditor().getSelection();
        if (selection) {
            quill.getEditor().insertText(selection.index, selected.emoji);
            // quill.getEditor().setSelection(selection.index, 4);
            // quill.getEditor().setSelection(selection.index + 4, 1);
            // quill.focus();
        }
    }
  }

  const editorKey = React.useMemo(
    () => {
        return new Date().getTime();
    },
    []
  );

  const checkMicPermission = React.useCallback(() => {
    navigator.mediaDevices.getUserMedia({video: false, audio: true})
    .then((stream) => {
      if(stream) {
        stream.getTracks().forEach(track => {
          track.stop();
        })
        setMicPermission(true);
        localStorage.setItem('MicPermission', 'Granted');
      }
    })
    .catch((err) => {
      console.error(`you got an error: ${err}`);
    })
  }, []);

  const convertSTM = (secs: number) => {
    let minutes = Math.floor(secs / 60);
    let seconds = secs % 60;
    let m, s;
    if(minutes < 10) {
      m = `0${minutes}`;
    } else {
      m = `${minutes}`
    }
    if(seconds < 10) {
      s = `0${seconds}`;
    } else {
      s = `${seconds}`
    }
    return `${m}:${s}`
  }

  const startRecordInterval = React.useCallback(() => {
    intervalIDRef.current = setInterval(() => {
      setRecordTime(prev => prev + 1);
    }, 1000);
  }, []);

  const startRecording = React.useCallback(() => {
    navigator.mediaDevices.getUserMedia({video: false, audio: true})
    .then((stream) => {
      if(stream) {
        setSpeechAgent('user');
        setAudioStram(stream);
        setRecording(true);
        startRecordInterval();
        setSpeaking(true);
        Mp3Recorder.start();
      }
    })
    .catch((err) => {
      console.error(`you got an error: ${err}`);
    })
  }, []);

  const stopRecording = React.useCallback(() => {
    audioStream?.getAudioTracks().forEach(track => {
      track.stop();
    });
    setRecording(false);
    setRecordTime(0);
    setSpeaking(false);
    setSpeechAgent('');
    clearInterval(intervalIDRef.current);
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]: any) => {
        const audioFile = new File([blob], 'speech.mp3', {
          type: 'audio/mp3'
        });
        uploadAudio({audio: audioFile})
          .then(resp => {
            if(resp && resp.data.msg) {
              handleMsg(resp.data.msg);
            }
          })
          .catch(err => {
            // setFeedBackModalState(true)
            // setFeedBackModalStep('miscommunication')
          })
      })
  }, [intervalIDRef, audioStream]);

  const handleSpeech = React.useCallback(() => {
    if(!micPermissionGranted) {
      checkMicPermission();
    } else {
      if(recording) {
        stopRecording();
      } else {
        startRecording();
      }
    }
    
  }, [micPermissionGranted, recording]);

  React.useEffect(() => {
    localStorage.getItem('MicPermission') === 'Granted' && checkMicPermission();
  }, []);

  return (
    <Card.Footer className={`card-footer ps-3 pe-4 py-3 ${recording ? 'widget-footer-active' : ''}`}>
      {!recording ?
        <>
          <ReactQuill
            key={`editorKey_${editorKey}`}
            className="form-control outline-none border-0 scrollbar resize-none mb-1 p-0 fs-8 fw-normal overflow-visible"
            theme="snow"
            value={messageText}
            onChange={handleChange}
            modules={modules}
            ref={quillRef}
          />
          {fileAttachments && (
            <div className="mb-1 d-flex flex-wrap gap-2 px-2">
              {fileAttachments.map((attachment, index) => (
                <AttachmentPreview
                  key={`attach_${index}`}
                  attachment={convertFileToAttachment(attachment)}
                  size="xl"
                  handleRemove={() => {
                    setFileAttachments(
                      fileAttachments.filter((_, i) => index !== i)
                    );
                  }}
                />
              ))}
            </div>
          )}

          <div className="d-flex gap-3 align-items-center px-3 border-black">
            <div>
              <Button className="p-0">
                <label
                  className="text-600 fs-9 cursor-pointer"
                  htmlFor="attachments"
                >
                  <FontAwesomeIcon icon={faPaperclip} size='lg' />
                </label>
              </Button>
              <Form.Control
                className="d-none"
                type="file"
                id="attachments"
                multiple
                onChange={({ target: { files } }: ChangeEvent<HTMLInputElement>) =>
                  files && setFileAttachments(Array.from(files))
                }
              />
            </div>
            <EmojiPicker
              onSelect={addEmoji}
            >
              <Button variant="link" className="p-0 text-600 fs-9 btn-emoji">
                <FontAwesomeIcon icon={['far', 'face-smile']} size='lg' />
              </Button>
            </EmojiPicker>
            <Button className="p-0 text-600 fs-8" onClick={addAutoMark}>
              @
            </Button>
            <Button className="p-0 text-600 fs-9">
              <FontAwesomeIcon icon={faVideo} size='lg' />
            </Button>
            <Button className="p-0 text-600 fs-9" onClick={handleSpeech}>
              <FontAwesomeIcon icon="microphone" size='lg' />
            </Button>

            <Button
              disabled={messageText === "<p><br></p>" ? true: false}
              variant="primary"
              className="ms-auto px-2 py-1"
              type="submit"
              size='sm'
              onClick={sentMessage}
            >
              <FontAwesomeIcon icon={faPaperPlane} size='lg' />
            </Button>
          </div>
        </>
        :
        <div className='d-flex justify-content-between align-items-center'>
          <Button className="p-0" onClick={() => stopRecording()}>
            <CloseIcon/>
          </Button>
          <div className='d-flex'>
            <div className='d-flex align-items-center'>
              <div className='record-indiciator me-2'></div>
              <div className="record-counter text-white">{convertSTM(recordTime)}</div>
            </div>
            <button className="btn p-0 border-0 mic-btn">
              <Button className="p-0" onClick={() => handleSpeech()}>
                <label
                  className="text-900 fs-9 cursor-pointer"
                  htmlFor="microphone"
                >
                  <MicIcon color={micPermissionGranted ? recording ? '#F26D7E' : 'green' : undefined}/>
                </label>
              </Button>
            </button>
          </div>
        </div>
      }
    </Card.Footer>
  );
};

export default CustomChatFooter;