import React from 'react';

const FlowerBaloon = ({ className, color = 'white' }: { className?: string, color?: string }) => {
  return (
    <svg width="176" className={className} height="256" viewBox="0 0 176 256" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_3767_1748)">
      <path d="M72.8806 176.273L76.9371 103.934" stroke="#A6C7D3" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M72.8806 176.274L121.838 111.35" stroke="#A6C7D3" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M72.8806 176.274L124.915 62.9365" stroke="#A6C7D3" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M156.795 51.8529C162.13 37.1834 157.19 21.9202 145.761 17.7615C134.333 13.6028 120.744 22.1236 115.409 36.793C110.074 51.4625 115.014 66.7258 126.443 70.8844C137.871 75.0431 151.46 66.5224 156.795 51.8529Z" fill="#07B208"/>
      <path d="M102.12 83.6351C103.206 68.063 94.2517 54.7531 82.1201 53.9067C69.9885 53.0602 59.2736 64.9977 58.1878 80.5698C57.1019 96.1419 66.0563 109.452 78.1879 110.298C90.3196 111.145 101.034 99.2073 102.12 83.6351Z" fill="#74BBD4"/>
      <path d="M159.871 96.1873C167.443 80.6225 162.939 62.8238 149.81 56.4327C136.682 50.0416 119.9 57.4783 112.328 73.0431C104.756 88.6078 109.26 106.407 122.389 112.798C135.517 119.189 152.299 111.752 159.871 96.1873Z" fill="#FFBD00"/>
      <path d="M117.012 38.3765C119.251 23.6531 111.185 10.2137 98.9958 8.35875C86.8065 6.50383 75.1099 16.9358 72.8708 31.6592C70.6317 46.3827 78.6978 59.8221 90.8871 61.677C103.076 63.5319 114.773 53.0999 117.012 38.3765Z" fill="#FFBD00"/>
      <path d="M123.819 77.195C128.011 63.3757 123.098 49.6503 112.846 46.5385C102.595 43.4267 90.8856 52.1068 86.6935 65.9262C82.5015 79.7455 87.4139 93.4709 97.6657 96.5827C107.918 99.6945 119.627 91.0143 123.819 77.195Z" fill="#3F849D"/>
      <path d="M78.1455 109.453L80.6633 115.368C80.7366 115.542 80.763 115.732 80.7399 115.919C80.7168 116.106 80.6451 116.284 80.5318 116.435C80.4185 116.585 80.2677 116.704 80.0944 116.778C79.9211 116.852 79.7314 116.879 79.5443 116.857L73.1686 116.071C72.981 116.048 72.8028 115.976 72.6518 115.863C72.5008 115.749 72.3825 115.598 72.3087 115.424C72.235 115.25 72.2084 115.059 72.2316 114.872C72.2549 114.684 72.3272 114.506 72.4412 114.355L76.3103 109.226C76.4243 109.078 76.5748 108.962 76.747 108.891C76.9193 108.819 77.1073 108.793 77.2925 108.816C77.4777 108.839 77.6538 108.909 77.8034 109.021C77.9529 109.133 78.0708 109.281 78.1455 109.453Z" fill="#74BBD4"/>
      <path d="M72.8803 176.274L63.6483 72.4512" stroke="#A6C7D3" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M72.8806 176.273L96.9998 97.5" stroke="#A6C7D3" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M98.8113 93.8316L100.277 100.092C100.32 100.275 100.313 100.467 100.258 100.648C100.203 100.828 100.102 100.991 99.964 101.12C99.8262 101.248 99.657 101.338 99.4733 101.381C99.2896 101.424 99.098 101.417 98.9176 101.362L92.763 99.4956C92.5824 99.4409 92.4194 99.3396 92.2903 99.2019C92.1613 99.0641 92.0709 98.8948 92.028 98.711C91.9852 98.5272 91.9915 98.3353 92.0464 98.1547C92.1013 97.9741 92.2027 97.8112 92.3405 97.6822L97.0293 93.2915C97.1668 93.1621 97.3361 93.0714 97.5199 93.0283C97.7037 92.9852 97.8957 92.9914 98.0764 93.0462C98.2571 93.1009 98.4201 93.2024 98.5492 93.3402C98.6782 93.4781 98.7686 93.6476 98.8113 93.8316Z" fill="#3F849D"/>
      <path d="M123.491 110.261L123.043 116.67C123.031 116.859 122.969 117.041 122.863 117.199C122.757 117.356 122.612 117.483 122.441 117.566C122.271 117.649 122.081 117.685 121.892 117.671C121.703 117.658 121.521 117.594 121.365 117.487L116.049 113.893C115.893 113.788 115.767 113.643 115.684 113.473C115.601 113.303 115.565 113.115 115.578 112.926C115.591 112.738 115.654 112.556 115.76 112.4C115.866 112.243 116.011 112.118 116.181 112.035L121.952 109.22C122.122 109.138 122.309 109.102 122.497 109.116C122.684 109.13 122.865 109.192 123.021 109.298C123.177 109.403 123.302 109.548 123.385 109.717C123.467 109.886 123.504 110.073 123.491 110.261Z" fill="#FFBD00"/>
      <path d="M63.9221 75.5714C78.4758 74.4001 89.1489 59.4632 87.7611 42.2089C86.3733 24.9546 73.4501 11.9168 58.8964 13.0881C44.3427 14.2594 33.6696 29.1963 35.0574 46.4506C36.4452 63.7049 49.3684 76.7427 63.9221 75.5714Z" fill="#FF4263"/>
      <path d="M64.4197 73.1312L68.0566 78.4203C68.1622 78.5757 68.2253 78.756 68.2397 78.9434C68.2542 79.1307 68.2193 79.3186 68.1387 79.4883C68.0581 79.658 67.9346 79.8037 67.7803 79.9109C67.626 80.0181 67.4464 80.0831 67.2593 80.0994L60.8668 80.6283C60.6789 80.6428 60.4904 80.6078 60.3202 80.5266C60.15 80.4455 60.0041 80.3211 59.897 80.1658C59.79 80.0106 59.7255 79.83 59.7101 79.642C59.6948 79.454 59.729 79.2653 59.8093 79.0947L62.565 73.2907C62.6448 73.1197 62.7683 72.9727 62.9231 72.8646C63.0778 72.7565 63.2582 72.691 63.4463 72.6748C63.6343 72.6587 63.8233 72.6923 63.9942 72.7725C64.1651 72.8526 64.3119 72.9763 64.4197 73.1312Z" fill="#FF4263"/>
      <path d="M47.0694 227.821L108.333 240L119.239 185.105L57.9754 172.926L47.0694 227.821Z" fill="#DE8E68"/>
      <path d="M60.4477 191.959L121.709 204.138L119.25 185.103L57.9858 172.924L60.4477 191.959Z" fill="#D37C59"/>
      <path d="M12 220.845L47.0754 227.817L57.9814 172.922L22.906 165.949L12 220.845Z" fill="#D37C59"/>
      <path d="M48.6086 189.602L13.5999 182.642L22.9801 165.961L57.986 172.923L48.6086 189.602Z" fill="#DE8E68"/>
      <path d="M70.5922 232.497L84.8112 235.323L88.0284 219.129L73.8095 216.302L70.5922 232.497Z" fill="#D37C59"/>
      <path d="M55.2381 206.576L76.6516 210.833L79.0002 199.011L57.5867 194.754L55.2381 206.576Z" fill="#FED385"/>
      <path d="M70.216 207.758L75.0562 208.72L76.7625 200.131L71.9222 199.169L70.216 207.758Z" fill="#F26D7E"/>
      <path d="M58.4663 198.243L70.5614 200.647L70.8864 199.012L58.7913 196.607L58.4663 198.243Z" fill="#F26D7E"/>
      <path d="M57.9072 201.042L70.0023 203.446L70.3273 201.81L58.2322 199.406L57.9072 201.042Z" fill="#F26D7E"/>
      </g>
      <defs>
      <filter id="filter0_d_3767_1748" x="0" y="0.145508" width="175.546" height="255.854" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="6"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3767_1748"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3767_1748" result="shape"/>
      </filter>
      </defs>
    </svg>
  );
};

export default FlowerBaloon;
