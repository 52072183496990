import * as React from 'react';
import NoItemBg from 'assets/craft/illustrations/sketchy-1/5.png';
const NoItemsView = () => {
  return (
    <tr className="odd">
      <td valign="top" colSpan={5} className="dataTables_empty">
        <div className="d-flex flex-column flex-center">
            <img src={NoItemBg} className="mw-400px" />
            <div className="fs-6 fw-bolder text-dark">No items found.</div>
            <div className="fs-8">Start creating new folders or uploading a new file!</div>
        </div>
      </td>
    </tr>
  )
}

export default NoItemsView;