import React from "react";
import { TeamMember } from "../types";

const UserCard = ({users} : {users: TeamMember[]}) => {
    return (
        <div className="tab-pane fade show active">
            <div className="row g-3 g-xl-5">
                {
                    users.map((user, idx) => <UserItem key={idx} member={user} />)
                }
            </div>
        </div>
    );
}

const UserItem = ({member}: {member: TeamMember}) => {
    return (
        <div className="col-md-6 col-xxl-4">
            <div className="card mb-0">
                <div className="card-body d-flex flex-center flex-column pt-3 p-2">
                    <div className="symbol symbol-65px symbol-circle mb-3">
                        {member.avatar ?
                            <img alt="Pic" src={member.avatar} />
                            :
                            <span className="d-flex justify-content-center align-items-center symbol-label fs-5 fw-semibold text-primary bg-light-primary">{member.name[0]}</span>
                        }
                        {member.online && <div className="bg-success position-absolute border border-4 border-white h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n2 mt-n2"></div>}
                    </div>
                    <a href="#" className="fs-7 text-800 text-hover-primary fw-bold mb-0">{member.name}</a>
                    <div className="fw-semibold text-400 mb-3 fs-8">{member.position}</div>
                    <div className="d-flex flex-center flex-wrap">
                        <div className="border border-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                            <div className="fs-8 fw-bold text-700">${member.earnings}</div>
                            <div className="fw-semibold text-400">Earnings</div>
                        </div>
                        <div className="border border-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                            <div className="fs-8 fw-bold text-700">{member.tasks}</div>
                            <div className="fw-semibold text-400">Tasks</div>
                        </div>
                        <div className="border border-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                            <div className="fs-8 fw-bold text-700">${member.sales}</div>
                            <div className="fw-semibold text-400">Sales</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserCard;