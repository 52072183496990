import React from "react";
import { Row, Col } from "react-bootstrap";
import Header from "./Header";
import OrganisationInfo from "./OrganisationInfo";
import OrganisationStructure from "./OrganisationStructure";
import SuperAdminInfo from "./SuperAdminInfo";
import Email from "./Email";
import ChangePassword from "./ChangePassword";
import Preferences from "./Preferences";
import TwoStepVerification from "./TwoStepVerification";
import ProductConfig from "./ProductConfig";
import RecentDevices from "./RecentDevices";
import OrganisationNotification from "./OrganisationNotification";
import OrganisationNewsletter from "./OrganisationNewsletter";
import ConnectedAccounts from "./ConnectedAccounts";
import SocialLogin from "./SocialLogin";
import DeactivateAccount from "./DeactiveAccount";
import { Collapse } from "react-bootstrap";
import { useBreakpoints } from 'providers/BreakpointsProvider';
import { navItems } from "./data";
import classNames from "classnames";
import AuditLogs from "./AuditLogs";
import BotSettings from "./BotSettings";

const Settings = () => {
    const { breakpoints } = useBreakpoints();

    const [show, setShow] = React.useState<boolean>(true);
    const [activeHash, setActiveHash] = React.useState<string>('');

    const handleHashChange = () => {
        setActiveHash(window.location.hash);
    }

    React.useEffect(() => {
        if (breakpoints.up('lg')) setShow(true);
        if (breakpoints.down('lg')) setShow(false);
    }, [breakpoints]);

    React.useEffect(() => {
        window.addEventListener("hashchange", handleHashChange);
        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        }
    }, []);

    return (
        <Row>
            <Col lg={3}>
                <div className="d-grid border border-1 mb-3 d-lg-none">
                    <button type="button" className="navbar-toggler btn btn-white bg-white" onClick={() => setShow(val => !val)}>
                        <span className="d-flex justify-content-between align-items-center">
                            <span className="text-dark">Menu</span>

                            {!show ?
                                <span className="navbar-toggler-default fs-7">
                                    <i className="bi-list"></i>
                                </span>
                                :
                                <span className="navbar-toggler-toggled fs-7">
                                    <i className="bi-x"></i>
                                </span>}
                        </span>
                    </button>
                </div>

                <Collapse in={show}>
                    <ul id="navbarSettings" className="position-sticky card card-navbar-nav nav nav-tabs nav-lg nav-vertical py-3" style={{ top: 96 }}>
                        {navItems.map((nav, idx) => (
                            <li key={`nav_${idx}`} className="nav-item">
                                <a className={classNames("nav-link d-flex align-items-center fs-8 fw-normal ps-3", {active: activeHash === nav.link})} href={nav.link}>
                                    <i className={`${nav.icon} nav-link-icon w-20px`}></i> {nav.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </Collapse>
            </Col>
            <Col lg={9}>
                <div className="d-grid gap-3 gap-lg-5">
                    <Header />
                    <OrganisationInfo />
                    <OrganisationStructure />
                    <SuperAdminInfo />
                    <Email />
                    <ChangePassword />
                    <Preferences />
                    <TwoStepVerification />
                    <ProductConfig />
                    <BotSettings />
                    <RecentDevices />
                    <OrganisationNotification />
                    <OrganisationNewsletter />
                    <ConnectedAccounts />
                    <AuditLogs />
                    <SocialLogin />
                    <DeactivateAccount />
                </div>
            </Col>
        </Row>
    )
}

export default Settings;