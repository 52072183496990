import { FileItem } from "data/fileManager";
import FileManagerModalView from "pages/apps/knowledge-base/file_manager/FileManagerModalView";
import { Dispatch, PropsWithChildren, createContext, useContext, useReducer } from "react";
import { ACTIONTYPE, FileManagerReducer } from "reducers/FileManagerReducer";


export interface FileManagerState {
  folders: FileItem[];
  tempFolders: FileItem[];
  editNewFoloder: boolean;
  editIndex: number | null;
  fileType: 'folder' | 'file' | null;
  modalState: 'delete' | 'success' | 'update' | 'upload' | null;
  removalFileName: string | null;
  removalFolderIndex: number | null;
  removalFileIndex: number | null;
}

interface FileManagerContextInterface extends FileManagerState {
  managerDispatch: Dispatch<ACTIONTYPE>,
  setEditFolder: (value: boolean) => void;
  createFolder: (value: string) => void;
  setEditState: (index: number | null, type: 'folder' | 'file' | null) => void;
  updateNameByIndex: (index: number, name: string) => void;
  setModalState: (value: 'delete' | 'success' | 'update' | 'upload' | null, fileIndex?: number | null, folderIndex?: number | null, name?: string) => void;
  removeFile: () => void;
  cancelRemove: () => void;
  setFolders: (folders: FileItem[], temp?: FileItem[]) => void;
  selectAll: (value: boolean) => void;
  selectFile: (index: number) => void;
}

export const FileManagerContext = createContext({} as FileManagerContextInterface);

const FileManagerProvider = ({ 
  children 
} : PropsWithChildren) => {
  const initState: FileManagerState = {
    folders: [],
    tempFolders: [],
    editNewFoloder: false,
    editIndex: null,
    fileType: 'folder',
    modalState: null,
    removalFileName: null,
    removalFileIndex: null,
    removalFolderIndex: null
  }

  const [fileManagerState, managerDispatch] = useReducer(FileManagerReducer, initState);

  const setEditFolder = (value: boolean) => {
    managerDispatch({
      type: 'SET_FILE_MANAGER_STATE',
      payload: {
        editNewFoloder: value
      }
    })
  }

  const setEditState = (index: number | null, type: 'folder' | 'file' | null ) => {
    managerDispatch({
      type: 'SET_FILE_MANAGER_STATE',
      payload: {
        editIndex: index,
        fileType: type
      }
    })
  }

  const updateNameByIndex = (index: number, name: string) => {
    managerDispatch({
      type: 'UPDATE_NAME_BY_INDEX',
      payload: {
        index,
        name
      }
    })
  }

  const createFolder = (value: string) => {
    managerDispatch({
      type: 'CREATE_FOLDER',
      payload: {
        name: value
      }
    })    
  }

  const setModalState = (value: 'delete' | 'success' | 'update' | 'upload' | null, folderIndex?: number | null, fileIndex?: number | null, name?: string) => {
    managerDispatch({
      type: 'SET_FILE_MANAGER_STATE',
      payload: {
        modalState: value,
        removalFileIndex: fileIndex,
        removalFolderIndex: folderIndex,
        removalFileName: name ? name : fileManagerState.removalFileName
      }
    })
  }

  const removeFile = () => {
    managerDispatch({
      type: 'REMOVE_FILE',
      payload: {
        folderIndex: fileManagerState.removalFolderIndex,
        fileIndex: fileManagerState.removalFileIndex
      }
    })
  }

  const cancelRemove = () => {
    managerDispatch({
      type: 'SET_FILE_MANAGER_STATE',
      payload: {
        modalState: null,
        removalFileIndex: null,
        removalFileName: null,
        removalFolderIndex: null
      }
    })
  }

  const setFolders = (folders: FileItem[], temp?: FileItem[]) => {
    managerDispatch({
      type: 'SET_FILE_MANAGER_STATE',
      payload: {
        folders,
        tempFolders: temp? temp : fileManagerState.tempFolders
      }
    })
  }

  const selectAll = (value: boolean) => {
    managerDispatch({
      type: 'SET_FILE_MANAGER_STATE',
      payload: {
        folders: fileManagerState.folders.map((folder) => {
          return {
            ...folder,
            selected: value
          }
        })
      }
    })
  }

  const selectFile = (index: number) => {
    managerDispatch({
      type: 'SET_FILE_MANAGER_STATE',
      payload: {
        folders: fileManagerState.folders.map((folder, i) => {
          if (i === index) {
            return {
              ...folder,
              selected: !folder.selected
            }
          }
          return folder
        })
      }
    })
  }

  return (
    <FileManagerContext.Provider
      value={{
        ...fileManagerState,
        managerDispatch,
        setEditFolder,
        createFolder,
        setEditState,
        updateNameByIndex,
        setModalState,
        removeFile,
        cancelRemove,
        setFolders,
        selectAll,
        selectFile
      }}
    >
      <FileManagerModalView/>
      {children}
    </FileManagerContext.Provider>
  )
};

export const useFileManagerContext = () => useContext(FileManagerContext);

export default FileManagerProvider;