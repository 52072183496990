import { FileItem } from "data/fileManager";
import moment from 'moment';

export const convertFileSize = (sizeInBytes: number) => {
  if (sizeInBytes >= 1000000000) {
    return (sizeInBytes / 1000000000).toFixed(2) + " GB";
  } else if (sizeInBytes >= 1000000) {
    return (sizeInBytes / 1000000).toFixed(2) + " MB";
  } else {
    return (sizeInBytes / 1000).toFixed(2) + " KB";
  }
}

export const convertDateString = (file: FileItem) => {
  const dateString = file?.created ? file.created : file.updated;
  if(dateString) {
    const [timestamp, milliseconds] = dateString.split('.');
    const date = moment.unix(Number(timestamp));
    date.add(Number(milliseconds), 'seconds');
    return date;
  }
  return file.created;
}