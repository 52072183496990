import * as React from 'react';

const FileManagerHeader = () => {

  const query = new URLSearchParams(window.location.search);
  const folderId = query.get('id');
  const [title, setTitle] = React.useState('Folders');
  React.useEffect(() => {
    if(folderId) {
      setTitle('Files');
    } else {
      setTitle('Folders');
    }
  }, [folderId]);

  return (
    <div className="toolbar" id="kt_toolbar">
      <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
        <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
          <h1 className="text-dark fw-bold my-1 fs-6">{title}</h1>
          <ul className="breadcrumb fw-semibold fs-base my-1">
            <li className="breadcrumb-item text-muted">Files</li>
            <li className="breadcrumb-item text-dark">{title}</li>
          </ul>
        </div>
        <div className="d-flex align-items-center flex-nowrap text-nowrap py-1">
          {/* <a href="#" className="btn bg-body btn-color-gray-700 btn-active-primary me-4" data-bs-toggle="modal" data-bs-target="#kt_modal_invite_friends">Invite Friends</a>
          <a href="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project" id="kt_toolbar_primary_button">New Project</a> */}
        </div>
      </div>
    </div>
  )
}

export default FileManagerHeader;