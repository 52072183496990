import React from "react";
import { Card } from "react-bootstrap";
import ChromeIcon from "assets/images/brands/chrome-icon.svg";

const OrgStructure = () => {
    return (
        <Card id="organisationStructure">
            <Card.Header>
                <Card.Title className="fs-7">Organisation Structure</Card.Title>
            </Card.Header>
            <Card.Body>
                <p className="card-text">The Organisation Structure enables you to tailor reporting across your organisation.</p>
                <div className="list-group list-group-lg list-group-flush list-group-no-gutters">
                    <div className="list-group-item">
                        <div className="d-flex">
                            <div className="flex-shrink-0">
                                <img className="avatar avatar-xs" src={ChromeIcon} alt="Image Description" />
                            </div>

                            <div className="flex-grow-1 ms-3">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h4 className="my-0">Directorate</h4>
                                        <p className="fs-8 text-body mb-0">A Directorate is a Group of Services enabling reporting across all Services within a Directorate</p>
                                    </div>

                                    <div className="col-auto">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input w-50px h-30px" type="checkbox" id="connectedAccounts1" defaultChecked={false} />
                                            <label className="form-check-label" htmlFor="connectedAccounts1"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="d-flex">
                            <div className="flex-shrink-0">
                                <img className="avatar avatar-xs" src={ChromeIcon} alt="Image Description" />
                            </div>

                            <div className="flex-grow-1 ms-3">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h4 className="my-0">Services</h4>
                                        <p className="fs-8 text-body mb-0">A Service is a Team of  Teams enabling reporting across all teams within a service.</p>
                                    </div>

                                    <div className="col-auto">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input w-50px h-30px" type="checkbox" id="connectedAccounts2" defaultChecked={false} />
                                            <label className="form-check-label" htmlFor="connectedAccounts2"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="d-flex">
                            <div className="flex-shrink-0">
                                <img className="avatar avatar-xs" src={ChromeIcon} alt="Image Description" />
                            </div>

                            <div className="flex-grow-1 ms-3">
                                <div className="row align-items-center">
                                    <div className="col">
                                    <h4 className="my-0">Teams</h4>
                                    <p className="fs-8 text-body mb-0">A Team is a collection of Product enabling users to group Products Like Knowledge Base, Journeys, Digi-Teams and much more.</p>
                                    </div>

                                    <div className="col-auto">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input w-50px h-30px" type="checkbox" id="connectedAccounts3" defaultChecked={true} disabled />
                                        <label className="form-check-label" htmlFor="connectedAccounts3"></label>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}

export default OrgStructure;