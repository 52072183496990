import React from 'react';
import { useSelector } from 'react-redux';
import { Nav, Navbar } from 'react-bootstrap';
import { routes } from 'sitemap';
import { capitalize } from 'helpers/utils';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import {
  UilArrowFromRight,
  UilLeftArrowToLeft
} from '@iconscout/react-unicons';
import { useAppContext } from 'providers/AppProvider';
import classNames from 'classnames';
import Button from 'components/base/Button';
import NavbarTopNav from '../navbar-horizontal/NavbarTopNav';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import { RootState } from 'redux/store';
import {
  UilChartPie
} from "@iconscout/react-unicons";

const NavbarVerical = () => {
  const {
    config: {
      navbarPosition,
      openNavbarVertical,
      navbarVerticalAppearance,
      isNavbarVerticalCollapsed
    },
    setConfig
  } = useAppContext();

  const { breakpoints } = useBreakpoints();

  const { knowledgeBases } = useSelector((state: RootState) => state.KnowledgeBase);

  const dynamicRoutes = React.useMemo(() => {
    let tmp = routes;
    tmp.splice(
      1,
      1,
      {
        label: "Knowledge Base",
        icon: UilChartPie,
        active: false,
        pages: [
          {
            name: "Knowledge Base(s)",
            icon: "clipboard",
            path: "/dashboard",
            pathName: "project-management-dashbaord",
            active: true
          },
          {
            name: "My To-do List",
            icon: "clipboard",
            path: "/apps/project-management/todo-list",
            pathName: "project-management-todo-list",
            active: true,
          },
          {
            name: "My Knowledge Bases",
            icon: "clipboard",
            path: "/app/knowledge-base/my-knowledge-bases",
            pathName: "my-knowledge-bases",
            active: true,
            pages: knowledgeBases.length !== 0 ? knowledgeBases.map((knowledgeBase: any) => ({
                name: knowledgeBase?.KnowledgeBaseName,
                active: true,
                pages: [
                  {
                    name: "Questions",
                    path: `/app/knowledge-base/${knowledgeBase?.KnowledgeBaseID}/create-question`,
                    pathName: "knowledge-base-create-question",
                    active: true
                  },
                  {
                    name: "Overview",
                    path: `/app/knowledge-base/${knowledgeBase?.KnowledgeBaseID}/overview`,
                    pathName: "knowledge-base-overview",
                    active: true
                  },
                  {
                    name: "Activity",
                    path: "/app/knowledge-base/activity",
                    pathName: "knowledge-base-activity",
                    active: true
                  },
                  {
                    name: "Targets",
                    path: "/app/knowledge-base/targets",
                    pathName: "knowledge-base-targets",
                    active: true
                  },
                  {
                    name: "Budget",
                    path: "/app/knowledge-base/budget",
                    pathName: "knowledge-base-budget",
                    active: true
                  },
                  {
                    name: "Calendar",
                    path: "/app/knowledge-base/calendar",
                    pathName: "knowledge-base-calendar",
                    active: true
                  },
                  {
                    name: "Roadmap",
                    path: "/app/knowledge-base/roadmap",
                    pathName: "knowledge-base-roadmap",
                    active: true
                  },
                  {
                    name: "To-do",
                    path: "/app/knowledge-base/todo",
                    pathName: "knowledge-base-todo",
                    active: true
                  },
                  {
                    name: "Workflow",
                    path: "/app/knowledge-base/workflow",
                    pathName: "knowledge-base-workflow",
                    active: true
                  },
                  {
                    name: "Team",
                    path: `/app/knowledge-base/team`,
                    pathName: "knowledge-base-team",
                    active: true
                  },
                  {
                    name: "Settings",
                    path: `/app/knowledge-base/${knowledgeBase?.KnowledgeBaseID}/settings`,
                    pathName: "knowledge-base-settings",
                    active: true
                  },
                  {
                    name: 'Files',
                    active: true,
                    pages: [
                      {
                        name: "Folders",
                        path: "/app/knowledge-base/files/folders",
                        pathName: "/",
                        active: true
                      },
                      {
                        name: "Files",
                        path: "/app/knowledge-base/files/file",
                        pathName: "/",
                        active: true
                      }
                    ]
                  }
                ]
              })) : undefined
          },
        ]
      },
    );
    return tmp;
  }, [knowledgeBases]);

  return (
    <Navbar
      className={classNames('navbar-vertical', {
        'navbar-darker': navbarVerticalAppearance === 'darker'
      })}
      expand="lg"
      variant=""
    >
      <Navbar.Collapse id="navbarVerticalCollapse" in={openNavbarVertical}>
        <div className="navbar-vertical-content">
          <Nav className="flex-column" as="ul" id="navbarVerticalNav">
            {dynamicRoutes.map(route => {
              return (
                <Nav.Item key={route.label}>
                {!route.labelDisabled && (
                  <>
                    <p className="navbar-vertical-label">
                      {capitalize(route.label)}
                    </p>
                    <hr className="navbar-vertical-line" />
                  </>
                )}
                <NavbarVerticalMenu level={1} routes={route.pages} />
              </Nav.Item>
              )
            })}
          </Nav>

          {navbarPosition === 'combo' && breakpoints.down('lg') && (
            <div className="move-container">
              <div className="navbar-vertical-divider">
                <hr className="navbar-vertical-hr" />
              </div>
              <NavbarTopNav />
            </div>
          )}
        </div>
      </Navbar.Collapse>
      <div className="navbar-vertical-footer">
        <Button
          className="navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center"
          onClick={() => {
            setConfig({
              isNavbarVerticalCollapsed: !isNavbarVerticalCollapsed
            });
          }}
        >
          {isNavbarVerticalCollapsed ? (
            <UilArrowFromRight size={16} className="mb-1" />
          ) : (
            <>
              <UilLeftArrowToLeft size={16} className="mb-1" />
              <span className="ms-2">Collapsed View</span>
            </>
          )}
        </Button>
      </div>
    </Navbar>
  );
};

export default NavbarVerical;
