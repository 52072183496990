import React, { useState, useEffect, useCallback, useRef } from "react";
import Eric from "assets/support-chat/30.webp";
import Message from "components/modules/chat/message";
import { AttachInfo, Message as MessageType, User as UserType } from "data/chat";
import { DirectLine } from 'botframework-directlinejs';
import WidgetFooter from "./WidgetFooter";
import ChatStateIndicator from "./ChatStateIndicator";
import ModalViews from "./ModalViews";
import { useChatContext } from "providers/ChatProvider";
import WidgetHeader from "./WidgetHeader";
import { sendMessage } from "hyper_helpers/api/chat";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SendSvg = () => {
  return (
    <svg
      className="svg-inline--fa fa-paper-plane text-primary fs--1 ms-1"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="paper-plane"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      data-fa-i2svg=""
    >
      <path
        fill="currentColor"
        d="M511.6 36.86l-64 415.1c-1.5 9.734-7.375 18.22-15.97 23.05c-4.844 2.719-10.27 4.097-15.68 4.097c-4.188 0-8.319-.8154-12.29-2.472l-122.6-51.1l-50.86 76.29C226.3 508.5 219.8 512 212.8 512C201.3 512 192 502.7 192 491.2v-96.18c0-7.115 2.372-14.03 6.742-19.64L416 96l-293.7 264.3L19.69 317.5C8.438 312.8 .8125 302.2 .0625 289.1s5.469-23.72 16.06-29.77l448-255.1c10.69-6.109 23.88-5.547 34 1.406S513.5 24.72 511.6 36.86z"
      ></path>
      <path
        fill="currentColor"
        d="M511.6 36.86l-64 415.1c-1.5 9.734-7.375 18.22-15.97 23.05c-4.844 2.719-10.27 4.097-15.68 4.097c-4.188 0-8.319-.8154-12.29-2.472l-122.6-51.1l-50.86 76.29C226.3 508.5 219.8 512 212.8 512C201.3 512 192 502.7 192 491.2v-96.18c0-7.115 2.372-14.03 6.742-19.64L416 96l-293.7 264.3L19.69 317.5C8.438 312.8 .8125 302.2 .0625 289.1s5.469-23.72 16.06-29.77l448-255.1c10.69-6.109 23.88-5.547 34 1.406S513.5 24.72 511.6 36.86z"
      ></path>
    </svg>
  );
};

const ActiveStatus = () => {
  return (
    <svg
      className="svg-inline--fa fa-circle text-success fs--1 ms-2"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="circle"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      data-fa-i2svg=""
    >
      <path
        fill="currentColor"
        d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"
      ></path>
      <path
        fill="currentColor"
        d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"
      ></path>
    </svg>
  );
};
const Below = () => {
  return (
    <svg
      className="svg-inline--fa fa-chevron-down text-primary fs-1"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="chevron-down"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      data-fa-i2svg=""
      style={{ width: "16px" }}
    >
      <path
        fill="currentColor"
        d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"
      ></path>
    </svg>
  );
};

const Erik: UserType = {
  id: 0,
  avatar: Eric,
  status: "online",
  name: "Eric",
};

export default function SupportChat() {
  const navigate = useNavigate();

  const USER_ID: string = 'user1';
  const BOT_ID: string = 'lg-localgov-chatbot-bot';
  const DIRECT_LINE_SECRET: string = 'mODcDfm2wOc.pvlhRBi3BecgzmLau4SZA9nCPlW8dVQjkEa2wRfs_LM';

  const [directLine, setDirectLine] = useState<DirectLine>();
  const [message, setMessage] = useState<MessageType[]>([]);
  const [messageText, setMessageText] = useState("");
  const [supportVisible, setSupportVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { menuOption, feedBackModalState, feedBackStep, setAudioVolume, setFeedBackModalState, setFeedBackModalStep, setAiLoading, setMenuOption, setSpeaking, setSpeechAgent } = useChatContext();
  const [percent, setPercent] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);
  let scriptProcessor: ScriptProcessorNode;
  const isJson = (str: string) => {
    try {
      JSON.parse(str);
    } catch(err) {
      return false;
    }
    return true;
  }
  

  const sentMessage = async (msg: string) => {
    setAiLoading(true);
    setMessageText('');
    setMessage((_message) => {
      return [
        ..._message,
        {
          id: USER_ID,
          type: 'sent',
          time: new Date().toISOString(),
          readAt: new Date(),
          message: msg
        } as MessageType
      ]
    })
    sendMessage({message_content: msg, 
      uploadHandle: (progressEvent: any) => {
        if(progressEvent.total){
          setPercent(Math.round(
            (20 * progressEvent.loaded) / progressEvent.total
          ));
        }
      },
      downloadHandle: (e: any) => {
        if(e.total){
					setPercent(Math.round(
						(80 * e.loaded) / e.total
					));
				}
      }
    })
      .then(resp => {
        if(resp && resp.data.res_msg.content) {
          setAiLoading(false);
          let botMessage = '';
          let attachments: AttachInfo[] = [];
          if(isJson(resp.data.res_msg.content)) {
            botMessage = JSON.parse(resp.data.res_msg.content).text;
            attachments = JSON.parse(resp.data.res_msg.content).attachments;
          } else {
            botMessage = resp.data.res_msg.content;
          }
          setSpeechAgent('bot');
          setMessage((_message) => {
            return [
              ..._message,
              {
                id: BOT_ID,
                type: 'received',
                time: new Date().toISOString(),
                readAt: new Date(),
                message: botMessage,
                attachments: attachments,
              } as MessageType
            ]
          })
          fetch("https://api.localgovai.uk:8000/v1/comm/msg/text-to-speech", {
            method: 'POST',
            body: JSON.stringify({
              text: botMessage
            })
          })
            .then((res: any) => res.arrayBuffer())
            .then(buffer => {
              
              const blob = new Blob([buffer], {type: 'audio/wav'});
              const url = URL.createObjectURL(blob);

              const audioContext = new AudioContext();
              const reader = new FileReader();
              reader.onload = () => {
                if(reader.result) {
                  try {
                    const audioData = reader.result as ArrayBuffer;
                    audioContext.decodeAudioData(audioData, function(decodedData) {
                      const source = audioContext.createBufferSource();
                      source.buffer = decodedData;
                      const analyser = audioContext.createAnalyser();
                      scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);
                      source.connect(analyser);

                      analyser.smoothingTimeConstant = 0.8;
                      analyser.fftSize = 1024;
                      source.connect(analyser);
                      analyser.connect(scriptProcessor);
                      scriptProcessor.connect(audioContext.destination);
                      scriptProcessor.onaudioprocess = function() {
                        const array = new Uint8Array(analyser.frequencyBinCount);
                        analyser.getByteFrequencyData(array);
                        const arraySum = array.reduce((a, value) => a + value, 0);
                        const average = arraySum / array.length;
                        setAudioVolume(Math.round(average))
                      };
                
                      source.start(0);
                    });
                  } catch(err) {

                  }
                }
              }
              reader.readAsArrayBuffer(blob);

              if(audioRef.current) {
                audioRef.current.src = url;
                setSpeaking(true);
                audioRef.current.play();
                audioRef.current.onended = () => {
                  scriptProcessor.disconnect();
                }
              }
            })
        }
      })
      .catch(err => {
        setAiLoading(false);
        toast.error('Something went wrong! Please try again later.');
      })
  };

  useEffect(() => {
    if(message) {
      scrollToBottom(message[message.length - 1])
    }
  }, [message])

  const scrollToBottom = (lastItem: MessageType) => {
    const msgNode = document.querySelector(`#message_end`);
    if(msgNode) msgNode.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  }

  const handleModalState = useCallback(() => {
    if(showModal) {
      setFeedBackModalState(true);
      setFeedBackModalStep('leave')
    } else {
      setShowModal(!showModal);
    }
  }, [showModal]);

  const goToMsgDetail = (e: MessageType) => {
    const msgNode = document.querySelector(`#msg_${new Date(e.time).getTime()}`);
    if(msgNode) msgNode.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  }

  useEffect(() => {
    if(menuOption && menuOption === 'close') {
      setFeedBackModalState(true);
      setFeedBackModalStep('leave');
    }
    if(menuOption && menuOption === 'finish') {
      if(feedBackStep === 'feedback_sent' || feedBackStep === 'share_alert') {
        setShowModal(false);
        setMenuOption(null);
        setFeedBackModalState(false);
      } 
    }
    if(menuOption && menuOption === 'report') {
      setShowModal(false);
      navigate(`/apps/chat/${104}/conversation`);
    }
  }, [menuOption])

  return (
    <div className="support-chat-container">
      <div className={`container-fluid support-chat ${showModal ? 'show-chat': ''}`}>
        <div className="card">
          <WidgetHeader closeModal={handleModalState} msgContent={message} viewMsgDetail={(e: MessageType) => goToMsgDetail(e)}/>
          <div className="card-body chat p-3 scrollbar">
            {message.length === 0 && (
              <div className="p-3">
                <div className="text-center mt-auto">
                  <div className="avatar avatar-3xl status-online">
                    <img
                      className="rounded-circle border border-3 border-white"
                      src={Eric}
                      alt=""
                    />
                  </div>
                  <h5 className="mt-2 mb-3">Eric</h5>
                  <p className="text-start text-black mb-0">
                    Hi, I'm [Concuil Name] assistant, here to help your [Query type] inqueries. Here are some of the types of questios that you can ask me 
                  </p>
                </div>
                <div className="text-start mt-6">
                  <a
                    className="mb-2 d-flex align-items-center text-decoration-none text-1100 hover-bg-soft rounded border border-dashed border-500 py-2 ps-4 pe-3"
                    href="#!"
                    onClick={() => sentMessage("I need help with something")}
                  >
                    <p className="mb-0 fw-semi-bold fs--1 font-14">
                      I need help with something
                    </p>
                  </a>
                  <a
                    className="mb-2 d-flex align-items-center text-decoration-none text-1100 hover-bg-soft rounded border border-dashed border-500 py-2 ps-4 pe-3"
                    href="#!"
                    onClick={() =>
                      sentMessage(
                        "I can’t reorder a product I previously ordered"
                      )
                    }
                  >
                    <p className="mb-0 fw-semi-bold fs--1 font-14">
                      I can’t reorder a product I previously ordered
                    </p>
                  </a>
                  <a
                    className="mb-2 d-flex align-items-center text-decoration-none text-1100 hover-bg-soft rounded border border-dashed border-500 py-2 ps-4 pe-3"
                    href="#!"
                    onClick={() => sentMessage("How do I place an order?")}
                  >
                    <p className="mb-0 fw-semi-bold fs--1 font-14">
                      How do I place an order?
                    </p>
                  </a>
                  <a
                    className="false d-flex align-items-center text-decoration-none text-1100 hover-bg-soft rounded border border-dashed border-500 py-2 ps-4 pe-3"
                    href="#!"
                    onClick={() => sentMessage("My payment method not working")}
                  >
                    <p className="mb-0 fw-semi-bold fs--1 font-14">
                      My payment method not working
                    </p>
                  </a>
                </div>
              </div>
            )}
            {message && message.length > 0 && (
              <div className="p-1" style={{overflow: 'scroll'}}>
                {message.map((msg, idx) =>
                  msg.type === "sent" ? (
                    <Message key={`msg_${idx}`} width="w-75" message={msg} id={`msg_${new Date(msg.time).getTime()}`}/>
                  ) : (
                    <Message key={`msg_${idx}`} width="w-75" message={msg} user={Erik} id={`msg_${new Date(msg.time).getTime()}`}/>
                  )
                )}
                <div className="messageEnd pb-10" id="message_end"></div>
              </div>
            )}
            <ChatStateIndicator percent={percent}/>
          </div>
          <WidgetFooter handleMsg={sentMessage}/>
        </div>
        {feedBackModalState && (
          <ModalViews/>
        )}
      </div>
      <button onClick={() => handleModalState()} className={`btn p-0 border border-200 btn-support-chat show ${showModal ? 'btn-chat-close' : ''}`}>
        <span className="fs-0 btn-text text-primary text-nowrap">
          Support
        </span>
        <ActiveStatus />
        <Below />
      </button>
      <audio ref={audioRef} style={{display: 'none'}} onEnded={() => {
        setSpeaking(false);
        setSpeechAgent('');
      }}/>
    </div>
  );
}