import React from "react";
import { Button, Form } from "react-bootstrap";

const Urls = () => {
    return (
        <div className="col-lg-5">
            <div className="card card-flush h-lg-100">
                <div className="card-header mt-2">
                    <div className="card-title flex-column">
                        <h3 className="fw-bold mb-1 fs-7">Knowledge Base URL(s)</h3>
                    </div>
                    <p className="fs-8 fw-semibold text-gray-600 py-2 m-0">Our Knowledge Base URL enables easy sharing with collaborators, ensuring that everyone has access to the same information. It's also designed to seamlessly integrate with bots, streamlining workflows, enriching knowledge bases, and enhancing Robotic Process Automation (RPA) systems for improved efficiency. 
                    <br /> </p>
                    <div className="w-100 mb-4">
                        <Form.Control className="w-50 me-3 d-inline-block" type="text" value={"https://thamesbridge.localgovai.uk/knowledgebase/?refid=345re66787k8"} disabled />
                        <Button variant="outline-primary">Copy Link</Button>
                    </div> 
                </div>
            </div>
        </div>
    );
}

export default Urls;