import React, { ChangeEvent } from 'react';
import { Card, Dropdown } from "react-bootstrap";
import { CountryOption, countryList } from 'constant';
import NarrowDown from 'components/icons/NarrowDown';
import avatar from "assets/craft/avatars/blank.png";
import IntroMsg from 'craft_components/IntroMsg';
import { introMsgData } from "./data";

const handleConvert = (txt: string) => {
  const res = txt.replace(/\[Council name\]/g, "<span style='color: blue'>[Council name]</span>")
                  .replace(/\[Bot name\]/g, "<span style='color: blue'>[Bot name]</span>")
                  .replace(/\[Entity name\]/g, "<span style='color: blue'>[Entity name]</span>");
  return res;
}

const BotSettings = () => {
  const [languageOption, setLanguageOption] = React.useState<CountryOption>();
  const [filteredCountryList, setFilteredCountryList] = React.useState<CountryOption[]>(countryList);
  const [searchKey, setSearchKey] = React.useState<string>('');
  const [namingConvention, setNamingConvention] = React.useState<string>('organisation');
  const [botProfile, setBotProfile] = React.useState<string>('organisation');
  const [introMsg, setIntroMsg] = React.useState<string>('organisation');
  const [publicSupport, setPublicSupport] = React.useState<string>(handleConvert(`<p>${introMsgData[0]}</p>`));
  const [staffSupport, setStaffSupport] = React.useState<string>(handleConvert(`<p>${introMsgData[1]}</p>`));
  const [publicCalls, setPublicCalls] = React.useState<string>(handleConvert(`<p>${introMsgData[2]}</p>`));
  const [staffCalls, setStaffCalls] = React.useState<string>(handleConvert(`<p>${introMsgData[3]}</p>`));
  const [avatarImage, setAvatarImage] = React.useState<any>(null);

  const handleAvatar = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
          setAvatarImage(e.target.files[0]);
      }
  }

  const setLanguage = (value: string) => {
    const option = countryList.filter(item => item.CountryCode === value)[0];
    setLanguageOption(option);
  }

  const handleFilterCountry = (searchKey: string) => {
    let filteredCountry = countryList.filter(country => country.CountryName.toLowerCase().startsWith(searchKey));
    setFilteredCountryList(filteredCountry);
  }

  const handleChangePublicSupport = (e: React.ChangeEvent<HTMLDivElement>) => {
    setPublicSupport(handleConvert(e.target.innerText));
  }

  return (
    <div id='botSettings'>
      <Card>
        <Card.Header>
          <Card.Title className="fs-7">Bot Settings</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="row align-items-center mb-4">
            <div className="col">
              <label htmlFor="timeZoneLabel fs-7" className="col-form-label form-label ps-0 text-notrans">Global Bot Settings</label>
              <p className="fs-8 text-muted mb-0">By inheriting the logo from the Profile, it provides you with a Brand to staff and Residents</p>
            </div>
            <div className="col-auto">
              <div className="form-check form-switch">
                <input className="form-check-input w-50px h-30px" type="checkbox" id="connectedAccounts1" />
                <label className="form-check-label" htmlFor="connectedAccounts1"></label>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <label htmlFor="locationLabel" className="col-sm-3 col-form-label form-label text-notrans">Default Language</label>

            <div className="col-sm-9">
                <div className="btn-reveal-trigger-custom mx-auto">
                    <Dropdown
                        onSelect={e => {if (e) setLanguage(e)}}
                        >
                        <Dropdown.Toggle as='div' className='cursor-pointer'>
                            <div className='d-flex p-1 px-3 align-items-center'>
                            <div className='rounded-pill widget-header-flag' style={{backgroundImage: `url('http://purecatamphetamine.github.io/country-flag-icons/3x2/` + `${languageOption?.CountryCode}` + `.svg')`, width: 28, height: 28}}>
                            </div>
                            <label className='text-black ms-2 flex-1 cursor-pointer'>
                                {languageOption?.CountryName}
                            </label>
                            <NarrowDown color='black'/>
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='w-100'>
                            <div className="px-3 mb-1">
                                <input type="text" className="form-control" placeholder="search" value={searchKey} onChange={e => handleFilterCountry(e.target.value)} />
                            </div>
                            <div style={{height: 180, overflow: 'scroll'}}>
                            {filteredCountryList.map((country, index) => {
                                return (
                                <Dropdown.Item eventKey={country.CountryCode} key={index}>
                                    <div className='d-flex align-items-center'>
                                    <div className='rounded-pill widget-header-flag' style={{backgroundImage: `url('http://purecatamphetamine.github.io/country-flag-icons/3x2/` + `${country?.CountryCode}` + `.svg')`, width: 28, height: 28}}>
                                    </div>
                                    <label className='text-black ms-2 flex-1 font-14 cursor-pointer'>
                                        {country?.CountryName}
                                    </label>
                                    </div>
                                </Dropdown.Item>
                                )
                            })}
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
          </div>
          <div className="row mb-2">
              <label htmlFor="colorTheme" className="col-sm-3 col-form-label form-label text-notrans">Colour Scheme</label>
          </div>
          <div className="row mb-4">
              <label htmlFor="publicHeader" className="col-sm-3 col-form-label form-label text-notrans">(Public) Support Chat Header</label>

              <div className="col-sm-9">
                <input type="color" className="form-control" name="currentPassword" id="publicHeader" aria-label="Your time zone" />
              </div>
          </div>
          <div className="row mb-4">
              <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">(Staff) Support Chat Header</label>

              <div className="col-sm-9">
                <input type="color" className="form-control" name="currentPassword" id="staffHeader" aria-label="Your time zone" />
              </div>
          </div>
          <div className="row align-items-center mb-4">
            <div className="col">
              <label htmlFor="timeZoneLabel1" className="col-form-label form-label ps-0 text-notrans">Inherit Logo</label>
              <p className="fs-8 text-muted mb-0">By inheriting the logo from the Profile, it provides you with a Brand to staff and Residents</p>
            </div>
            <div className="col-auto">
              <div className="form-check form-switch">
                <input className="form-check-input w-50px h-30px" type="checkbox" id="connectedAccounts1" />
                <label className="form-check-label" htmlFor="connectedAccounts1"></label>
              </div>
            </div>
          </div>
          <label className="row mb-4" htmlFor="accountSettingsPreferencesSwitch1">
            <p className="col-8 col-sm-9 ms-0">
              <h4 className="d-block text-dark fs-7">Naming Convention</h4>
              <span className="d-block fs-8">The naming ensures that the Bot inherits the name of the entity you chose. By default, it will inherit the team name. </span>
            </p>
            <p className="d-block fs-8">An example would be when a user calls would you rather they Speak to Your Organisation, or be more specific e.g. Housing, the Social Housing Service, or the Housing Repair Team?</p>
            <span className="d-block fs-8 text-primary">The name of the Product will change based on the URL or Phone number.</span>
          </label>
          <div className="list-group list-group-lg list-group-flush list-group-no-gutters">
            <div className="list-group-item">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="my-0">Organisation</h4>
                  <p className="fs-8 text-body mb-0">By selecting Organisation, it means Products will inherit the name of the Organisation instead of the Directorate, Service or Team name.</p>
                </div>

                <div className="col-auto">
                  <div className="form-check form-radio" onClick={() => setNamingConvention('organisation')}>
                    <input className="form-check-input w-20px h-20px" type="radio" name='namingConvention' id="connectedAccounts1" checked={namingConvention === 'organisation'} />
                    <label className="form-check-label" htmlFor="connectedAccounts1"></label>
                  </div>
                </div>
              </div>
            </div>
            <div className="list-group-item">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="my-0">Directorate</h4>
                  <p className="fs-8 text-body mb-0">By selecting Directorate, it means Products will inherit the name of the Directorate instead of the Service or Team name.</p>
                </div>

                <div className="col-auto">
                  <div className="form-check form-radio" onClick={() => setNamingConvention('directorate')}>
                    <input className="form-check-input w-20px h-20px" type="radio" name='namingConvention' id="connectedAccounts1" checked={namingConvention === 'directorate'} />
                    <label className="form-check-label" htmlFor="connectedAccounts1"></label>
                  </div>
                </div>
              </div>
            </div>
            <div className="list-group-item">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="my-0">Services</h4>
                  <p className="fs-8 text-body mb-0">By selecting Service, it means Products will inherit the name of the Service instead of the Directorate or Team name.</p>
                </div>

                <div className="col-auto">
                  <div className="form-check form-radio" onClick={() => setNamingConvention('services')}>
                    <input className="form-check-input w-20px h-20px" type="radio" name='namingConvention' id="connectedAccounts1" checked={namingConvention === 'services'} />
                    <label className="form-check-label" htmlFor="connectedAccounts1"></label>
                  </div>
                </div>
              </div>
            </div>
            <div className="list-group-item">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="my-0">Teams</h4>
                  <p className="fs-8 text-body mb-0">Team is the default naming convention, it means Products will inherit the name of the Team instead of the Directorate or Service name.</p>
                </div>

                <div className="col-auto">
                  <div className="form-check form-radio" onClick={() => setNamingConvention('team')}>
                    <input className="form-check-input w-20px h-20px" type="radio" name='namingConvention' id="connectedAccounts1" checked={namingConvention === 'team'} />
                    <label className="form-check-label" htmlFor="connectedAccounts1"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <label className="row mb-4" htmlFor="accountSettingsPreferencesSwitch1">
            <p className="col-8 col-sm-9 ms-0">
              <h4 className="d-block text-dark fs-7">Bot Profile</h4>
              <span className="d-block fs-8">The Bot Avatar is the profile photo and name that will be used. You don't have to select one. The Bot Profile ensures that the Bot inherits the correct Profile across Products and Entities. By default, it will be set at the Team level.</span>
            </p>
            <p className="d-block fs-8">An example would be when a user calls would you rather they Speak to Your Organisation, James from the Housing Directorate, the Social Housing Service, or the Housing Repair Team?</p>
            <span className="d-block fs-8 text-primary">The Profile can change based on the URL or Phone number.</span>
          </label>
          <label className="mt-0 avatar avatar-5xl avatar-uploader rounded-circle profile-cover-avatar">
            <img className="avatar-img rounded-circle" src={avatarImage ? URL.createObjectURL(avatarImage) : avatar} alt="Upload logo" />

            <input type="file" accept="image/*" onChange={handleAvatar} className="js-file-attach avatar-uploader-input" />

            <span className="avatar-uploader-trigger">
              <i className="bi-pencil-fill avatar-uploader-icon shadow-sm"></i>
            </span>
          </label>
          <div className="list-group list-group-lg list-group-flush list-group-no-gutters mb-4">
            <div className="list-group-item">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="my-0">Organisation</h4>
                  <p className="fs-8 text-body mb-0">By selecting Organisation, it means Products will inherit the name of the Organisation instead of the Directorate, Service or Team name.</p>
                </div>

                <div className="col-auto">
                  <div className="form-check form-radio" onClick={() => setBotProfile('organisation')}>
                    <input className="form-check-input w-20px h-20px" type="radio" name='botProfile' id="connectedAccounts1" checked={botProfile === 'organisation'} />
                    <label className="form-check-label" htmlFor="connectedAccounts1"></label>
                  </div>
                </div>
              </div>
              {botProfile === 'organisation' && <div className="row">
                <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Bot Name</label>

                <div className="col-sm-9">
                  <input type="text" className="form-control" name="currentPassword" id="staffHeader" placeholder="Enter bot Name address" aria-label="Your time zone" />
                </div>
              </div>}
            </div>
            <div className="list-group-item">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="my-0">Directorate</h4>
                  <p className="fs-8 text-body mb-0">By selecting Directorate, it means Products will inherit the name of the Directorate instead of the Service or Team name.</p>
                </div>

                <div className="col-auto">
                  <div className="form-check form-radio" onClick={() => setBotProfile('directorate')}>
                    <input className="form-check-input w-20px h-20px" type="radio" name='botProfile' id="connectedAccounts1" checked={botProfile === 'directorate'} />
                    <label className="form-check-label" htmlFor="connectedAccounts1"></label>
                  </div>
                </div>
              </div>
              {botProfile === 'directorate' && <div className="row">
                <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Bot Name</label>

                <div className="col-sm-9">
                  <input type="text" className="form-control" name="currentPassword" id="staffHeader" placeholder="Enter bot Name address" aria-label="Your time zone" />
                </div>
              </div>}
            </div>
            <div className="list-group-item">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="my-0">Services</h4>
                  <p className="fs-8 text-body mb-0">By selecting Service, it means Products will inherit the name of the Service instead of the Directorate or Team name.</p>
                </div>

                <div className="col-auto">
                  <div className="form-check form-radio" onClick={() => setBotProfile('services')}>
                    <input className="form-check-input w-20px h-20px" type="radio" name='botProfile' id="connectedAccounts1" checked={botProfile === 'services'} />
                    <label className="form-check-label" htmlFor="connectedAccounts1"></label>
                  </div>
                </div>
              </div>
              {botProfile === 'services' && <div className="row">
                <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Bot Name</label>

                <div className="col-sm-9">
                  <input type="text" className="form-control" name="currentPassword" id="staffHeader" placeholder="Enter bot Name address" aria-label="Your time zone" />
                </div>
              </div>}
            </div>
            <div className="list-group-item">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="my-0">Teams</h4>
                  <p className="fs-8 text-body mb-0">Team is the default naming convention, it means Products will inherit the name of the Team instead of the Directorate or Service name.</p>
                </div>

                <div className="col-auto">
                  <div className="form-check form-radio" onClick={() => setBotProfile('team')}>
                    <input className="form-check-input w-20px h-20px" type="radio" name='botProfile' id="connectedAccounts1" checked={botProfile === 'team'} />
                    <label className="form-check-label" htmlFor="connectedAccounts1"></label>
                  </div>
                </div>
              </div>
              {botProfile === 'team' && <div className="row">
                <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Bot Name</label>

                <div className="col-sm-9">
                  <input type="text" className="form-control" name="currentPassword" id="staffHeader" placeholder="Enter bot Name address" aria-label="Your time zone" />
                </div>
              </div>}
            </div>
          </div>
          <label className="row mb-4" htmlFor="accountSettingsPreferencesSwitch1">
            <p className="col-8 col-sm-9 ms-0">
              <h4 className="d-block text-dark fs-7">Bot Intro Message</h4>
              <span className="d-block fs-8">The Bot Avatar is the profile photo and name that will be used. You don't have to select one. The Bot Profile ensures that the Bot inherits the correct Profile across Products and Entities. By default, it will be set at the Team level.</span>
            </p>
            <p className="d-block fs-8">An example would be when a user calls would you rather they Speak to Your Organisation, James from the Housing Directorate, the Social Housing Service, or the Housing Repair Team?</p>
            <span className="d-block fs-8 text-primary">The Profile can change based on the URL or Phone number.</span>
          </label>
          <div className="list-group list-group-lg list-group-flush list-group-no-gutters">
            <div className="list-group-item">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="my-0">Organisation</h4>
                  <p className="fs-8 text-body mb-0">By selecting Organisation, it means Products will inherit the name of the Organisation instead of the Directorate, Service or Team name.</p>
                </div>

                <div className="col-auto">
                  <div className="form-check form-radio" onClick={() => setIntroMsg('organisation')}>
                    <input className="form-check-input w-20px h-20px" type="radio" name='introMessage' id="connectedAccounts1" checked={introMsg === 'organisation'} />
                    <label className="form-check-label" htmlFor="connectedAccounts1"></label>
                  </div>
                </div>
              </div>
              {introMsg === 'organisation' && <>
                <div className="row mt-3">
                  <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Public Support Chat</label>

                  <div className="col-sm-9">
                    <IntroMsg id='publicSupport' value={publicSupport} setValue={setPublicSupport} />
                  </div>
                </div>
                <div className="row mt-3">
                  <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Staff Support Chat</label>

                  <div className="col-sm-9">
                    <IntroMsg id='staffSupport' value={staffSupport} setValue={setStaffSupport} />
                  </div>
                </div>
                <div className="row mt-3">
                  <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Public Calls</label>

                  <div className="col-sm-9">
                    <IntroMsg id='publicCalls' value={publicCalls} setValue={setPublicCalls} />
                  </div>
                </div>
                <div className="row mt-3">
                  <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Staff Calls</label>

                  <div className="col-sm-9">
                    <IntroMsg id='staffCalls' value={staffCalls} setValue={setStaffCalls} />
                  </div>
                </div>
              </>}
            </div>
            <div className="list-group-item">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="my-0">Directorate</h4>
                  <p className="fs-8 text-body mb-0">By selecting Directorate, it means Products will inherit the name of the Directorate instead of the Service or Team name.</p>
                </div>

                <div className="col-auto">
                  <div className="form-check form-radio" onClick={() => setIntroMsg('directorate')}>
                    <input className="form-check-input w-20px h-20px" type="radio" name='introMessage' id="connectedAccounts1" checked={introMsg === 'directorate'} />
                    <label className="form-check-label" htmlFor="connectedAccounts1"></label>
                  </div>
                </div>
              </div>
              {introMsg === 'directorate' && <>
                <div className="row mt-3">
                  <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Public Support Chat</label>

                  <div className="col-sm-9">
                    <IntroMsg id='publicSupport' value={publicSupport} setValue={setPublicSupport} />
                  </div>
                </div>
                <div className="row mt-3">
                  <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Staff Support Chat</label>

                  <div className="col-sm-9">
                    <IntroMsg id='staffSupport' value={staffSupport} setValue={setStaffSupport} />
                  </div>
                </div>
                <div className="row mt-3">
                  <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Public Calls</label>

                  <div className="col-sm-9">
                    <IntroMsg id='publicCalls' value={publicCalls} setValue={setPublicCalls} />
                  </div>
                </div>
                <div className="row mt-3">
                  <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Staff Calls</label>

                  <div className="col-sm-9">
                    <IntroMsg id='staffCalls' value={staffCalls} setValue={setStaffCalls} />
                  </div>
                </div>
              </>}
            </div>
            <div className="list-group-item">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="my-0">Services</h4>
                  <p className="fs-8 text-body mb-0">By selecting Service, it means Products will inherit the name of the Service instead of the Directorate or Team name.</p>
                </div>

                <div className="col-auto">
                  <div className="form-check form-radio" onClick={() => setIntroMsg('services')}>
                    <input className="form-check-input w-20px h-20px" type="radio" name='introMessage' id="connectedAccounts1" checked={introMsg === 'services'} />
                    <label className="form-check-label" htmlFor="connectedAccounts1"></label>
                  </div>
                </div>
              </div>
              {introMsg === 'services' && <>
                <div className="row mt-3">
                  <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Public Support Chat</label>

                  <div className="col-sm-9">
                    <IntroMsg id='publicSupport' value={publicSupport} setValue={setPublicSupport} />
                  </div>
                </div>
                <div className="row mt-3">
                  <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Staff Support Chat</label>

                  <div className="col-sm-9">
                    <IntroMsg id='staffSupport' value={staffSupport} setValue={setStaffSupport} />
                  </div>
                </div>
                <div className="row mt-3">
                  <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Public Calls</label>

                  <div className="col-sm-9">
                    <IntroMsg id='publicCalls' value={publicCalls} setValue={setPublicCalls} />
                  </div>
                </div>
                <div className="row mt-3">
                  <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Staff Calls</label>

                  <div className="col-sm-9">
                    <IntroMsg id='staffCalls' value={staffCalls} setValue={setStaffCalls} />
                  </div>
                </div>
              </>}
            </div>
            <div className="list-group-item">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="my-0">Teams</h4>
                  <p className="fs-8 text-body mb-0">Team is the default naming convention, it means Products will inherit the name of the Team instead of the Directorate or Service name.</p>
                </div>

                <div className="col-auto">
                  <div className="form-check form-radio" onClick={() => setIntroMsg('team')}>
                    <input className="form-check-input w-20px h-20px" type="radio" name='introMessage' id="connectedAccounts1" checked={introMsg === 'team'} />
                    <label className="form-check-label" htmlFor="connectedAccounts1"></label>
                  </div>
                </div>
              </div>
              {introMsg === 'team' && <>
                <div className="row mt-3">
                  <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Public Support Chat</label>

                  <div className="col-sm-9">
                    <IntroMsg id='publicSupport' value={publicSupport} setValue={setPublicSupport} />
                  </div>
                </div>
                <div className="row mt-3">
                  <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Staff Support Chat</label>

                  <div className="col-sm-9">
                    <IntroMsg id='staffSupport' value={staffSupport} setValue={setStaffSupport} />
                  </div>
                </div>
                <div className="row mt-3">
                  <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Public Calls</label>

                  <div className="col-sm-9">
                    <IntroMsg id='publicCalls' value={publicCalls} setValue={setPublicCalls} />
                  </div>
                </div>
                <div className="row mt-3">
                  <label htmlFor="staffHeader" className="col-sm-3 col-form-label form-label text-notrans">Staff Calls</label>

                  <div className="col-sm-9">
                    <IntroMsg id='staffCalls' value={staffCalls} setValue={setStaffCalls} />
                  </div>
                </div>
              </>}
            </div>
          </div>
          <div className="row align-items-center mb-4">
              <div className="col">
                  <label htmlFor="timeZoneLabel" className="col-form-label form-label ps-0 text-notrans">See info about people who view my profile</label>
                  <p className="fs-8 text-muted mb-0">
                      <a className="link" href="#">More about viewer info.</a>
                  </p>
              </div>

              <div className="col-auto">
                  <div className="form-check form-switch">
                      <input className="form-check-input w-50px h-30px" type="checkbox" id="connectedAccounts1" />
                      <label className="form-check-label" htmlFor="connectedAccounts1"></label>
                  </div>
              </div>
          </div>
          <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">Save Changes</button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default BotSettings;
