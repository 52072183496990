import React from "react";
import { Button, Modal } from "react-bootstrap";
import Stepper from "./Stepper";
import KnowledgeBaseType from "./KnowledgeBaseType";
import Settings from "./Settings";
import Budget from "./Budget";
import InviteTeam from "./InviteTeam";
import SetTargets from "./SetTargets";
import UploadFiles from "./UploadFiles";
import Completed from "./Completed";
import GoogleIcon from "assets/craft/images/google-icon.svg";
import { teamMembers } from "data/teamMemeber";
import TeamMemberItem from "craft_components/TeamMemberItem";
import { ISettings, IBudget, ITeam, ISettingsError } from "./types";
import { initialSettings, initialBudget, initialTeam, initialSettingsError } from "./data";
import { createKnowledgebase } from "hyper_helpers/api/knowledgebase";
import { toast } from "react-toastify";

const CreateNew = ({showNew, setShowNew, showInvite, setShowInvite} : {showNew: boolean, setShowNew: (show: boolean) => void, showInvite: boolean, setShowInvite: (showInvite: boolean) => void}) => {
    const [step, setStep] = React.useState<number>(1);
    const [knowledgeBaseType, setKnowledgeBaseType] = React.useState<string>("internal");
    const [settings, setSettings] = React.useState<ISettings>(initialSettings);
    const [settingsError, setSettingsError] = React.useState<ISettingsError>(initialSettingsError);
    const [budget, setBudget] = React.useState<IBudget>(initialBudget);
    const [team, setTeam] = React.useState<ITeam>(initialTeam);
    const [uploadFiles, setUploadFiles] = React.useState<File[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleInit = () => {
        setKnowledgeBaseType("internal");
        setSettings(initialSettings);
        setSettingsError(initialSettingsError);
        setBudget(initialBudget);
        setTeam(initialTeam);
        setUploadFiles([]);
    }

    const handleNext =  async () => {
        if (step === 2) {
            let result: ISettingsError = initialSettingsError;
            // if (settings.parentName === "")
            //     result = { ...result, status: false, parentName: true };
            if (settings.name === "")
                result = { ...result, status: false, name: true };
            if (settings.description === "")
                result = { ...result, status: false, description: true };
            setSettingsError(result);
            if (!result.status) return;
        }
        if (step === 6) {
            let notifications = [];
            if (settings.email) notifications.push('email');
            if (settings.phone) notifications.push('phone');
            let teamMates = team.teamMembers.map(member => ({userId: member.id, role: member.position}));
            console.log(teamMates);

            const formData = new FormData();
            formData.append('knowledge_base_type', knowledgeBaseType);
            formData.append('knowledge_base_parent', settings.parentName);
            formData.append('knowledge_base_name', settings.name);
            formData.append('knowledge_base_searchable', settings.searchable ? "true" : "false");
            formData.append('knowledge_base_url', settings.url);
            formData.append('knowledge_base_phone', settings.telephone);
            formData.append('proj_description', settings.description);
            formData.append('publish_date', settings.date);
            formData.append('notifications', notifications.join());
            formData.append('budget', budget.budget.toString());
            formData.append('budget_usage', budget.usage);
            formData.append('allowance_change_budget', budget.changable ? "true" : "false");
            formData.append('team_members', JSON.stringify(teamMates));
            formData.append('allowance_invite_by_members', team.addUserByMember ? "true" : "false");
            uploadFiles.map(file => {
                formData.append('knowledge_files', file);
            });

            setLoading(true);
            try {
                await createKnowledgebase(formData);
            } catch (err: any) {
                toast.warn(err);
                return;
            }
            setLoading(false);
        }
        if (step === 7) {
            handleInit();
        }
        setStep(step => step !== 7 ? step === 2 ? step + 2 : step + 1 : 1);
    }

    const handlePrevious = () => {
        setStep(step => step === 4 ? step - 2 : step -1);
    }

    return (
        <div>
            <Modal dialogClassName="modal-90w" show={showNew} onHide={() => setShowNew(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Project</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-5 my-2">
                    <div className="stepper stepper-links d-flex flex-column" id="kt_modal_create_project_stepper">
                        <div className="container">
                            <div className="stepper-nav justify-content-center py-1">
                                <Stepper title={"Knowledge Base Type"} num={1} step={step} />
                                <Stepper title={"Settings"} num={2} step={step} />
                                {/* <Stepper title={"Budget"} num={3} step={step} /> */}
                                <Stepper title={"Invite Team"} num={4} step={step} />
                                <Stepper title={"Set Targets"} num={5} step={step} />
                                <Stepper title={"Upload Files"} num={6} step={step} />
                                <Stepper title={"Completed"} num={7} step={step} />
                            </div>
                            <div className="mx-auto w-100 mw-600px py-5">
                                <KnowledgeBaseType num={1} step={step} knowledgeBaseType={knowledgeBaseType} setKnowledgeBaseType={setKnowledgeBaseType} next={handleNext} />
                                <Settings num={2} step={step} next={handleNext} previous={handlePrevious} settings={settings} setSettings={setSettings} settingsError={settingsError} />
                                {/* <Budget num={3} step={step} next={handleNext} previous={handlePrevious} budget={budget} setBudget={setBudget} /> */}
                                <InviteTeam num={4} step={step} next={handleNext} previous={handlePrevious} team={team} setTeam={setTeam} />
                                <SetTargets num={5} step={step} next={handleNext} previous={handlePrevious} />
                                <UploadFiles num={6} step={step} next={handleNext} previous={handlePrevious} uploadFiles={uploadFiles} setUploadFiles={setUploadFiles} loading={loading} />
                                <Completed num={7} step={step} next={handleNext} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={showInvite} onHide={() => setShowInvite(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="mx-2 mx-xl-9 pt-0 pb-7">
                    <div className="text-center mb-6">
                        <h1 className="mb-3 fs-6">Invite a Friend</h1>
                        <div className="text-muted fw-semibold fs-7">If you need more info, please check out
                        <a href="#" className="link-primary fw-bold ms-1">FAQ Page</a>.</div>
                    </div>
                    <Button variant="outline-primary" className="fw-bold w-100 mb-2">
                        <img alt="Logo" src={GoogleIcon} className="h-20px me-2" />Invite Gmail Contacts
                    </Button>
                    <div className="separator d-flex flex-center mb-4 mt-4">
                        <span className="text-uppercase fs-7 fw-semibold text-muted px-3 bg-white">or</span>
                    </div>
                    <textarea className="form-control form-control-solid mb-4" rows={3} placeholder="Type or paste emails here"></textarea>
                    <div className="mb-5">
                        <div className="fs-8 fw-semibold mb-2">Your Invitations</div>
                        <div className="mh-300px scroll-y me-n3 pe-3">
                            {
                                teamMembers.map((member, idx) => <TeamMemberItem key={member.id} isLast={teamMembers.length === idx + 1} user={member} />)
                            }
                        </div>
                    </div>
                    <div className="d-flex flex-stack mb-7">
                        <div className="me-3 fw-semibold">
                            <label className="fs-8">Adding Users by Team Members</label>
                            <div className="fs-9 text-muted">If you need more info, please check budget planning</div>
                        </div>
                        <label className="d-flex align-items-center form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input h-30px w-50px" type="checkbox" value="" defaultChecked />
                            <span className="form-check-label fw-semibold text-muted ms-2 fs-8">Allowed</span>
                        </label>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CreateNew;