import { TeamMember } from "data/teamMemeber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const TeamMemberItem = ({
    isLast,
    user,
    roleChange,
    onRemove,
} : {
    isLast: boolean,
    user: TeamMember,
    roleChange?: (id: string, val: string) => void,
    onRemove?: (val: string) => void,
}) => {
    return (
        <div className={`d-flex flex-stack p-2 ${!isLast ? "border-bottom border-gray-300 border-bottom-dashed" :  ""}`}>
            <div className="d-flex align-items-center">
                <div className="avatar avatar-m">
                    <img className="rounded-circle" alt="Pic" src={user.avatar} />
                </div>
                <div className="ms-3">
                    <span className="fs-8 fw-bold text-gray-900 text-hover-primary mb-1">{user.name}</span>
                    <div className="fw-semibold text-muted">{user.email}</div>
                </div>
            </div>
            <div className="d-flex align-items-center">
                <div className="ms-1 w-100px">
                    <select className="form-select form-select-solid form-select-sm" data-control="select2" data-hide-search="true" value={user.position} onChange={roleChange ? e => roleChange(user.id, e.target.value) : () => {}}>
                        <option value="view">View</option>
                        <option value="owner">Owner</option>
                        <option value="edit">Can Edit</option>
                    </select>
                </div>
                {onRemove && <FontAwesomeIcon icon={faTimes} className="ms-3 cursor-pointer" onClick={() => onRemove(user.id)} />}
            </div>
        </div>
    )
}

export default TeamMemberItem;