import {
    Settings,
    SettingsError,
    Organisation,
    OrganisationError,
    UserInfo,
    UserInfoError,
    Billing,
} from "./types";

export const initialSettings: Settings = {
    domainName: "",
    domainConfirm: "",
    teamSize: "1-1",
    accPlan: "organisation",
};

export const initialSettingsError: SettingsError = {
    status: true,
    domainName: false,
    domainConfirm: false,
};

export const initialOrganisation: Organisation = {
    name: "",
    description: "",
    type: "1",
    contactEmail: "",
};

export const initialOrganisationError: OrganisationError = {
    status: true,
    name: false,
    description: false,
    contactEmail: false,
};

export const initialUserInfo: UserInfo = {
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    password: "",
    confirmPwd: "",
    policy: false,
};

export const initialUserInfoError: UserInfoError = {
    status: true,
    firstName: false,
    lastName: false,
    userName: false,
    email: false,
    password: false,
    confirmPwd: false,
    policy: false,
};
