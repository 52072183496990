import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocket, faUser, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

interface KnowledgeBaseTypeProps {
    num: number;
    step: number;
    knowledgeBaseType: string,
    setKnowledgeBaseType: (type: string) => void;
    next: () => void;
}

const KnowledgeBaseType = (props: KnowledgeBaseTypeProps) => {
    const { num, step, knowledgeBaseType, setKnowledgeBaseType, next } = props;

    let otherClass = 'pending';
    if (step === num) otherClass = 'current';
    if (step > num) otherClass = 'completed';

    return (
        <div className={otherClass} data-kt-stepper-element="content">
            <div className="w-100">
                <div className="pb-4 pb-lg-6">
                    <h1 className="fw-bold text-dark fs-6">Types of Knowledge Base</h1>
                    <div className="text-muted fw-semibold fs-7">If you need more info, please check out
                    <a href="#" className="link-primary fw-bold ms-1">FAQ Page</a></div>
                </div>
                <div className="fv-row mb-7" data-kt-buttons="true">
                    <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-3 mb-3 ${knowledgeBaseType === "internal" ? "active" : ""}`}
                        onClick={() => setKnowledgeBaseType("internal")}
                    >
                        <span className="d-flex">
                            <FontAwesomeIcon icon={faUser} width={100} className="fs-4" />
                            <span className="ms-3">
                                <span className="fs-6 fw-bold text-gray-900 mb-2 d-block">Staff (internal) Knowledge Base</span>
                                <span className="fw-semibold fs-8 text-muted">This kind of Knowledge Base is only accessible by staff and those within your organisation. Users needs permissions to Create, Read, Update and Delete files and folders.</span>
                            </span>
                        </span>
                    </label>
                    <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-3 mb-3 ${knowledgeBaseType === "shared" ? "active" : ""}`}
                        onClick={() => setKnowledgeBaseType("shared")}
                    >
                        <span className="d-flex">
                            <FontAwesomeIcon icon={faUserGroup} width={100} className="fs-4" />
                            <span className="ms-3">
                                <span className="fs-6 fw-bold text-gray-900 mb-2 d-block">Shared Knowledge Base</span>
                                <span className="fw-semibold fs-8 text-muted">This kind of Knowledge Base is only accessible by staff and those your organisation invite to collaborate. Users needs special permissions to Create, Read, Update and Delete files and folders.</span>
                            </span>
                        </span>
                    </label>
                    <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-3 mb-3 ${knowledgeBaseType === "public" ? "active" : ""}`}
                        onClick={() => setKnowledgeBaseType("public")}
                    >
                        <span className="d-flex">
                            <FontAwesomeIcon icon={faRocket} width={100} className="fs-4" />
                            <span className="ms-3">
                                <span className="fs-6 fw-bold text-gray-900 mb-2 d-block">Public Knowledge Base</span>
                                <span className="fw-semibold fs-8 text-muted">This kind of knowledge Base is created for Customers, Services Users and Residents. Files and Folders here are accessible to the public, or anyone who has acess to the services your provide.</span>
                            </span>
                        </span>
                    </label>
                </div>
                <div className="d-flex justify-content-end">
                    <Button variant="primary" size="lg" onClick={next}>
                        Next
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default KnowledgeBaseType;