import ChatContent from 'components/modules/chat/chat-content';
import ChatSidebar from 'components/modules/chat/ChatSidebar';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import { useChatContext } from 'providers/ChatProvider';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ChatConversation = () => {
  const { userId } = useParams();

  const { chatDispatch, conversations, addNewChat } = useChatContext();

  const { breakpoints } = useBreakpoints();
  useEffect(() => {
    if (userId === '104') {
      console.log('userId', userId);
      addNewChat({
        id: 104,
        user: {
          id: 104,
          status: "online",
          name: 'Customer Service',
          bot: true,
        },
        messages: []
      });
    }
    chatDispatch({
      type: 'SET_CURRENT_CONVERSATION',
      payload: {
        userId
      }
    });
  }, [userId, conversations]);

  return (
    <>
      {breakpoints.up('sm') && <ChatSidebar />}
      <ChatContent />
    </>
  );
};

export default ChatConversation;
