import { faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
} from "react-bootstrap";

type BudgetProps = {
  handleBack: () => void;
  handleNext: () => void;
};

const Budget = ({ handleBack, handleNext }: BudgetProps) => {
  return (
    <div className="d-flex justify-content-center" >
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <div className="w-100 rounded-3">
              <h3>Budget</h3>
              <p className="fs-8 text-muted">
                If you need more info, please check out{" "}
                <strong className="text-primary">
                  <a href="#">Project Guidelines</a>
                </strong>
              </p>
            </div>
            <div className="w-100 rounded-3">
              <Button variant="soft-info" className="border-2 w-100 mb-3">
                <Form.Check
                  className="fs-5 text-start"
                  type="radio"
                  id="checkedRadio"
                  label={
                    <div>
                      <h5>Precise Usage</h5>
                      <p className="fs-8 text-start text-muted">
                        Withdraw money to your bank account per transaction
                        under $50,000 budget
                      </p>
                    </div>
                  }
                  defaultChecked
                  name="radio"
                />
              </Button>
              <Button variant="soft-info" className="border-2 w-100 mb-3">
                <Form.Check
                  className="fs-5 text-start"
                  type="radio"
                  id="checkedRadio"
                  label={
                    <div>
                      <h5>Extreme Usage</h5>
                      <p className="fs-8 text-start text-muted">
                        Withdraw money to your bank account per transaction
                        under $50,000 budget
                      </p>
                    </div>
                  }
                  defaultChecked
                  name="radio"
                />
              </Button>
            </div>
            <div className="w-100 rounded-3">
              <div className="d-flex align-items-center rounded p-3 mb-2">
                <div className="flex-shrink-0 me-3"></div>
                <div className="flex-grow-1">
                  <h5 className="fw-semibold my-0">Google</h5>
                  <p className="mb-0">Plan properly your workflow</p>
                </div>
                <div className="flex-shrink-0 my-0">
                  <Form.Check
                    type="checkbox"
                    id="showPhone"
                    className="form-switch"
                  >
                    <Form.Check.Input
                      type="checkbox"
                      name="showPhone"
                      value="option2"
                      defaultChecked
                    />
                  </Form.Check>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <Container>
                  <Row>
                    <Col className="mb-1" sm={6}>
                      <Button
                        className="btn-secondary fs-8 w-100"
                        onClick={() => handleBack()}
                      >
                        Project Settings
                      </Button>
                    </Col>
                    <Col className="mb-1" sm={6}>
                      <Button
                        className="btn-primary fs-8 w-100"
                        onClick={() => handleNext()}
                      >
                        Start Target
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Budget;
