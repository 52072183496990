import * as React from 'react';

const BotLoading = ({percent}: any) => {

  const [state, setState] = React.useState(0);

  React.useEffect(() => {
    if(percent === 20) {
      setState(percent);
    } else {
      setState(percent);
    }
  }, [percent]);

  React.useEffect(() => {
    let interval: any;

    if(state >= 20 && state < 60) {
      interval = setInterval(() => {
        setState((_state) => {
          if(_state < 75) {
            return _state + 5;
          } else {
            return _state;
          }
        })
      }, 800);
    }
    return () => {
      clearInterval(interval);
    }
  }, [state]);

  React.useEffect(() => {
    setState(0);
  }, [])

  return (
    <div className='bot-loading-spinner mx-auto bg-white p-2 w-80 mb-4 justify-content-center mt-1'>
      <h5 className='text-center mt-1'>Hmm... let me find the answer for you...</h5>
      <div className='d-flex mx-auto w-80 mb-1'>
        <div className={`mx-1 bot-loading-spinner-item${state >= 0  ? '-active' : ''}`}></div>
        <div className={`mx-1 bot-loading-spinner-item${state >= 20 ? '-active' : ''}`}></div>
        <div className={`mx-1 bot-loading-spinner-item${state >= 40 ? '-active' : ''}`}></div>
        <div className={`mx-1 bot-loading-spinner-item${state >= 60 ? '-active' : ''}`}></div>
        <div className={`mx-1 bot-loading-spinner-item${state >= 80 ? '-active' : ''}`}></div>
      </div>
    </div>
  )
}

export default BotLoading;