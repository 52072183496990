import { IKnowledgeBase } from "data/knowledgebase";
import { KnowledgeBaseActionTypes } from "./constants";

const INIT_STATE = {
    knowledgeBases: [],
};

type State = {
    knowledgeBases: IKnowledgeBase[];
};

type KnowledgeBaseActionType = {
    type:
        | KnowledgeBaseActionTypes.SET_KNOWLEDGE_BASE
        | KnowledgeBaseActionTypes.DELETE_KNOWLEDGE_BASE;
    payload: {
        knowledgeBases?: IKnowledgeBase[];
        knowledgeBaseId?: string;
    };
};

const KnowledgeBase = (
    state: State = INIT_STATE,
    action: KnowledgeBaseActionType
) => {
    switch (action.type) {
        case KnowledgeBaseActionTypes.SET_KNOWLEDGE_BASE:
            return { ...state, knowledgeBases: action.payload.knowledgeBases };
        case KnowledgeBaseActionTypes.DELETE_KNOWLEDGE_BASE:
            let filtered = state.knowledgeBases.filter(
                (knowledge: IKnowledgeBase) =>
                    knowledge.KnowledgeBaseID !== action.payload.knowledgeBaseId
            );
            return { ...state, knowledgeBases: filtered };
        default:
            return state;
    }
};

export default KnowledgeBase;
