import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';

interface SuccessProps {
    num: number;
    step: number;
}

const Success = (props: SuccessProps) => {
    const { num, step } = props;

    let otherClass = 'pending';
    if (step === num) otherClass = 'current';
    if (step > num) otherClass = 'completed';

    return (
        <div className={otherClass} data-kt-stepper-element="content">
            <div className="w-100">
                <div className="pb-8 pb-lg-10">
                    <h2 className="fw-bold text-dark fs-6">Your Are Done!</h2>
                    <div className="text-muted fw-semibold fs-8">
                        If you need more info, please
                        <Link className="link-primary fw-bold ms-1" to={'/login'}>Sign In</Link>
                    </div>
                </div>
                <div className="mb-0">
                    <div className="fs-8 text-gray-600 mb-5">Check your email to verify your email</div>
                    <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-3">
                        <i className="ki-duotone ki-information fs-4 text-warning me-2">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                        </i>
                        <div className="d-flex flex-stack flex-grow-1">
                            <div className="fw-semibold">
                                <h4 className="text-gray-900 fw-bold fs-7 mt-0">We need your attention!</h4>
                                <div className="fs-8 text-gray-700">To start using great tools, please,
                                <a href="../dist/utilities/wizards/vertical.html" className="fw-bold ms-1">Create Team Platform</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Success;