import React, { ChangeEvent } from "react";
import ProfileImg from "assets/images/img2.jpg";
import { Card } from "react-bootstrap";
import avatar from "assets/craft/avatars/blank.png";

const Header = () => {
    const [avatarImage, setAvatarImage] = React.useState<any>(null);

    const handleAvatar = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setAvatarImage(e.target.files[0]);
        }
    }

    return (
        <Card className="mb-0">
            <div className="profile-cover">
                <div className="profile-cover-img-wrapper">
                <img id="profileCoverImg" className="profile-cover-img" src={ProfileImg} alt="Image Description" />

                <div className="profile-cover-content profile-cover-uploader p-3">
                    <input type="file" accept="image/*" className="js-file-attach profile-cover-uploader-input" id="profileCoverUplaoder" />
                    <label className="profile-cover-uploader-label btn btn-sm bg-white" htmlFor="profileCoverUplaoder">
                        <i className="bi-camera-fill"></i>
                        <span className="d-none d-sm-inline-block ms-1">Upload header</span>
                    </label>
                </div>
                </div>
            </div>

            <label className="avatar avatar-5xl avatar-uploader rounded-circle profile-cover-avatar" htmlFor="editAvatarUploaderModal">
                <img id="editAvatarImgModal" className="avatar-img rounded-circle" src={avatarImage ? URL.createObjectURL(avatarImage) : avatar} alt="Upload logo" />

                <input type="file" accept="image/*" className="js-file-attach avatar-uploader-input" onChange={handleAvatar} id="editAvatarUploaderModal" />

                <span className="avatar-uploader-trigger">
                    <i className="bi-pencil-fill avatar-uploader-icon shadow-sm"></i>
                </span>
            </label>

            <div className="card-body">
                <div className="row">
                    <div className="col-sm-5">
                        <span className="d-block fs-8 mb-2">Who can see the organisations profile photo? <i className="bi-question-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="The organisations visibility setting only applies to it's profile photo. The header image is always visible to anyone."></i></span>

                        <div className="tom-select-custom">
                            <select className="form-select">
                                <option value="privacy1" data-option-template='<div className="d-flex align-items-start"><div className="flex-shrink-0"><i className="bi-globe"></i></div><div className="flex-grow-1 ms-2"><span className="d-block fw-semibold">Anyone</span><span className="tom-select-custom-hide small">Visible to anyone who can view your content. Accessible by installed apps.</span></div></div>'>Anyone</option>
                                <option value="privacy2" data-option-template='<div className="d-flex align-items-start"><div className="flex-shrink-0"><i className="bi-lock"></i></div><div className="flex-grow-1 ms-2"><span className="d-block fw-semibold">Only you</span><span className="tom-select-custom-hide small">Only visible to you.</span></div></div>'>Only you</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default Header;