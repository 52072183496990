import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Button, Dropdown } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { LatestFile } from "../types";
import { latestFiles } from "../data";

const LatestFiles = () => {
    const [selectedFiles, setSelectedFiles] = React.useState<File[]>([]);

    const onDrop = (files: File[]) => {
        if (files.length > 0) {
        setSelectedFiles(files);
        }
    }

    return (
        <div className="col-lg-6">
            <div className="card card-flush h-lg-100">
                <div className="card-header mt-3">
                    <div className="card-title flex-column">
                        <h3 className="fw-bold mb-1 fs-7">Latest Files</h3>
                        <div className="fs-8 text-400">Total 382 fiels, 2,6GB space usage</div>
                    </div>
                    <div className="card-toolbar">
                        <Button variant="outline-primary" size="sm">View All</Button>
                    </div>
                </div>
                <div className="card-body p-5 pt-1 pb-0">
                    <div className="d-flex flex-column mb-2">
                        {
                            latestFiles.map((item, idx) => <FileItem key={idx} icon={item.icon} status={item.status} title={item.title} owner={item.owner} />)
                        }
                    </div>
                    <Dropzone onDrop={onDrop} multiple={true}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="p-0">
                                <div {...getRootProps({ className: "dropzone bg-light-primary rounded" })}>
                                    <input {...getInputProps()} />
                                    {selectedFiles.length > 0 && selectedFiles[0].name ? (
                                        <div className="selected-file">
                                            {selectedFiles && selectedFiles[0].name}
                                        </div>
                                    ) : (
                                        <div className="notice">
                                            <div className="d-flex flex-stack flex-grow-1">
                                                <div className="fw-semibold">
                                                    <h4 className="text-900 fw-bold mt-0">Quick file uploader</h4>
                                                    <div className="fs-8 text-700">Drag & Drop or choose files from computer</div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>
            </div>
        </div>
    );
}

const FileItem = (props: LatestFile) => {
    const { icon, title, status, owner } = props;

    return (
        <div className="d-flex align-items-center mb-3">
            <div className="symbol symbol-30px me-2">
                <img alt="Icon" src={icon} />
            </div>
            <div className="fw-semibold fs-8">
                <a className="fw-bold text-dark text-hover-primary" href="#">{title}</a>
                <div className="text-400">
                    {status}
                    <a href="#" className="ms-1">{owner}</a>
                </div>
            </div>
            <div className="btn-reveal-trigger ms-auto">
                <Dropdown
                    align="end"
                    id="dropdown-menu-align-right"
                >
                    <Dropdown.Toggle as='a' className='cursor-pointer'>
                        <FontAwesomeIcon icon={faEllipsisV} size="sm" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <div className="px-3 pb-2">
                            <div className="fs-8 text-dark fw-bold">Filter Options</div>
                        </div>
                        <div className="separator border-200"></div>
                        <div className="px-3 py-2">
                            <div className="mb-4">
                                <label className="fw-semibold">Member Type:</label>
                                <div className="d-flex">
                                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                                        <input className="form-check-input" type="checkbox" value="1" />
                                        <span className="form-check-label">Author</span>
                                    </label>
                                    <label className="form-check form-check-sm form-check-custom form-check-solid">
                                        <input className="form-check-input" type="checkbox" value="2" defaultChecked />
                                        <span className="form-check-label">Customer</span>
                                    </label>
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="fw-semibold">Notifications:</label>
                                <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                    <input className="form-check-input" type="checkbox" value="" name="notifications" defaultChecked />
                                    <label className="form-check-label">Enabled</label>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <Button className="me-2" variant="outline-primary" size="sm">Reset</Button>
                                <Button variant="primary" size="sm">Apply</Button>
                            </div>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
}

export default LatestFiles;