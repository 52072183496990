import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/store";
import CreateNew from "./CreateNew";
import ProjectCardView from "../project-management/ProjectCardView";
import ProjectListView from "../project-management/ProjectListView";
import { getKnowledgeBase as getKnowledgeBaseApi } from "hyper_helpers/api/knowledgebase";
import { setKnowledgeBases } from "redux/knowledgebase/actions";

const MyKnowledgeBases = () => {
    const dispatch = useDispatch();
    const { knowledgeBases } = useSelector((state: RootState) => state.KnowledgeBase);

    useEffect(() => {
        const getKnowledgeBase = async () => {
            try {
                const res = await getKnowledgeBaseApi({});
                dispatch(setKnowledgeBases({knowledgeBases: res.data.data}));
            } catch (e) {
                console.log(e);
            }
        }
        getKnowledgeBase();
    }, []);

    return (knowledgeBases.length === 0 ?
        <CreateNew />
        :
        <ProjectListView />
    );
}

export default MyKnowledgeBases;