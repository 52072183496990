interface StepperProps {
    title: string;
    num: number;
    step: number;
}

const Stepper = (props: StepperProps) => {
    const { title, step, num } = props;

    let otherClass = '';
    if (num === step) otherClass = 'current';
    if (num < step) otherClass = 'completed';

    return (
        <div className={`stepper-item ${otherClass}`}>
            <h3 className="stepper-title fs-7">{title}</h3>
        </div>
    );
}

export default Stepper;