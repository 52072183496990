import { FileItem } from 'data/fileManager';
import { removeFolder, renameFolder } from 'hyper_helpers/api/file';
import { useFileManagerContext } from 'providers/FileManagerProvider';
import * as React from 'react';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import { convertDateString, convertFileSize } from 'utils';

interface FileManagerViewProps {
  file: FileItem;
  index: number;
  edit: boolean;
  clear: boolean;
}
const FileManagerItemView = ({file, index, edit = false, clear}: FileManagerViewProps) => {

  const [fileName, setFileName] = React.useState(file.name);
  const { setEditState, updateNameByIndex, setModalState, folders, selectFile } = useFileManagerContext();
  const [showSetting, setShowSetting] = React.useState(false);
  const [showShare, setShowShare] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [canceling, setCanceling] = React.useState(false);
  const [linking, setLinking] = React.useState(false);
  

  const openFileSetting = React.useCallback((e: any) => {
    e.stopPropagation();
    setShowSetting(!showSetting);
  }, [showSetting]);

  const openShareLink = React.useCallback((e: any) => {
    e.stopPropagation();
    setShowShare(!showShare);
    setLinking(true);
    setTimeout(() => {
      setLinking(false);
    }, 2000)
  }, [showShare])

  const rename = React.useCallback(() => {
    setEditState(index, file.type);
    setShowSetting(false);
  }, [index, file])

  const updateFile = React.useCallback(() => {
    setModalState('update', index, null, fileName);
    setShowSetting(false);
  }, [index, fileName]);

  const remove = React.useCallback(() => {
    setModalState('delete', index, null, fileName);
    setShowSetting(false);
  }, [index]);

  React.useEffect(() => {
    setShowSetting(false);
    setShowShare(false);
  }, [clear])

  const cancelEditFolder = () => {
    setEditState(null, null)
  }

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    selectFile(index);
  }

  const handleView = () => {
    window.location.href = `/folder?id=${file.id}`
  }

  return (
    <tr>
      <td>
        <div className="form-check form-check-sm form-check-custom form-check-solid fs-8">
          <input className="form-check-input" type="checkbox" checked={file.selected} onChange={e => handleSelect(e)} />
        </div>
      </td>
      <td data-order="account">
        <div className="d-flex align-items-center">
          {file.type === 'folder' && (
            <span className="icon-wrapper">
              <i className="ki-duotone ki-folder fs-6 text-primary me-4">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </span>
          )}
          {edit ? (
            <>
            <input 
              type="text" 
              name="new_folder_name" 
              placeholder="Enter the folder name" 
              className="form-control mw-250px me-3"
              value={fileName}
              onChange={e => setFileName(e.target.value)}
            />
            <button
              onClick={() => updateFile()}
              className="btn btn-icon btn-outline-primary me-3" id="kt_file_manager_add_folder">
                {!loading ? 
                  <span className="indicator-label">
                    <i className="ki-duotone ki-check fs-6"></i>
                  </span>
                  :
                  <span className="indicator-progress">
                    <span className="spinner-border spinner-border-sm align-middle"></span>
                  </span>
                }
              </button>
              <button 
                onClick={() => cancelEditFolder()}
                className="btn btn-icon btn-outline-danger" 
                id="kt_file_manager_cancel_folder">
                {!canceling ?
                  <span className="indicator-label">
                      <i className="ki-duotone ki-cross fs-6">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                  </span>
                  :
                  <span className="indicator-progress">
                    <span className="spinner-border spinner-border-sm align-middle"></span>
                  </span>
                  }
              </button>
            </>
            )
            :
            (
              <a href={`/folder?id=${file.id}`} className="fs-8 text-black text-hover-primary">
                {file.name}
              </a>
            )
          }
        </div>
      </td>
      <td className='fs-8'>{convertFileSize(Number(file.size))}</td>
      <td className='fs-8'>
        <Moment format='D, MMM, YYYY, hh:mm A'>{convertDateString(file)}</Moment>
      </td>
      <td className="text-end" data-kt-filemanager-table="action_dropdown">
        <div className="d-flex justify-content-end">
          <div className="ms-2" data-kt-filemanger-table="copy_link">
            <button 
              onClick={(e) => openShareLink(e)}
              type="button" 
              className="btn btn-sm btn-icon btn-light btn-active-light-primary" 
              data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
              <i className="ki-duotone ki-fasten fs-6 m-0">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </button>
            <div 
              style={ showShare ? {
                zIndex: 107,
                position: 'fixed', 
                inset: '0px 0px auto auto',
                margin: '0px',
                transform: 'translate(-152px, 605px)'
              } : {}}
              className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px ${showShare ? 'show' : ''}`} 
              data-kt-menu="true">
              <div className="card card-flush mb-0">
                <div className="card-body p-5">
                  {linking ? 
                    <div className="d-flex" data-kt-filemanger-table="copy_link_generator">
                      <div className="me-5" data-kt-indicator="on">
                        <span className="indicator-progress">
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </div>
                      <div className="fs-8 text-dark">Generating Share Link...</div>
                    </div>
                    :
                    <div className="d-flex flex-column text-start" data-kt-filemanger-table="copy_link_result">
                      <div className="d-flex mb-3">
                        <i className="ki-duotone ki-check fs-6 text-success me-3"></i>
                        <div className="fs-8 text-dark">Share Link Generated</div>
                      </div>
                      <input type="text" className="form-control form-control-sm" value="https://path/to/file/or/folder/" />
                      <div className="text-muted fw-normal mt-2 fs-8 px-3">Read only.
                      <a href="/app/knowledge-base/settings" className="ms-2 cursor-pointer">Change permissions</a></div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="ms-2">
            <button 
              onClick={(e) => openFileSetting(e)}
              type="button" 
              className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
              <i className="ki-duotone ki-dots-square fs-6 m-0">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
              </i>
            </button>
            <div 
              className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-150px py-4 fs-9 text-black ${showSetting ? 'show' : ''}`}
              style={ showSetting ? {    
                zIndex: 107,
                position: 'fixed',
                inset: 'auto 0px 0px auto',
                margin: '0px',
                transform: 'translate(-111px, -275px)'
              } : {}} 
              data-kt-menu="true">
              {file.type === 'folder' && (
                <div className="menu-item px-3" onClick={() => handleView()}>
                  <a className="menu-link px-3 text-black">View</a>
                </div>
              )}
              {file.type === 'folder' && (
                <div className="menu-item px-3" onClick={() => rename()}>
                  <a className="menu-link px-3 text-black" data-kt-filemanager-table="rename">Rename</a>
                </div>
              )}
              {file.type === 'folder' && (
                <div className="menu-item px-3">
                  <a className="menu-link px-3 text-black">Download Folder</a>
                </div>
              )}
              <div className="menu-item px-3">
                <a 
                  className="menu-link px-3 text-black" 
                  data-kt-filemanager-table-filter="move_row" 
                  data-bs-toggle="modal" data-bs-target="#kt_modal_move_to_folder"
                >
                  Move to folder
                </a>
              </div>
              <div className="menu-item px-3" onClick={() => remove()}>
                <a className="menu-link text-danger px-3" data-kt-filemanager-table-filter="delete_row">Delete</a>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  )
}

export default FileManagerItemView;