import React from 'react';
import { Dropdown } from 'react-bootstrap';
import SearchBox from 'components/common/SearchBox';

interface IStatus {
  name: string;
  badge?: string;
  count?: number;
}

const statusMenu: IStatus[] = [
  {
    name: "Backlog",
  },
  {
    name: "Todo",
    badge: "success",
    count: 23,
  },
  {
    name: "Inprogress",
  },
  {
    name: "Done",
    badge: "warning",
    count: 23,
  },
  {
    name: "Cancel",
  },
]

const OrganisationTopSection = () => {
  return (
    <div className="row align-items-center g-3 mt-0 mb-6 px-3">
      <div className="col-sm-6">
        <div className="d-flex gap-2">
          <SearchBox
            placeholder="Search..."
            className="w-100 w-md-50"
            size="sm"
          />
          <Dropdown className="btn-reveal-trigger">
            <Dropdown.Toggle as={'button'} className="btn btn-sm">
              <i className="bi bi-plus-circle"></i>
              <span className="ms-2">Category</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className='py-1'>
              <div className="dropdown-item py-1 px-2 d-flex align-items-center">
                <div className="text-lg">
                  <input className="form-check-input" type="checkbox" id="checkPriority1" />
                </div>
                <div className="ms-3 me-5">
                  <label className="d-flex align-items-center" htmlFor="checkPriority1">
                    <span className="w-rem-2 h-rem-2 me-2 bg-success rounded-circle"></span>
                    <span>Low</span>
                  </label>
                </div>
              </div>
              <div className="dropdown-item py-1 px-2 d-flex align-items-center">
                <div className="text-lg">
                  <input className="form-check-input" type="checkbox" id="checkPriority2" />
                </div>
                <div className="ms-3 me-5">
                  <label className="d-flex align-items-center" htmlFor="checkPriority2">
                    <span className="w-rem-2 h-rem-2 me-2 bg-warning rounded-circle"></span>
                    <span>Medium</span>
                  </label>
                </div>
              </div>
              <div className="dropdown-item py-1 px-2 d-flex align-items-center">
                <div className="text-lg">
                  <input className="form-check-input" type="checkbox" id="checkPriority3" />
                </div>
                <div className="ms-3 me-5">
                  <label className="d-flex align-items-center" htmlFor="checkPriority3">
                    <span className="w-rem-2 h-rem-2 me-2 bg-danger rounded-circle"></span>
                    <span>High</span>
                  </label>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="btn-reveal-trigger">
            <Dropdown.Toggle as={'button'} className="btn btn-sm">
              <i className="bi bi-plus-circle"></i>
              <span className="ms-2">Status</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className='py-1'>
              {statusMenu.map((menu, idx) => (<div key={`status_menu_${idx}`} className="dropdown-item py-1 px-2 d-flex align-items-center">
                <div className="text-lg">
                  <input className="form-check-input w-15px h-15px" type="checkbox" id="checkStatus1" />
                </div>
                <div className="ms-3 me-5">
                  <label htmlFor="checkStatus1">{menu.name}</label>
                </div>
                {menu.count && <div className="ms-auto">
                  <span className={`badge rounded-pill bg-opacity-25 bg-${menu.badge} text-${menu.badge}`}>{menu.count}</span>
                </div>}
              </div>))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="btn-reveal-trigger">
            <Dropdown.Toggle as={'button'} className="btn btn-sm">
              <i className="bi bi-plus-circle"></i>
              <span className="ms-2">Priority</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className='py-1'>
              <div className="dropdown-item py-1 px-2 d-flex align-items-center">
                <div className="text-lg">
                  <input className="form-check-input" type="checkbox" id="checkPriority1" />
                </div>
                <div className="ms-3 me-5">
                  <label className="d-flex align-items-center" htmlFor="checkPriority1">
                    <span className="w-rem-2 h-rem-2 me-2 bg-success rounded-circle"></span>
                    <span>Low</span>
                  </label>
                </div>
              </div>
              <div className="dropdown-item py-1 px-2 d-flex align-items-center">
                <div className="text-lg">
                  <input className="form-check-input" type="checkbox" id="checkPriority2" />
                </div>
                <div className="ms-3 me-5">
                  <label className="d-flex align-items-center" htmlFor="checkPriority2">
                    <span className="w-rem-2 h-rem-2 me-2 bg-warning rounded-circle"></span>
                    <span>Medium</span>
                  </label>
                </div>
              </div>
              <div className="dropdown-item py-1 px-2 d-flex align-items-center">
                <div className="text-lg">
                  <input className="form-check-input" type="checkbox" id="checkPriority3" />
                </div>
                <div className="ms-3 me-5">
                  <label className="d-flex align-items-center" htmlFor="checkPriority3">
                    <span className="w-rem-2 h-rem-2 me-2 bg-danger rounded-circle"></span>
                    <span>High</span>
                  </label>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="col-sm-6">
        <div className="hstack justify-content-end gap-2">
          <button className="btn btn-sm">
            <i className="bi bi-x-lg"></i>
            <span className="ms-2">Category</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default OrganisationTopSection;
