import Button from 'components/base/Button';
import { AttachInfo } from 'data/chat';
import React from 'react';

interface MessageAttachmentsProps {
  attachments: AttachInfo[];
  openLightbox: (slideIndex: number) => void;
}
const MessageAttachments = ({
  attachments,
  openLightbox
}: MessageAttachmentsProps) => {
  const [showAll, setShowAll] = React.useState<boolean>(false);

  // const spans = () => {
  //   if (attachments.length > 3) {
  //     return {
  //       xs: 6,
  //       md: 4,
  //       xl: 3
  //     };
  //   }
  //   if (attachments.length === 2) {
  //     return {
  //       xs: 6
  //     };
  //   }
  //   if (attachments.length === 1) {
  //     return {
  //       xs: 'auto'
  //     };
  //   }
  // };
  return (
    // <Row className={classNames('g-2 mt-0')}>
    //   {attachments.map((attachment, index) => (
    //     <Col {...(spans() as ColProps)} key={attachment}>
    //       <img
    //         src={attachment}
    //         alt=""
    //         className="rounded-2 fit-cover cursor-pointer"
    //         onClick={() => {
    //           openLightbox(index + 1);
    //         }}
    //       />
    //     </Col>
    //   ))}
    // </Row>
    <div className="d-flex flex-wrap mb-2">
      {attachments.length > 3 ?
        <>
          {showAll ?
            attachments.map((attachment, index) => (
              <a key={`attach_${index}`} href={attachment.url} className='attachment text-300 text-decoration-none me-2' target='_blank' download>
                <img
                  className='w-50px h-50px rounded-2 fit-cover cursor-pointer'
                  src={attachment.url}
                  alt=''
                />
                <p className='w-50px fs-9 light mb-1'>{attachment.fileName}</p>
              </a>
            ))
            :
            <>
              {attachments.slice(0, 2).map((attachment, index) => (
                <a key={`attach_${index}`} href={attachment.url} className='attachment text-300 text-decoration-none me-2' target='_blank' download>
                  <img
                    className='w-50px h-50px rounded-2 fit-cover cursor-pointer'
                    src={attachment.url}
                    alt=''
                  />
                  <p className='w-50px fs-9 light mb-1'>{attachment.fileName}</p>
                </a>
              ))}
              <div className="d-flex justify-content-center align-items-center cursor-pointer w-50px h-50px fs-8 fw-bold rounded-2 bg-white text-700" onClick={() => setShowAll(true)}>
                {`+${attachments.length - 2}`}
              </div>
            </>
          }
        </>
        :
        <>
          {attachments.map((attachment, index) => {
            if(attachment.contentType && attachment.content) {
              if(attachment.content.buttons) {
                return attachment.content.buttons.map((button, btnIndex) => 
                  <Button key={btnIndex} variant="outline-primary" size='sm' className='mt-2'>
                    {button.displayText}
                  </Button>
                )
              }
            } else {
              return (
                <a key={`attach_${index}`} href={attachment.url} className='attachment text-300 text-decoration-none me-2' target='_blank' download>
                  <img
                    className='w-50px h-50px rounded-2 fit-cover cursor-pointer'
                    src={attachment.url}
                    alt=''
                  />
                  <p className='w-50px fs-9 light mb-1'>{attachment.fileName}</p>
                </a>
              )
            }
          })}
        </>
      }
    </div>
  );
};

export default MessageAttachments;
