import * as React from 'react';
import SuccessModal from './SuccessModal';
import { useFileManagerContext } from 'providers/FileManagerProvider';
import DeleteModal from './DeleteModal';
import UpdateModal from './UpdateModal';
import UploadModal from './UploadModal';

const FileManagerModalView = () => {
  const { modalState } = useFileManagerContext();
  return (
    <>
      {modalState === 'delete' && 
        <div className="swal2-container swal2-center swal2-backdrop-show" style={{overflowY: 'auto'}}>
          <DeleteModal/>
        </div>
      }
      {modalState === 'update' && 
        <div className="swal2-container swal2-center swal2-backdrop-show" style={{overflowY: 'auto'}}>
          <UpdateModal/>
        </div>
      }
      {modalState === 'success' && 
        <div className="swal2-container swal2-center swal2-backdrop-show" style={{overflowY: 'auto'}}>
          <SuccessModal/>
        </div>
      }
      {modalState === 'upload' && 
        <UploadModal/>
      }
    </>
  )
}

export default FileManagerModalView;