import React from "react";
import { Card } from "react-bootstrap";

const ChangePassword = () => {
    return (
        <Card id="password">
            <Card.Header>
                <Card.Title className="fs-7">Change your password</Card.Title>
            </Card.Header>
            <Card.Body>
                <div className="row mb-4">
                    <label htmlFor="currentPasswordLabel" className="col-sm-3 col-form-label form-label text-notrans">Current password</label>

                    <div className="col-sm-9">
                      <input type="password" className="form-control" name="currentPassword" id="currentPasswordLabel" placeholder="Enter current password" aria-label="Enter current password" />
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="newPassword" className="col-sm-3 col-form-label form-label text-notrans">New password</label>

                    <div className="col-sm-9">
                      <input type="password" className="form-control" name="newPassword" id="newPassword" placeholder="Enter new password" aria-label="Enter new password" />
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="confirmNewPasswordLabel" className="col-sm-3 col-form-label form-label text-notrans">Confirm new password</label>

                    <div className="col-sm-9">
                      <div className="mb-3">
                        <input type="password" className="form-control" name="confirmNewPassword" id="confirmNewPasswordLabel" placeholder="Confirm your new password" aria-label="Confirm your new password" />
                      </div>

                      <h5>Password requirements:</h5>

                      <p className="fs-8 mb-2">Ensure that these requirements are met:</p>

                      <ul className="fs-8">
                        <li>Minimum 8 characters long - the more, the better</li>
                        <li>At least one lowercase character</li>
                        <li>At least one uppercase character</li>
                        <li>At least one number, symbol, or whitespace character</li>
                      </ul>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary">Save Changes</button>
                </div>
            </Card.Body>
        </Card>
    );
}

export default ChangePassword;