import React from 'react';

const CardStateChat = (
  {
    img,
    title,
    subtitle,
    badge,
    total,
    data,
    icon,
  }
  :
  {
    img?: string,
    title?: string,
    subtitle?: string,
    badge?: string,
    total?: string,
    data?: string,
    icon?: string
  }
) => {
  return (
    <div className='col'>
      <div className={`card bg-${ badge ? badge : 'success' } bg-opacity-10 border border-${ badge ? badge : 'success' } border-opacity-40`}>
        <div className="p-4">
          <div className="d-flex gap-1 mb-2">
            {img && <img src={img} className="avatar" />}
            <div className="">
              <a className="d-inline-block fs-7 text-1100 fw-semibold" href="/pages/trade/details.html">
                { title ? title : 'BTC-USD' }
              </a>
              <span className="d-block fs-9 text-muted">{ subtitle ? subtitle : 'Bitcoin' }</span>
            </div>
          </div>
          <div className="d-flex align-items-end">
            <div className="hstack gap-1">
              <span className={`badge bg-${ badge ? badge : 'success' } bg-opacity-25 text-${ badge ? badge : 'success' }`}>{ badge ? badge : '+2,44%' }</span>
              <span className={`badge badge-count bg-${ badge ? badge : 'success' } text-xs rounded-circle`}>
                <i className={`bi bi-${ icon ? icon : 'arrow-up-right' }`}></i>
              </span>
            </div>
            <div className="w-rem-16 ms-auto">
              <div data-toggle="spark-chart" data-type="line" data-height="36" data-color={`${ badge ? badge : 'success' }`} data-dataset={`${ data ? data : '[22, 21, 20, 18, 26, 65, 31, 47, 10, 44, 45, 22, 21, 20, 18, 26, 65, 31, 47, 10, 44, 45]' }`}></div>
            </div>
          </div>
          <div className="fs-7 text-1100 fw-bold text-heading mt-1">
            { total ? total : '$23,000.00' }
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardStateChat;
