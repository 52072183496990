import React from "react";
import { Card, Dropdown } from "react-bootstrap";
import { CountryOption, countryList } from 'constant';
import NarrowDown from 'components/icons/NarrowDown';

const Preferences = () => {
    const [languageOption, setLanguageOption] = React.useState<CountryOption>();
    const [filteredCountryList, setFilteredCountryList] = React.useState<CountryOption[]>(countryList);
    const [searchKey, setSearchKey] = React.useState<string>('');

    const setLanguage = (value: string) => {
        const option = countryList.filter(item => item.CountryCode === value)[0];
        setLanguageOption(option);
    }

    const handleFilterCountry = (searchKey: string) => {
        let filteredCountry = countryList.filter(country => country.CountryName.toLowerCase().startsWith(searchKey));
        setFilteredCountryList(filteredCountry);
    }

    return (
        <Card id="preferences">
            <Card.Header>
                <Card.Title className="fs-7">Preferences</Card.Title>
            </Card.Header>
            <Card.Body>
                <div className="row mb-4">
                    <label htmlFor="locationLabel" className="col-sm-3 col-form-label form-label text-notrans">Default Language</label>

                    <div className="col-sm-9">
                        <div className="btn-reveal-trigger-custom mx-auto">
                            <Dropdown
                                onSelect={e => {if (e) setLanguage(e)}}
                                >
                                <Dropdown.Toggle as='div' className='cursor-pointer'>
                                    <div className='d-flex p-1 px-3 align-items-center'>
                                    <div className='rounded-pill widget-header-flag' style={{backgroundImage: `url('http://purecatamphetamine.github.io/country-flag-icons/3x2/` + `${languageOption?.CountryCode}` + `.svg')`, width: 28, height: 28}}>
                                    </div>
                                    <label className='text-black ms-2 flex-1 cursor-pointer'>
                                        {languageOption?.CountryName}
                                    </label>
                                    <NarrowDown color='black'/>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='w-100'>
                                    <div className="px-3 mb-1">
                                        <input type="text" className="form-control" placeholder="search" value={searchKey} onChange={e => handleFilterCountry(e.target.value)} />
                                    </div>
                                    <div style={{height: 180, overflow: 'scroll'}}>
                                    {filteredCountryList.map((country, index) => {
                                        return (
                                        <Dropdown.Item eventKey={country.CountryCode} key={index}>
                                            <div className='d-flex align-items-center'>
                                            <div className='rounded-pill widget-header-flag' style={{backgroundImage: `url('http://purecatamphetamine.github.io/country-flag-icons/3x2/` + `${country?.CountryCode}` + `.svg')`, width: 28, height: 28}}>
                                            </div>
                                            <label className='text-black ms-2 flex-1 font-14 cursor-pointer'>
                                                {country?.CountryName}
                                            </label>
                                            </div>
                                        </Dropdown.Item>
                                        )
                                    })}
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <label htmlFor="timeZoneLabel" className="col-sm-3 col-form-label form-label text-notrans">Time zone</label>

                    <div className="col-sm-9">
                      <input type="text" className="form-control" name="currentPassword" id="timeZoneLabel" placeholder="Your time zone" aria-label="Your time zone" value="GMT+01:00" readOnly />
                    </div>
                </div>
                <div className="row align-items-center mb-4">
                    <div className="col">
                        <label htmlFor="timeZoneLabel" className="col-form-label form-label ps-0 text-notrans">Early release</label>
                        <p className="fs-8 text-body mb-0">Get included on early releases for new Front features.</p>
                    </div>

                    <div className="col-auto">
                        <div className="form-check form-switch">
                            <input className="form-check-input w-50px h-30px" type="checkbox" id="connectedAccounts1" />
                            <label className="form-check-label" htmlFor="connectedAccounts1"></label>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mb-4">
                    <div className="col">
                        <label htmlFor="timeZoneLabel" className="col-form-label form-label ps-0 text-notrans">See info about people who view my profile</label>
                        <p className="fs-8 text-muted mb-0">
                            <a className="link" href="#">More about viewer info.</a>
                        </p>
                    </div>

                    <div className="col-auto">
                        <div className="form-check form-switch">
                            <input className="form-check-input w-50px h-30px" type="checkbox" id="connectedAccounts1" />
                            <label className="form-check-label" htmlFor="connectedAccounts1"></label>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary">Save Changes</button>
                </div>                    
            </Card.Body>
        </Card>
    );
}

export default Preferences;