import * as React from 'react';

const NarrowDown = ({ className, color = 'white' }: { className?: string, color?: string }) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 15.0387L6.34619 9.38488L7.40002 8.33105L12 12.9311L16.6 8.33105L17.6538 9.38488L12 15.0387Z" fill={color}/>
    </svg> 
  );
};

export default NarrowDown;