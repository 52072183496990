import React from "react";
import { Button } from "react-bootstrap";
import { Contributer } from "../types";
import { contributerData } from "../data";

const NewContributers = () => {
    return (
        <div className="col-lg-6">
            <div className="card card-flush h-lg-100">
                <div className="card-header mt-3">
                    <div className="card-title flex-column">
                        <h3 className="fw-bold mb-1 fs-7">New Contibutors</h3>
                        <div className="fs-8 text-400">From total 482 Participants</div>
                    </div>
                    <div className="card-toolbar">
                        <Button variant="outline-primary" size="sm">View All</Button>
                    </div>
                </div>
                <div className="card-body d-flex flex-column p-4 pt-1 mb-4">
                    {
                        contributerData.map((user, idx) => <ContributerItem key={idx} avatar={user.avatar} name={user.name} status={user.status} badge={user.badge} online={user.online} />)
                    }
                </div>
            </div>
        </div>
    );
}

const ContributerItem = (props: Contributer) => {
    const { avatar, name, status, badge, online } = props;

    return (
        <div className="d-flex align-items-center mb-2">
            <div className="me-2 position-relative">
                <div className="symbol symbol-35px symbol-circle">
                    {avatar ?
                        <img alt="Pic" src={avatar} />
                        :
                        <span className="d-flex justify-content-center align-items-center symbol-label bg-light-danger text-danger fw-semibold text-uppercase fs-7">{name[0]}</span>
                    }
                </div>
                {online && <div className="bg-success position-absolute border border-1 border-white h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1"></div>}
            </div>
            <div className="fw-semibold">
                <a href="#" className="fs-8 fw-bold text-900 text-hover-primary">{name}</a>
                <div className="text-400 fs-8">{status}</div>
            </div>
            <div className="badge bg-light ms-auto fs-9 text-500 py-1 px-2">{badge}</div>
        </div>
    );
}

export default NewContributers;