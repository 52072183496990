import { faFileUpload, faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "components/base/DatePicker";
import Dropzone from "components/base/Dropzone";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ReactSelect from "react-select";

type ProjectSettingProps = {
  handleNext: () => void;
  handleBack: () => void;
};

const ProjectSetting = ({ handleBack, handleNext }: ProjectSettingProps) => {
  return (
    <div className="d-flex justify-content-center">
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <div className="w-100 rounded-3">
              <h3>Project Settings</h3>
              <p className="fs-8 text-muted">
                If you need more info, please check{" "}
                <strong className="text-primary">
                  <a href="#">Project Guidelines</a>
                </strong>
              </p>
            </div>
            <div className="w-100 rounded-3">
              <Button variant="soft-info" className="border-2 w-100 mb-3 p-0">
                <Dropzone
                  className="dropezone"
                  multiple={false}
                  onDrop={(acceptedFiles) => console.log(acceptedFiles)}
                />
              </Button>
            </div>
            <div className="w-100 rounded-3 mb-3">
              <Row>
                <div className="w-100">
                  <Form.Group>
                    <h5>Customer</h5>
                    <ReactSelect
                      className="fs-8"
                      options={[
                        {
                          value: "Keenthemes",
                          label: "Keenthemes",
                        },
                        {
                          value: "CRM App",
                          label: "CRM App",
                        },
                      ]}
                      placeholder="Select customer"
                    />
                  </Form.Group>
                </div>
              </Row>
            </div>
            <div className="w-100 rounded-3 mb-3">
              <Form.Group>
                <h5>Project Name</h5>
                <Form.Control
                  type="text"
                  className="fs-8"
                  placeholder="Normal text"
                />
              </Form.Group>
            </div>
            <div className="w-100 rounded-3 mb-3">
              <Form.Group>
                <h5>Project Description</h5>
                <Form.Control as="textarea" className="fs-8" />
              </Form.Group>
            </div>
            <div className="w-100 rounded-3 mb-3">
              <Form.Group>
                <h5>Release Date</h5>
                <DatePicker placeholder="Select Date" />
              </Form.Group>
            </div>
            <div className="w-100 rounded-3 mb-3">
              <Row>
                <Col sm={8}>
                  <h5 className="m-0">Notification</h5>
                  <p className="fs-9 text-muted">
                    Allow Notifications by Phone or Email
                  </p>
                </Col>
                <Col sm={4}>
                  <Row>
                    <Col sm={6}>
                      <Form.Check label="Email" className="fs-7" />
                    </Col>
                    <Col sm={6}>
                      <Form.Check label="Phone" className="fs-7" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="w-100 rounded-3 d-flex justify-content-between mt-5 mb-5">
              <Container>
                <Row>
                  <Col className="mb-1" sm={6}>
                    <Button
                      className="bg-secondary fs-8 w-100"
                      onClick={() => handleBack()}
                    >
                      Project Type
                    </Button>
                  </Col>
                  <Col className="mb-1" sm={6}>
                    <Button
                      className="btn-primary fs-8 w-100"
                      onClick={() => handleNext()}
                    >
                      Project Settings
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProjectSetting;
