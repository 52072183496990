import ChatHomepageCard from 'components/cards/ChatHomepageCard';
import ChatSidebar from 'components/modules/chat/ChatSidebar';
import { useChatContext } from 'providers/ChatProvider';
import { Navigate } from "react-router-dom";

const ChatHomepage = () => {
  const { conversations } = useChatContext();

  return (conversations.length === 0 ?
    <>
      <ChatSidebar />
      <ChatHomepageCard />
    </>
    :
    <Navigate to={`/apps/chat/${conversations[0].id}/conversation`} />
  );
};

export default ChatHomepage;
