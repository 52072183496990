import team33 from "assets/phoenix/img/team/33.webp";
import team30 from "assets/phoenix/img/team/30.webp";
import team31 from "assets/phoenix/img/team/31.webp";
import team60 from "assets/phoenix/img/team/60.webp";
import team32 from "assets/phoenix/img/team/32.webp";
import team35 from "assets/phoenix/img/team/35.webp";
import team57 from "assets/phoenix/img/team/57.webp";
import team58 from "assets/phoenix/img/team/58.webp";
import team59 from "assets/phoenix/img/team/59.webp";
import team34 from "assets/phoenix/img/team/34.webp";

export interface TeamMember {
    id: string;
    avatar: string;
    name: string;
    email: string;
    position: string;
}

export const teamMembers: TeamMember[] = [
    {
        id: "1",
        name: "Tyrion Lannister",
        avatar: team33,
        email: "tyrion222@com",
        position: "1",
    },
    {
        id: "2",
        name: "Milind Mikuja",
        avatar: team30,
        email: "milind12@com",
        position: "1",
    },
    {
        id: "3",
        name: "Stanly Drinkwater",
        avatar: team31,
        email: "drinkwater8@com",
        position: "1",
    },
    {
        id: "4",
        name: "Josef Stravinsky",
        avatar: team60,
        email: "josef60@com",
        position: "2",
    },
    {
        id: "5",
        name: "Igor Borvibson",
        avatar: team59,
        email: "Igor65@com",
        position: "3",
    },
    {
        id: "6",
        name: "Carry Anna",
        avatar: team32,
        email: "carry_anna@com",
        position: "1",
    },
    {
        id: "7",
        name: "Milind Mikuja",
        avatar: team34,
        email: "milind_mikuja@com",
        position: "2",
    },
    {
        id: "8",
        name: "Stanly Drinkwater",
        avatar: team35,
        email: "stanly_drinkwater@com",
        position: "3",
    },
    {
        id: "9",
        name: "Josef Stravinsky",
        avatar: team57,
        email: "josef_stravinsky@com",
        position: "3",
    },
    {
        id: "10",
        name: "Igor Borvibson",
        avatar: team58,
        email: "igor_borvibson@com",
        position: "3",
    },
];
