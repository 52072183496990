import React from "react";
import { Button } from "react-bootstrap";
import { TeamMember } from "../types";

const UserTable = ({users} : {users: TeamMember[]}) => {
    return (
        <div className="tab-pane fade show active">
            <div className="card card-flush">
                <div className="card-body pt-0">
                    <div className="table-responsive">
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bold">
                            <thead className="fs-7 text-400 text-uppercase">
                                <tr>
                                    <th className="min-w-250px">Manager</th>
                                    <th className="min-w-150px">Date</th>
                                    <th className="min-w-90px">Amount</th>
                                    <th className="min-w-90px">Status</th>
                                    <th className="min-w-50px text-end">Details</th>
                                </tr>
                            </thead>
                            <tbody className="fs-8">
                                {users.map((user, idx) => <UserItem key={idx} member={user} />)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

const UserItem = ({member} : {member: TeamMember}) => {

    let bgColor = '';
    switch (member.status) {
        case "Rejected":
            bgColor = "badge-light-danger";
            break;
        case "Pending":
            bgColor = "badge-light-warning";
            break;
        case "In progress":
            bgColor = "badge-light-info";
            break;
        case "Approved":
            bgColor = "badge-light-success";
            break;
        default:
            break;
    }

    return (
        <tr>
            <td>
                <div className="d-flex align-items-center">
                    <div className="me-5 position-relative">
                        <div className="symbol symbol-35px symbol-circle">
                            {member.avatar ?
                                <img alt="Pic" src={member.avatar} />
                                :
                                <span className="d-flex justify-content-center align-items-center symbol-label bg-light-danger text-danger fw-semibold">{member.name[0]}</span>
                            }
                            {member.online && <div className="bg-success position-absolute border border-1 border-white h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1"></div>}
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                        <a href="" className="mb-1 text-800 text-hover-primary">{member.name}</a>
                        <div className="fw-semibold fs-8 text-400">{member.email}</div>
                    </div>
                </div>
            </td>
            <td>{member.date}</td>
            <td>{member.amount}</td>
            <td>
                <span className={`badge ${bgColor} fw-bold p-2`}>{member.status}</span>
            </td>
            <td className="text-end">
                <Button variant="outline-primary" size="sm">View</Button>
            </td>
        </tr>
    );
}

export default UserTable;