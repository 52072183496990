import {
  Card,
  CardBody,
  Table,
  Row,
  Col,
  Form,
  Button,
  ListGroup,
} from "react-bootstrap";

import { Project } from "./types";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const roles = [
  {
    role: "Successful Payments",
    description: "Receive a notification for every successful payment.",
  },
  {
    role: "Payouts",
    description: "Receive a notification for every initiated payout.",
  },
  {
    role: "Application fees",
    description:
      "Receive a notification each time you collect a fee from an account.",
  },
  {
    role: "Disputes",
    description:
      "Receive a notification if a payment is disputed by a customer and for dispute resolutions.",
  },
  {
    role: "Payment reviews",
    description:
      "Receive a notification if a payment is marked as an elevated risk.",
  },
  {
    role: "Mentions",
    description: "Receive a notification if a teammate mentions you in a note.",
  },
  {
    role: "Invoice Mispayments",
    description:
      "Receive a notification if a customer sends an incorrect amount to pay their invoice.",
  },
  {
    role: "Webhooks",
    description:
      "Receive notifications about consistently failing webhook endpoints.",
  },
  {
    role: "Trial",
    description: "Receive helpful tips when you try out our products.",
  },
];

type SecurityProps = {
  projects: Project[];
};

const Security = ({ projects }: SecurityProps) => {
  return (
    <>
      <Card>
        <Card.Header>
          <div className="text-start d-flex justify-content-start m-3 w-100">
            <h4>Profile</h4>
          </div>
        </Card.Header>
        <Card.Body>
          <Table
            responsive
            className="table table-borderless table-nowrap mb-0"
          >
            <tbody>
              <tr>
                <td>Email</td>
                <td>smith@kpmg.com</td>
                <td>
                  <i className="mdi mdi-pencil widget-icon rounded-circle"></i>
                </td>
              </tr>
              <tr>
                <td>Password</td>
                <td>*********</td>
                <td>
                  <i className="mdi mdi-pencil widget-icon rounded-circle"></i>
                </td>
              </tr>
              <tr>
                <td>Role</td>
                <td>Administrator</td>
                <td>
                  <i className="mdi mdi-pencil widget-icon rounded-circle"></i>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-start w-100 m-3">
            <h4>Two Step Authentication</h4>
            <p className="text-muted fs-8 mt-2">
              Keep your account extra secure with a second authentication step.
            </p>
          </div>
        </Card.Header>
        <CardBody>
          <Button value="outline-info">
            <FontAwesomeIcon icon={"check"} /> Add Authentication Step
          </Button>
          <div className="d-flex align-items-center border border-light rounded p-2 mb-2 mt-2">
            <div className="flex-grow-1">
              <h5 className="fw-semibold m-2">SMS</h5>
              <p className="m-2">+61 412 345 678</p>
            </div>
            <div className="flex-shrink-0 me-3">
              <Button className="phoenix-secondary p-2">
                <FontAwesomeIcon icon={"pencil"} />
              </Button>
            </div>
            <div className="flex-shrink-0 me-3">
              <Button className="phoenix-secondary p-2">
                <FontAwesomeIcon icon={"trash"} />
              </Button>
            </div>
          </div>
        </CardBody>
        <Card.Footer>
          <p className="fs-8">
            If you lose your mobile device or security key, you can{" "}
            <strong className="text-primary">generate a backup code</strong> to
            sign in to your account.
          </p>
        </Card.Footer>
      </Card>
      <Card>
        <Card.Body>
          <div>
            <h4 className="mb-1">Email Notifications</h4>
            <p className="fs-8 text-muted">
              Choose what messages you'd like to receive for each of your
              accounts.
            </p>
          </div>
          <ListGroup variant="flush">
            {roles.map((item, index) => {
              return (
                <ListGroup.Item>
                  <Form.Check
                    size={100}
                    label={
                      <div>
                        <h5 className="m-1">{item.role}</h5>
                        <p className="fs-8 m-1">{item.description}</p>
                      </div>
                    }
                    type="checkbox"
                    name="formHorizontalRadios"
                    id={"formHorizontalRadios" + index}
                  ></Form.Check>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
          <div className="d-flex justify-content-end mt-2">
            <Button variant="phoenix-secondary" className="me-2">
              Cancel
            </Button>
            <Button variant="primary" className="me-2">
              Save
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Security;
