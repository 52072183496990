import React from "react";
import { Button, Card } from "react-bootstrap";
import { loginSession, logs } from "./data";

const AuditLogs = () => {
    return (
        <div id="auditLogs">
            <Card>
                <Card.Header>
                    <Card.Title className="fs-7">Login Sessions</Card.Title>
                    <div className="card-toolbar">
                        <div className="my-1 me-4">
                            <select className="form-select form-select-sm form-select-solid w-125px" data-control="select2" data-placeholder="Select Hours" data-hide-search="true">
                                <option value="1">1 Hours</option>
                                <option value="2">6 Hours</option>
                                <option value="3">12 Hours</option>
                                <option value="4">24 Hours</option>
                            </select>
                        </div>
                        <a href="#" className="btn btn-sm btn-primary my-1">View All</a>
                    </div>
                </Card.Header>
                <Card.Body className="p-0">
                    <div className="table-responsive">
                        <table className="table align-middle table-row-bordered table-row-solid gy-4 gs-9">
                            <thead className="border-gray-200 fs-8 fw-semibold bg-lighten">
                                <tr>
                                    <th className="min-w-250px ps-5">Location</th>
                                    <th className="min-w-100px">Status</th>
                                    <th className="min-w-150px">Device</th>
                                    <th className="min-w-150px">IP Address</th>
                                    <th className="min-w-150px">Time</th>
                                </tr>
                            </thead>
                            <tbody className="fw-6 fw-semibold text-gray-600">
                                {loginSession.map((session, idx) => {
                                    let bgColor = 'success';
                                    switch (session.status) {
                                        case "ERR":
                                            bgColor = 'danger';
                                            break;
                                        case "WRN":
                                            bgColor = 'warning';
                                            break;
                                        default:
                                            break;
                                    }

                                    return (<tr key={`log_session_${idx}`}>
                                        <td className="ps-5">
                                            <a href="#" className="text-hover-primary text-gray-600">{session.location}</a>
                                        </td>
                                        <td>
                                            <span className={`badge badge-light-${bgColor} fs-9 fw-bold`}>{session.status}</span>
                                        </td>
                                        <td>{session.device}</td>
                                        <td>{session.ip_address}</td>
                                        <td>{session.time}</td>
                                    </tr>);
                                })}
                            </tbody>
                        </table>
                    </div>
                </Card.Body>
            </Card>
            <Card>
                <Card.Header>
                    <Card.Title className="fs-7">Logs</Card.Title>
                    <div className="card-toolbar">
                        <Button variant="soft-primary" size="sm" className="d-flex align-items-center">
                            <i className="ki-duotone ki-cloud-download fs-7 me-1">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </i>
                            Download Report
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body className="p-0">
                    <div className="table-responsive">
                        <table className="table align-middle table-row-dashed fw-semibold text-gray-600 fs-8 gy-5" id="kt_table_customers_logs">
                            <tbody>
                                {logs.map((log, idx) => {
                                    let bgColor = 'success';
                                    switch (log.status.split(' ')[1]) {
                                        case "ERR":
                                            bgColor = 'danger';
                                            break;
                                        case "WRN":
                                            bgColor = 'warning';
                                            break;
                                        default:
                                            break;
                                    }

                                    return (<tr key={`log_${idx}`}>
                                        <td className="min-w-70px ps-5">
                                            <div className={`badge badge-light-${bgColor}`}>{log.status}</div>
                                        </td>
                                        <td>{log.action}</td>
                                        <td className="pe-5 text-end min-w-200px">{log.time}</td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default AuditLogs;