import {
  UilBooks,
  UilInvoice,
  UilRefresh,
  UilUsersAlt
} from '@iconscout/react-unicons';

export interface ProjectManagementStat {
  title: string;
  count: string;
  icon: JSX.Element;
  subtitle: string;
}

export const stats: ProjectManagementStat[] = [
  {
    title: 'Journeys',
    count: '32',
    icon: <UilBooks className="text-primary-500" size={40} />,
    subtitle: 'Journey Mapper'
  },
  {
    title: 'Queries',
    icon: <UilUsersAlt className="text-success-500" size={40} />,
    count: '94',
    subtitle: 'Knowledge Base'
  },
  {
    title: 'Scans',
    icon: <UilInvoice className="text-warning-500" size={40} />,
    count: '23',
    subtitle: 'Web Crawlers'
  },
  {
    title: 'Agents',
    icon: <UilRefresh className="text-danger-500" size={40} />,
    count: '3',
    subtitle: 'RPAs'
  }
];
