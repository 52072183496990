import Button from 'components/base/Button';
import NarrowDown from 'components/icons/NarrowDown';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useChatContext } from 'providers/ChatProvider';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import SearchResult from 'components/common/SearchResult';
import { EventKey } from 'picmo';
import ChatSearchResult from './ChatSearchResult';
import CloseIcon from 'components/icons/CloseIcon';
import { RootState } from 'redux/store';

const WidgetHeader = ({ closeModal, msgContent = [], viewMsgDetail }: any) => {

  const { setFeedBackModalState, setFeedBackModalStep, setLanguage, setMenuOption, menuOption, language} = useChatContext();
  const { user } = useSelector((state: RootState) => state.Auth);

  React.useEffect(() => {
    if(navigator.language) {
      setLanguage(navigator.language.split('-')[1]);
    }
  }, [])
  
  const handleClose = () => {
    closeModal();
  }

  const openLanguageModal = () => {
    setFeedBackModalState(true);
    setFeedBackModalStep('language');
  }

  const openSearchBox = (e: EventKey<any>) => {
    if(e) {
      setMenuOption(e);
    }
  }

  return (
    <div className="card-header d-flex flex-between-center px-4 py-3 border-bottom">
      {menuOption === 'search' && (
        <div className='chat-history-search position-absolute d-flex border-bottom justify-content-center align-items-center px-4 top-0 start-0 w-100 bg-white'>
          <DropdownSearchBox
            className="navbar-top-search-box"
            inputClassName="rounded-pill"
            size="sm"
          >
            <ChatSearchResult messages={msgContent} handleItemClick={viewMsgDetail}/>
          </DropdownSearchBox>
          <div className='position-absolute h-100 me-2 top-0 end-0 d-flex justify-content-center align-items-center'>
            <Button className='p-0' onClick={() => setMenuOption('')}>
              <CloseIcon color="black"/>
            </Button>
          </div>
        </div>
      )}
      <Button className='d-flex p-0 align-items-center' onClick={() => openLanguageModal()}>
         <div className='rounded-pill widget-header-flag' style={{backgroundImage: `url('http://purecatamphetamine.github.io/country-flag-icons/3x2/` + `${language}` + `.svg')`, width: 28, height: 28}}>
         </div>
         <NarrowDown color="black"/>
      </Button>
      <h5 className="mb-0 mt-0 d-flex align-items-center gap-2">
        <span id="organization-name">{user ? user.organization_name : 'Customer Services'}</span>
        <FontAwesomeIcon className="text-success mt-0" icon="circle" size="2xs" />
      </h5>
      <div className="btn-reveal-trigger d-flex align-items-center ps-4">
        <Dropdown
          align="end"
          id="dropdown-menu-align-right"
          onSelect={openSearchBox}
        >
          <Dropdown.Toggle as='a' className='cursor-pointer'>
            <FontAwesomeIcon icon="ellipsis" size="lg" className="text-black" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item eventKey="1">Request a callback</Dropdown.Item>
            <Dropdown.Item eventKey="search">Search in chat</Dropdown.Item>
            <Dropdown.Item eventKey="3">Show history</Dropdown.Item>
            <Dropdown.Item eventKey="report">Contact Service</Dropdown.Item>
            <Dropdown.Item eventKey="close">Close support</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

export default WidgetHeader;