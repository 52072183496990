import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown, faLocationDot, faUserCircle, faCopy, faKey, faLock, faEllipsisV } from "@fortawesome/free-solid-svg-icons";

const ConnectedUrl = () => {
    return (
        <div className="card mb-3 mb-xl-4">
            <div className="card-header mt-2">
                <div className="card-title flex-column">
                    <h3 className="fw-bold mb-1 fs-7">Connected URL(s)</h3>
                </div>
                <p className="fs-8 fw-semibold text-gray-600 py-2 m-0">Connected URLs serve as gateways to the services associated with our knowledge base, allowing for the deployment of bots across various platforms. Use these URLs to embed bots on your website, social media pages, partner sites, or intranets, enhancing user interaction and support wherever needed.
                </p>
                <table className="table align-middle table-row-bordered table-row-solid gy-2 gs-4 mb-4" id="kt_api_keys_table">
                    <thead className="border-gray-200 fs-8 fw-semibold bg-lighten">
                        <tr>
                            <th className="min-w-175px ps-4">Connected URLs</th>
                            <th className="min-w-250px px-0">URLs</th>
                            <th className="min-w-100px">Date</th>
                            <th className="min-w-100px">Status</th>
                            <th className="min-w-100px">Copy</th>
                            <th className="w-100px">Control</th>
                            <th className="w-100px">Edit</th>
                        </tr>
                    </thead>
                    <tbody className="fs-8 fw-semibold text-gray-600">
                        <tr>
                            <td className="ps-4">Housing Repairs</td>
                            <td data-bs-target="license" className="ps-0">https://thamesbridge.gov.uk/repairs</td>
                            <td>DD.MM.YYYY</td>
                            <td>
                                <span className="badge badge-light-success fs-9 fw-semibold">Active</span>
                            </td>
                            <td>
                                <button data-action="copy" className="btn btn-active-color-primary btn-color-gray-400 btn-icon btn-sm btn-outline-light">
                                    <FontAwesomeIcon className="text-muted" icon={faCopy} />
                                </button>
                            </td>
                            <td>
                                <button data-action="copy" className="btn btn-color-gray-400 btn-active-color-primary btn-icon btn-sm btn-outline-light">
                                    <FontAwesomeIcon className="text-muted" icon={faKey} />
                                </button>
                            </td>
                            <td>
                                <button data-action="copy" className="btn btn-color-gray-400 btn-active-color-primary btn-icon btn-sm btn-outline-light">
                                    <FontAwesomeIcon className="text-muted" icon={faEllipsisV} size="xl" />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td className="ps-4">Damp, mould and condensation</td>
                            <td data-bs-target="license" className="ps-0">https://thamesbridge.gov.uk/damp-and-mould</td>
                            <td>DD.MM.YYYY</td>
                            <td>
                                <span className="badge badge-light-primary fs-9 fw-semibold">Updated</span>
                            </td>
                            <td>
                                <button data-action="copy" className="btn btn-active-color-primary btn-color-gray-400 btn-icon btn-sm btn-outline-light">
                                    <FontAwesomeIcon className="text-muted" icon={faCopy} />
                                </button>
                            </td>
                            <td>
                                <button data-action="copy" className="btn btn-color-gray-400 btn-active-color-primary btn-icon btn-sm btn-outline-light">
                                    <FontAwesomeIcon className="text-muted" icon={faKey} />
                                </button>
                            </td>
                            <td>
                                <button data-action="copy" className="btn btn-color-gray-400 btn-active-color-primary btn-icon btn-sm btn-outline-light">
                                    <FontAwesomeIcon className="text-muted" icon={faEllipsisV} size="xl" />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td className="ps-4">DIY repairs videos</td>
                            <td data-bs-target="license" className="ps-0">https://thamesbridge.gov.uk/diy-repairs</td>
                            <td>DD.MM.YYYY</td>
                            <td>
                                <span className="badge badge-light-danger fs-9 fw-semibold">Inactive</span>
                            </td>
                            <td>
                                <button data-action="copy" className="btn btn-active-color-primary btn-color-gray-400 btn-icon btn-sm btn-outline-light">
                                    <FontAwesomeIcon className="text-muted" icon={faCopy} />
                                </button>
                            </td>
                            <td>
                                <button data-action="copy" className="btn btn-color-gray-400 btn-active-color-primary btn-icon btn-sm btn-outline-light">
                                    <FontAwesomeIcon className="text-muted" icon={faLock} />
                                </button>
                            </td>
                            <td>
                                <button data-action="copy" className="btn btn-color-gray-400 btn-active-color-primary btn-icon btn-sm btn-outline-light">
                                    <FontAwesomeIcon className="text-muted" icon={faEllipsisV} size="xl" />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td className="ps-4">Planned maintenance works programme</td>
                            <td data-bs-target="license" className="ps-0">https://thamesbridge.gov.uk/planned-works</td>
                            <td>DD.MM.YYYY</td>
                            <td>
                                <span className="badge badge-light-success fs-9 fw-semibold">Active</span>
                            </td>
                            <td>
                                <button data-action="copy" className="btn btn-active-color-primary btn-color-gray-400 btn-icon btn-sm btn-outline-light">
                                    <FontAwesomeIcon className="text-muted" icon={faCopy} />
                                </button>
                            </td>
                            <td>
                                <button data-action="copy" className="btn btn-color-gray-400 btn-active-color-primary btn-icon btn-sm btn-outline-light">
                                    <FontAwesomeIcon className="text-muted" icon={faLock} />
                                </button>
                            </td>
                            <td>
                                <button data-action="copy" className="btn btn-color-gray-400 btn-active-color-primary btn-icon btn-sm btn-outline-light">
                                    <FontAwesomeIcon className="text-muted" icon={faEllipsisV} size="xl" />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ConnectedUrl;