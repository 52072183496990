import config from "hyper_config";

export interface LanguageOption {
  CountryCode: string,
  LanguageCode: string,
  LanguageName: string
}
export const supportedLanguages: LanguageOption[] = [
  {CountryCode: 'US', LanguageCode: 'en', LanguageName: 'US English'},
  {CountryCode: 'GB', LanguageCode: 'en', LanguageName: 'UK English'},
  {CountryCode: 'FR', LanguageCode: 'fr', LanguageName: 'French'},
  {CountryCode: 'DE', LanguageCode: 'de', LanguageName: 'German'},
  {CountryCode: 'IT', LanguageCode: 'it', LanguageName: 'Italian'},
  {CountryCode: 'ES', LanguageCode: 'es', LanguageName: 'Spanish'},
  {CountryCode: 'CN', LanguageCode: 'zh', LanguageName: 'Chinese'},
  {CountryCode: 'JP', LanguageCode: 'ja', LanguageName: 'Japanese'},
  {CountryCode: 'RU', LanguageCode: 'ru', LanguageName: 'Russian'},
  {CountryCode: 'BR', LanguageCode: 'pt', LanguageName: 'Portuguese'},
  {CountryCode: 'NL', LanguageCode: 'nl', LanguageName: 'Dutch'},
  {CountryCode: 'KR', LanguageCode: 'ko', LanguageName: 'Korean'},
  {CountryCode: 'TR', LanguageCode: 'tr', LanguageName: 'Turkish'},
  {CountryCode: 'AR', LanguageCode: 'ar', LanguageName: 'Arabic'},
  {CountryCode: 'IL', LanguageCode: 'he', LanguageName: 'Hebrew'},
  {CountryCode: 'SE', LanguageCode: 'sv', LanguageName: 'Swedish'},
  {CountryCode: 'NO', LanguageCode: 'no', LanguageName: 'Norwegian'},
  {CountryCode: 'PL', LanguageCode: 'pl', LanguageName: 'Polish'},
  {CountryCode: 'RO', LanguageCode: 'ro', LanguageName: 'Romanian'},
  {CountryCode: 'AU', LanguageCode: 'en', LanguageName: 'English'},
  {CountryCode: 'CA', LanguageCode: 'en', LanguageName: 'English'},
  {CountryCode: 'MX', LanguageCode: 'es', LanguageName: 'Spanish'},  //Mexico
  {CountryCode: 'ZA', LanguageCode: 'af', LanguageName: 'Afrikaans'},  //South Africa
  {CountryCode: 'AE', LanguageCode: 'ar', LanguageName: 'Arabic'},  //United Arab Emirates
  {CountryCode: 'AM', LanguageCode: 'hy', LanguageName: 'Armenian'},   //Armenia
  {CountryCode: 'AZ', LanguageCode: 'az', LanguageName: 'Azerbaijani'},  // Azerbaijan
  {CountryCode: 'BY', LanguageCode: 'be', LanguageName: 'Belarusian'},  // Belarus
  {CountryCode: 'BA', LanguageCode: 'bs', LanguageName: 'Bosnian'},  // Bosnia and Herzegovina
  {CountryCode: 'BG', LanguageCode: 'bg', LanguageName: 'Bulgarian'},  // Bulgaria
  {CountryCode: 'AD', LanguageCode: 'ca', LanguageName: 'Catalan'},  // Andorra
  {CountryCode: 'HR', LanguageCode: 'hr', LanguageName: 'Croatian'},  // Croatia
  {CountryCode: 'CZ', LanguageCode: 'cs', LanguageName: 'Czech'},  // Czech Republic
  {CountryCode: 'DK', LanguageCode: 'da', LanguageName: 'Danish'},  // Denmark
  {CountryCode: 'EE', LanguageCode: 'et', LanguageName: 'Estonian'},  // Estonia
  {CountryCode: 'FI', LanguageCode: 'fi', LanguageName: 'Finnish'},  // Finland
  {CountryCode: 'GL', LanguageCode: 'gl', LanguageName: 'Galician'},  // Greenland
  {CountryCode: 'GR', LanguageCode: 'el', LanguageName: 'Greek'},  // Greece
  {CountryCode: 'IL', LanguageCode: 'he', LanguageName: 'Hebrew'},  // Israel
  {CountryCode: 'IS', LanguageCode: 'is', LanguageName: 'Icelandic'},  // Iceland
  {CountryCode: 'ID', LanguageCode: 'id', LanguageName: 'Indonesian'},  // Indonesia
  {CountryCode: 'KZ', LanguageCode: 'kk', LanguageName: 'Kazakh'},  // Kazakhstan
  {CountryCode: 'LV', LanguageCode: 'lv', LanguageName: 'Latvian'},  // Latvia
  {CountryCode: 'LT', LanguageCode: 'lt', LanguageName: 'Lithuanian'},  // Lithuania
  {CountryCode: 'MK', LanguageCode: 'mk', LanguageName: 'Macedonian'},  // North Macedonia
  {CountryCode: 'MY', LanguageCode: 'ms', LanguageName: 'Malay'},  // Malaysia
  {CountryCode: 'NZ', LanguageCode: 'mi', LanguageName: 'Māori'},  // New Zealand
  {CountryCode: 'NP', LanguageCode: 'ne', LanguageName: 'Nepali'},  // Nepal
  {CountryCode: 'IR', LanguageCode: 'fa', LanguageName: 'Persian'},  // Iran
  {CountryCode: 'RS', LanguageCode: 'sr', LanguageName: 'Serbian'},  // Serbia
  {CountryCode: 'SK', LanguageCode: 'sk', LanguageName: 'Slovak'},  // Slovakia
  {CountryCode: 'SI', LanguageCode: 'sl', LanguageName: 'Slovenian'},  // Slovenia
  {CountryCode: 'KE', LanguageCode: 'sw', LanguageName: 'Swahili'},  // Kenya
  {CountryCode: 'PH', LanguageCode: 'tl', LanguageName: 'Tagalog'},  // Philippines
  {CountryCode: 'LK', LanguageCode: 'ta', LanguageName: 'Tamil'},  // Sri Lanka
  {CountryCode: 'TH', LanguageCode: 'th', LanguageName: 'Thai'},  // Thailand
  {CountryCode: 'UA', LanguageCode: 'uk', LanguageName: 'Ukrainian'},  // Ukraine
  {CountryCode: 'PK', LanguageCode: 'ur', LanguageName: 'Urdu'},  // Pakistan
  {CountryCode: 'VN', LanguageCode: 'vi', LanguageName: 'Vietnamese'},  // Vietnam
  {CountryCode: 'WLS',LanguageCode: 'cy', LanguageName: 'Welsh'},  // Wales
  {CountryCode: 'HU', LanguageCode: 'hu', LanguageName: 'Hungarian'},  // Hungary
  {CountryCode: 'IN', LanguageCode: 'hi', LanguageName: 'Hindi'},  // India
  {CountryCode: 'IN', LanguageCode: 'kn', LanguageName: 'Kannada'},  // India
  {CountryCode: 'IN', LanguageCode: 'mr', LanguageName: 'Marathi'}, 
];

export interface CountryOption {
  CountryCode: string;
  CountryName: string;
}
export const countryList: CountryOption[] = [
  {CountryCode: 'US', CountryName: 'USA'},
  {CountryCode: 'GB', CountryName: 'United Kingdom'},
  {CountryCode: 'FR', CountryName: 'France'},
  {CountryCode: 'DE', CountryName: 'Germany'},
  {CountryCode: 'IT', CountryName: 'Italy'},
  {CountryCode: 'ES', CountryName: 'Spain'},
  {CountryCode: 'CN', CountryName: 'China'},
  {CountryCode: 'JP', CountryName: 'Japan'},
  {CountryCode: 'RU', CountryName: 'Russia'},
  {CountryCode: 'BR', CountryName: 'Portugal'},
  {CountryCode: 'NL', CountryName: 'Netherland'},
  {CountryCode: 'KR', CountryName: 'Korea'},
  {CountryCode: 'TR', CountryName: 'Turkey'},
  {CountryCode: 'AR', CountryName: 'Arabic'},
  {CountryCode: 'IL', CountryName: 'Hebrew'},
  {CountryCode: 'SE', CountryName: 'Sweden'},
  {CountryCode: 'NO', CountryName: 'Norway'},
  {CountryCode: 'PL', CountryName: 'Poland'},
  {CountryCode: 'RO', CountryName: 'Romania'},
  {CountryCode: 'AU', CountryName: 'Australia'},
  {CountryCode: 'CA', CountryName: 'Canada'},
  {CountryCode: 'MX', CountryName: 'Mexico'},  //Mexico
  {CountryCode: 'ZA', CountryName: 'South Africa'},  //South Africa
  {CountryCode: 'AE', CountryName: 'United Arab Emirates'},  //United Arab Emirates
  {CountryCode: 'AM', CountryName: 'Armenia'},   //Armenia
  {CountryCode: 'AZ', CountryName: 'Azerbaijan'},  // Azerbaijan
  {CountryCode: 'BY', CountryName: 'Belarus'},  // Belarus
  {CountryCode: 'BA', CountryName: 'Bosnia and Herzegovina'},  // Bosnia and Herzegovina
  {CountryCode: 'BG', CountryName: 'Bulgaria'},  // Bulgaria
  {CountryCode: 'AD', CountryName: 'Andorra'},  // Andorra
  {CountryCode: 'HR', CountryName: 'Croatia'},  // Croatia
  {CountryCode: 'CZ', CountryName: 'Czech Republic'},  // Czech Republic
  {CountryCode: 'DK', CountryName: 'Denmark'},  // Denmark
  {CountryCode: 'EE', CountryName: 'Estonia'},  // Estonia
  {CountryCode: 'FI', CountryName: 'Finland'},  // Finland
  {CountryCode: 'GL', CountryName: 'Greenland'},  // Greenland
  {CountryCode: 'GR', CountryName: 'Greece'},  // Greece
  {CountryCode: 'IL', CountryName: 'Israel'},  // Israel
  {CountryCode: 'IS', CountryName: 'Iceland'},  // Iceland
  {CountryCode: 'ID', CountryName: 'Indonesia'},  // Indonesia
  {CountryCode: 'KZ', CountryName: 'Kazakhstan'},  // Kazakhstan
  {CountryCode: 'LV', CountryName: 'Latvia'},  // Latvia
  {CountryCode: 'LT', CountryName: 'Lithuania'},  // Lithuania
  {CountryCode: 'MK', CountryName: 'North Macedonia'},  // North Macedonia
  {CountryCode: 'MY', CountryName: 'Malaysia'},  // Malaysia
  {CountryCode: 'NZ', CountryName: 'New Zealand'},  // New Zealand
  {CountryCode: 'NP', CountryName: 'Nepal'},  // Nepal
  {CountryCode: 'IR', CountryName: 'Iran'},  // Iran
  {CountryCode: 'RS', CountryName: 'Serbia'},  // Serbia
  {CountryCode: 'SK', CountryName: 'Slovakia'},  // Slovakia
  {CountryCode: 'SI', CountryName: 'Slovenia'},  // Slovenia
  {CountryCode: 'KE', CountryName: 'Kenya'},  // Kenya
  {CountryCode: 'PH', CountryName: 'Philippines'},  // Philippines
  {CountryCode: 'LK', CountryName: 'Sri Lanka'},  // Sri Lanka
  {CountryCode: 'TH', CountryName: 'Thailand'},  // Thailand
  {CountryCode: 'UA', CountryName: 'Ukraine'},  // Ukraine
  {CountryCode: 'PK', CountryName: 'Pakistan'},  // Pakistan
  {CountryCode: 'VN', CountryName: 'Vietnam'},  // Vietnam
  {CountryCode: 'HU', CountryName: 'Hungary'},  // Hungary
  {CountryCode: 'IN', CountryName: 'India'},  // India
]

export const AvatarUrl = `${config.API_URL}/rsc/avatar/`;