import React, { useRef, useState } from 'react';
import ReactQuill from 'react-quill';

const IntroMsg = ({
  id,
  value,
  setValue
} : {
  id: string,
  value: string,
  setValue: (txt: string) => void
}) => {
  const quillRef = useRef<ReactQuill>(null);

  const handleConvert = (txt: string) => {
    const res = txt.replace(/\[Council name\]/g, "<span style='color: blue'>[Council name]</span>")
                    .replace(/\[Bot name\]/g, "<span style='color: blue'>[Bot name]</span>")
                    .replace(/\[Entity name\]/g, "<span style='color: blue'>[Entity name]</span>");
    return res;
  }

  const handleChange = (content: any, delta: any, source: any, editor: any) => {
    setValue(content);
  };

  const handleBlur = () => {
    const tmp = handleConvert(value);
    setValue(tmp);
  }

  const handleFocus = () => {
    const tmp = value.replace(/<span.*?>/g, '').replace(/<\/span>/g, '');
    setValue(tmp);
  }

  const modules = React.useMemo(
    () => ({
        toolbar: false,
    }),
    []
  );

  const editorKey = React.useMemo(
    () => {
        return new Date().getTime();
    },
    []
  );

  return (
    <ReactQuill
      key={`intro_${editorKey}_${id}`}
      className="form-control outline-none scrollbar resize-none mb-1 p-0 fs-8 fw-normal overflow-visible"
      theme="snow"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      modules={modules}
      ref={quillRef}
    />
  );
}

export default IntroMsg;
