import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";

const Availability = ({
    knowledgeBase
} : {
    knowledgeBase?: any
}) => {
    return (
        <div className="col-lg-5">
            <div className="card card-flush h-lg-100">
                <div className="card-header mt-2">
                    <div className="card-title flex-column">
                        <h3 className="fw-bold mb-1 fs-7">Knowledge Base Availability</h3>
                    </div>
                    <p className="fs-8 fw-semibold text-gray-600 py-2 m-0">
                        Knowledge Base Availability refers to the publication and accessibility of information. When you publish a Knowledge Base, it is in 'Staging' mode, allowing for final reviews. Making the Knowledge Base 'Searchable' transitions it to 'Production', where it becomes a resource for bots to reference and utilize the data effectively.
                    </p>
                    <div className="d-flex flex-wrap fw-semibold fs-8 pe-2">
                        <p className="d-flex align-items-center text-muted me-5">
                            <FontAwesomeIcon className="me-2" icon={faUserCircle} size="sm" />
                            <span className="me-2">Published:</span>
                            <Moment format="DD.MM.YYYY">{knowledgeBase?.PublishDate}</Moment>
                        </p>
                        <p className="d-flex align-items-center text-muted me-5">
                            <FontAwesomeIcon className="me-2" icon={faLocationDot} size="sm" />
                            Searchable: {knowledgeBase?.KnowledgeBaseSearchable ? "Yes" : "No"}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Availability;