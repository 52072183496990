import React from "react";
import { Button, Form } from "react-bootstrap";

const TelephoneNumber = ({
    number
} : {
    number?: string
}) => {
    return (
        <div className="col-lg-7">
            <div className="card card-flush h-lg-100">
                <div className="card-header mt-2">
                    <div className="card-title flex-column">
                        <h3 className="fw-bold mb-1 fs-7">Knowledge Base Telephone Number</h3>
                    </div>
                    <p className="fs-8 fw-semibold text-gray-600 py-2 m-0">Telephone numbers provide a direct line for users who prefer or require voice communication, enabling them to interact with our support systems. These numbers connect callers to your database, allowing for real-time engagement with bots and access to our knowledge base, facilitating on-the-move service access.
                    <br /> </p>
                    <div className="w-100 mb-4">
                        <Form.Control className="w-50 me-3 d-inline-block" type="text" value={number} disabled />
                        <Button variant="outline-primary">Copy Number</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TelephoneNumber;