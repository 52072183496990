import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getFileIcon } from 'helpers/utils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Avatar, { Status } from 'components/base/Avatar';
import { SearchResult as SearchResultType, searchItems } from 'data/search';
import Scrollbar from 'components/base/Scrollbar';
import useSearchHook from 'hooks/useSearchHook';
import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';

const ResultSectionHeader = ({ title }: { title: string }) => {
  return (
    <h6 className="text-1000 fs-9 border-y border-200 py-2 lh-sm mb-0 px-3">
      {title}
    </h6>
  );
};

const searchFields: ColumnDef<SearchResultType>[] = [
  {
    accessorKey: 'label'
  }
];

const ChatSearchResult = ({ searchValue = '', messages = [], handleItemClick }: { searchValue?: string, messages: any[], handleItemClick: (item: any) => void }) => {

  const results = useMemo(
    () => messages.filter(item => item.message.toLowerCase().includes(searchValue)),
    [searchValue]
  );

  return (
    <Scrollbar autoHeight autoHeightMax={'30rem'}>
      <h6 className="text-1000 fs-10 py-2 mb-0 px-3">
        {results.length} <span className="text-500">Results</span>{' '}
      </h6>
      {results.length > 0 && (
        <>
          <div className="py-2">
            {results.map((item, index) => (
              <Dropdown.Item key={index}>
                <div
                  onClick={() => handleItemClick(item)}
                  className="d-flex align-items-center fw-normal gap-1 text-1000">
                  {/* <FontAwesomeIcon
                    icon="clock-rotate-left"
                    transform="shrink-2"
                  /> */}
                  {item.message}
                </div>
              </Dropdown.Item>
            ))}
          </div>
        </>
      )}
    </Scrollbar>
  );
};

export default ChatSearchResult;
